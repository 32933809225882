import { pdf } from '@react-pdf/renderer';

import {
  CompletedProgramAssessmentsDataQuery,
  ProgramAssessmentDataFragment,
} from '../../generated/graphql';

import ProgramAssessmentPDF from '../components/ProgramActivitySlideover/ProgramAssessmentPDF';

import { getTimeStamp } from './time';

export const handlePdfDownload = async (
  blob: Blob | null,
  programAssessment: ProgramAssessmentDataFragment,
  patientName: string,
  onSuccess?: () => void,
) => {
  if (blob) {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;

    const dateCompleted = getTimeStamp(programAssessment?.completedAt, false);
    const title = programAssessment?.assessment?.name;

    const clientName = patientName || 'Client';
    link.download = `${clientName} - ${title} - ${dateCompleted}.pdf`;

    link.click();
    URL.revokeObjectURL(url);
    onSuccess?.();
  }
};

export const downloadAllPdfs = async (
  completedAssessments: CompletedProgramAssessmentsDataQuery['completedProgramAssessmentsByAssessment'],
  patientFirstName?: string,
) => {
  try {
    if (!completedAssessments?.length) {
      throw new Error('No assessments to download');
    }

    // Generate all PDFs in parallel using pdf.createPDF instead of BlobProvider
    const pdfPromises = completedAssessments.map(async (assessment) => {
      try {
        const pdfDocument = (
          <ProgramAssessmentPDF programAssessment={assessment} isProviderUser />
        );

        const blob = await pdf(pdfDocument).toBlob();
        return { blob, assessment };
      } catch (error) {
        console.error(
          'PDF generation error for assessment',
          assessment.id,
          ':',
          error,
        );
        return { blob: null, assessment };
      }
    });

    // Wait for all PDFs to be generated
    const pdfs = await Promise.all(pdfPromises);
    console.log('PDFs generated:', pdfs.length);

    // Download all valid PDFs
    let downloadCount = 0;
    for (const { blob, assessment } of pdfs) {
      if (blob) {
        console.log('Downloading PDF for assessment:', assessment.id);
        await handlePdfDownload(blob, assessment, patientFirstName);
        downloadCount++;
      } else {
        console.warn('No blob generated for assessment:', assessment.id);
      }
    }

    if (downloadCount === 0) {
      throw new Error('No PDFs were generated successfully');
    }

    return downloadCount;
  } catch (error) {
    console.error('PDF download error:', error);
    throw error;
  }
};
