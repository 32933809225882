export const LOGO_CONFIGS = {
  squareLogoMediaUrl: {
    graphqlField: 'squareLogoMedia',
    deleteField: 'deleteSquareLogoMedia',
    successMessage: 'Custom square logo uploaded successfully',
    deleteMessage: 'Square logo restored to Homecoming default',
    altText: 'Square logo',
    description: 'Upload a square logo for your organization.',
  },
  wordmarkLogoMediaUrl: {
    graphqlField: 'wordmarkLogoMedia',
    deleteField: 'deleteWordmarkLogoMedia',
    successMessage: 'Custom wordmark logo uploaded successfully',
    deleteMessage: 'Wordmark logo restored to Homecoming default',
    altText: 'Wordmark logo',
    description:
      'Add your wordmark (logo + name) to display at the top of client emails.',
  },
  emailImageMediaUrl: {
    graphqlField: 'emailImageMedia',
    deleteField: 'deleteEmailImageMedia',
    successMessage: 'Custom email image uploaded successfully',
    deleteMessage: 'Email image restored to Homecoming default',
    altText: 'Email image',
    description: 'Choose an image to include in your emails.',
  },
} as const;

export const DEFAULT_COLORS = {
  PRIMARY: '#394642',
  SECONDARY: '#00B20A',
} as const;
