import { useEffect, useState } from 'react';

import { useAuth } from '../../../../contexts/AuthContext';
import Integration from '../../../components/Integration';
import PageContainer from '../../../components/Containers/PageContainer';
import StripeLogo from '../../../svgs/StripeLogo';
import Config from '../../../../lib/config';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  ProviderAnalyticsEvent,
  trackProviderEvent,
} from '../../../../lib/analytics';
import { useRemoveStripeIntegrationMutation } from '../../../../generated/graphql';
import { PARAM_CONNECT_ERROR, PARAM_CONNECT_SUCCESS } from './Callback';
import { isProviderUserOwnerOrAdminAuthorized } from '../../../../lib/auth';

const STRIPE_AUTHORIZATION_URL = 'https://connect.stripe.com/oauth/authorize';
const STRIPE_REDIRECT_URI = `${window.location.origin}/integrations/stripe/callback`;

const StripeIntegration: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [isIntegrationLoading, setIsIntegrationLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const connectSuccess = searchParams.get(PARAM_CONNECT_SUCCESS);
  const connectError = searchParams.get(PARAM_CONNECT_ERROR);

  const [removeStripeIntegrationMutation] =
    useRemoveStripeIntegrationMutation();

  const { authedProviderUser, refreshAuthedProviderUser } = useAuth();
  const isConnected = Boolean(authedProviderUser?.provider.stripeAccountId);

  useEffect(() => {
    // TODO: Replace with some global permission route handling
    if (
      authedProviderUser &&
      !isProviderUserOwnerOrAdminAuthorized(authedProviderUser)
    ) {
      navigate('/');
    }
  }, [authedProviderUser]);

  const clearSearchParams = () =>
    window.history.replaceState({}, document.title, window.location.pathname);

  const onPressConnectStripe = async () => {
    trackProviderEvent(ProviderAnalyticsEvent.AddStripeIntegrationAttempted);

    const args = new URLSearchParams({
      state: authedProviderUser?.id ?? '',
      client_id: Config.REACT_APP_STRIPE_CLIENT_ID,
      scope: 'read_write',
      response_type: 'code',
      redirect_uri: STRIPE_REDIRECT_URI,
    });

    // Redirect to Stripe authorization page
    window.location.href = `${STRIPE_AUTHORIZATION_URL}?${args.toString()}`;
  };

  const onPressDisconnectStripe = async () => {
    setIsIntegrationLoading(true);
    setSuccessMessage(null);
    setErrorMessage(null);

    try {
      await removeStripeIntegrationMutation();
      // Refetch authedProviderUser so it has the new integration data
      await refreshAuthedProviderUser();
      setSuccessMessage(
        `Your Stripe account is now disconnected from Homecoming. If you’d like to reconnect Stripe, you can do so at any time.`,
      );
    } catch (error) {
      setErrorMessage((error as Error).message);
    } finally {
      setIsIntegrationLoading(false);
    }
  };

  useEffect(() => {
    if (connectSuccess) {
      refreshAuthedProviderUser();
      setSuccessMessage(`Your Stripe account is connected to Homecoming. You 
      will now be able to send invoices to all of your clients and keep track as 
      you get paid. Checkout <a href="/payments">Payments</a> to get started.`);
      clearSearchParams();
    } else if (connectError) {
      setErrorMessage(decodeURIComponent(connectError));
      setSuccessMessage(null);
      clearSearchParams();
    }
  }, [connectSuccess, connectError]);

  return (
    <PageContainer containerClassName="min-h-[calc(100vh-200px)] flex text-neutral-125 items-center">
      <Integration
        name="Stripe"
        logo={<StripeLogo className="h-24 w-24" />}
        isConnected={isConnected}
        isLoading={isIntegrationLoading}
        onPressConnect={onPressConnectStripe}
        onPressDisconnect={onPressDisconnectStripe}
        successMessage={successMessage}
        dismissSuccessMessage={() => setSuccessMessage(null)}
        errorMessage={errorMessage}
        dismissErrorMessage={() => setErrorMessage(null)}
        accountUrl="https://dashboard.stripe.com/dashboard"
        supportUrl="https://www.notion.so/homecoming-health/Stripe-FAQ-b1d4003c925740e2b23b340b09e9c38d"
        pricing="Free with transaction fees"
        description={
          <>
            <a
              className="text-blue-100 hover:text-blue-125"
              href="https://stripe.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Stripe
            </a>{' '}
            is a full online payment infrastructure that makes it easy to start
            accepting payments online.
          </>
        }
        features={[
          {
            title: 'Invoice your clients',
            description:
              'Send clients invoices for services rendered, flexibly charging your desired rate.',
          },
          {
            title: 'Keep track of sent invoices',
            description:
              'Be notified as clients complete sent invoices. Keep tabs on invoices that are overdue.',
          },
          {
            title: 'Remind clients of overdue invoices',
            description:
              'Simply click a button, and we’ll take care of reminding clients of overdue invoices.',
          },
        ]}
        disconnectConfirmText="You will lose access to your entire invoice history."
      />
    </PageContainer>
  );
};

export default StripeIntegration;
