import classNames from 'classnames';
import { FC, useRef, useState } from 'react';

import {
  ActivityDataFragment,
  useAddActivityToProviderCollectionMutation,
  useRemoveActivityFromProviderCollectionMutation,
} from '../../../../generated/graphql';
import {
  AnalyticsComponent,
  AnalyticsPage,
  CommonAnalyticsEvent,
  trackProviderEvent,
} from '../../../../lib/analytics';

import { RefetchProviderActivities } from '../../../types/activity';
import {
  ProviderCollection,
  ProviderCollections,
} from '../../../types/providerCollection';
import { ColumnHeaderType, ColumnOrder } from '../../../types/tables';

import {
  ProviderActivityObject,
  getProviderActivitiesObject,
  getProviderActivityData,
} from '../../../lib/providerActivity';

import CloseX from '../../../svgs/CloseX';
import IllustrationBook from '../../../svgs/IllustrationBook';

import Button from '../../../components/Button';
import TableHeader from '../../../components/TableHeader';

import {
  PROVIDER_ACTIVITY_ORDERS,
  columnHeaders,
  sortOptions,
} from '../helpers';

import { ApolloError } from '@apollo/client';
import {
  ChevronRightIcon,
  CollectionIcon,
  DotsHorizontalIcon,
  HomeIcon,
} from '@heroicons/react/outline';
import toast from 'react-hot-toast';
import { HomecomingError, matchHomecomingError } from '../../../../lib/errors';
import { ContextMenuPosition } from '../../../types/contextMenu';
import { AddToLibraryMenuProps } from '../../AddToLibraryMenu';
import ToastAlert from '../../ToastAlert';
import ActivityActionsMenu from './ActivityActionsMenu';
import LibraryTableRow from './LibraryTableRow';
import ProviderCollectionTableRow from './LibraryTableRow/ProviderCollectionTableRow';
import ProviderCollectionMenu from './ProviderCollectionMenu';
import { ContentType } from '../../../lib/followUp';

const LibraryTable: FC<{
  activityOrder: ColumnOrder;
  hasEmptyProviderCollection: boolean;
  providerActivities: ActivityDataFragment[] | undefined;
  selectedProviderCollection: ProviderCollection | undefined;
  setActivityOrder: React.Dispatch<React.SetStateAction<ColumnOrder>>;
  setSelectedProviderActivity: React.Dispatch<
    React.SetStateAction<ActivityDataFragment | undefined>
  >;
  setSelectedProviderCollection: React.Dispatch<
    React.SetStateAction<ProviderCollection | undefined>
  >;
  refetchProviderActivities: RefetchProviderActivities;
  clearSearchAndFilters: () => void;
  showEmptyTableFilterState: boolean;
  allProviderCollections: ProviderCollections | undefined;
  sortedProviderCollections: ProviderCollections | undefined;
  duplicateActivity?: () => void;
  renameProviderCollection?: () => void;
  setIsConfirmDeleteCollectionModalOpen?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsConfirmArchiveActivityModalOpen?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsRenameActivityModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFileSettingsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsProviderActivitySlideoverOpen?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  createProviderCollection?: (
    addSelectedProviderActivityToCollection?: boolean,
  ) => void;
  openAddOrEditTextModal?: () => void;
  followUpMode?: boolean;
  restrictSingleSelection?: boolean;
  setSelectedProviderActivitiesObject?: React.Dispatch<
    React.SetStateAction<ProviderActivityObject>
  >;
  selectedProviderActivitiesObject?: ProviderActivityObject;
  selectedProviderActivitiesList?: ActivityDataFragment[];
  selectedProviderActivity?: ActivityDataFragment;
  showActivityActionsMenu?: boolean;
  setShowActivityActionsMenu?: React.Dispatch<React.SetStateAction<boolean>>;
  sendProviderActivitiesInFollowUp?: (
    providerActivities: ActivityDataFragment[],
  ) => void;
  contentTypeFilter: ContentType | null;
  searchTerm: string | null;
  BoundAddToLibrary?: FC<AddToLibraryMenuProps>;
  allProviderActivities: ActivityDataFragment[] | undefined;
}> = ({
  activityOrder,
  hasEmptyProviderCollection,
  providerActivities,
  showEmptyTableFilterState,
  selectedProviderCollection,
  setActivityOrder,
  duplicateActivity,
  renameProviderCollection,
  setSelectedProviderActivity,
  setIsProviderActivitySlideoverOpen,
  setSelectedProviderCollection,
  setIsConfirmArchiveActivityModalOpen,
  setIsRenameActivityModalOpen,
  setIsFileSettingsModalOpen,
  setIsConfirmDeleteCollectionModalOpen,
  clearSearchAndFilters,
  refetchProviderActivities,
  createProviderCollection,
  openAddOrEditTextModal,
  setSelectedProviderActivitiesObject,
  setShowActivityActionsMenu,
  sortedProviderCollections,
  allProviderCollections,
  followUpMode = false,
  restrictSingleSelection = false,
  selectedProviderActivitiesObject,
  selectedProviderActivitiesList,
  selectedProviderActivity,
  showActivityActionsMenu,
  sendProviderActivitiesInFollowUp,
  contentTypeFilter,
  searchTerm,
  BoundAddToLibrary,
  allProviderActivities,
}) => {
  const columns = columnHeaders.activity;

  const [previousColumnClicked, setPreviousColumnClicked] =
    useState<ColumnHeaderType | null>();

  const [activityActionsMenuPosition, setActivityActionsMenuPosition] =
    useState<ContextMenuPosition>({ x: 0, y: 0 });

  const [removeActivityFromProviderCollectionMutation] =
    useRemoveActivityFromProviderCollectionMutation();

  const [addActivityToProviderCollectionMutation] =
    useAddActivityToProviderCollectionMutation();

  const globalCheckboxRef =
    useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleHeaderClick = (column: ColumnHeaderType) => {
    function getNextActivityOrder(
      activityOrder: ColumnOrder | null,
      orderType: 'activity' | 'dateAdded',
    ) {
      const nextOrderIndex =
        PROVIDER_ACTIVITY_ORDERS[orderType].findIndex(
          (order) => order === activityOrder,
        ) + 1;

      if (PROVIDER_ACTIVITY_ORDERS[orderType][nextOrderIndex])
        return PROVIDER_ACTIVITY_ORDERS[orderType][nextOrderIndex];
      return PROVIDER_ACTIVITY_ORDERS[orderType][0];
    }

    if (column.field === 'activity' || column.field === 'dateAdded') {
      const nextActivityOrder = getNextActivityOrder(
        activityOrder,
        column.field,
      );
      setPreviousColumnClicked(column);
      setActivityOrder(nextActivityOrder);
    }
  };

  const emptyTableWithoutSelectedProviderCollection = Boolean(
    showEmptyTableFilterState && !selectedProviderCollection,
  );
  const emptyTableWithSelectedProviderCollection = Boolean(
    showEmptyTableFilterState && selectedProviderCollection,
  );

  const globalCheckboxChecked = Boolean(
    providerActivities?.length &&
      selectedProviderActivitiesList?.length &&
      providerActivities?.length === selectedProviderActivitiesList?.length,
  );

  function toggleAllCheckboxes() {
    setSelectedProviderActivitiesObject?.(
      globalCheckboxChecked
        ? {}
        : getProviderActivitiesObject?.(providerActivities),
    );
  }

  const addActivityToProviderCollection = async (
    providerCollection: ProviderCollection,
    providerActivity: ActivityDataFragment,
  ) => {
    if (providerActivity) {
      const providerActivityData = getProviderActivityData(providerActivity);

      if (
        providerActivityData.activityId &&
        providerCollection.id &&
        providerActivity.activityType
      ) {
        const addActivityToProviderCollectionInput = {
          activityId: providerActivityData.activityId,
          providerCollectionId: providerCollection.id,
          activityType: providerActivity.activityType,
        };

        try {
          const { data } = await addActivityToProviderCollectionMutation({
            variables: {
              input: addActivityToProviderCollectionInput,
            },
          });

          if (data?.addActivityToProviderCollection) {
            toast.custom(({ visible }) => (
              <ToastAlert
                isVisible={visible}
                level="success"
                message={`Added to collection.`}
              />
            ));

            await refetchProviderActivities?.();
          }
        } catch (err) {
          if (
            matchHomecomingError(
              err as ApolloError,
              HomecomingError.ResourceForbidden,
            )
          ) {
            const warningMessage = `Oops! This resource is already in this collection.`;
            toast.custom(({ visible }) => (
              <ToastAlert
                isVisible={visible}
                message={warningMessage}
                level="warning"
              />
            ));
          } else {
            const errorMessage = `Couldn't add ${providerActivityData?.activityTitle} to ${providerCollection.name}. Please try again later.`;

            toast.custom(({ visible }) => (
              <ToastAlert
                isVisible={visible}
                message={errorMessage}
                level="error"
              />
            ));
          }
        } finally {
          trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
            originPage: AnalyticsPage.Library,
            originComponent: AnalyticsComponent.ActivityActionsMenu,
            buttonName: 'Add to a collection',
          });
        }
      }
    }
  };

  const removeActivityFromProviderCollection = async (
    selectedProviderActivity?: ActivityDataFragment,
  ) => {
    if (selectedProviderCollection && selectedProviderActivity) {
      const providerActivity = getProviderActivityData(
        selectedProviderActivity,
      );
      const removeActivityFromProviderCollectionInput = {
        activityId: providerActivity?.activityId ?? '',
        providerCollectionId: selectedProviderCollection?.id,
        activityType: selectedProviderActivity?.activityType,
      };

      try {
        const { data } = await removeActivityFromProviderCollectionMutation({
          variables: {
            input: removeActivityFromProviderCollectionInput,
          },
        });

        if (data?.removeActivityFromProviderCollection) {
          await refetchProviderActivities();
          toast.custom(({ visible }) => (
            <ToastAlert
              isVisible={visible}
              level="success"
              message={`Removed from collection.`}
            />
          ));
        }
      } catch (err) {
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message={`Couldn't remove ${providerActivity?.activityTitle} from ${selectedProviderCollection.name}.`}
            level="warning"
          />
        ));
      }
    }
  };

  return (
    <>
      <div className="h-full w-full overflow-hidden">
        <div className="flex h-full max-h-full w-full flex-col items-stretch justify-start overflow-y-scroll">
          <div
            className={classNames(
              'sticky top-0 z-[9] flex w-full flex-row items-center justify-between bg-white px-4 transition-all duration-150',
              !selectedProviderCollection && 'min-h-0 opacity-0',
              selectedProviderCollection &&
                'top-0 min-h-[57px] border-b border-neutral-75 opacity-100',
            )}
          >
            {selectedProviderCollection && (
              <>
                <div className="flex flex-row items-center text-caption text-neutral-150">
                  <div
                    className="flex cursor-pointer flex-row items-center rounded-md px-3 py-2 hover:bg-neutral-25"
                    onClick={() => setSelectedProviderCollection(undefined)}
                  >
                    <HomeIcon className="mr-2 h-[18px] w-[18px] min-w-[18px] text-secondary-125" />
                    <div className="font-medium line-clamp-2">
                      All resources
                    </div>
                  </div>
                  <div className="mr-1">
                    <ChevronRightIcon className="ml-1 mr-2 h-[18px] w-[18px] text-neutral-150" />
                  </div>
                  <div className="flex flex-row items-center py-2 px-2">
                    <CollectionIcon className="mr-2 h-[18px] w-[18px] min-w-[18px] text-secondary-150" />
                    <div className="font-medium font-medium">
                      {selectedProviderCollection?.name}
                    </div>
                  </div>
                </div>

                {!followUpMode && (
                  <ProviderCollectionMenu
                    onClickRenameCollection={() => {
                      renameProviderCollection?.();
                      trackProviderEvent(CommonAnalyticsEvent.ComponentViewed, {
                        originPage: 'Provider Library',
                        component: `Rename Provider Collection | Provider Library Table`,
                      });
                    }}
                    onClickDeleteCollection={() => {
                      setIsConfirmDeleteCollectionModalOpen?.(true);
                      trackProviderEvent(CommonAnalyticsEvent.ComponentViewed, {
                        originPage: 'Provider Library',
                        component: `Delete Provider Collection | Provider Library Table`,
                      });
                    }}
                    onClickSendToClient={() => {
                      sendProviderActivitiesInFollowUp?.(
                        allProviderActivities?.filter((activity) =>
                          activity.providerCollectionIds.includes(
                            selectedProviderCollection?.id,
                          ),
                        ) ?? [],
                      );
                      trackProviderEvent(CommonAnalyticsEvent.ComponentViewed, {
                        originPage: 'Provider Library',
                        component: `Send Provider Collection | Provider Library Table`,
                      });
                    }}
                  />
                )}
              </>
            )}
          </div>
          {Boolean(
            !showEmptyTableFilterState && !hasEmptyProviderCollection,
          ) && (
            <table className="min-w-full">
              <thead
                className={classNames(
                  'sticky top-0 h-[40px] w-full border-b border-neutral-100 bg-white',
                  {
                    'top-[57px]': selectedProviderCollection,
                  },
                )}
              >
                <tr className="border-b border-neutral-75 bg-white">
                  <th
                    scope="col"
                    className="w-12 bg-white pl-6 pr-4 sm:w-16 sm:px-8"
                  >
                    {!restrictSingleSelection && (
                      <input
                        ref={globalCheckboxRef}
                        checked={globalCheckboxChecked}
                        type="checkbox"
                        className="h-4 w-4 cursor-pointer rounded border-b border-neutral-100 text-secondary-125 focus:ring-secondary-125 sm:left-6"
                        onChange={toggleAllCheckboxes}
                      />
                    )}
                  </th>
                  {columns.map((column: ColumnHeaderType, i: number) => {
                    return (
                      <TableHeader
                        key={`providerActivitiesTableColumnHeader_${column.field}`}
                        className="text-caption"
                        column={column}
                        columnOrder={activityOrder}
                        sortOptions={sortOptions}
                        previousColumnClicked={previousColumnClicked}
                        handleHeaderClick={handleHeaderClick}
                      />
                    );
                  })}
                  <th />
                </tr>
              </thead>
              <tbody className="mt-[40px] overflow-y-scroll">
                {!selectedProviderCollection &&
                  !contentTypeFilter &&
                  sortedProviderCollections?.map((collection, idx) => {
                    return (
                      <ProviderCollectionTableRow
                        key={collection.id}
                        providerCollection={collection}
                        setSelectedProviderCollection={
                          setSelectedProviderCollection
                        }
                        followUpMode={followUpMode}
                        sendProviderActivitiesInFollowUp={
                          sendProviderActivitiesInFollowUp
                        }
                        providerActivitiesInCollection={allProviderActivities?.filter(
                          (activity) =>
                            activity.providerCollectionIds.includes(
                              collection.id,
                            ),
                        )}
                      />
                    );
                  })}
                {providerActivities?.map(
                  (providerActivity, providerActivityIndex) => {
                    const providerActivityId =
                      getProviderActivityData(providerActivity).activityId;

                    const foundCheckedProviderActivity =
                      selectedProviderActivitiesObject?.[providerActivityId];

                    return (
                      <LibraryTableRow
                        key={`activityTableRow_${providerActivityIndex}`}
                        followUpMode={followUpMode}
                        restrictSingleSelection={restrictSingleSelection}
                        providerActivity={providerActivity}
                        setSelectedProviderActivity={
                          setSelectedProviderActivity
                        }
                        setIsProviderActivitySlideoverOpen={
                          setIsProviderActivitySlideoverOpen
                        }
                        openAddOrEditTextModal={openAddOrEditTextModal}
                        isLastProviderActivity={
                          providerActivities.length - 1 ===
                          providerActivityIndex
                        }
                        setSelectedProviderActivitiesObject={
                          setSelectedProviderActivitiesObject
                        }
                        selectedProviderActivitiesObject={
                          selectedProviderActivitiesObject
                        }
                        isChecked={Boolean(foundCheckedProviderActivity)}
                        providerActivityId={providerActivityId}
                        showActivityActionsMenu={showActivityActionsMenu}
                        setShowActivityActionsMenu={setShowActivityActionsMenu}
                        setActivityActionsMenuPosition={
                          setActivityActionsMenuPosition
                        }
                        sendProviderActivitiesInFollowUp={
                          sendProviderActivitiesInFollowUp
                        }
                      />
                    );
                  },
                )}
              </tbody>
            </table>
          )}
          <div
            className={classNames(
              'flex w-full flex-col items-center justify-start transition-all duration-500',
              !emptyTableWithoutSelectedProviderCollection && 'h-0 opacity-0',
            )}
          >
            {emptyTableWithoutSelectedProviderCollection && (
              <>
                <IllustrationBook className="m-3 mt-12 h-[64px] w-[79px] text-primary-125" />
                <h3 className="mt-1 font-sans text-subtitle-small font-medium text-green-150">
                  It's quiet in here...
                </h3>
                <Button
                  theme="secondary"
                  size="small"
                  className="mt-6"
                  title="Clear search and filters"
                  IconComponent={CloseX}
                  iconClassName="text-neutral-125 h-2.5 w-2.5 mt-0.5"
                  onClick={clearSearchAndFilters}
                />
              </>
            )}
          </div>
          {emptyTableWithSelectedProviderCollection && (
            <div className="mt-6 flex w-full flex-col items-center justify-start">
              <CollectionIcon className="mb-1 mt-6 !h-12 w-full text-secondary-125" />
              <h3 className="mt-1 font-sans text-subtitle-small font-medium text-neutral-150">
                {hasEmptyProviderCollection
                  ? 'This collection is empty'
                  : "It's quiet in here..."}
              </h3>
              {hasEmptyProviderCollection && (
                <p className="mt-2 w-96 text-center text-body text-neutral-150">
                  {!followUpMode ? (
                    <span className="inline-block items-center text-neutral-150">
                      Add existing resources by right-clicking or clicking the
                      <DotsHorizontalIcon className="ml-1 inline-block h-4 w-4 text-neutral-150" />{' '}
                      icon on an item in
                      <HomeIcon className="ml-1 mb-0.5 inline-block h-4 w-4 text-secondary-125" />{' '}
                      <span className="text-caption font-medium">
                        All resources
                      </span>
                      , or add a new resource using the button below.
                    </span>
                  ) : (
                    <span>
                      Add resources to this collection in the Library.
                    </span>
                  )}
                </p>
              )}
              {hasEmptyProviderCollection ? (
                <div className="mt-3">
                  {BoundAddToLibrary && (
                    <BoundAddToLibrary
                      title="Add to collection"
                      buttonTheme="secondary"
                      iconPosition="left"
                      buttonSize="small"
                      iconClassName="text-secondary-100"
                    />
                  )}
                </div>
              ) : (
                <Button
                  theme="secondary"
                  size="small"
                  className="mt-6"
                  title="Clear search and filters"
                  IconComponent={CloseX}
                  iconClassName="text-neutral-125 h-2.5 w-2.5 mt-0.5"
                  onClick={clearSearchAndFilters}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {setShowActivityActionsMenu && (
        <ActivityActionsMenu
          setShowActivityActionsMenu={setShowActivityActionsMenu}
          setSelectedProviderActivity={setSelectedProviderActivity}
          isOpen={showActivityActionsMenu}
          setIsOpen={(e) => {
            e.preventDefault();
            if (!followUpMode) {
              if (!showActivityActionsMenu) {
                setActivityActionsMenuPosition({
                  x: e.pageX,
                  y: e.pageY,
                });
              }
              setShowActivityActionsMenu(!showActivityActionsMenu);
            }
          }}
          position={activityActionsMenuPosition}
          providerActivity={selectedProviderActivity}
          providerCollections={allProviderCollections}
          selectedProviderCollection={selectedProviderCollection}
          addActivityToProviderCollection={addActivityToProviderCollection}
          onClickRemoveActivityFromProviderCollection={async () => {
            await removeActivityFromProviderCollection(
              selectedProviderActivity,
            );
            trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
              originPage: AnalyticsPage.Library,
              originComponent: AnalyticsComponent.ActivityActionsMenu,
              buttonName: 'Remove this from collection',
            });
          }}
          onClickAddActivityToProviderCollection={() => {
            createProviderCollection?.(true);
            trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
              originPage: AnalyticsPage.Library,
              originComponent: AnalyticsComponent.ActivityActionsMenu,
              buttonName: 'Add to a collection',
            });
          }}
          onClickDuplicateResource={duplicateActivity}
          onClickRenameResource={() => {
            setIsRenameActivityModalOpen?.(true);
            trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
              originPage: AnalyticsPage.Library,
              originComponent: AnalyticsComponent.ActivityActionsMenu,
              buttonName: 'Rename',
            });
          }}
          onClickModifyFileSettings={() => {
            setIsFileSettingsModalOpen?.(true);
            trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
              originPage: AnalyticsPage.Library,
              originComponent: AnalyticsComponent.ActivityActionsMenu,
              buttonName: 'Modify file settings',
            });
          }}
          onClickArchiveResource={() => {
            setIsConfirmArchiveActivityModalOpen?.(true);
            trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
              originPage: AnalyticsPage.Library,
              originComponent: AnalyticsComponent.ActivityActionsMenu,
              buttonName: 'Archive resource',
            });
          }}
        />
      )}
    </>
  );
};

export default LibraryTable;
