import classNames from 'classnames';
import { FC, FocusEvent, MouseEvent, useEffect, useRef } from 'react';

import {
  Control,
  Controller,
  DeepRequired,
  FieldErrorsImpl,
  UseFieldArrayInsert,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  UseFormRegister,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import {
  AssessmentQuestionType,
  AssessmentSignatureUserInput,
} from '../../../../generated/graphql';

import { PlusSmIcon } from '@heroicons/react/outline';
import { $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown';
import CloseX from '../../../svgs/CloseX';
import Trash from '../../../svgs/Trash';

import {
  isArrowDownKey,
  isArrowUpKey,
  isBackspaceKey,
  isEnterKey,
  resizeTextArea,
} from '../../../lib/form';

import IconButton from '../../../components/IconButton';
import InputGroup from '../../../components/InputGroup';
import SelectMenu from '../../../components/SelectMenu';

import FormBlock from '../FormBlock';
import { defaultQuestionSchema } from '../helpers';

import Editor from '../../../components/Editor';
import {
  isFreeTextAssessmentQuestion,
  isMultiSelectAssessmentQuestion,
  isMultipleChoiceAssessmentQuestion,
  isRichTextAssessmentQuestion,
  isSignatureAssessmentQuestion,
  isMultiSignatureAssessmentQuestion,
  isStatementAssessmentQuestion,
  isInitialsAssessmentQuestion,
  isSignatureTypeAssessmentQuestion,
} from '../../../lib/assessments';
import {
  Question,
  QuestionForm,
  QuestionTypeItem,
  SUPPORTED_QUESTION_TYPE_OPTIONS,
} from './helpers';
import Checkbox from '../../../components/Checkbox';
import Button from '../../../components/Button';

import SignatureUserDisplay from '../../../components/SignatureUserDisplay';
import SignatureFlagName from '../../../svgs/SignatureFlagName';
import { customToast } from '../../../components/ToastAlert/customToast';

const MAX_ANSWER_OPTIONS = 100;
const MAX_ANSWER_OPTION_LENGTH = 500;
const MAX_ANSWER_OPTION_WARNING_LENGTH = 450;

type QuestionRegisterValue = `questions.${number}.question`;
type QuestionTypeRegisterValue = `questions.${number}.questionType`;
type IsRequiredRegisterValue = `questions.${number}.isRequired`;

type AnswerOptionRegisterValue = `questions.${number}.answerOptions.${number}`;

const Bullet = () => (
  <div className="mr-2 mt-3 h-2.5 w-2.5 rounded-full border-2 border-secondary-125 bg-white" />
);

const Square = () => (
  <div className="mr-2 mt-3 h-3 w-3 rounded-sm border-2 border-secondary-125 bg-white" />
);

const getLabels = (
  question: Question,
  isSignatureTypeQuestion: boolean,
): [string, string, string] => {
  if (isStatementAssessmentQuestion(question)) {
    return ['Statement', 'Enter a statement...', 'Please enter a statement'];
  } else if (isSignatureTypeQuestion) {
    return ['Prompt', 'Enter a prompt...', 'Please enter a prompt'];
  } else {
    return ['Question', 'Enter a question...', 'Please enter a question'];
  }
};

const getQuestionInputId = (index: number) => `questionInput_${index}`;

const WINDOW_OFFSET = 145;

const QuestionContainer: FC<{
  question: Question;
  questionIndex: number;
  isLastQuestion: boolean;
  errors: FieldErrorsImpl<DeepRequired<QuestionForm>>;
  control: Control<QuestionForm, object>;
  register: UseFormRegister<QuestionForm>;
  insert: UseFieldArrayInsert<QuestionForm, 'questions'>;
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<QuestionForm, 'questions'>;
  setValue: UseFormSetValue<QuestionForm>;
  setFocus: UseFormSetFocus<QuestionForm>;
  watchAssessmentSignatureUsers: AssessmentSignatureUserInput[];
  openSignatureSettingsModal: () => void;
  watch: UseFormWatch<QuestionForm>;
  setCurrentQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
  currentQuestionIndex: number;
  fromServiceBuilder: boolean;
}> = ({
  question,
  questionIndex,
  isLastQuestion,
  errors,
  control,
  register,
  setValue,
  insert,
  remove,
  update,
  setFocus,
  watchAssessmentSignatureUsers,
  openSignatureSettingsModal,
  watch,
  setCurrentQuestionIndex,
  currentQuestionIndex,
  fromServiceBuilder,
}) => {
  const questionRegisterValue: QuestionRegisterValue = `questions.${questionIndex}.question`;
  const questionTypeRegisterValue: QuestionTypeRegisterValue = `questions.${questionIndex}.questionType`;
  const isRequiredRegisterValue: IsRequiredRegisterValue = `questions.${questionIndex}.isRequired`;

  const useableQuestionRef = useRef<HTMLTextAreaElement | null>(null);

  // resizes QuestionTextAreas on initial load
  useEffect(() => {
    resizeTextArea(useableQuestionRef);
  }, [useableQuestionRef]);

  const scrollToQuestion = (
    targetElement: HTMLElement | null,
    offset: number,
    clickY = 0,
  ) => {
    if (targetElement) {
      const containerRect = targetElement.getBoundingClientRect();
      const containerHeight = containerRect.height;
      const clickedInBottomHalf =
        clickY > containerRect.top + containerHeight / 2;

      let targetScrollTop;
      if (clickedInBottomHalf) {
        targetScrollTop =
          containerRect.bottom + window.scrollY - window.innerHeight + offset;
      } else {
        targetScrollTop = containerRect.top + window.scrollY - offset;
      }

      window.scrollTo({
        top: targetScrollTop,
        behavior: 'smooth',
      });
    }
  };

  const scrollQuestionContainerToPosition = (
    event:
      | MouseEvent<HTMLDivElement, globalThis.MouseEvent>
      | FocusEvent<
          HTMLTextAreaElement | HTMLInputElement | HTMLButtonElement,
          Element
        >,
  ) => {
    const clickY = 'clientY' in event ? event.clientY : 0;
    scrollToQuestion(questionContainerRef.current, WINDOW_OFFSET, clickY);
    event.stopPropagation();
  };

  const isFirstQuestion = questionIndex === 0;

  const questionContainerRef = useRef<HTMLDivElement>(null);
  const questionInputRef = useRef<HTMLDivElement>(null);

  const selectAnswerOption = (
    answerOptionRegisterValue: AnswerOptionRegisterValue,
  ) => {
    setTimeout(() => {
      setFocus(answerOptionRegisterValue);
    }, 0);
  };

  const isInitialsQuestion = isInitialsAssessmentQuestion(question);
  const isSignatureQuestion = isSignatureAssessmentQuestion(question);
  const isMultiSignatureQuestion = isMultiSignatureAssessmentQuestion(question);
  const isSignatureTypeQuestion = isSignatureTypeAssessmentQuestion(question);

  const [label, placeholder, errorMessage] = getLabels(
    question,
    isSignatureTypeQuestion,
  );

  const watchAssessmentUserOrderIndices = watch(
    `questions.${questionIndex}.assessmentUserOrderIndices`,
  );

  const addAssessmentSignatureUserToQuestion = (
    assessmentSignatureUser: AssessmentSignatureUserInput,
  ) => {
    const updatedIndices = [
      ...watchAssessmentUserOrderIndices,
      assessmentSignatureUser.orderIndex,
    ].sort((a, b) => a - b);
    setValue(
      `questions.${questionIndex}.assessmentUserOrderIndices`,
      updatedIndices,
    );
  };

  const removeSignatureUserFromQuestion = (index: number) => {
    const updatedIndices = watchAssessmentUserOrderIndices.filter(
      (orderIndex) => orderIndex !== index,
    );
    setValue(
      `questions.${questionIndex}.assessmentUserOrderIndices`,
      updatedIndices,
    );
  };

  return (
    <div className="relative" ref={questionContainerRef}>
      <FormBlock
        onClick={(e) => {
          if (questionIndex !== currentQuestionIndex) {
            scrollQuestionContainerToPosition(e);
          }
          setCurrentQuestionIndex(questionIndex);
        }}
      >
        <div className="mb-3 flex flex-row items-center justify-between">
          <div className="text-small-caption font-medium uppercase text-secondary-100">
            {label}
          </div>
          <div className="flex flex-row items-center">
            {!isSignatureTypeQuestion &&
              !isStatementAssessmentQuestion(question) && (
                <Controller
                  control={control}
                  name={isRequiredRegisterValue}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      id={isRequiredRegisterValue}
                      checked={value}
                      onChange={onChange}
                      className="mr-4"
                      labelContent="Required"
                    />
                  )}
                />
              )}
            <Controller
              control={control}
              name={questionTypeRegisterValue}
              defaultValue={AssessmentQuestionType.MultipleChoice}
              rules={{ required: true }}
              render={({ field }) => {
                const { onChange, value } = field;

                return (
                  <SelectMenu
                    fieldValue={value}
                    onChange={(value) => {
                      if (
                        fromServiceBuilder &&
                        value === AssessmentQuestionType.MultiSignature
                      ) {
                        customToast.warning(
                          "Collaborative signature isn't available for intake forms.",
                        );
                        return;
                      }
                      onChange(value);
                    }}
                    fieldOptions={SUPPORTED_QUESTION_TYPE_OPTIONS}
                    SelectOptionComponent={QuestionTypeItem}
                    buttonClassName="max-h-[40px] min-w-[210px]"
                    placeholder="Select a form or assessment"
                    label="Select question type"
                    hideLabel
                  />
                );
              }}
            />
          </div>
        </div>
        <div
          id={getQuestionInputId(questionIndex)}
          ref={questionInputRef}
          className="flex w-full flex-row items-start justify-between rounded-lg border border-neutral-75 p-4"
        >
          <Controller
            control={control}
            name={questionRegisterValue}
            rules={{
              required: {
                value: true,
                message: errorMessage,
              },
            }}
            render={({ field }) => {
              const { onChange, value } = field;
              return (
                <Editor
                  placeholder={placeholder}
                  className="text-neutral-150"
                  containerClassName="w-full"
                  initialContentMarkdown={value}
                  onChange={(editorState) => {
                    editorState.read(() => {
                      onChange($convertToMarkdownString(TRANSFORMERS));
                    });
                  }}
                  onError={(error: Error) => {
                    console.error(error);
                  }}
                />
              );
            }}
          />
        </div>
        <div className="mt-2 flex items-center text-small-caption text-red-100">
          {errors.questions?.[questionIndex]?.question?.message}
        </div>
        <div className="mt-6 flex flex-row items-center justify-start pb-2">
          {(isFreeTextAssessmentQuestion(question) ||
            isRichTextAssessmentQuestion(question)) && (
            <div
              className={classNames(
                'w-full rounded-lg border border-neutral-75 bg-neutral-25 p-4 text-caption text-neutral-110',
                {
                  'pb-20': isRichTextAssessmentQuestion(question),
                },
              )}
            >
              Response goes here...
            </div>
          )}
          {isInitialsQuestion && (
            <div className="flex flex-col items-start justify-start">
              <div className="mb-3 text-caption font-medium">
                Click the box to add your initials
              </div>
              <InputGroup
                IconLeft={SignatureFlagName}
                containerClassName="mb-3 w-[120px]"
                className="rounded-lg pl-12"
                disabled
              />
            </div>
          )}
          {isSignatureQuestion && (
            <div className="flex flex-col items-start justify-start">
              <div className="mb-3 text-caption font-medium">
                Write your name to sign electronically
              </div>
              <InputGroup
                IconLeft={SignatureFlagName}
                containerClassName="mb-3 w-[398px]"
                className="rounded-lg pl-12"
                disabled
                placeholder={`Client signs name here`}
              />
            </div>
          )}
          {isMultiSignatureQuestion && (
            <div className="flex w-full flex-col items-start justify-start">
              {watchAssessmentSignatureUsers.length < 2 ? (
                <Button
                  title="Add form signatures"
                  iconPosition="right"
                  size="small"
                  theme="secondary"
                  IconComponent={PlusSmIcon}
                  iconClassName="text-secondary-100"
                  onClick={openSignatureSettingsModal}
                />
              ) : (
                <div className="flex w-full flex-col items-start justify-start">
                  <div className="mb-4 text-body font-medium text-neutral-125">
                    Choose signers for this section
                  </div>

                  {watchAssessmentSignatureUsers?.map(
                    (assessmentSignatureUser, index) => {
                      const isSignatureUserInQuestion =
                        watchAssessmentUserOrderIndices.includes(index);

                      return assessmentSignatureUser ? (
                        <div
                          key={index}
                          className="mb-4 flex w-auto cursor-pointer select-none flex-row items-center justify-start rounded-2xl border border-neutral-50"
                          onClick={() => {
                            if (isSignatureUserInQuestion) {
                              removeSignatureUserFromQuestion(index);
                            } else {
                              addAssessmentSignatureUserToQuestion(
                                assessmentSignatureUser,
                              );
                            }
                          }}
                        >
                          <SignatureUserDisplay
                            assessmentSignatureUser={assessmentSignatureUser}
                            disabled={!isSignatureUserInQuestion}
                          />
                          <Checkbox
                            id={`question_${questionIndex}_multi_ signature_user_${index}`}
                            checked={isSignatureUserInQuestion}
                            aria-label={
                              isSignatureUserInQuestion
                                ? 'Remove form signer'
                                : 'Add form signer'
                            }
                            className="ml-10 mr-4"
                          />
                        </div>
                      ) : null;
                    },
                  )}
                </div>
              )}
            </div>
          )}

          {(isMultipleChoiceAssessmentQuestion(question) ||
            isMultiSelectAssessmentQuestion(question)) && (
            <div className="flex w-full flex-col items-start justify-start">
              <div className="mb-2 text-small-caption font-medium uppercase text-secondary-100">
                Options
              </div>
              {question.answerOptions?.map(
                (answerOption, answerOptionIndex) => {
                  const answerOptionRegisterValue: AnswerOptionRegisterValue = `questions.${questionIndex}.answerOptions.${answerOptionIndex}`;
                  const previousAnswerOptionRegisterValue: AnswerOptionRegisterValue = `questions.${questionIndex}.answerOptions.${
                    answerOptionIndex - 1
                  }`;
                  const nextAnswerOptionRegisterValue: AnswerOptionRegisterValue = `questions.${questionIndex}.answerOptions.${
                    answerOptionIndex + 1
                  }`;

                  const isFirstAnswerOption = answerOptionIndex === 0;
                  const isFirstOrSecondAnswerOption = answerOptionIndex < 2;

                  const lastAnswerOptionIndex =
                    question.answerOptions.length - 1;
                  const isLastAnswerOption =
                    answerOptionIndex === lastAnswerOptionIndex;

                  const hasAvailableOptions =
                    question.answerOptions.length < MAX_ANSWER_OPTIONS;
                  const hasAnswerOptionInput = Boolean(answerOption);

                  const isEligibleToAddOptions =
                    hasAvailableOptions && isLastAnswerOption;

                  const answerOptionRegister = register(
                    answerOptionRegisterValue,
                    {
                      required: {
                        value: true,
                        message: !isFirstOrSecondAnswerOption
                          ? 'Please write text or remove this answer option'
                          : !isFirstQuestion
                          ? 'Please enter at least 2 answer options or remove the question'
                          : 'Please enter at least 2 answer options',
                      },
                      onBlur: () => {
                        if (answerOption) {
                          setValue(
                            answerOptionRegisterValue,
                            answerOption
                              .trim()
                              .slice(0, MAX_ANSWER_OPTION_LENGTH),
                          );
                        } else if (
                          isLastAnswerOption &&
                          !isFirstOrSecondAnswerOption
                        ) {
                          update(questionIndex, {
                            ...question,
                            answerOptions: question.answerOptions.slice(
                              0,
                              lastAnswerOptionIndex,
                            ),
                          });
                        }
                      },
                    },
                  );

                  const addAnswerOption = () => {
                    update(questionIndex, {
                      ...question,
                      answerOptions: [...question.answerOptions, ''],
                    });
                  };

                  const deleteAnswerOption = () => {
                    if (!(isFirstAnswerOption && !hasAnswerOptionInput)) {
                      const answerOptionsToSplice = question.answerOptions;
                      answerOptionsToSplice.splice(answerOptionIndex, 1);

                      update(questionIndex, {
                        ...question,
                        answerOptions: answerOptionsToSplice,
                      });

                      selectAnswerOption(previousAnswerOptionRegisterValue);
                    }
                  };

                  const onAnswerOptionInputKeyDown = (
                    event: React.KeyboardEvent<HTMLInputElement>,
                  ) => {
                    const keyDown = event.key;

                    if (isEnterKey(keyDown)) {
                      if (isEligibleToAddOptions) {
                        addAnswerOption();
                      }
                      selectAnswerOption(nextAnswerOptionRegisterValue);
                      event.preventDefault();
                    }

                    if (isBackspaceKey(keyDown) && !hasAnswerOptionInput) {
                      if (!isFirstOrSecondAnswerOption) {
                        deleteAnswerOption();
                      } else if (!isFirstAnswerOption) {
                        selectAnswerOption(previousAnswerOptionRegisterValue);
                      }
                      event.preventDefault();
                    }

                    if (
                      isArrowDownKey(keyDown) &&
                      answerOptionIndex < lastAnswerOptionIndex
                    ) {
                      selectAnswerOption(nextAnswerOptionRegisterValue);
                      event.preventDefault();
                    }

                    if (isArrowUpKey(keyDown) && !isFirstAnswerOption) {
                      setFocus(previousAnswerOptionRegisterValue);
                      event.preventDefault();
                    }
                  };

                  const showCharacterCounter =
                    answerOption?.length > MAX_ANSWER_OPTION_WARNING_LENGTH;

                  return (
                    <div
                      key={answerOptionIndex}
                      className="flex w-full flex-col items-start justify-start"
                    >
                      <div className="mb-2 flex w-full flex-row items-start justify-start">
                        {isMultiSelectAssessmentQuestion(question) ? (
                          <Square />
                        ) : (
                          <Bullet />
                        )}
                        <InputGroup
                          label={answerOptionRegisterValue}
                          labelHidden
                          autoFocus={false}
                          backgroundHidden
                          onKeyDown={onAnswerOptionInputKeyDown}
                          errorMessage={
                            errors.questions?.[questionIndex]?.answerOptions?.[
                              answerOptionIndex
                            ]?.message
                          }
                          inputSize="extra-small"
                          placeholder={`Option ${answerOptionIndex + 1}`}
                          characterCounter={showCharacterCounter}
                          maxLength={MAX_ANSWER_OPTION_LENGTH}
                          value={answerOption}
                          currentLengthValue={answerOption?.length ?? 0}
                          maxLengthValue={MAX_ANSWER_OPTION_LENGTH}
                          containerClassName="w-full"
                          className={classNames(
                            'rounded-none border-0 border-b-2',
                            showCharacterCounter && 'group-focus-within:pr-20',
                          )}
                          type="text"
                          {...answerOptionRegister}
                        />
                        <IconButton
                          aria-label="Delete option"
                          IconComponent={CloseX}
                          className="mt-1.5 hover:bg-neutral-50"
                          disabled={
                            isFirstOrSecondAnswerOption &&
                            question.answerOptions.length < 3
                          }
                          iconClassName="h-3 w-3 pl-0.5 mt-0.5"
                          onClick={() => deleteAnswerOption()}
                        />
                      </div>
                      {isEligibleToAddOptions && (
                        <div className="flex w-full flex-row items-start justify-start">
                          {isMultiSelectAssessmentQuestion(question) ? (
                            <Square />
                          ) : (
                            <Bullet />
                          )}
                          <button
                            tabIndex={-1}
                            className={classNames(
                              'mt-1 flex h-[30px] w-full flex-row items-center justify-start',
                              'border-b border-b-neutral-25 px-3 py-1 pb-2 text-body text-neutral-100',
                            )}
                            onClick={(e) => {
                              if (isEligibleToAddOptions) {
                                addAnswerOption();
                              }
                              selectAnswerOption(nextAnswerOptionRegisterValue);
                              e.preventDefault();
                            }}
                          >
                            Add option
                          </button>
                        </div>
                      )}
                    </div>
                  );
                },
              )}
            </div>
          )}
        </div>
        <div className="mt-4 flex w-full flex-row items-center justify-end border-t border-neutral-50 pt-2">
          <IconButton
            aria-label="Remove question"
            IconComponent={Trash}
            disabled={isFirstQuestion && isLastQuestion}
            iconClassName="h-5 w-5"
            className="hover:bg-neutral-50"
            onClick={() => remove(questionIndex)}
          />
        </div>
      </FormBlock>
      <button
        className={classNames(
          'absolute -right-16 top-0 flex h-10 w-10 flex-col items-center justify-center',
          'rounded-full bg-white shadow-100',
          'hover:cursor-pointer hover:bg-neutral-25',
          'focus:outline-none focus:ring-2 focus:ring-secondary-50',
        )}
        onClick={(e) => {
          const nextQuestionIndex = questionIndex + 1;
          insert(nextQuestionIndex, {
            ...defaultQuestionSchema,
            // Default to adding another question of the same type
            questionType: question.questionType,
            ...(isMultiSignatureQuestion && {
              assessmentUserOrderIndices: watchAssessmentSignatureUsers.map(
                (_, index) => index,
              ),
            }),
          });

          setTimeout(() => {
            const nextQuestionInputId = getQuestionInputId(nextQuestionIndex);
            const nextQuestionInputElement =
              document.getElementById(nextQuestionInputId);
            scrollToQuestion(nextQuestionInputElement, WINDOW_OFFSET + 100);
          }, 50);

          e.preventDefault();
        }}
      >
        <PlusSmIcon className="h-6 w-6 text-secondary-100" />
      </button>
    </div>
  );
};

export default QuestionContainer;
