import { MediaCategory } from '../../generated/graphql';

export const acceptedImageFileExtensions = [
  'image/jpeg',
  'image/jpg',
  'image/png',
];
export const acceptedAudioFileExtensions = [
  'audio/mpeg',
  'audio/wav',
  'audio/ogg',
  'audio/flac',
  'audio/m4a',
  'audio/x-m4a',
];
export const acceptedVideoFileExtensions = [
  'video/mp4',
  'video/mov',
  'video/avi',
  'video/mkv',
];
export const acceptedPdfFileExtensions = ['application/pdf'];
export const acceptedDocumentFileExtensions = [
  'text/plain',
  'application/msword', // Word 97-2003
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Word 2007+
  'application/vnd.oasis.opendocument.text', // OpenDocument Text
  'application/pdf', // PDF
  'application/rtf', // Rich Text Format
  'application/vnd.ms-excel', // Excel 97-2003
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Excel 2007+
  'application/vnd.oasis.opendocument.spreadsheet', // OpenDocument Spreadsheet
  'application/vnd.ms-powerpoint', // PowerPoint 97-2003
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PowerPoint 2007+
  'text/csv', // CSV
];

export const mediaCategoryToLabel = {
  [MediaCategory.ModuleAudio]: 'Audio',
  [MediaCategory.ModuleImage]: 'Image',
  [MediaCategory.ModuleVideo]: 'Video',
  [MediaCategory.ModulePdf]: 'PDF',
  [MediaCategory.ModuleFile]: 'File',
};

export const allAcceptedFileExtensions = [
  ...acceptedImageFileExtensions,
  ...acceptedAudioFileExtensions,
  ...acceptedVideoFileExtensions,
  ...acceptedPdfFileExtensions,
  ...acceptedDocumentFileExtensions,
];

export const MAX_UPLOAD_SIZE_MB = 200;
export const MAX_UPLOAD_SIZE_BYTES = MAX_UPLOAD_SIZE_MB * 1000 * 1000;
