import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { ProgramActivityStatus } from '../ProgramActivityStatus';
import {
  ProgramActivityDataFragment,
  UserType,
} from '../../../generated/graphql';

interface CardContentProps {
  isSmallStyle: boolean;
  showHoverWarning: boolean;
  signatureWarningMessage: string;
  title: string;
  providerNote: string;
  programActivity: ProgramActivityDataFragment;
  userType: UserType;
}

export const CardContent: React.FC<CardContentProps> = ({
  isSmallStyle,
  showHoverWarning,
  signatureWarningMessage,
  title,
  providerNote,
  programActivity,
  userType,
}) => {
  if (isSmallStyle) {
    return (
      <div className="flex w-full flex-row items-center justify-between">
        <div className="flex flex-col items-start justify-center">
          {showHoverWarning ? (
            <div className="grow break-words pr-5 text-small-caption text-neutral-125">
              {signatureWarningMessage}
            </div>
          ) : (
            <>
              <div className="grow break-words text-caption font-medium text-neutral-150 line-clamp-2">
                {title}
              </div>
              <div className="whitespace-pre-wrap break-words text-caption text-neutral-125 line-clamp-1">
                {providerNote}
              </div>
            </>
          )}
        </div>
        {showHoverWarning && (
          <div className="relative">
            <ExclamationCircleIcon className="h-5 w-5 text-orange-100" />
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="grow text-caption font-medium text-neutral-150 line-clamp-2">
        {title}
      </div>
      <ProgramActivityStatus
        programActivity={programActivity}
        className="ml-4 flex-none"
        userType={userType}
      />
    </>
  );
};
