import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { usePublicStorefrontQuery } from '../../../generated/graphql';

import { HOMEPAGE_URL } from '../../lib/constants';

import { useProviderColors } from '../../hooks/useProviderColors';
import useDisableOverscroll from '../../hooks/useDisableOverscroll';

import PublicPageFooter from '../../components/PublicPageFooter';
import PageContainer from '../../components/Containers/PageContainer';
import PractitionerBanner from '../../components/Storefront/PractitionerBanner';
import PractitionerInfoContainer from '../../components/Storefront/PractitionerInfoContainer';
import PractitionerInfoRightColumn from '../../components/Storefront/PractitionerInfoRightColumn';
import PractitionerInfoLeftColumn from '../../components/Storefront/PractitionerInfoLeftColumn';
import StorefrontIntakeCompleteModal from '../../components/Modals/StorefrontIntakeCompleteModal';

import Loading from '../Loading';

const PublicPage: React.FC = () => {
  const location = useLocation();
  const params = useParams();
  useDisableOverscroll();

  const slug = params.slug;
  const fromIntakeSuccess = (location.state as { fromIntakeSuccess?: boolean })
    ?.fromIntakeSuccess;

  const { data: storefrontData } = usePublicStorefrontQuery({
    variables: {
      slug,
    },
    skip: !slug,
    onError: () => {
      window.location.href = HOMEPAGE_URL;
    },
  });

  const [
    isStorefrontIntakeCompleteModalOpen,
    setIsStorefrontIntakeCompleteModalOpen,
  ] = useState(false);

  const storefront = storefrontData?.publicStorefront;

  useEffect(() => {
    if (storefront && fromIntakeSuccess) {
      setIsStorefrontIntakeCompleteModalOpen(true);
    }
  }, [storefront, fromIntakeSuccess]);

  const [isAvatarImagePopUpOpen, setIsAvatarImagePopUpOpen] = useState(false);

  useProviderColors({
    providerPrimaryColor: storefront?.publicServices?.[0]?.providerPrimaryColor,
    providerSecondaryColor:
      storefront?.publicServices?.[0]?.providerSecondaryColor,
  });

  return (
    <div>
      {storefront ? (
        <>
          <PageContainer containerClassName="pt-0 pb-0 px-0 sm:pl-16 min-h-screen min-w-full">
            <div
              onClick={() =>
                isAvatarImagePopUpOpen && setIsAvatarImagePopUpOpen(false)
              }
              className="flex min-h-screen flex-col justify-between md:w-full"
            >
              <div className="flex flex-col justify-start">
                <PractitionerBanner
                  avatarImageUrl={storefront.profileImageUrl}
                  setIsAvatarImagePopUpOpen={setIsAvatarImagePopUpOpen}
                  isAvatarImagePopUpOpen={isAvatarImagePopUpOpen}
                  avatarName={storefront.providerName}
                  bannerImageUrl={storefront.bannerImageUrl}
                  initialBannerPosition={storefront.bannerPosition}
                  isPublicPage
                />
                <PractitionerInfoContainer>
                  <PractitionerInfoLeftColumn
                    providerName={storefront.providerName}
                    roleTitle={storefront.providerRoleTitle}
                    ctaFormLabel={storefront.providerCtaFormLabel}
                    ctaButtonText={storefront.providerCtaButtonText}
                    providerStorefrontSlug={slug}
                    isPublicPage
                  />
                  <PractitionerInfoRightColumn
                    tagline={storefront.tagline}
                    about={storefront.about}
                    publicServices={storefront.publicServices}
                  />
                </PractitionerInfoContainer>
              </div>
              <PublicPageFooter />
            </div>
          </PageContainer>
          <StorefrontIntakeCompleteModal
            isModalOpen={isStorefrontIntakeCompleteModalOpen}
            setClosed={() => setIsStorefrontIntakeCompleteModalOpen(false)}
            avatarImageUrl={storefront.profileImageUrl}
            avatarName={storefront.providerName}
          />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default PublicPage;
