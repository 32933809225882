import classNames from 'classnames';
import { FC } from 'react';

import {
  Exact,
  MeProviderProgramTagsQuery,
  PatientLifecycleStatusV2,
  PatientTableDataFragment,
  ProgramTagDataFragment,
} from '../../../../../generated/graphql';

import { getRelativeTimestamp } from '../../../../lib/time';
import ChevronRight from '../../../../svgs/ChevronRight';

import { AnalyticsPage } from '../../../../../lib/analytics';
import Avatar from '../../../../components/Avatar';
import Button from '../../../../components/Button';
import NoText from '../../../../components/NoText';
import TestClientPill from '../../../../components/TestClientPill';
import { PatientCallback } from '../../helpers';
import { ChatIcon, MailIcon } from '@heroicons/react/outline';
import ProgramTagManager from '../../../../components/ProgramTag/ProgramTagManager';
import { ApolloQueryResult } from '@apollo/client';
import Bolt from '../../../../svgs/Bolt';
import { isPatientNewFromStorefront } from '../../../../lib/patient';

type ActiveRowProps = {
  isChecked: boolean;
  patient: PatientTableDataFragment;
  patientIndex: number;
  patientLifecycleStatus?: PatientLifecycleStatusV2;
  nextStepsButtonsDisabled: boolean;
  navigateToPatientFollowUp: PatientCallback;
  navigateToPatientIntake: PatientCallback;
  navigateToPatientMessages: PatientCallback;
  navigateToPatientProfile: (
    patient: PatientTableDataFragment,
    subRoute?: string,
  ) => void;
  setIsSendAssessmentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSinglePatient: React.Dispatch<
    React.SetStateAction<PatientTableDataFragment | null>
  >;
  providerProgramTags?: ProgramTagDataFragment[];
  refetchProviderProgramTags?: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<MeProviderProgramTagsQuery>>;
  handleProgramTagsUpdate: (
    programId: string,
    tags: ProgramTagDataFragment[],
  ) => void;
};

const ActiveRow: FC<ActiveRowProps> = ({
  isChecked,
  patient,
  navigateToPatientProfile,
  navigateToPatientFollowUp,
  navigateToPatientMessages,
  providerProgramTags = [],
  refetchProviderProgramTags,
  handleProgramTagsUpdate,
}) => {
  const hasUnreadComments = patient?.aggregateUnreadCommentCount > 0;
  const isNewStorefrontPatient = isPatientNewFromStorefront(patient);

  return (
    <tr
      className={classNames(
        'group max-w-full cursor-pointer whitespace-nowrap text-green-150',
        isChecked && 'bg-neutral-25',
        !isChecked && 'hover:bg-neutral-25',
        isNewStorefrontPatient && 'bg-secondary-20 hover:bg-secondary-25',
      )}
      onClick={(event) => {
        navigateToPatientProfile?.(patient);
        event.stopPropagation();
      }}
    >
      <td className="w-[3.75rem]">
        <div className="flex justify-center">
          {isNewStorefrontPatient && (
            <Bolt className="h-[1.125rem] w-[1.125rem] text-orange-100" />
          )}
        </div>
      </td>

      <td className="w-1/6 py-4 px-0">
        <div className="flex flex-row items-center gap-x-4">
          <Avatar
            imageUrl={patient?.profileImageMedia?.url}
            name={patient?.firstName ?? ''}
            size="medium"
          />
          <div className="font-serif text-extra-small">
            {patient?.firstName + ' ' + patient?.lastName}
          </div>
          {patient?.isTestProgram && <TestClientPill />}
        </div>
      </td>

      <td className="max-w-[300px]">
        <ProgramTagManager
          key={JSON.stringify(patient.tags)}
          programId={patient.programInstanceId}
          availableProgramTags={providerProgramTags}
          refreshAvailableProgramTags={refetchProviderProgramTags}
          selectedProgramTags={patient.tags}
          onTagsUpdated={(tags) => {
            handleProgramTagsUpdate(patient.programInstanceId, tags);
          }}
          analyticsPage={AnalyticsPage.ClientsTableActive}
          className="w-full max-w-full"
        />
      </td>

      <td className="w-12">
        {hasUnreadComments ? (
          <div className="flex flex-row items-center gap-x-3 text-caption">
            <div className="flex h-7 w-7 flex-row items-center justify-center rounded-full bg-secondary-100 text-small-caption font-medium text-neutral-0">
              {patient.aggregateUnreadCommentCount}
            </div>
            <span className="text-neutral-110">
              {getRelativeTimestamp(patient.latestUnreadCommentAt)}
            </span>
          </div>
        ) : (
          <NoText text="None" className="text-caption" />
        )}
      </td>

      <td className="w-32">
        <div className="flex flex-row">
          <button
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              navigateToPatientMessages(patient);
            }}
            className="mr-2 rounded-full bg-neutral-50 p-3 hover:bg-neutral-100/75"
          >
            <ChatIcon className="h-5 w-5 text-neutral-125" />
          </button>
          <button
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              navigateToPatientFollowUp(patient);
            }}
            className="rounded-full bg-neutral-50 p-3 hover:bg-neutral-100/75"
          >
            <MailIcon className="h-5 w-5 text-neutral-125" />
          </button>
        </div>
      </td>

      <td className="flex w-12 flex-row items-center justify-end">
        <Button
          noOutline
          noBackground
          theme="secondary"
          className="pt-2"
          IconComponent={ChevronRight}
        />
      </td>
    </tr>
  );
};

export default ActiveRow;
