import {
  BasicScheduledEventFragment,
  CalendarScheduledEventFragment,
  CompleteScheduledEventFragment,
  Location,
  LocationType,
  UpcomingScheduledEventFragment,
} from '../../generated/graphql';
import {
  ConferenceLocation,
  LocationMap,
  NonConferenceLocation,
} from '../types/scheduled-event';

export const CALENDLY_AUTHORIZATION_PAGE_URL =
  'https://calendly.com/oauth/authorize';
export const CALENDLY_LANDING_PAGE_URL = 'https://calendly.com';
export const CALENDLY_SCHEDULED_EVENTS_URL =
  'https://calendly.com/event_types/user/me';

export function getCalendlyRedirectUri(): string {
  return window.location.origin + '/integrations/calendly/callback';
}

export const conferenceLocationTypes = [
  LocationType.GoogleConference,
  LocationType.ZoomConference,
  LocationType.GoToMeeting,
  LocationType.MicrosoftTeamsConference,
  LocationType.WebexConference,
];

export const nonConferenceLocationTypes = [
  LocationType.Physical,
  LocationType.OutboundCall,
  LocationType.AskInvitee,
  LocationType.InboundCall,
  LocationType.Custom,
];

export function isConferenceLocation(
  location: Location,
): location is ConferenceLocation {
  return conferenceLocationTypes.includes(location.type);
}

export function isNonConferenceLocation(
  location: Location,
): location is NonConferenceLocation {
  return nonConferenceLocationTypes.includes(location.type);
}

export const ConferenceLocationTypeMap: LocationMap = {
  [LocationType.GoogleConference]: 'Google',
  [LocationType.ZoomConference]: 'Zoom',
  [LocationType.GoToMeeting]: 'GoToMeeting',
  [LocationType.MicrosoftTeamsConference]: 'Microsoft Teams',
  [LocationType.WebexConference]: 'WebEx',

  /* placeholders to make TS happy */
  [LocationType.Physical]: '',
  [LocationType.OutboundCall]: '',
  [LocationType.AskInvitee]: '',
  [LocationType.InboundCall]: '',
  [LocationType.Custom]: '',
};

export const getFirstInviteeData = (
  scheduledEvent?:
    | BasicScheduledEventFragment
    | UpcomingScheduledEventFragment
    | CompleteScheduledEventFragment
    | CalendarScheduledEventFragment,
) => {
  const firstInvitee = scheduledEvent?.invitees?.[0];

  const fullNameFromProgram = `${firstInvitee?.program?.firstName} ${firstInvitee?.program?.lastName}`;
  const fullNameFromCalendly = `${firstInvitee?.firstName} ${firstInvitee?.lastName}`;

  const hasProgramInvite = Boolean(
    firstInvitee?.program?.onboardingLastProgramInviteSentAt,
  );

  return {
    firstName: hasProgramInvite
      ? firstInvitee?.program?.firstName
      : firstInvitee?.firstName,
    fullName: hasProgramInvite ? fullNameFromProgram : fullNameFromCalendly,
    programEmail: firstInvitee?.program?.email,
    inviteeEmail: firstInvitee?.email,
    email: hasProgramInvite
      ? firstInvitee?.program?.email
      : firstInvitee?.email,
    profileImageUrl: firstInvitee?.program?.profileImageMedia?.url,
    onboardingLastProgramInviteSentAt:
      firstInvitee?.program?.onboardingLastProgramInviteSentAt,
    onboardingCompletedAt: firstInvitee?.program?.onboardingCompletedAt,
    programInstanceId: firstInvitee?.program?.programInstanceId, // invited and active patients have it
    programId: firstInvitee?.program?.id, // only active patients have it
    calendlyRescheduleUrl: firstInvitee?.calendlyRescheduleUrl, // only active patients have it
    calendlyCancelUrl: firstInvitee?.calendlyCancelUrl, // only active patients have it
  };
};
