import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import Config from './config';
import { MeProviderDataFragment } from '../generated/graphql';

const safeSentryCall = (callback: () => void) => {
  try {
    if (Config.REACT_APP_SENTRY_DSN) {
      callback();
    } else {
      console.warn('Sentry DSN is not set, skipping initialization');
    }
  } catch (error) {
    console.error('Failed to send data to Sentry:', error);
  }
};

const initSentry = () => {
  if (Config.REACT_APP_SENTRY_DSN)
    safeSentryCall(() => {
      Sentry.init({
        dsn: Config.REACT_APP_SENTRY_DSN,
        integrations: [
          new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
              React.useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes,
            ),
          }),
        ],
        environment: Config.REACT_APP_ENVIRONMENT,
        release: Config.REACT_APP_VERSION,
        tracesSampleRate: parseFloat(Config.REACT_APP_SENTRY_SAMPLE_RATE),
      });
    });
};

const setUserScope = (meProvider: MeProviderDataFragment) => {
  safeSentryCall(() => {
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: meProvider.id,
      });
      scope.setContext('authInfo', {
        role: meProvider.role,
        providerId: meProvider.provider.id,
      });
    });
  });
};

const clearUserScope = () => {
  safeSentryCall(() => {
    Sentry.configureScope((scope) => scope.setUser(null));
  });
};

const SentryHelpers = {
  initSentry,
  setUserScope,
  clearUserScope,
  safeSentryCall,
};

export default SentryHelpers;
