import { customToast } from '../v2/components/ToastAlert/customToast';
import {
  AnalyticsPage,
  trackProviderEvent,
  CommonAnalyticsEvent,
} from './analytics';

export const urlFromSlug = (slug?: string): string => {
  if (!slug) {
    return '';
  }

  return `${window.location.origin}/p/${slug}`;
};

export const handleCopyMyPage = (url: string) => {
  navigator.clipboard.writeText(url);
  customToast.success('Link copied to clipboard!');
  trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
    buttonName: 'Share your page',
    originPage: AnalyticsPage.MyWebpage,
  });
};

export const handleSeeMyPage = (url: string) => {
  window.open(url, '_blank');
  trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
    buttonName: 'See your page',
    originPage: AnalyticsPage.MyWebpage,
  });
};
