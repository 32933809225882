import classNames from 'classnames';
import { useState } from 'react';

import PadlockIcon from '../../assets/svgs/padlock.svg';
import { ProgramNoteDataFragment } from '../../generated/graphql';
import Pin from '../svgs/Pin';
import { getTimeStamp } from '../lib/time';
import Button from './Button';

type NoteCardProps = {
  programNote: ProgramNoteDataFragment;
  className?: string;
  showPin?: boolean;
};

const CLAMP_LINE_COUNT = 3;

const NoteCard = ({
  programNote,
  className,
  showPin = true,
}: NoteCardProps) => {
  const [isClamped, setIsClamped] = useState(true);
  const isClampable = programNote.note.split('\n').length > CLAMP_LINE_COUNT;

  return (
    <div
      className={classNames(
        'flex flex-col items-start rounded-lg bg-white p-5 shadow-100',
        className,
      )}
    >
      <div
        className={classNames(
          'flex w-full flex-row items-center',
          showPin ? 'justify-between' : 'justify-center',
        )}
      >
        {showPin && <Pin className="invisible" />}
        <div className="flex flex-row justify-center self-center text-center text-small-caption text-neutral-125">
          {getTimeStamp(programNote.updatedAt)}
          <img src={PadlockIcon} alt="private note" className="ml-1 h-4 w-4" />
        </div>
        {showPin && (
          <Pin
            className={classNames(
              programNote?.isPinnedNote ? 'text-neutral-125 ' : 'invisible',
            )}
          />
        )}
      </div>
      <div className="mt-2 flex flex-row items-center text-small text-neutral-150">
        {programNote.title}
      </div>
      <div
        className={classNames(
          'editor mt-4 flex flex-col justify-center text-caption text-neutral-150',
          isClamped && 'line-clamp-[25]',
        )}
        dangerouslySetInnerHTML={{ __html: programNote.note }}
      />
      {isClampable && (
        <Button
          title={isClamped ? 'See more' : 'See less'}
          className="mt-5 text-caption font-medium text-secondary-100"
          type="button"
          theme="none"
          noBackground
          noFocus
          noOutline
          onClick={() => setIsClamped(!isClamped)}
        />
      )}
    </div>
  );
};

export default NoteCard;
