import React from 'react';
import classNames from 'classnames';
import { SvgIconComponent } from '../../../types/svgs';

interface DocumentNavButtonProps {
  title: string;
  isSelected: boolean;
  onClick: () => void;
  className?: string;
  IconComponent?: SvgIconComponent;
}

const DocumentNavButton: React.FC<DocumentNavButtonProps> = ({
  title,
  isSelected,
  onClick,
  className,
  IconComponent,
}) => {
  return (
    <div
      className={classNames(
        'flex min-h-[40px] w-full cursor-pointer items-center justify-between gap-x-1 overflow-y-auto rounded-md px-3 hover:rounded-md hover:bg-neutral-25',
        isSelected && 'bg-secondary-20 font-medium hover:bg-secondary-25',
        className,
      )}
      onClick={onClick}
    >
      <div className="flex flex-row items-center gap-x-2">
        {IconComponent && (
          <IconComponent className="h-5 w-5 shrink-0 text-neutral-150" />
        )}
        <div className="text-caption text-neutral-150 line-clamp-1">
          {title}
        </div>
      </div>
    </div>
  );
};

export default DocumentNavButton;
