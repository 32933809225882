import { FC } from 'react';

import Button from '../Button';
import Modal from '../Modal';
import { pluralize } from '../../lib/copy';

type ConfirmArchiveActivitiesModalProps = {
  isModalOpen: boolean;
  setClosed: () => void;
  onConfirm: () => void;
  isArchivingResources: boolean;
  selectedProviderActivitiesListLength: number;
};

const ConfirmArchiveActivitiesModal: FC<ConfirmArchiveActivitiesModalProps> = ({
  isModalOpen,
  setClosed,
  onConfirm,
  isArchivingResources,
  selectedProviderActivitiesListLength,
}) => {
  return (
    <Modal isOpen={isModalOpen} setClosed={setClosed} width="small">
      <div className="px-10 py-8">
        <div className="flex flex-col items-center">
          <div className="mb-2 flex text-center font-serif text-subtitle-small text-green-150">
            Would you like to delete{' '}
            {pluralize(
              selectedProviderActivitiesListLength,
              'resource',
              'resources',
            )}
            ?
          </div>
          <div className="text-center text-body text-green-125">
            Clients who have already received{' '}
            {pluralize(
              selectedProviderActivitiesListLength,
              'this resource',
              'these resources',
              false,
            )}{' '}
            can still access{' '}
            {pluralize(
              selectedProviderActivitiesListLength,
              'it',
              'them',
              false,
            )}{' '}
            in the Homecoming app. Standard assessments cannot be deleted.
          </div>
        </div>
        <div className="mt-11 flex w-full flex-row items-center justify-center">
          <Button
            title="Cancel"
            theme="secondary"
            className="mr-5"
            onClick={setClosed}
          />
          <Button
            title={!isArchivingResources ? 'Delete' : 'Deleting...'}
            theme="primary"
            onClick={onConfirm}
            disabled={isArchivingResources}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmArchiveActivitiesModal;
