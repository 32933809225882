import { FC, Fragment } from 'react';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import Button from './Button';

type ButtonAction = {
  title: string;
  onClick: () => void;
  className?: string;
  theme?: 'secondary' | 'destructive';
  disabled?: boolean;
};

const BottomButtons: FC<{
  isVisible: boolean;
  actions: ButtonAction[];
}> = ({ isVisible, actions }) => {
  return (
    <>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 z-10 flex items-end px-4 py-6 sm:items-end sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4">
          <Transition
            show={isVisible}
            as={Fragment}
            enter="transform ease-out duration-50 transition"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto">
              <div className="flex items-start">
                {actions.map((action, index) => {
                  const isFirstAction = index === 0;
                  const isLastAction = index === actions.length - 1;
                  const has1Item = actions.length === 1;
                  return (
                    <Button
                      key={`${index}_${action.title}`}
                      onClick={action.onClick}
                      theme={action.theme}
                      className={classNames(
                        'h-16 shadow-lg focus:ring-inset',
                        !action.className &&
                          isFirstAction &&
                          !has1Item &&
                          'rounded-tr-none rounded-br-none',
                        !action.className &&
                          isLastAction &&
                          !has1Item &&
                          'rounded-tl-none rounded-bl-none border-l border-neutral-50',
                        !action.className &&
                          !isFirstAction &&
                          !isLastAction &&
                          !has1Item &&
                          'rounded-none border-l border-neutral-50',
                        action.className,
                      )}
                      disabled={action.disabled}
                      title={action.title}
                    />
                  );
                })}
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default BottomButtons;
