import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { CreateEventFormData, EventParticipant } from '../components/types';

export const useEventParticipants = (
  setValue: UseFormSetValue<CreateEventFormData>,
  watch: UseFormWatch<CreateEventFormData>,
  defaultParticipants: EventParticipant[],
) => {
  const participants = watch('participants', defaultParticipants);

  const addParticipant = (email: string) => {
    if (
      participants.some((p) => p.email.toLowerCase() === email.toLowerCase())
    ) {
      return; // Participant already exists
    }

    const newParticipant: EventParticipant = {
      email,
      status: 'noreply',
    };

    setValue('participants', [...participants, newParticipant], {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const removeParticipant = (index: number) => {
    const updatedParticipants = [
      ...participants.slice(0, index),
      ...participants.slice(index + 1),
    ];
    setValue('participants', updatedParticipants, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  return {
    participants,
    addParticipant,
    removeParticipant,
  };
};
