import { FC } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

export interface TabProps {
  route: string;
  name: string;
  count?: number;
  isLastTab?: boolean;
  isPills?: boolean;
}

const Tab: FC<TabProps> = ({ route, name, count, isLastTab, isPills }) => {
  return (
    <NavLink
      to={route}
      className={classNames(
        !isLastTab && !isPills && 'mr-6',
        !isLastTab && isPills && 'mr-2',
        'rounded-full focus:outline-none',
      )}
      // Ensures that this Link isn't "active" when its descendant paths are matched
      end={route === '.'}
    >
      {({ isActive }) => {
        const defaultActive = isActive && !isPills;
        return (
          <>
            <div
              className={classNames(
                defaultActive && 'pt-[2px]',
                !isPills && 'mb-2 px-2',
                isPills && 'px-0',
                'relative flex flex-col items-center justify-between',
              )}
            >
              <div
                className={classNames(
                  'flex flex-row items-center justify-between px-3',
                  !isPills && 'rounded-xl py-2 hover:bg-neutral-25',
                  isPills &&
                    'rounded-full border border-transparent bg-neutral-50 px-4 py-1.5',
                  isPills && !isActive && 'hover:bg-neutral-75',
                  isPills &&
                    isActive &&
                    'border-green-100 bg-green-25 hover:bg-green-25',
                )}
              >
                <span
                  className={classNames(
                    'whitespace-nowrap font-sans text-caption text-neutral-150',
                    isActive && 'text-green-150',
                  )}
                >
                  {name}
                </span>
                {count !== undefined && (
                  <div
                    className={classNames(
                      'flex flex-row items-center justify-center rounded-full text-neutral-150',
                      !isPills &&
                        'min-h-6 min-w-6 ml-3 bg-neutral-100/30 p-1 px-2',
                      !isPills && isActive && 'bg-green-25',
                      isPills && 'ml-2 pt-[1px] text-green-150',
                    )}
                  >
                    <span className="text-small-caption font-bold">
                      {count}
                    </span>
                  </div>
                )}
              </div>
            </div>
            {defaultActive && (
              <div className="relative top-[1px] w-full border-t-2 border-secondary-100" />
            )}
          </>
        );
      }}
    </NavLink>
  );
};

export default Tab;
