import classNames from 'classnames';
import omit from 'lodash.omit';
import { FC, useState } from 'react';

import {
  AnalyticsComponent,
  AnalyticsPage,
  trackProviderComponentViewedEvent,
} from '../../../../../lib/analytics';

import { ProviderActivityType } from '../../../../types/activity';

import { ProviderActivityObject } from '../../../../lib/providerActivity';

import Button from '../../../Button';

import { DotsHorizontalIcon } from '@heroicons/react/outline';
import { ContextMenuPosition } from '../../../../types/contextMenu';
import IconButton from '../../../IconButton';
import ActivityDateAdded from './ActivityDateAdded';
import ActivitySummary from './ActivitySummary';
import { ActivityDataFragment } from '../../../../../generated/graphql';

const LibraryTableRow: FC<{
  providerActivity: ActivityDataFragment;
  setSelectedProviderActivity: React.Dispatch<
    React.SetStateAction<ActivityDataFragment | undefined>
  >;
  setIsProviderActivitySlideoverOpen?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  openAddOrEditTextModal?: () => void;
  followUpMode?: boolean;
  restrictSingleSelection?: boolean;
  isLastProviderActivity?: boolean;
  setSelectedProviderActivitiesObject?: React.Dispatch<
    React.SetStateAction<ProviderActivityObject>
  >;
  selectedProviderActivitiesObject?: ProviderActivityObject;
  isChecked?: boolean;
  providerActivityId?: string;
  showActivityActionsMenu?: boolean;
  setShowActivityActionsMenu?: React.Dispatch<React.SetStateAction<boolean>>;
  setActivityActionsMenuPosition: React.Dispatch<
    React.SetStateAction<ContextMenuPosition>
  >;
  sendProviderActivitiesInFollowUp?: (
    providerActivities: ActivityDataFragment[],
  ) => void;
}> = ({
  providerActivity,
  setSelectedProviderActivity,
  setIsProviderActivitySlideoverOpen,
  openAddOrEditTextModal,
  followUpMode = false,
  restrictSingleSelection = false,
  isLastProviderActivity,
  setSelectedProviderActivitiesObject,
  selectedProviderActivitiesObject,
  isChecked = false,
  providerActivityId = '',
  showActivityActionsMenu,
  setShowActivityActionsMenu,
  setActivityActionsMenuPosition,
  sendProviderActivitiesInFollowUp,
}) => {
  const onClickResourceRow = () => {
    if (!showActivityActionsMenu) {
      setSelectedProviderActivity(providerActivity);
      if (providerActivity.__typename === ProviderActivityType.Text) {
        openAddOrEditTextModal?.();
      } else {
        setIsProviderActivitySlideoverOpen?.(true);
        trackProviderComponentViewedEvent(
          AnalyticsComponent.ActivitySlideover,
          AnalyticsPage.Library,
          {
            activityType: providerActivity.activityType,
          },
        );
      }
    }
  };

  const handleCheckboxInteraction = () => {
    if (!selectedProviderActivitiesObject?.[providerActivityId]) {
      if (restrictSingleSelection) {
        // Clear the object and set the selected provider activity to ensure only 1 selection
        // at a time
        setSelectedProviderActivitiesObject?.({
          [providerActivityId]: providerActivity,
        });
      } else {
        setSelectedProviderActivitiesObject?.({
          ...selectedProviderActivitiesObject,
          ...{ [providerActivityId]: providerActivity },
        });
      }
    } else {
      const remainingSelectedProviderActivities = omit(
        selectedProviderActivitiesObject,
        [providerActivityId],
      );

      setSelectedProviderActivitiesObject?.(
        remainingSelectedProviderActivities,
      );
    }
  };

  const [showPreviewButton, setShowPreviewButton] = useState(false);

  return (
    <tr
      className={classNames(
        'max-h-[48px] cursor-pointer text-ellipsis text-caption text-green-150 hover:bg-neutral-25',
        !isLastProviderActivity && 'border-b border-b-neutral-75',
      )}
      onClick={() => {
        if (!followUpMode) {
          onClickResourceRow();
        } else {
          handleCheckboxInteraction();
        }
      }}
      onMouseEnter={() => setShowPreviewButton(true)}
      onMouseLeave={() => {
        setShowPreviewButton(false);
      }}
      onContextMenu={(e) => {
        // Prevent the browser context menu from showing when right clicked
        e.preventDefault();
        if (!followUpMode) {
          setSelectedProviderActivity(providerActivity);
          setActivityActionsMenuPosition({
            x: e.pageX,
            y: e.pageY,
          });
          setShowActivityActionsMenu?.(true);
        }
      }}
    >
      <td
        className="py-0 pl-6 pr-4 sm:w-16 sm:px-8"
        onClick={(event) => {
          // Prevent the click from bubbling up to the row.
          event.stopPropagation();
        }}
      >
        {isChecked && <div className="inset-y-0 left-0 w-0.5 bg-white" />}
        <input
          type="checkbox"
          className="h-4 w-4 cursor-pointer rounded border-neutral-100 text-secondary-125 focus:ring-secondary-125 sm:left-6"
          value={providerActivityId}
          checked={isChecked}
          onChange={handleCheckboxInteraction}
        />
      </td>
      <td className={classNames(!followUpMode ? 'w-2/3' : 'w-1/2')}>
        <ActivitySummary providerActivity={providerActivity} followUpMode />
      </td>

      <td className="w-1/3">
        <ActivityDateAdded providerActivity={providerActivity} />
      </td>
      {followUpMode ? (
        <td className="flex min-h-full flex-row justify-start">
          <Button
            title="Preview"
            size="small"
            className={classNames(
              'mr-4',
              showPreviewButton ? 'visible' : 'invisible',
            )}
            theme="secondary"
            disabled={!showPreviewButton}
            onClick={(e) => {
              onClickResourceRow();
              e.stopPropagation();
            }}
          />
        </td>
      ) : sendProviderActivitiesInFollowUp ? (
        <td className="flex min-h-full flex-row justify-start">
          <Button
            onClick={(e) => {
              e.stopPropagation();
              sendProviderActivitiesInFollowUp?.([providerActivity]);
            }}
            size="small"
            theme="secondary"
            title="Send to client"
          />
        </td>
      ) : null}
      <td className="min-w-[56px]">
        {!followUpMode && (
          <IconButton
            aria-label="Resource actions"
            IconComponent={DotsHorizontalIcon}
            iconClassName="h-4 w-4"
            className="hover:bg-neutral-75"
            onClick={(e) => {
              setSelectedProviderActivity(providerActivity);
              setShowActivityActionsMenu?.(true);
              if (!followUpMode) {
                setActivityActionsMenuPosition({
                  x: e.pageX,
                  y: e.pageY,
                });
              }
              e.stopPropagation();
            }}
          />
        )}
      </td>
    </tr>
  );
};

export default LibraryTableRow;
