import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/Button';
import IconButton from '../../../../components/IconButton';
import Spinner from '../../../../svgs/Spinner';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  AdjustmentsIcon,
} from '@heroicons/react/outline';
import { Resource, Calendar } from './types';
import { Tab } from '@headlessui/react';
import { CalendarViewType } from '../utils/enums';
// import { ViewSelector } from './ViewSelector';

interface CalendarHeaderProps {
  viewType: CalendarViewType;
  onViewTypeChange: (type: CalendarViewType) => void;
  onTodayClick: () => void;
  onPrevClick: () => void;
  onNextClick: () => void;
  isLoading: boolean;
  isLoadingCalendars: boolean;
  isLoadingResources: boolean;
  calendars: Calendar[];
  resources: Resource[];
  selectedCalendarIds: string[];
  selectedResourceIds: string[];
  onCalendarSelect: (ids: string[]) => void;
  onResourceSelect: (ids: string[]) => void;
}

export const CalendarHeader: FC<CalendarHeaderProps> = ({
  viewType,
  onViewTypeChange,
  onTodayClick,
  onPrevClick,
  onNextClick,
  isLoading,
  isLoadingCalendars,
  isLoadingResources,
  calendars,
  resources,
  selectedCalendarIds,
  selectedResourceIds,
  onCalendarSelect,
  onResourceSelect,
}) => {
  const navigate = useNavigate();

  return (
    <header className="flex flex-none items-center justify-between border-b border-neutral-75 px-0 pb-4">
      <div className="flex h-[39px] w-full flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <Tab.Group
            selectedIndex={viewType === CalendarViewType.CALENDARS ? 0 : 1}
            onChange={(index) =>
              onViewTypeChange(
                index === 0
                  ? CalendarViewType.CALENDARS
                  : CalendarViewType.RESOURCES,
              )
            }
          >
            <Tab.List className="mr-4 flex space-x-1 rounded-xl bg-neutral-50 p-1">
              <Tab
                className={({ selected }) =>
                  `text-sm rounded-lg px-3 py-1 font-medium ${
                    selected
                      ? 'bg-white text-green-150 shadow'
                      : 'text-neutral-125 hover:text-green-150'
                  }`
                }
              >
                Calendars
              </Tab>
              <Tab
                className={({ selected }) =>
                  `text-sm rounded-lg px-3 py-1 font-medium ${
                    selected
                      ? 'bg-white text-green-150 shadow'
                      : 'text-neutral-125 hover:text-green-150'
                  }`
                }
              >
                Resources
              </Tab>
            </Tab.List>
          </Tab.Group>

          <Button
            title="Today"
            theme="secondary"
            className="mr-4"
            size="small"
            onClick={onTodayClick}
          />
          <IconButton
            aria-label="Previous week"
            IconComponent={ChevronLeftIcon}
            iconClassName="h-6 w-6 text-neutral-125"
            onClick={onPrevClick}
          />
          <IconButton
            aria-label="Next week"
            IconComponent={ChevronRightIcon}
            iconClassName="h-6 w-6 text-neutral-125"
            onClick={onNextClick}
          />
        </div>

        {/* TODO: Hide for now... */}
        {/* <div className="flex items-center space-x-4">
          {viewType === CalendarViewType.CALENDARS ? (
            <ViewSelector
              // label="Select Calendars"
              items={calendars}
              selectedIds={selectedCalendarIds}
              onChange={onCalendarSelect}
              getItemId={(calendar) => calendar.id}
              getItemLabel={(calendar) => calendar.name}
              loading={isLoadingCalendars}
            />
          ) : (
            <ViewSelector
              // label="Select Resources"
              items={resources}
              selectedIds={selectedResourceIds}
              onChange={onResourceSelect}
              getItemId={(resource) => resource.email}
              getItemLabel={(resource) => resource.name}
              loading={isLoadingResources}
            />
          )}
        </div> */}

        <div className="flex flex-row items-center"></div>

        <div className="flex flex-row items-center gap-x-2">
          {isLoading && <Spinner className="mr-1.5 h-5 w-5" />}
          <IconButton
            aria-label="Calendar integration"
            IconComponent={AdjustmentsIcon}
            iconClassName="text-green-150 h-5 w-5 rotate-90"
            onClick={() => navigate('/integrations/calendar')}
          />
        </div>
      </div>
    </header>
  );
};
