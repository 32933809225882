import { FC } from 'react';
import { SvgIconComponent } from '../../types/svgs';
import SectionLabel from '../../components/SectionLabel';
import classNames from 'classnames';

interface SectionProps {
  children: React.ReactNode;
  title?: string;
  IconComponent?: SvgIconComponent;
  className?: string;
  iconClassName?: string;
  SectionDetail?: React.ReactNode;
}

const Section: FC<SectionProps> = ({
  children,
  title,
  IconComponent,
  className,
  iconClassName,
  SectionDetail,
}) => {
  return (
    <div className="flex w-full flex-row items-start justify-start space-x-32">
      <div className="mr-1 flex w-80 flex-col items-start justify-start">
        {title && (
          <SectionLabel
            title={title}
            className={classNames('text-neutral-125', className)}
            iconClassName={classNames('text-neutral-125', iconClassName)}
            IconComponent={IconComponent}
          />
        )}
        {SectionDetail && SectionDetail}
      </div>
      <section className="w-full space-y-6">{children}</section>
    </div>
  );
};

export default Section;
