import { FC } from 'react';
import IconButton from './IconButton';
import HelpQuestion from '../svgs/HelpQuestion';
import { DEMO_CLIENT_NOTION_URL } from '../lib/constants';

const DemoClientInfoButton: FC = () => (
  <IconButton
    aria-label="Demo Client info"
    IconComponent={HelpQuestion}
    iconClassName="h-6 w-6 text-secondary-100"
    onClick={(e) => {
      e.stopPropagation();
      window.open(DEMO_CLIENT_NOTION_URL, '_blank', 'noopener,noreferrer');
    }}
  />
);

export default DemoClientInfoButton;
