import classNames from 'classnames';
import { FC, ReactElement } from 'react';
import {
  AssessmentQuestionType,
  AssessmentSignatureUser,
} from '../../../generated/graphql';

import { SignatureUserTypeLabel } from '../../lib/multiSignature';
import SignatureFlag from '../../svgs/SignatureFlag';
import { marked } from 'marked';

const QuestionItem: FC<{
  question: string;
  isForm?: boolean;
  questionIndex: number;
  answer?: string;
  subscale?: string | null;
  answerOptions?: string[];
  className?: string;
  answerClassName?: string;
  assessmentSignatureUsers?: AssessmentSignatureUser[];
  additionalAnswerElem?: ReactElement;
  questionType?: AssessmentQuestionType;
}> = ({
  answer,
  question,
  subscale,
  questionIndex,
  answerOptions,
  className,
  answerClassName,
  assessmentSignatureUsers,
  additionalAnswerElem,
  isForm = false,
  questionType,
}) => {
  return (
    <div
      key={questionIndex}
      className={classNames(
        'flex flex-row items-start justify-start text-caption',
        answer ? 'mb-3' : 'mb-2',
        className,
      )}
    >
      {!isForm && (
        <div className="mr-1 w-6 flex-none text-left font-bold">{`${
          questionIndex + 1
        }.`}</div>
      )}
      <div className="mb-1 flex flex-col items-start justify-start">
        <span className="break-words">
          <div
            className={classNames('editor text-green-150')}
            dangerouslySetInnerHTML={{ __html: marked(question) }}
          />
          {subscale && (
            <span className="mb-2 inline-flex items-center justify-between rounded-full bg-neutral-50 px-2 py-1 text-small-label font-normal text-neutral-150">
              {subscale}
            </span>
          )}
          {assessmentSignatureUsers && (
            <div className="mt-2 flex flex-col items-start justify-start px-2 py-1 text-small-caption font-medium text-neutral-150">
              {assessmentSignatureUsers.map((user) => (
                <div
                  key={user.signatureUserType}
                  className="mb-1.5 flex flex-row items-center justify-start"
                >
                  <SignatureFlag className="mr-1.5" />
                  {SignatureUserTypeLabel[user.signatureUserType]}
                </div>
              ))}
            </div>
          )}
        </span>
        {answerOptions && (
          <div className="mt-2 mb-1 flex flex-col items-start justify-start pl-6">
            {answerOptions.map((answer, index) => {
              return (
                <div
                  className="mb-0.5 flex flex-row items-start justify-start break-words"
                  key={index}
                >
                  <div
                    className={classNames(
                      'mt-[5px] h-[10px] min-w-[10px] rounded-full border border-neutral-125',
                      questionType === AssessmentQuestionType.MultiSelect
                        ? 'rounded-sm'
                        : 'rounded-full',
                    )}
                  />
                  <span className="ml-3 text-caption font-medium text-neutral-125">{`${answer}`}</span>
                </div>
              );
            })}
          </div>
        )}
        {(answer || additionalAnswerElem) && (
          <div className={classNames('break-words font-bold', answerClassName)}>
            {additionalAnswerElem && <div>{additionalAnswerElem}</div>}
            {answer && (
              <div
                className={classNames('editor font-medium text-green-150')}
                dangerouslySetInnerHTML={{ __html: marked(answer) }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionItem;
