import { SvgIconComponent } from '../types/svgs';

const CalendlyIcon: SvgIconComponent = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
      className={className}
    >
      <path
        fill="#006BFF"
        d="M21.852 21.064c-1.032.915-2.32 2.054-4.655 2.054H15.8c-1.69 0-3.227-.614-4.327-1.728-1.075-1.088-1.666-2.577-1.666-4.193v-1.91c0-1.617.591-3.106 1.666-4.194 1.1-1.114 2.637-1.727 4.327-1.727h1.397c2.338 0 3.623 1.138 4.655 2.053 1.07.944 1.994 1.769 4.458 1.769.376 0 .751-.03 1.122-.09l-.008-.022a8.431 8.431 0 00-.518-1.064l-1.647-2.853a8.459 8.459 0 00-7.326-4.229h-3.294a8.459 8.459 0 00-7.326 4.23l-1.647 2.852a8.46 8.46 0 000 8.46l1.647 2.852a8.46 8.46 0 007.326 4.229h3.294a8.46 8.46 0 007.326-4.23l1.647-2.847c.197-.342.37-.698.518-1.064l.008-.021a6.982 6.982 0 00-1.122-.094c-2.463 0-3.387.82-4.458 1.768"
      />
      <path
        fill="#006BFF"
        d="M17.197 11.098H15.8c-2.572 0-4.262 1.837-4.262 4.188v1.911c0 2.352 1.69 4.189 4.262 4.189h1.397c3.748 0 3.454-3.822 9.113-3.822.536 0 1.072.049 1.599.147a8.446 8.446 0 000-2.939 8.727 8.727 0 01-1.599.147c-5.661 0-5.365-3.821-9.113-3.821z"
      />
      <path
        fill="#006BFF"
        d="M31.16 19.108a7.891 7.891 0 00-3.25-1.398v.029a8.468 8.468 0 01-.472 1.646c.97.15 1.892.53 2.687 1.107 0 .008-.005.017-.007.025a14.491 14.491 0 110-8.551c0 .008.004.018.007.025a6.174 6.174 0 01-2.687 1.106c.212.532.37 1.085.472 1.649v.026a7.889 7.889 0 003.25-1.396c.927-.686.748-1.461.607-1.92a16.242 16.242 0 100 9.571c.14-.459.32-1.233-.607-1.92z"
      />
      <path
        fill="#0AE8F0"
        d="M27.432 13.097c-.37.062-.746.093-1.122.095-2.463 0-3.387-.82-4.457-1.769-1.033-.915-2.318-2.054-4.656-2.054h-1.396c-1.691 0-3.228.614-4.328 1.728-1.075 1.088-1.666 2.577-1.666 4.193v1.91c0 1.617.591 3.106 1.666 4.194 1.1 1.114 2.637 1.727 4.327 1.727h1.397c2.338 0 3.623-1.138 4.656-2.053 1.07-.944 1.993-1.769 4.457-1.769.376 0 .751.03 1.122.09a8.409 8.409 0 00.472-1.647v-.028a8.737 8.737 0 00-1.599-.146c-5.661 0-5.365 3.822-9.113 3.822h-1.396c-2.572 0-4.262-1.838-4.262-4.19v-1.914c0-2.351 1.69-4.188 4.262-4.188h1.396c3.748 0 3.454 3.82 9.113 3.82a8.65 8.65 0 001.599-.146v-.026a8.524 8.524 0 00-.472-1.649z"
      />
    </svg>
  );
};

export default CalendlyIcon;
