import classNames from 'classnames';

import hcLogo from '../../../assets/images/logo/logo-green-xl.png';

const UnauthedHeader = () => {
  return (
    <header
      className={classNames(
        'fixed z-20 flex h-16 w-screen flex-row items-center justify-between px-6 md:px-20',
        'border-b border-neutral-50 bg-neutral-25 text-caption font-medium text-green-150',
      )}
    >
      <div className="flex flex-row items-center justify-start">
        <div className="mr-5 flex h-full items-center justify-center">
          <img src={hcLogo} className="h-[36px] w-[36px]" alt="logo" />
        </div>
      </div>
    </header>
  );
};

export default UnauthedHeader;
