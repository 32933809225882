import { FC } from 'react';
import { marked } from 'marked';
import classNames from 'classnames';
import { $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown';

import {
  Control,
  Controller,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';

import {
  AssessmentQuestionType,
  FreeTextAssessmentQuestion,
  RichTextAssessmentQuestion,
  InitialsAssessmentQuestion,
  SignatureAssessmentQuestion,
  MultiSignatureAssessmentQuestion,
  MultiSelectAssessmentQuestion,
  MultipleChoiceAssessmentQuestion,
  ProgramAssessmentSignatureUserDataFragment,
  AssessmentAnswer,
} from '../../../../../generated/graphql';

import SignatureFlagName from '../../../../svgs/SignatureFlagName';

import { formatCurrentDate } from '../../../../lib/time';
import markdownToTxt from '../../../../lib/markdown-to-txt';
import { ProgramAssessmentQuestion } from '../../../../lib/program-assessment';

import Editor from '../../../../components/Editor';
import Checkbox from '../../../../components/Checkbox';
import InputGroup from '../../../../components/InputGroup';
import CompletedSignature from '../../../../components/CompletedSignature';

import { AssessmentFormValues } from './helpers';
import {
  isFreeTextAssessmentAnswer,
  isMultipleChoiceAssessmentAnswer,
  isMultiSelectAssessmentAnswer,
  isRichTextAssessmentAnswer,
} from '../../../../lib/assessments';
import { formatOxfordCommaList } from '../../../../lib/copy';

const QuestionField: FC<{
  question: string;
  isStatementQuestion?: boolean;
}> = ({ question, isStatementQuestion = false }) => {
  return (
    <div
      className={classNames(
        'text-body',
        !isStatementQuestion && 'mb-3 border-b border-neutral-75 pb-1',
      )}
    >
      <div
        className={classNames('editor text-green-150')}
        dangerouslySetInnerHTML={{ __html: marked(question) }}
      />
    </div>
  );
};

const Question: FC<{
  question: ProgramAssessmentQuestion;
  answer?: AssessmentAnswer;
  idx: number;
  register: UseFormRegister<AssessmentFormValues>;
  control: Control<AssessmentFormValues, unknown>;
  watch: UseFormWatch<AssessmentFormValues>;
  patientAssessmentSignatureUserId?: string;
  programAssessmentSignatureUsers: ProgramAssessmentSignatureUserDataFragment[];
  currentSigner: ProgramAssessmentSignatureUserDataFragment;
  isMobileDevice: boolean;
  patientName: {
    fullName: string;
    initials: string;
  };
  formIsReadOnly: boolean;
}> = ({
  question,
  answer,
  register,
  idx,
  control,
  watch,
  programAssessmentSignatureUsers,
  currentSigner,
  isMobileDevice,
  patientName,
  formIsReadOnly = false,
}) => {
  const signatureNamePath: `answers.${number}.signatureName` = `answers.${idx}.signatureName`;
  const signatureNameWatch = watch(signatureNamePath);

  const { fullName, initials } = patientName;
  const isValidSignature = signatureNameWatch === fullName;

  switch (question.questionType) {
    case AssessmentQuestionType.Statement:
      question = question as FreeTextAssessmentQuestion;
      return (
        <div className="mt-2">
          <QuestionField question={question.question} isStatementQuestion />
        </div>
      );

    case AssessmentQuestionType.FreeText:
      question = question as FreeTextAssessmentQuestion;
      return (
        <div>
          <QuestionField question={question.question} />
          <InputGroup
            key={markdownToTxt(question.question)}
            label={question.question}
            {...register(`answers.${idx}.value`, {
              required: question.isRequired,
            })}
            defaultValue={
              answer && isFreeTextAssessmentAnswer(answer) ? answer.answer : ''
            }
            labelHidden
            inputSize="extra-small"
            containerClassName="w-full md:w-[398px]"
            readOnly={formIsReadOnly}
          />
        </div>
      );

    case AssessmentQuestionType.RichText:
      question = question as RichTextAssessmentQuestion;
      return (
        <div>
          <QuestionField question={question.question} />
          <Controller
            control={control}
            name={`answers.${idx}.value`}
            defaultValue={
              answer && isRichTextAssessmentAnswer(answer) ? answer.answer : ''
            }
            rules={{
              required: {
                value: question.isRequired,
                message: 'Please provide a response',
              },
            }}
            render={({ field }) => {
              const { onChange, value } = field;
              return (
                <Editor
                  placeholder="Type your response..."
                  className="text-neutral-150"
                  initialContentMarkdown={value}
                  allowLinks={false}
                  onChange={(editorState) => {
                    editorState.read(() => {
                      onChange($convertToMarkdownString(TRANSFORMERS));
                    });
                  }}
                  onError={(error: Error) => {
                    console.error(error);
                  }}
                  readOnly={formIsReadOnly}
                />
              );
            }}
          />
        </div>
      );

    case AssessmentQuestionType.MultipleChoiceScored:
    case AssessmentQuestionType.MultipleChoice:
      const multipleChoiceQuestion =
        question as MultipleChoiceAssessmentQuestion;
      return (
        <div>
          <QuestionField question={multipleChoiceQuestion.question} />
          <Controller
            name={`answers.${idx}.value`}
            control={control}
            rules={{
              required: {
                value: multipleChoiceQuestion.isRequired,
                message: 'Please choose one of the options',
              },
            }}
            defaultValue={
              answer && isMultipleChoiceAssessmentAnswer(answer)
                ? answer.answer
                : undefined
            }
            render={({ field: { onChange, value } }) => (
              <>
                {multipleChoiceQuestion.answerOptions.map(
                  ({ answerOption }, index) => (
                    <Checkbox
                      key={index}
                      id={`answer-${idx}-${index}`}
                      type="radio"
                      className="mb-2"
                      value={answerOption}
                      checked={value === answerOption}
                      readOnly={formIsReadOnly}
                      onChange={() => onChange(answerOption)}
                      labelContent={
                        <div className="ml-2 text-caption">{answerOption}</div>
                      }
                    />
                  ),
                )}
              </>
            )}
          />
        </div>
      );

    case AssessmentQuestionType.MultiSelect:
      question = question as MultiSelectAssessmentQuestion;
      return (
        <div>
          <QuestionField question={question.question} />
          {question.answerOptions.map(({ answerOption }, index) => {
            return (
              <div key={index} className="mb-2">
                <Controller
                  name={`answers.${idx}.values`}
                  control={control}
                  defaultValue={
                    answer && isMultiSelectAssessmentAnswer(answer)
                      ? answer.answers
                      : []
                  }
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      id={`answer-${idx}-${index}`}
                      value={answerOption}
                      checked={
                        Array.isArray(value) && value.includes(answerOption)
                      }
                      readOnly={formIsReadOnly}
                      onChange={(e) => {
                        const newValue = e.target.checked
                          ? [...(value || []), answerOption]
                          : (value || []).filter((v) => v !== answerOption);
                        onChange(newValue);
                      }}
                      labelContent={
                        <div className="ml-2 text-caption">{answerOption}</div>
                      }
                    />
                  )}
                />
              </div>
            );
          })}
        </div>
      );

    case AssessmentQuestionType.Initials:
      question = question as InitialsAssessmentQuestion;
      return (
        <div>
          <QuestionField question={question.question} />
          <div className={signatureNameWatch && 'h-2'} />
          {!signatureNameWatch && (
            <div className="text-caption font-medium">
              {isMobileDevice ? 'Tap' : 'Click'} the box to add your initials
            </div>
          )}
          <Controller
            name={signatureNamePath}
            control={control}
            rules={{
              required: { value: true, message: 'Initials are required' },
              validate: (value) => value === initials,
            }}
            render={({ field }) => (
              <div className="flex flex-row items-center justify-start">
                {!field.value ? (
                  <InputGroup
                    IconLeft={SignatureFlagName}
                    iconLeftClassName="h-5 w-5 -ml-1"
                    containerClassName="mt-2 mb-1 w-[120px]"
                    className="cursor-pointer rounded-lg"
                    inputSize="extra-small"
                    disabled={field.value === initials}
                    value={field.value || ''}
                    onClick={() => !formIsReadOnly && field.onChange(initials)}
                  />
                ) : (
                  <CompletedSignature
                    signatureName={signatureNameWatch}
                    nowFormattedTimestamp={formatCurrentDate()}
                    hideNameAndRole
                  />
                )}
              </div>
            )}
          />
        </div>
      );

    case AssessmentQuestionType.Signature:
      question = question as SignatureAssessmentQuestion;

      return (
        <div>
          <QuestionField question={question.question} />
          <div
            className={classNames(
              'mb-2 mt-6 text-small-caption font-normal',
              isValidSignature && 'hidden',
            )}
          >
            Type your name, <span className="font-medium">{fullName}</span>, to
            sign electronically:
          </div>
          <InputGroup
            IconLeft={SignatureFlagName}
            containerClassName={classNames(
              'mt-3 mb-2 w-full md:w-[398px]',
              isValidSignature && 'hidden',
            )}
            className="rounded-lg pl-12"
            readOnly={formIsReadOnly}
            {...register(signatureNamePath, {
              required: { value: true, message: 'Signature is required' },
              validate: {
                nameMatch: (value) => {
                  if (value !== fullName) {
                    return 'Signature must match your full name';
                  }
                  return true;
                },
              },
            })}
          />
          {signatureNameWatch && (
            <CompletedSignature
              signatureName={signatureNameWatch}
              nowFormattedTimestamp={formatCurrentDate()}
              hideNameAndRole={!isValidSignature}
              className="mt-2"
              hideDate={!isValidSignature}
            />
          )}
        </div>
      );

    case AssessmentQuestionType.MultiSignature:
      question = question as MultiSignatureAssessmentQuestion;

      const assessmentSignatureUsersIds = question.assessmentSignatureUsers.map(
        ({ id }) => id,
      );

      const filteredSignatureUsers = programAssessmentSignatureUsers
        .sort(
          (a, b) =>
            a.assessmentSignatureUser.orderIndex -
            b.assessmentSignatureUser.orderIndex,
        )
        .filter(({ assessmentSignatureUser }) =>
          assessmentSignatureUsersIds.includes(assessmentSignatureUser.id),
        );

      const hasPreviousSignatures = filteredSignatureUsers.some(
        (user) => user.signedAt,
      );

      const showEmptyState =
        !hasPreviousSignatures &&
        !assessmentSignatureUsersIds.includes(
          currentSigner?.assessmentSignatureUser?.id,
        );

      return (
        <div>
          <QuestionField question={question.question} />
          <div>
            {showEmptyState ? (
              <p className="mt-6 text-caption text-neutral-110">
                Awaiting signature from{' '}
                {formatOxfordCommaList(
                  filteredSignatureUsers
                    .map((user) => user.providerUser?.name || user.patientName)
                    .filter(Boolean),
                )}
                .
              </p>
            ) : (
              filteredSignatureUsers.map((programAssessmentSignatureUser) => {
                const { id, signedAt, signatureName, signatureRole } =
                  programAssessmentSignatureUser;
                const isCurrentSigner = currentSigner?.id === id;

                return (
                  <div key={id}>
                    {!isCurrentSigner && signedAt && (
                      <CompletedSignature
                        signatureName={signatureName}
                        signatureRole={signatureRole}
                        programAssessmentSignatureUser={
                          programAssessmentSignatureUser
                        }
                        className="mt-6"
                      />
                    )}
                    {isCurrentSigner && (
                      <>
                        <div
                          className={classNames(
                            'mb-2 mt-6 text-small-caption font-normal',
                            isValidSignature && 'hidden',
                          )}
                        >
                          Type your name,{' '}
                          <span className="font-medium">{fullName}</span>, to
                          sign electronically:
                        </div>
                        <InputGroup
                          IconLeft={SignatureFlagName}
                          className="rounded-lg pl-12"
                          containerClassName={classNames(
                            'my-3 w-full md:w-[398px]',
                            isValidSignature && 'hidden',
                          )}
                          readOnly={formIsReadOnly}
                          {...register(signatureNamePath, {
                            required: {
                              value: true,
                              message: 'Signature is required',
                            },
                            validate: {
                              nameMatch: (value) => {
                                if (value !== fullName) {
                                  return 'Signature must match your full name';
                                }
                                return true;
                              },
                            },
                          })}
                        />
                        {signatureNameWatch && (
                          <CompletedSignature
                            signatureName={signatureNameWatch}
                            nowFormattedTimestamp={formatCurrentDate()}
                            hideNameAndRole={!isValidSignature}
                            className="mt-4"
                            hideDate={!isValidSignature}
                          />
                        )}
                      </>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>
      );
  }

  return <div></div>;
};

export default Question;
