import { useState } from 'react';
import { Transition } from '@headlessui/react';

import { defaultTransitionProps } from '../../../lib/animation';

import DeactivatePatientModal from '../../../components/Modals/DeactivatePatientModal';
import { getTimeStamp } from '../../../lib/time';
import { usePatientProfileContext } from '..';
import Panel from './Panel';
import Button from '../../../components/Button';
import { SubmitErrorHandler, useForm } from 'react-hook-form';
import {
  MAX_NAME_LENGTH,
  fullNameRegisterOptions,
  phoneRegisterOptions,
} from '../../../lib/register-options';
import Spinner from '../../../svgs/Spinner';
import toast from 'react-hot-toast';
import ToastAlert from '../../../components/ToastAlert';
import {
  PatientLifecycleStatusV2,
  useUpdatePatientMutation,
} from '../../../../generated/graphql';
import { fullNameFromFirstAndLast } from '../../../lib/string';
import ResendInvitesModal from '../../../components/Modals/ResendInvitesModal';

interface AccountFormData {
  phoneNumber: string;
  name: string;
}

const Account = () => {
  const { patient, refetchPatient, isMobileActiveProfile } =
    usePatientProfileContext();
  const [updatePatientMutation] = useUpdatePatientMutation();

  const [isDeactivatePatientModalOpen, setIsDeactivatePatientModalOpen] =
    useState(false);
  const [isResendInvitesModalOpen, setIsResendInvitesModalOpen] =
    useState(false);

  const [submitting, setSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<AccountFormData>({
    reValidateMode: 'onChange',
    defaultValues: {
      phoneNumber: patient?.phoneNumber || '',
      name: fullNameFromFirstAndLast(patient?.firstName, patient?.lastName),
    },
  });

  const handleErrors: SubmitErrorHandler<AccountFormData> = (errors) => {
    console.log('errors:', errors);
  };

  const contactData = [
    {
      label: 'Email',
      value: patient?.email,
      disabled: true,
      readOnly: true,
    },
    {
      label: 'Name',
      register: register('name', fullNameRegisterOptions),
      errorMessage: errors.name?.message,
      maxLength: MAX_NAME_LENGTH,
    },
    {
      label: 'Phone',
      placeholder: '(817) 569-8900',
      disabled: false,
      register: register('phoneNumber', phoneRegisterOptions),
      errorMessage: errors.phoneNumber?.message,
    },
  ];

  const accountData = isMobileActiveProfile
    ? [
        {
          label: 'Mobile app activation date',
          value: getTimeStamp(patient?.startTime, false),
          disabled: true,
          readOnly: true,
        },
      ]
    : [
        {
          label: 'Last mobile app invitation date',
          value: patient?.onboardingLastProgramInviteSentAt
            ? getTimeStamp(patient?.onboardingLastProgramInviteSentAt, false)
            : 'Not invited yet',
          disabled: true,
          readOnly: true,
        },
      ];

  const onSubmit = async (formData: AccountFormData) => {
    try {
      setSubmitting(true);
      await updatePatientMutation({
        variables: {
          input: {
            programId: patient?.programInstanceId,
            name: formData.name.trim(),
            phoneNumber: formData.phoneNumber,
          },
        },
      });
      refetchPatient?.();
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message={'Successfully saved your changes'}
        />
      ));
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level="error"
          message={`Failed to save changes.`}
        />
      ));
      console.error(err as Error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Transition
        show={Boolean(patient)}
        {...defaultTransitionProps}
        className="max-h-[calc(100vh-208px)] w-full overflow-y-scroll"
      >
        <div className="flex max-w-[700px] flex-grow flex-col overflow-y-auto pb-10">
          <Panel title="Contact information" data={contactData} />
          <Panel
            title="Mobile app status"
            data={accountData}
            {...(!isMobileActiveProfile && {
              onMobileInviteButtonClick: () => {
                setIsResendInvitesModalOpen(true);
              },
            })}
          />
          <Panel
            title="Settings"
            hasArchiveClient
            setIsDeactivatePatientModalOpen={setIsDeactivatePatientModalOpen}
          />
          <div className="mt-5 w-full">
            <Button
              title="Save changes"
              size="medium"
              onClick={handleSubmit(onSubmit, handleErrors)}
              disabled={submitting || !isDirty}
              {...(submitting && {
                IconComponent: Spinner,
              })}
            />
          </div>
        </div>
      </Transition>
      <DeactivatePatientModal
        isSinglePatient
        singlePatient={patient}
        isModalOpen={isDeactivatePatientModalOpen}
        patientsObject={null}
        setClosed={() => setIsDeactivatePatientModalOpen(false)}
      />
      {!isMobileActiveProfile && (
        <ResendInvitesModal
          isSinglePatient
          isModalOpen={isResendInvitesModalOpen}
          singlePatient={patient}
          patientLifecycleStatus={PatientLifecycleStatusV2.Invited}
          setClosed={() => setIsResendInvitesModalOpen(false)}
          refetchPatient={refetchPatient}
        />
      )}
    </>
  );
};

export default Account;
