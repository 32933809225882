import React from 'react';
import classNames from 'classnames';

const ClientSessionsPagination: React.FC<{
  currentPage: number;
  totalPages: number;
  onPreviousPage: () => void;
  onNextPage: () => void;
}> = ({ currentPage, totalPages, onPreviousPage, onNextPage }) => {
  return (
    <div className="flex items-center justify-center space-x-4 py-4">
      <button
        onClick={onPreviousPage}
        disabled={currentPage === 1}
        className={classNames(
          'rounded-md px-4 py-2 text-small-caption',
          'border border-neutral-75 bg-white text-green-150',
          'hover:bg-neutral-25 focus:outline-none focus:ring-2 focus:ring-secondary-50',
          'disabled:cursor-not-allowed disabled:opacity-50',
        )}
      >
        Previous
      </button>

      <span className="text-caption text-neutral-125">
        Page {currentPage} of {totalPages}
      </span>

      <button
        onClick={onNextPage}
        disabled={currentPage === totalPages}
        className={classNames(
          'rounded-md px-4 py-2 text-small-caption',
          'border border-neutral-75 bg-white text-green-150',
          'hover:bg-neutral-25 focus:outline-none focus:ring-2 focus:ring-secondary-50',
          'disabled:cursor-not-allowed disabled:opacity-50',
        )}
      >
        Next
      </button>
    </div>
  );
};

export default ClientSessionsPagination;
