import { useNavigate } from 'react-router-dom';
import { GlobeAltIcon } from '@heroicons/react/outline';
import IconButton from '../IconButton';

const MyPageButton = () => {
  const navigate = useNavigate();

  return (
    <div className="px-3">
      <IconButton
        IconComponent={GlobeAltIcon}
        onClick={() => navigate('/your-page')}
        className="h-8 w-8 text-neutral-125 focus:ring-0"
        aria-label={'My Page'}
      />
    </div>
  );
};

export default MyPageButton;
