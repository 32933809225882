import { useState } from 'react';

import {
  useNylasAuthUrlLazyQuery,
  useRevokeNylasIntegrationMutation,
} from '../../../../generated/graphql';

import { useAuth } from '../../../../contexts/AuthContext';
import Integration from '../../../components/Integration';
import PageContainer from '../../../components/Containers/PageContainer';
import IllustrationCalendar from '../../../svgs/IllustrationCalendar';
import { trackProviderEvent } from '../../../../lib/analytics';
import { ProviderAnalyticsEvent } from '../../../../lib/analytics';
import GoogleCalLogo from '../../../svgs/GoogleCalLogo';
import SignInWithGoogle from '../../../svgs/SignInWithGoogle';
import IconButton from '../../../components/IconButton';

const NylasIntegration: React.FC = () => {
  const [isIntegrationLoading, setIsIntegrationLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { authedProviderUser, refreshAuthedProviderUser } = useAuth();

  const [revokeNylasIntegrationMutation] = useRevokeNylasIntegrationMutation();

  const [getNylasAuthUrl] = useNylasAuthUrlLazyQuery();

  const onPressConnectNylas = async () => {
    trackProviderEvent(ProviderAnalyticsEvent.AddNylasIntegrationAttempted);

    const response = await getNylasAuthUrl();
    if (response.data?.nylasAuthUrl) {
      window.location.href = response.data?.nylasAuthUrl;
    }
  };

  const onPressDisconnectNylas = async () => {
    setIsIntegrationLoading(true);
    setSuccessMessage(null);
    setErrorMessage(null);

    try {
      await revokeNylasIntegrationMutation();
      await refreshAuthedProviderUser();
      setSuccessMessage(
        `Your calendar is now disconnected from Homecoming. If you'd like to reconnect your calendar, you can do so at any time.`,
      );
    } catch (error) {
      setErrorMessage((error as Error).message);
    } finally {
      setIsIntegrationLoading(false);
    }
  };

  return (
    <PageContainer containerClassName="min-h-[calc(100vh-200px)] flex text-neutral-125 items-center">
      <Integration
        name="Calendar sync"
        logo={<IllustrationCalendar className="h-24 w-24 text-blue-100" />}
        isConnected={Boolean(authedProviderUser?.hasNylasIntegration)}
        isLoading={isIntegrationLoading}
        CustomConnectButton={
          <IconButton
            IconComponent={SignInWithGoogle}
            aria-label="Sign in with Google"
            className="focus:rounded-full"
            hoverClassName="hover:bg-transparent"
            onClick={onPressConnectNylas}
            disabled={isIntegrationLoading}
          />
        }
        onPressConnect={onPressConnectNylas}
        onPressDisconnect={onPressDisconnectNylas}
        successMessage={successMessage}
        dismissSuccessMessage={() => setSuccessMessage(null)}
        errorMessage={errorMessage}
        dismissErrorMessage={() => setErrorMessage(null)}
        pricing="Free"
        extraSupportSections={[
          {
            title: 'Supported calendars',
            content: (
              <div className="flex flex-row items-center gap-x-2">
                <GoogleCalLogo className="h-4 w-4 flex-shrink-0" />
                <span>Google Calendar</span>
              </div>
            ),
          },
        ]}
        description={
          <>
            Easily sync your Google calendar with Homecoming to view all your
            appointments and availability.
          </>
        }
        features={[
          {
            title: 'Organize your workflow with all your events in one place',
            description:
              'With real-time calendar sync, your calendar stays organized and always up to date, without the need for juggling multiple calendars.',
          },
          {
            title: 'Real-time updates and avoid scheduling conflicts.',
            description:
              'Any changes made to your calendar are instantly reflected, preventing double bookings and ensuring both you and your clients stay in sync with accurate availability.',
          },
        ]}
        disconnectConfirmText="Your calendar events will no longer be synced to Homecoming, and you will no longer be able to track session data through Homecoming."
      ></Integration>
    </PageContainer>
  );
};

export default NylasIntegration;
