import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { logger } from '../../../../../lib/logger';
import { CalendarViewType } from '../utils/enums';

export const useDemoEvents = (
  viewType: CalendarViewType,
  realEvents: any[],
) => {
  const demoEvents = useMemo(() => {
    if (viewType !== CalendarViewType.RESOURCES) {
      return realEvents;
    }

    logger.debug('📅 useDemoEvents generating resource events');

    return [
      {
        id: 'demo-1',
        title: 'Team Meeting',
        start: DateTime.now().toISO(),
        end: DateTime.now().plus({ hours: 2 }).toISO(),
        resourceId: 'room1@demo.com',
        backgroundColor: '#6e77747a',
        extendedProps: {
          readOnly: true,
        },
      },
      {
        id: 'demo-2',
        title: 'Client Presentation',
        start: DateTime.now().plus({ hours: 3 }).toISO(),
        end: DateTime.now().plus({ hours: 4 }).toISO(),
        resourceId: 'room2@demo.com',
        backgroundColor: '#6e77747a',
        extendedProps: {
          readOnly: true,
        },
      },
      {
        id: 'demo-3',
        title: 'Sprint Planning',
        start: DateTime.now().plus({ days: 1 }).toISO(),
        end: DateTime.now().plus({ days: 1, hours: 2 }).toISO(),
        resourceId: 'room3@demo.com',
        backgroundColor: '#6e77747a',
        extendedProps: {
          readOnly: true,
        },
      },
      {
        id: 'demo-4',
        title: 'Product Demo',
        start: DateTime.now().minus({ days: 1, hours: 5 }).toISO(),
        end: DateTime.now().minus({ days: 1 }).toISO(),
        resourceId: 'room4@demo.com',
        backgroundColor: '#6e77747a',
        extendedProps: {
          readOnly: true,
        },
      },
      {
        id: 'demo-5',
        title: 'Strategy Session',
        start: DateTime.now().minus({ days: 1, hours: 6 }).toISO(),
        end: DateTime.now().minus({ days: 1 }).toISO(),
        resourceId: 'room1@demo.com',
        backgroundColor: '#6e77747a',
        extendedProps: {
          readOnly: true,
        },
      },
      {
        id: 'demo-6',
        title: 'Quarterly Review',
        start: DateTime.now().minus({ days: 1, hours: 7 }).toISO(),
        end: DateTime.now().minus({ days: 1 }).toISO(),
        resourceId: 'room2@demo.com',
        backgroundColor: '#6e77747a',
        extendedProps: {
          readOnly: true,
        },
      },
    ];
  }, [viewType, realEvents]);

  return {
    events: demoEvents,
    isLoading: false,
  };
};
