import { FC } from 'react';
import { PlusSmIcon } from '@heroicons/react/outline';

import Button from '../../components/Button';

const CreateACollectionButton: FC<{
  createProviderCollection: (
    addSelectedProviderActivityToCollection?: boolean,
  ) => void;
}> = ({ createProviderCollection }) => (
  <Button
    IconComponent={PlusSmIcon}
    noFocus
    size="small"
    theme="secondary"
    title="Create a collection"
    iconClassName="text-secondary-100 h-[20px] w-[20px]"
    className="mt-1 mb-3 ml-1 w-[194px] justify-between rounded-md pl-5 text-left"
    onClick={() => createProviderCollection()}
  />
);

export default CreateACollectionButton;
