import { useState, useEffect } from 'react';

const useScrollTitle = (titleElementId: string, threshold = 80) => {
  const [title, setTitle] = useState<string | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const titleElement = document.querySelector(`#${titleElementId}`);
      if (titleElement) {
        const rect = titleElement.getBoundingClientRect();
        if (rect.top < threshold) {
          setTitle(titleElement.textContent);
        } else {
          setTitle(null);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [titleElementId, threshold]);

  return title;
};

export default useScrollTitle;
