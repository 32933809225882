import React from 'react';
import { SparklesIcon } from '@heroicons/react/outline';
import { getSignatureWarningMessage } from './helpers';
import {
  ProgramActivityDataFragment,
  UserType,
} from '../../../generated/graphql';
import classNames from 'classnames';

interface WarningMessageProps {
  isSmallStyle?: boolean;
  isPendingSignature: boolean;
  voidedAt: string | null;
  isCurrentSigner: boolean;
  programActivity: ProgramActivityDataFragment;
  userType: UserType;
  authedProviderUserId: string;
  className?: string;
}

export const WarningMessage: React.FC<WarningMessageProps> = ({
  isSmallStyle = false,
  isPendingSignature,
  voidedAt,
  isCurrentSigner,
  programActivity,
  userType,
  authedProviderUserId,
  className,
}) => {
  const signatureWarningMessage = getSignatureWarningMessage(
    programActivity,
    userType,
    authedProviderUserId,
  );

  if (isSmallStyle || voidedAt || !isPendingSignature) {
    return null;
  }

  return (
    <div
      className={classNames(
        'mb-5 flex w-full flex-row items-start justify-start rounded-md bg-neutral-25 p-4 group-hover:bg-neutral-50',
        className,
      )}
    >
      <SparklesIcon className="mr-2 h-5 w-5 text-neutral-125" />
      <div className="w-full text-caption text-neutral-125">
        {isCurrentSigner
          ? "It's your turn to complete and sign."
          : signatureWarningMessage}
      </div>
    </div>
  );
};
