import { FC } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import {
  PencilAltIcon,
  ExternalLinkIcon,
  TrashIcon,
} from '@heroicons/react/outline';

import { ProviderActivityType } from '../../types/activity';

import {
  trackProviderEvent,
  AnalyticsPage,
  AnalyticsComponent,
  CommonAnalyticsEvent,
} from '../../../lib/analytics';

import { isAssessmentAForm } from '../../lib/assessments';

import { getTimeStamp } from '../../lib/time';
import { getProviderActivityData } from '../../lib/providerActivity';

import Button from '../../components/Button';
import Slideover from '../../components/Slideover';
import AudioPlayer from '../../components/AudioPlayer';
import SlideoverSection from '../../components/SlideoverSection';
import QuestionsSection from '../../components/Assessments/QuestionsSection';
import InterpretationSection from '../../components/Assessments/InterpretationSection';
import ResponseOptionsSection from '../../components/Assessments/ResponseOptionsSection';
import { ActivityDataFragment } from '../../../generated/graphql';

const ProviderActivitySlideover: FC<{
  isOpen: boolean;
  onClose: () => void;
  followUpMode?: boolean;
  selectedProviderActivity: ActivityDataFragment | undefined;
  setIsConfirmArchiveActivityModalOpen?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}> = ({
  isOpen,
  onClose,
  followUpMode = false,
  selectedProviderActivity,
  setIsConfirmArchiveActivityModalOpen,
}) => {
  const navigate = useNavigate();

  const hasArchivalFunctionality = Boolean(
    setIsConfirmArchiveActivityModalOpen,
  );

  const isActivityAudio =
    selectedProviderActivity?.__typename === ProviderActivityType.Audio;

  const isActivityImage =
    selectedProviderActivity?.__typename === ProviderActivityType.Image;

  const isActivityLink =
    selectedProviderActivity?.__typename === ProviderActivityType.Link;

  const isActivityPDF =
    selectedProviderActivity?.__typename === ProviderActivityType.PDF;

  const isActivityAssessment =
    selectedProviderActivity?.__typename === ProviderActivityType.Assessment;

  const isActivityForm =
    isActivityAssessment &&
    isAssessmentAForm(selectedProviderActivity.assessment);

  const providerActivityData =
    selectedProviderActivity &&
    getProviderActivityData(selectedProviderActivity);

  const ActivityThumbnail = providerActivityData?.ActivityThumbnail;
  const activityImage = providerActivityData?.activityImage;

  const isImageAudioLinkOrPDF =
    isActivityImage || isActivityAudio || isActivityLink || isActivityPDF;

  const TitleComponent = (
    <>
      {isActivityForm && (
        <div className="mt-3 flex flex-row items-center">
          {!followUpMode && (
            <Button
              title="Edit form"
              theme="secondary"
              size="small"
              IconComponent={PencilAltIcon}
              onClick={() =>
                navigate(
                  `/library/forms/${selectedProviderActivity?.assessment.id}`,
                  {
                    state: {
                      fromPage: 'library',
                    },
                  },
                )
              }
            />
          )}
          {hasArchivalFunctionality && (
            <Button
              title="Delete form"
              iconClassName="text-red-100"
              theme="destructive"
              className="ml-3"
              IconComponent={TrashIcon}
              size="small"
              onClick={() => {
                setIsConfirmArchiveActivityModalOpen?.(true);
                trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
                  buttonName: 'Delete resource',
                  originPage: AnalyticsPage.Library,
                  originComponent: AnalyticsComponent.ActivitySlideover,
                });
              }}
            />
          )}
        </div>
      )}
      {isImageAudioLinkOrPDF && (
        <div className="flex w-full flex-col items-start justify-start">
          <div className="flex flex-row items-center justify-start">
            {Boolean(activityImage) && (
              <img
                src={activityImage}
                className="h-[88px] w-[88px] rounded-lg object-cover"
                alt="Activity thumbnail"
              />
            )}
            {!activityImage && ActivityThumbnail && (
              <ActivityThumbnail className="min-h-[88px] min-w-[88px] rounded-lg" />
            )}
            <div className="ml-4 flex flex-col items-start justify-center">
              <div className="text-small-caption text-neutral-125/75">
                Added {getTimeStamp(providerActivityData?.createdAt, false)}
              </div>
              <div className="my-1 text-left text-category font-medium text-green-150">
                {providerActivityData?.activityTitle}
              </div>
              {providerActivityData?.activitySubtitle && (
                <div className="text-caption text-neutral-125/75">
                  {providerActivityData?.activitySubtitle}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );

  return (
    <Slideover
      isAboveModal={followUpMode}
      isOpen={isOpen}
      onClose={onClose}
      {...(!isImageAudioLinkOrPDF && {
        title: providerActivityData?.activityTitle,
        subtitle: providerActivityData?.activitySubtitle,
      })}
      {...((isImageAudioLinkOrPDF || isActivityForm) && {
        titleComponent: TitleComponent,
      })}
    >
      {isImageAudioLinkOrPDF && (
        <div
          className={classNames(
            'flex w-full items-start justify-start',
            isActivityAudio ? 'flex-col' : 'flex-row',
          )}
        >
          {isActivityAudio && (
            <div className="w-full rounded-md bg-white p-4">
              <AudioPlayer
                playerId={
                  providerActivityData?.activityId ?? 'provider-activity-player'
                }
                mediaUrl={providerActivityData?.activityMediaUrl}
                duration={providerActivityData?.activityDuration}
                triggerStop={!isOpen}
              />
            </div>
          )}
          <div className="flex w-full flex-row items-center justify-start">
            {!isActivityAudio && (
              <Button
                title="Preview resource"
                theme="secondary-white"
                size="small"
                IconComponent={ExternalLinkIcon}
                onClick={(e) => {
                  window.open(providerActivityData?.activityMediaUrl, '_blank');
                  e.preventDefault();
                }}
              />
            )}
            {hasArchivalFunctionality && (
              <Button
                title="Delete"
                theme="secondary-white"
                size="small"
                iconClassName="text-red-100"
                IconComponent={TrashIcon}
                onClick={() => setIsConfirmArchiveActivityModalOpen?.(true)}
                className={classNames(
                  'text-red-100',
                  !isActivityAudio ? 'ml-4' : 'mt-6',
                )}
              />
            )}
          </div>
        </div>
      )}

      {isActivityAssessment && (
        <>
          {selectedProviderActivity?.assessment?.instructions && (
            <SlideoverSection
              title="Instructions"
              textContent={selectedProviderActivity?.assessment.instructions}
            />
          )}
          {isActivityForm ? (
            <SlideoverSection
              title="Description"
              textContent={selectedProviderActivity?.assessment?.description}
            />
          ) : (
            <InterpretationSection
              assessment={selectedProviderActivity?.assessment}
            />
          )}
          <ResponseOptionsSection
            questions={selectedProviderActivity?.assessment?.questions}
          />
          <QuestionsSection
            questionFrame={selectedProviderActivity?.assessment?.questionFrame}
            questions={selectedProviderActivity?.assessment?.questions}
            isActivityForm={isActivityForm}
          />
        </>
      )}
    </Slideover>
  );
};

export default ProviderActivitySlideover;
