import NotificationItem from './NotificationItem';
import OnboardingNotification from './OnboardingNotification';
import { isNotification } from './helpers';
import { Transition } from '@headlessui/react';
import { defaultTransitionProps } from '../../lib/animation';
import { useMemo } from 'react';
import {
  getIconComponent,
  SERVICE_ICONS,
} from '../../components/ServiceCard/helpers';
import { shouldUseProviderPrimaryColor } from '../../lib/colors';
import classNames from 'classnames';

interface InboxViewProps {
  allNotifications: Array<any>;
  initialDelay: number;
  providerPrimaryColor?: string;
}

const ICON_STORAGE_KEY = 'currentInboxEmptyIcon';
const TWENTY_FOUR_HOURS = 24 * 60 * 60 * 1000;

const getNextServiceIcon = () => {
  const stored = localStorage.getItem(ICON_STORAGE_KEY);
  let storedData;

  try {
    storedData = stored ? JSON.parse(stored) : null;
  } catch (e) {
    storedData = null;
  }

  const now = new Date().getTime();

  // If we have stored data and it's less than 24 hours old, use it
  if (storedData && now - storedData.timestamp < TWENTY_FOUR_HOURS) {
    return storedData.icon;
  }

  // Get next icon in sequence
  const currentIndex = storedData ? SERVICE_ICONS.indexOf(storedData.icon) : -1;
  const nextIndex = (currentIndex + 1) % SERVICE_ICONS.length;
  const nextIcon = SERVICE_ICONS[nextIndex];

  // Store new icon with timestamp
  localStorage.setItem(
    ICON_STORAGE_KEY,
    JSON.stringify({
      icon: nextIcon,
      timestamp: now,
    }),
  );

  return nextIcon;
};

const InboxView = ({
  allNotifications,
  initialDelay,
  providerPrimaryColor,
}: InboxViewProps) => {
  const currentServiceIcon = useMemo(() => getNextServiceIcon(), []);
  const IconComponent = getIconComponent(currentServiceIcon);

  const useProviderPrimaryColor =
    shouldUseProviderPrimaryColor(providerPrimaryColor);

  return (
    <>
      {allNotifications.map((notification, index) => (
        <Transition
          key={index}
          show={true}
          appear={true}
          {...defaultTransitionProps}
          style={{ transitionDelay: `${initialDelay + index * 50}ms` }}
        >
          {isNotification(notification) ? (
            <NotificationItem
              key={`notification-${notification.id}`}
              notification={notification}
            />
          ) : (
            <OnboardingNotification
              key={`onboardingNotification-${index}`}
              step={notification}
            />
          )}
        </Transition>
      ))}
      {!Boolean(allNotifications.length) && (
        <div className="flex w-full flex-col items-center justify-center">
          <IconComponent
            className={classNames(
              'mb-2.5 h-16 w-16',
              useProviderPrimaryColor ? 'text-primary-125' : 'text-green-125',
            )}
          />
          <div className="text-center font-light text-neutral-200">
            You're all caught up
          </div>
        </div>
      )}
    </>
  );
};

export default InboxView;
