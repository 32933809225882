import { useState } from 'react';
import classNames from 'classnames';

import FirstServiceCard from './FirstServiceCard';
import {
  useProviderServicesQuery,
  ProviderService,
  useArchiveProviderServiceMutation,
} from '../../../generated/graphql';
import ConfirmDeleteModal from '../ConfirmDeleteModal';
import ServiceCard from '../ServiceCard';
import { customToast } from '../ToastAlert/customToast';
import { useHomecomingLoader } from '../../hooks/useHomecomingLoader';
import { useAuth } from '../../../contexts/AuthContext';

const ServicesModule: React.FC<{
  className?: string;
}> = ({ className }) => {
  const { authedProviderUser } = useAuth();
  const {
    data: providerServicesData,
    refetch: refetchProviderServices,
    loading: loadingProviderServices,
  } = useProviderServicesQuery();
  const providerServices = providerServicesData?.providerServices;
  const HomecomingLoader = useHomecomingLoader();

  const [isDeleteServiceModalOpen, setIsDeleteServiceModalOpen] =
    useState(false);

  const [providerServiceToDelete, setProviderServiceToDelete] =
    useState<ProviderService | null>(null);

  const handleDeleteService = (service: ProviderService) => {
    setProviderServiceToDelete(service);
    setIsDeleteServiceModalOpen(true);
  };

  const [archiveProviderService, { loading: archiveProviderServiceLoading }] =
    useArchiveProviderServiceMutation();

  return (
    <>
      <div
        className={classNames(
          'px-6 py-10',
          className,
          loadingProviderServices && 'flex justify-center',
        )}
      >
        {loadingProviderServices ? (
          HomecomingLoader
        ) : !providerServices?.length ? (
          <FirstServiceCard />
        ) : (
          <div className="flex flex-wrap justify-start gap-6">
            {providerServices?.map((providerService: ProviderService) => (
              <ServiceCard
                key={providerService.id}
                service={providerService}
                handleDeleteService={handleDeleteService}
                providerPrimaryColor={authedProviderUser?.primaryColor}
              />
            ))}
          </div>
        )}
      </div>
      <ConfirmDeleteModal
        isOpen={isDeleteServiceModalOpen}
        setClosed={() => setIsDeleteServiceModalOpen(false)}
        title={`Delete ${providerServiceToDelete?.name}`}
        fetching={loadingProviderServices || archiveProviderServiceLoading}
        actionButtonTitle="Delete service"
        performDelete={async () => {
          try {
            await archiveProviderService({
              variables: { providerServiceId: providerServiceToDelete.id },
            });
            await refetchProviderServices();
          } catch (error) {
            customToast.error('Failed to delete service');
          }
        }}
      />
    </>
  );
};

export default ServicesModule;
