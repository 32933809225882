import { DateTime } from 'luxon';
import { CreateEventFormData, EventParticipant } from '../components/types';
import { NylasCalendarEvent } from '../../../../../generated/graphql';
import { whenToCalendarEvent } from './whenHelpers';

export const hasDirtyFields = (
  dirtyFields: Record<string, any>,
  fields: string[],
): boolean => {
  return fields.some((field) => {
    const parts = field.split('.');
    let current = dirtyFields;

    for (const part of parts) {
      if (!current[part]) return false;
      current = current[part];
    }

    return true;
  });
};

export const getDefaultEventFormValues = (
  selectInfo: any | null,
  defaultStartDateTime = DateTime.now(),
): CreateEventFormData => {
  const startDateTime = selectInfo
    ? DateTime.fromJSDate(selectInfo.start)
    : defaultStartDateTime;

  const endDateTime = selectInfo
    ? DateTime.fromJSDate(selectInfo.end)
    : startDateTime.plus({ hours: 1 });

  const roundedStartDateTime = startDateTime.set({
    second: 0,
    millisecond: 0,
    minute: Math.round(startDateTime.minute / 15) * 15,
  });

  const roundedEndDateTime = endDateTime.set({
    second: 0,
    millisecond: 0,
    minute: Math.round(endDateTime.minute / 15) * 15,
  });

  return {
    title: '',
    description: '',
    location: '',
    isAllDay: selectInfo?.allDay ?? false,
    when: {
      startDate: roundedStartDateTime.toFormat('yyyy-MM-dd'),
      startTime: roundedStartDateTime.toFormat('HH:mm'),
      endDate: roundedEndDateTime.toFormat('yyyy-MM-dd'),
      endTime: roundedEndDateTime.toFormat('HH:mm'),
      startTimezone: 'UTC',
      endTimezone: 'UTC',
    },
    participants: [],
  };
};

export const getEditEventFormValues = (
  event: NylasCalendarEvent | null,
): CreateEventFormData => {
  if (!event) return getDefaultEventFormValues(null);

  const calendarEvent = whenToCalendarEvent(event.when, {});
  if (!calendarEvent) return getDefaultEventFormValues(null);

  const startDateTime = DateTime.fromISO(calendarEvent.start);
  const endDateTime = DateTime.fromISO(calendarEvent.end);

  return {
    title: event.title || '',
    description: event.description || '',
    location: event.location || '',
    when: {
      startDate: startDateTime.toFormat('yyyy-MM-dd'),
      endDate: endDateTime.toFormat('yyyy-MM-dd'),
      startTime: calendarEvent.allDay ? '' : startDateTime.toFormat('HH:mm'),
      endTime: calendarEvent.allDay ? '' : endDateTime.toFormat('HH:mm'),
      startTimezone: startDateTime.zoneName || 'UTC',
      endTimezone: endDateTime.zoneName || 'UTC',
    },
    isAllDay: calendarEvent.allDay,
    participants: (event.participants as EventParticipant[]) || [],
  };
};
