import React from 'react';
import { TrashIcon } from '@heroicons/react/outline';
import { EventParticipant } from './types';

interface EventParticipantsListProps {
  participants: EventParticipant[];
  organizer?: EventParticipant;
  onRemoveParticipant: (email: string, index: number) => void;
}

const EventParticipantsList: React.FC<EventParticipantsListProps> = ({
  participants,
  organizer,
  onRemoveParticipant,
}) => {
  return (
    <div className="space-y-2">
      {organizer && (
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <span className="text-small-caption text-neutral-150">
              {organizer.email} (Organizer)
            </span>
          </div>
        </div>
      )}

      {participants.map((participant, index) => (
        <div
          key={participant.email}
          className="flex items-center justify-between"
        >
          <div className="flex items-center">
            <span className="text-small-caption text-neutral-150">
              {participant.email}
            </span>
          </div>
          <button
            type="button"
            onClick={() => onRemoveParticipant(participant.email, index)}
            className="text-neutral-150 hover:text-red-150"
          >
            <TrashIcon className="h-4 w-4" />
          </button>
        </div>
      ))}
    </div>
  );
};

export default EventParticipantsList;
