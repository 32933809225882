import { FC, useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { Transition } from '@headlessui/react';

import {
  NotificationFrequency,
  useMeProviderUserNotificationControlsLazyQuery,
  useUpdateProviderUserNotificationControlsMutation,
} from '../../../generated/graphql';
import Spinner from '../../svgs/Spinner';

import ToastAlert from '../../components/ToastAlert';
import { defaultTransitionProps } from '../../lib/animation';
import {
  ChatIcon,
  DocumentReportIcon,
  UserIcon,
} from '@heroicons/react/outline';
import { SvgIconComponent } from '../../types/svgs';
import BoxCheck from '../../svgs/BoxCheck';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import debounce from 'lodash.debounce';

const FORM_DEBOUNCE_MS = 50;

interface NotificationControlsFormData {
  patientActivationEmailEnabled: boolean;
  patientCommentEmailEnabled: boolean;
  patientCommentEmailOption: NotificationFrequency | null;
  patientActivityCompletionEmailEnabled: boolean;
  patientAssessmentCompletionEmailEnabled: boolean;
}

const NotificationsSettingSubSection: FC<{
  name: string;
  description: string;
  Icon: SvgIconComponent;
  emailEnabledFormFieldName: keyof NotificationControlsFormData;
  emailOptionFormFieldName?: keyof NotificationControlsFormData;
  defaultEmailOption: NotificationFrequency;
}> = ({
  name,
  description,
  Icon,
  emailEnabledFormFieldName,
  emailOptionFormFieldName,
  defaultEmailOption,
}) => {
  const { register, watch, setValue } =
    useFormContext<NotificationControlsFormData>();

  const emailEnabled = watch(emailEnabledFormFieldName);

  const emailEnabledInputId = `${emailEnabledFormFieldName}Input`;
  const emailDailyDigestOptionInputId = `${emailOptionFormFieldName}DailyDigestInput`;
  const emailRealTimeOptionInputId = `${emailOptionFormFieldName}RealTimeInput`;

  return (
    <div className="flex flex-row py-5">
      <div className="w-10">
        <Icon className="h-6 w-6 text-secondary-100" />
      </div>
      <div className="flex-1">
        <div>
          <div className="mb-1 text-caption font-medium text-green-150">
            {name}
          </div>
          <div className="text-small-caption text-neutral-125">
            {description}
          </div>
        </div>

        <div className="mt-5">
          <div className="flex items-center">
            <input
              id={emailEnabledInputId}
              type="checkbox"
              className="mr-2 h-5 w-5 cursor-pointer rounded border-neutral-100 text-secondary-125 focus:ring-secondary-125"
              {...register(emailEnabledFormFieldName, {
                onChange: (e) => {
                  if (e.target.checked && emailOptionFormFieldName) {
                    setValue(emailOptionFormFieldName, defaultEmailOption);
                  }
                },
              })}
            />
            <label
              className="cursor-pointer text-caption text-neutral-125"
              htmlFor={emailEnabledInputId}
            >
              Email notification
            </label>
          </div>
          {emailOptionFormFieldName && emailEnabled && (
            <div className="ml-7">
              <div className="mt-2 flex items-center">
                <input
                  id={emailDailyDigestOptionInputId}
                  type="radio"
                  value={NotificationFrequency.DailyDigest}
                  className="mr-3 h-5 w-5 cursor-pointer rounded-full border-neutral-100 text-secondary-125 focus:ring-secondary-125"
                  {...register(emailOptionFormFieldName)}
                />
                <label
                  className="cursor-pointer"
                  htmlFor={emailDailyDigestOptionInputId}
                >
                  <div className="text-caption text-neutral-125">
                    Email digest
                  </div>
                  <div className="text-small-caption text-neutral-110">
                    Get one email per day with all unread comments
                  </div>
                </label>
              </div>
              <div className="mt-2 flex items-center">
                <input
                  id={emailRealTimeOptionInputId}
                  type="radio"
                  value={NotificationFrequency.RealTime}
                  className="mr-3 h-5 w-5 cursor-pointer rounded-full border-neutral-100 text-secondary-125 focus:ring-secondary-125"
                  {...register(emailOptionFormFieldName)}
                />
                <label
                  className="cursor-pointer"
                  htmlFor={emailRealTimeOptionInputId}
                >
                  <div className="text-caption text-neutral-125">
                    Email for each comment
                  </div>
                  <div className="text-small-caption text-neutral-110">
                    Get a real-time email for each comment you receive
                  </div>
                </label>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const NotificationsSection: FC = () => {
  const [
    notificationControlsQuery,
    {
      loading: isLoadingNotificationControls,
      error: notificationControlsError,
    },
  ] = useMeProviderUserNotificationControlsLazyQuery();

  const [updateProviderUserNotificationControls] =
    useUpdateProviderUserNotificationControlsMutation();

  useEffect(() => {
    if (notificationControlsError) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong getting your notification controls. Please try refreshing."
          level="error"
        />
      ));
    }
  }, [notificationControlsError]);

  const formMethods = useForm<NotificationControlsFormData>({
    mode: 'onChange',
    defaultValues: async () => {
      const response = await notificationControlsQuery();
      const notificationControls =
        response.data?.meProvider?.notificationControls;
      return {
        patientActivationEmailEnabled: Boolean(
          notificationControls?.patientActivationEmailOption,
        ),
        patientCommentEmailEnabled: Boolean(
          notificationControls?.patientCommentEmailOption,
        ),
        patientCommentEmailOption:
          notificationControls?.patientCommentEmailOption ?? null,
        patientActivityCompletionEmailEnabled: Boolean(
          notificationControls?.patientActivityCompletionEmailOption,
        ),
        patientAssessmentCompletionEmailEnabled: Boolean(
          notificationControls?.patientAssessmentCompletionEmailOption,
        ),
      };
    },
  });

  const previousFormValues = useRef<NotificationControlsFormData | null>(null); // keep track of previous form values

  const handleSubmit = formMethods.handleSubmit;
  const watch = formMethods.watch;

  // Debounce form submission to prevent double-send on checkbox change + radio change
  const saveForm = debounce(
    async (formData: NotificationControlsFormData) => {
      // Only handle form change if formValues have changed from previous form values
      if (
        previousFormValues.current !== null &&
        formData !== previousFormValues.current
      ) {
        try {
          await updateProviderUserNotificationControls({
            variables: {
              input: {
                patientActivationEmailOption:
                  formData.patientActivationEmailEnabled
                    ? NotificationFrequency.RealTime
                    : null,
                patientCommentEmailOption:
                  formData.patientCommentEmailEnabled &&
                  formData.patientCommentEmailOption
                    ? formData.patientCommentEmailOption
                    : null,
                patientActivityCompletionEmailOption:
                  formData.patientActivityCompletionEmailEnabled
                    ? NotificationFrequency.RealTime
                    : null,
                patientAssessmentCompletionEmailOption:
                  formData.patientAssessmentCompletionEmailEnabled
                    ? NotificationFrequency.RealTime
                    : null,
              },
            },
          });
          toast.custom(({ visible }) => (
            <ToastAlert
              isVisible={visible}
              message="Changes to your notification settings were successfully saved"
              level="success"
            />
          ));
        } catch (error) {
          toast.custom(({ visible }) => (
            <ToastAlert
              isVisible={visible}
              message="Something went wrong saving your notification settings. Please try again."
              level="error"
            />
          ));
        }
      }
      // Update previous form values to current form values
      previousFormValues.current = formData;
    },
    FORM_DEBOUNCE_MS,
    {
      leading: false,
      trailing: true,
    },
  );

  useEffect(() => {
    // Callback form of watch (https://react-hook-form.com/api/useform/watch/)
    const subscription = watch(() => handleSubmit(saveForm)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <div className="mt-2 flex w-[555px] flex-col">
      <Transition
        show={!isLoadingNotificationControls && !notificationControlsError}
        {...defaultTransitionProps}
      >
        <form>
          <FormProvider {...formMethods}>
            <div className="divide-y divide-neutral-75">
              <NotificationsSettingSubSection
                name="Activations"
                description="Be notified when clients sign up to work with you through your Homecoming webpage or activate on the mobile app."
                Icon={UserIcon}
                emailEnabledFormFieldName="patientActivationEmailEnabled"
                defaultEmailOption={NotificationFrequency.RealTime}
              />
              <NotificationsSettingSubSection
                name="Comments"
                description="Be notified when a client replies to a follow-up."
                Icon={ChatIcon}
                emailEnabledFormFieldName="patientCommentEmailEnabled"
                emailOptionFormFieldName="patientCommentEmailOption"
                defaultEmailOption={NotificationFrequency.DailyDigest}
              />
              <NotificationsSettingSubSection
                name="Activity completions"
                description="Be notified when a client completes an action or a resource-based activity."
                Icon={BoxCheck}
                emailEnabledFormFieldName="patientActivityCompletionEmailEnabled"
                defaultEmailOption={NotificationFrequency.RealTime}
              />
              <NotificationsSettingSubSection
                name="Form and Assessment completions"
                description="Be notified when a client completes a form or assessment."
                Icon={DocumentReportIcon}
                emailEnabledFormFieldName="patientAssessmentCompletionEmailEnabled"
                defaultEmailOption={NotificationFrequency.RealTime}
              />
            </div>
          </FormProvider>
        </form>
      </Transition>

      {isLoadingNotificationControls && <Spinner className="mx-auto mt-12" />}
    </div>
  );
};

export default NotificationsSection;
