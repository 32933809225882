import { Combobox } from '@headlessui/react';
import { PlusIcon, XIcon } from '@heroicons/react/outline';
import { FC, Fragment, useEffect, useRef, useState } from 'react';

import Button from '../Button';
import {
  Exact,
  MeProviderProgramTagsQuery,
  ProgramTagDataFragment,
  ProgramTagWithCountDataFragment,
  useAddProgramTagMutation,
  useRemoveProgramTagMutation,
} from '../../../generated/graphql';
import Tag from '../../svgs/Tag';
import { ApolloQueryResult } from '@apollo/client';
import InputGroup from '../InputGroup';
import classNames from 'classnames';
import ToastAlert from '../ToastAlert';
import toast from 'react-hot-toast';
import { WithOptional } from '../../types/utils';
import {
  AnalyticsPage,
  ProviderAnalyticsEvent,
  trackProviderEvent,
} from '../../../lib/analytics';
import {
  PROGRAM_TAG_COLOR_TO_TAILWIND_BG_COLOR_MAP,
  PROGRAM_TAG_COLOR_TO_TAILWIND_HOVER_COLOR_MAP,
} from '../../lib/colors';
import { usePopper } from 'react-popper';
import { pluralize } from '../../lib/copy';

// When being created in the add patients modal, tags don't yet have IDs
export type StagedProgramTagDataFragment = WithOptional<
  ProgramTagWithCountDataFragment,
  'id' | 'description' | 'color' | 'programCount'
>;

enum ProgramTagOperation {
  AddNew = 'Add new',
  AddExisting = 'Add existing',
  Remove = 'Remove',
}

type ProgramTagManagerProps = {
  // When programId is set, this component operates as a Program-linked version of ProgramTagManager,
  // which immediately updates tag changes to that particular program the server.
  // When programId is not set, this component operates as a non-Program-linked version of ProgramTagManager,
  // which stages tag changes until the parent component is ready to handle them.
  programId?: string;
  availableProgramTags: ProgramTagDataFragment[];
  refreshAvailableProgramTags?: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<MeProviderProgramTagsQuery>>;
  selectedProgramTags?: StagedProgramTagDataFragment[];
  minimumProgramTagCount?: number;
  maximumProgramTagCount?: number;
  // Should only be used when programId is not set.
  onTagsChange?: (tags: StagedProgramTagDataFragment[]) => Promise<void>;
  // Should only be used when programId is set.
  // We need this second version to distinguish that the tags are guaranteed to have IDs
  // because they've been synced to the server
  onTagsUpdated?: (tags: ProgramTagDataFragment[]) => void;
  analyticsPage?: AnalyticsPage;
  alwaysIncludeAddTagButton?: boolean;
  wrapTags?: boolean;
  className?: string;
};

const ProgramTagManager: FC<ProgramTagManagerProps> = ({
  programId,
  availableProgramTags,
  refreshAvailableProgramTags,
  selectedProgramTags = [],
  minimumProgramTagCount,
  maximumProgramTagCount,
  onTagsChange,
  onTagsUpdated,
  analyticsPage,
  alwaysIncludeAddTagButton = false,
  wrapTags = false,
  className,
}) => {
  const [addProgramTagMutation] = useAddProgramTagMutation();
  const [removeProgramTagMutation] = useRemoveProgramTagMutation();

  const programTagManagerRef = useRef<HTMLDivElement | null>(null);

  const [isTagSelectorOpen, setIsTagSelectorOpen] = useState(false);
  const [currentTags, setCurrentTags] =
    useState<StagedProgramTagDataFragment[]>(selectedProgramTags);
  const [selectedTag] = useState<StagedProgramTagDataFragment | null>(null);

  const [inputTagName, setInputTagName] = useState('');

  // Remove current tags from available tags
  let filteredAvailableProgramTags = availableProgramTags.filter(
    (tag) =>
      !currentTags.map((currentTag) => currentTag.name).includes(tag.name),
  );

  let newTag: StagedProgramTagDataFragment | undefined;

  if (inputTagName.length) {
    // When user is typing, use it to filter down available tags
    filteredAvailableProgramTags = filteredAvailableProgramTags.filter((tag) =>
      tag.name.toLowerCase().includes(inputTagName.toLowerCase()),
    );

    // If unseen tag name is being typed, use it as the new tag option
    if (
      !filteredAvailableProgramTags
        .map((tag) => tag.name.toLowerCase())
        .concat(currentTags.map((tag) => tag.name.toLowerCase()))
        .includes(inputTagName.toLowerCase())
    ) {
      newTag = {
        name: inputTagName,
      };
    }
  }

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    strategy: 'fixed',
  });

  const reset = () => {
    setInputTagName('');
    setIsTagSelectorOpen(false);
  };

  const handleTagSelection = async (
    selectedTag: StagedProgramTagDataFragment | null,
  ) => {
    if (!selectedTag) {
      return;
    }

    let operation: ProgramTagOperation;
    let newCurrentTags: StagedProgramTagDataFragment[];

    if (programId) {
      // Handle Program-linked version of ProgramTagManager, which immediately
      // updates tag changes to the server.
      if (!selectedTag.id) {
        const { data } = await addProgramTagMutation({
          variables: {
            input: {
              programId,
              programTagName: selectedTag.name,
            },
          },
        });
        if (data?.addProgramTag.updatedProgramTags) {
          onTagsUpdated?.(data.addProgramTag.updatedProgramTags);
          newCurrentTags = data.addProgramTag.updatedProgramTags;
          setCurrentTags(newCurrentTags);
        }
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={`New tag added`}
          />
        ));
        operation = ProgramTagOperation.AddNew;
      } else if (selectedTag.id) {
        if (
          currentTags
            .map((currentTag) => currentTag.id)
            .includes(selectedTag.id)
        ) {
          const { data } = await removeProgramTagMutation({
            variables: {
              input: {
                programId,
                programTagId: selectedTag.id,
              },
            },
          });
          if (data?.removeProgramTag.updatedProgramTags) {
            onTagsUpdated?.(data.removeProgramTag.updatedProgramTags);
            newCurrentTags = data.removeProgramTag.updatedProgramTags;
            setCurrentTags(newCurrentTags);
          }
          toast.custom(({ visible }) => (
            <ToastAlert
              isVisible={visible}
              level="success"
              message={`Tag removed`}
            />
          ));
          operation = ProgramTagOperation.Remove;
        } else {
          const { data } = await addProgramTagMutation({
            variables: {
              input: {
                programId,
                programTagId: selectedTag.id,
              },
            },
          });
          if (data?.addProgramTag.updatedProgramTags) {
            onTagsUpdated?.(data.addProgramTag.updatedProgramTags);
            newCurrentTags = data.addProgramTag.updatedProgramTags;
            setCurrentTags(newCurrentTags);
          }
          toast.custom(({ visible }) => (
            <ToastAlert
              isVisible={visible}
              level="success"
              message={`Tag added`}
            />
          ));
          operation = ProgramTagOperation.AddExisting;
        }
      }
    } else {
      // Handle non-Program-linked version of ProgramTagManager, which stages
      // tag changes until the parent component is ready to handle them.
      if (
        currentTags
          .map((currentTag) => currentTag.name)
          .includes(selectedTag.name)
      ) {
        if (currentTags.length <= (minimumProgramTagCount ?? 0)) {
          toast.custom(({ visible }) => (
            <ToastAlert
              isVisible={visible}
              level="error"
              message={`At least ${pluralize(
                minimumProgramTagCount,
                'tag is',
                'tags are',
              )} required`}
            />
          ));
          reset();
          return;
        }
        setCurrentTags((prevTags) => {
          newCurrentTags = prevTags.filter(
            (tag) => tag.name !== selectedTag.name,
          );
          return newCurrentTags;
        });
        operation = ProgramTagOperation.Remove;
      } else {
        if (
          maximumProgramTagCount &&
          currentTags.length >= maximumProgramTagCount
        ) {
          toast.custom(({ visible }) => (
            <ToastAlert
              isVisible={visible}
              level="error"
              message={`No more than ${pluralize(
                maximumProgramTagCount,
                'tag is',
                'tags are',
              )} allowed`}
            />
          ));
          reset();
          return;
        }
        setCurrentTags((prevTags) => {
          newCurrentTags = [...prevTags, selectedTag];
          return newCurrentTags;
        });
        if (selectedTag.id) {
          operation = ProgramTagOperation.AddExisting;
        } else {
          operation = ProgramTagOperation.AddNew;
        }
      }
    }

    if (newCurrentTags != null && onTagsChange) {
      await onTagsChange(newCurrentTags);
    }

    await refreshAvailableProgramTags?.();

    trackProviderEvent(ProviderAnalyticsEvent.ProgramTagSelected, {
      operation,
    });

    reset();
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        programTagManagerRef.current &&
        !programTagManagerRef.current.contains(event.target as Node)
      ) {
        reset();
      }
    }

    // Attach the click event handler
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [programTagManagerRef]);

  return (
    <>
      <div
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          if (!isTagSelectorOpen) {
            setIsTagSelectorOpen(true);
            trackProviderEvent(ProviderAnalyticsEvent.ProgramTagManagerOpened, {
              originPage: analyticsPage,
            });
          }
        }}
        className={classNames(
          'relative',
          !isTagSelectorOpen && !wrapTags && 'overflow-x-auto',
          className,
        )}
        ref={programTagManagerRef}
      >
        <>
          {!isTagSelectorOpen ? (
            <div
              className={classNames(
                'flex max-w-full items-center gap-2 py-2 pl-1',
                wrapTags ? 'flex-wrap' : 'overflow-x-scroll scrollbar-hide',
              )}
            >
              {Boolean(currentTags.length) && (
                <>
                  {currentTags.map((tag, index) => (
                    <Button
                      key={`currentTagSelection_${index}`}
                      size="smaller"
                      theme="secondary"
                      title={tag.name}
                      IconComponent={Tag}
                      iconPosition="left"
                      className={classNames(
                        PROGRAM_TAG_COLOR_TO_TAILWIND_BG_COLOR_MAP[tag.color],
                        PROGRAM_TAG_COLOR_TO_TAILWIND_HOVER_COLOR_MAP[
                          tag.color
                        ],
                      )}
                    />
                  ))}
                </>
              )}
              {(!Boolean(currentTags.length) || alwaysIncludeAddTagButton) && (
                <Button
                  size="smaller"
                  theme="secondary"
                  title="Tags"
                  IconComponent={PlusIcon}
                  iconPosition="left"
                />
              )}
            </div>
          ) : (
            <Combobox
              value={selectedTag}
              onChange={handleTagSelection}
              as="div"
              className="relative flex w-full min-w-[220px] items-center gap-x-2 py-0.5"
              immediate
            >
              <Tag className="min-w-[18px] text-secondary-100" />
              <Combobox.Input
                as={InputGroup}
                placeholder="Search or create tag"
                containerClassName="w-full"
                inputSize="extra-small"
                labelHidden
                autoFocus
                onChange={(event) => setInputTagName(event.target.value)}
                ref={setReferenceElement}
                maxLength={120}
              />
              {(newTag ||
                Boolean(currentTags.length) ||
                Boolean(filteredAvailableProgramTags.length)) && (
                <Combobox.Options
                  className="z-50 max-h-[25rem] w-auto min-w-[196px] max-w-md divide-y divide-neutral-75 overflow-y-auto rounded-md border border-neutral-75 bg-white shadow-lg focus:outline-none"
                  ref={setPopperElement}
                  style={styles.popper}
                  {...attributes.popper}
                >
                  {newTag && (
                    <Combobox.Option
                      value={newTag}
                      className={({ active }) =>
                        classNames(
                          'flex cursor-pointer flex-row items-center px-5 py-3 text-secondary-100',
                          active && 'bg-neutral-50',
                        )
                      }
                    >
                      <Tag className="mr-3 h-[18px] min-w-[18px]" />
                      <div className="truncate text-caption">
                        {newTag.name} (New)
                      </div>
                    </Combobox.Option>
                  )}
                  {currentTags.map((tag, index) => (
                    <Combobox.Option
                      key={`currentTagOption_${index}`}
                      value={tag}
                      className={({ active }) =>
                        classNames(
                          'flex cursor-pointer flex-row items-center px-5 py-3 text-secondary-100',
                          active && 'bg-neutral-50',
                        )
                      }
                    >
                      <XIcon className="mr-3 h-[18px] min-w-[18px]" />
                      <div className="truncate text-caption">{tag.name}</div>
                    </Combobox.Option>
                  ))}
                  {filteredAvailableProgramTags.map((tag, index) => (
                    <Combobox.Option
                      key={`availableTag_${index}`}
                      value={tag}
                      className={({ active }) =>
                        classNames(
                          'flex cursor-pointer flex-row items-center px-5 py-3 text-neutral-150',
                          active && 'bg-neutral-50',
                        )
                      }
                    >
                      <Button
                        key={`currentTagSelection_${index}`}
                        size="smaller"
                        theme="secondary"
                        title={tag.name}
                        IconComponent={Tag}
                        iconPosition="left"
                        className={classNames(
                          PROGRAM_TAG_COLOR_TO_TAILWIND_BG_COLOR_MAP[tag.color],
                          PROGRAM_TAG_COLOR_TO_TAILWIND_HOVER_COLOR_MAP[
                            tag.color
                          ],
                        )}
                      />
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}
            </Combobox>
          )}
        </>
      </div>
    </>
  );
};

export default ProgramTagManager;
