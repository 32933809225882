import React from 'react';
import { useProviderUserSignableFormsLazyQuery } from '../../../generated/graphql';
import UnsignedFormItem from './UnsignedFormItem';

const UnsignedFormsView: React.FC = () => {
  const [
    getSignableForms,
    { data: signableFormsData, loading: isLoadingSignableForms },
  ] = useProviderUserSignableFormsLazyQuery();

  React.useEffect(() => {
    getSignableForms();
  }, []);

  const unsignedForms = signableFormsData?.providerUserSignableForms;

  if (isLoadingSignableForms) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {unsignedForms?.map((unsignedForm) => (
        <UnsignedFormItem
          key={`unsignedForm-${unsignedForm.id}`}
          unsignedForm={unsignedForm}
          unsignedForms={unsignedForms}
        />
      ))}
    </>
  );
};

export default UnsignedFormsView;
