import _ from 'lodash';
import { NotificationDataFragment } from '../../../generated/graphql';
import { QuickActionProps } from './QuickAction';

type NotificationElem = QuickActionProps | NotificationDataFragment;

export const isNotification = (
  elem: NotificationElem,
): elem is NotificationDataFragment => {
  return _.has(elem, '__typename');
};
