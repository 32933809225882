import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  PatientAccessLevel,
  PublicServiceSignUpError,
  usePublicProviderServiceQuery,
  usePublicServiceSignUpMutation,
} from '../../../generated/graphql';

import { HOMEPAGE_URL } from '../../lib/constants';
import PageContainer from '../../components/Containers/PageContainer';
import useDisableOverscroll from '../../hooks/useDisableOverscroll';
import Loading from '../Loading';
import { Transition } from '@headlessui/react';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import Button from '../../components/Button';
import IconButton from '../../components/IconButton';
import { defaultTransitionProps } from '../../lib/animation';

import usePaintScreen from '../../hooks/usePaintScreen';
import Avatar from '../../components/Avatar';
import classNames from 'classnames';
import {
  CALENDLY_COLOR_MAP,
  getIconComponent,
} from '../../components/ServiceCard/helpers';

import ErrorMessage from '../../components/ErrorMessage';
import InputGroup from '../../components/InputGroup';
import { NAME_REGEX, EMAIL_REGEX } from '../../lib/regex';
import { DEFAULT_CTA_BUTTON_TEXT } from '../MyPage';
import { useForm } from 'react-hook-form';
import { usePatientAuth } from '../../../contexts/PatientAuthContext';
import PublicPageFooter from '../../components/PublicPageFooter';
import ServiceIntakeCompleteModal from '../../components/Modals/ServiceIntakeCompleteModal';
import Spinner from '../../svgs/Spinner';
import { useProviderColors } from '../../hooks/useProviderColors';

interface PublicServiceSignUpFormData {
  fullName: string;
  email: string;
}

const MOBILE_DESCRIPTION_MAX_LENGTH = 300;

const PublicServicePage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  useDisableOverscroll();
  const paintScreen = usePaintScreen();
  const { updateTokenPayload } = usePatientAuth();
  const [checkYourEmail, setCheckYourEmail] = useState(false);

  const slug = params.slug;
  const serviceSlug = params.serviceSlug;

  const fromIntakeSuccess = (location.state as { fromIntakeSuccess?: boolean })
    ?.fromIntakeSuccess;

  const { data: publicServiceData, loading: publicServiceLoading } =
    usePublicProviderServiceQuery({
      variables: {
        slug,
        serviceSlug,
      },
      skip: !slug || !serviceSlug,
      onError: () => {
        window.location.href = HOMEPAGE_URL;
      },
    });

  const publicService = publicServiceData?.publicProviderService;

  const { useProviderPrimaryColor } = useProviderColors({
    providerPrimaryColor: publicService?.providerPrimaryColor,
    providerSecondaryColor: publicService?.providerSecondaryColor,
  });

  const showPage = paintScreen && !publicServiceLoading;

  const isNotAllAccess =
    publicService?.patientAccessLevel !== PatientAccessLevel.All;
  const isNewPatientsAccess =
    publicService?.patientAccessLevel === PatientAccessLevel.New;
  const isCurrentPatientsAccess =
    publicService?.patientAccessLevel === PatientAccessLevel.Current;

  const IconComponent = getIconComponent(publicService?.serviceIcon);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors: formErrors, isSubmitting: isFormSubmitting },
  } = useForm<PublicServiceSignUpFormData>();

  const [publicServiceSignUp, { loading: isPublicServiceSignUpLoading }] =
    usePublicServiceSignUpMutation();

  const onSubmit = async (formData: PublicServiceSignUpFormData) => {
    if (!slug || !serviceSlug) {
      return;
    }

    const { email, fullName } = formData;

    try {
      const { data } = await publicServiceSignUp({
        variables: {
          input: {
            email,
            name: fullName,
            storefrontSlug: slug,
            serviceSlug,
          },
        },
      });

      const publicServiceSignUpData = data?.publicServiceSignUp;

      if (publicServiceSignUpData?.error) {
        const publicServiceSignUpError = publicServiceSignUpData.error;
        switch (publicServiceSignUpError) {
          case PublicServiceSignUpError.InvalidServiceSlug:
            setError('root.serverError', {
              message: `We couldn't find the service you're trying to connect with. Please try again later.`,
            });
            break;
          case PublicServiceSignUpError.EmailAlreadyExistsOtherProvider:
            setError('root.serverError', {
              message: `It looks like you're already working with another provider on Homecoming. Please provide another email to work with ${publicService?.providerName}`,
            });
            break;
          case PublicServiceSignUpError.EmailAlreadyExistsThisProvider:
            setCheckYourEmail(true);
            break;
          case PublicServiceSignUpError.CurrentClientsOnly:
            setError('root.serverError', {
              message: `It looks like you're a new client. This service is only available for current clients.`,
            });
            break;
          case PublicServiceSignUpError.NewClientsOnly:
            setError('root.serverError', {
              message: `It looks like you're an existing client. This service is only available for new clients.`,
            });
            break;
          default:
            break;
        }
        return;
      }

      if (publicServiceSignUpData?.authToken) {
        updateTokenPayload({
          authSessionToken: publicServiceSignUpData.authToken,
        });
        navigate(
          `/client/intake/${publicServiceSignUpData.serviceIntakePackageId}`,
          {
            state: {
              fullName,
            },
          },
        );
      }
    } catch (error) {
      setError('root.serverError', {
        message:
          "Something went wrong. Please contact support@homecoming.health and we'll help you get connected.",
      });
    }
  };

  const [
    isServiceIntakeCompleteModalOpen,
    setIsServiceIntakeCompleteModalOpen,
  ] = useState(false);

  useEffect(() => {
    if (publicService && fromIntakeSuccess) {
      setIsServiceIntakeCompleteModalOpen(true);
      const timeout = setTimeout(() => {
        if (publicService.patientAccessLevel === PatientAccessLevel.Current) {
          navigate('/client', { replace: true });
        }
      }, 3500);
      return () => clearTimeout(timeout);
    }
  }, [publicService, fromIntakeSuccess, navigate]);

  const [showFullDescription, setShowFullDescription] = useState(false);

  const isStorefrontEnabled = publicService?.storefrontEnabled;

  return (
    <>
      {publicService ? (
        <div
          className={classNames('relative scroll-smooth', 'min-h-[100vh-64px]')}
        >
          {isStorefrontEnabled && (
            <Transition show={showPage} {...defaultTransitionProps}>
              <div className="t-16 fixed z-10 flex w-full flex-row items-center justify-start border-b border-neutral-50 bg-white py-3 px-6 md:px-20">
                <div className="w-[289px]">
                  <IconButton
                    IconComponent={ArrowLeftIcon}
                    iconClassName="text-secondary-100 w-5"
                    aria-label="See my homepage"
                    className="rounded-xl px-3 py-2"
                    onClick={() => navigate(`/p/${slug}`)}
                  >
                    <div className="small-caption ml-2 font-bold text-secondary-100">
                      Back to my homepage
                    </div>
                  </IconButton>
                </div>
              </div>
            </Transition>
          )}

          <PageContainer
            containerClassName={classNames(
              'h-[calc(100vh-var(--top-nav-height))] md:overflow-y-hidden',
              'pb-8 md:pb-0 px-8 pt-16 md:px-24 min-w-full',
            )}
            noPadding
          >
            <div className="flex h-full w-full flex-col gap-6 py-8 md:flex-row md:gap-12">
              {/* Left side - Service Info */}
              <div className="flex-1">
                <div className="mb-10 flex items-center gap-4">
                  <Avatar
                    imageUrl={publicService.profileImageUrl}
                    name={publicService.providerName}
                    size="medium-large"
                  />
                  <div className="text-neutral-125">
                    <div className="font-serif text-subtitle-small font-light">
                      {publicService.providerName}
                    </div>
                    <div className="text-small-caption">
                      {publicService.providerRoleTitle}
                    </div>
                  </div>
                </div>

                <div className="mb-12 mt-4 md:min-w-[350px]">
                  <div className="mb-6 flex w-full items-center justify-between">
                    <div className="flex flex-row items-end justify-start">
                      <IconComponent
                        className={classNames(
                          'h-12 w-12',
                          publicService.serviceIconColorUseCalendlyDefault &&
                            publicService.calendlyEventColor
                            ? CALENDLY_COLOR_MAP[
                                publicService.calendlyEventColor
                              ]
                            : useProviderPrimaryColor
                            ? 'text-primary-125'
                            : 'text-blue-100',
                        )}
                      />
                      {isNotAllAccess && (
                        <div
                          className={classNames(
                            'ml-3 rounded-full px-3 py-1 text-small-caption',
                            isNewPatientsAccess && 'bg-green-25 text-green-150',
                            isCurrentPatientsAccess &&
                              'bg-blue-25 text-blue-150',
                          )}
                        >
                          {isNewPatientsAccess && 'New clients only'}
                          {isCurrentPatientsAccess && 'Current clients only'}
                        </div>
                      )}
                    </div>
                    <div className="text-category font-medium text-green-150">
                      {publicService.isPaid && publicService.price
                        ? `$${publicService.price}`
                        : 'Free'}
                    </div>
                  </div>
                  <div className="mb-2 font-serif text-subtitle-small font-light md:text-subtitle">
                    {publicService.name}
                  </div>
                  {Boolean(publicService.durationMinutes) && (
                    <p className="mb-4 text-small-caption text-neutral-110">
                      {publicService.durationMinutes} minutes
                    </p>
                  )}
                  <div className="md:hidden">
                    {showFullDescription ? (
                      <p className="whitespace-pre-wrap break-words text-caption text-neutral-125">
                        {publicService.description}
                      </p>
                    ) : (
                      <p className="whitespace-pre-wrap break-words text-caption text-neutral-125">
                        {publicService.description.slice(
                          0,
                          MOBILE_DESCRIPTION_MAX_LENGTH,
                        )}
                        {publicService.description.length >
                          MOBILE_DESCRIPTION_MAX_LENGTH && (
                          <>
                            {'... '}
                            <button
                              onClick={() => setShowFullDescription(true)}
                              className="inline font-medium text-secondary-100"
                            >
                              Read more
                            </button>
                          </>
                        )}
                      </p>
                    )}
                  </div>
                  <p className="hidden whitespace-pre-wrap break-words text-caption text-neutral-125 md:block">
                    {publicService.description}
                  </p>
                </div>
              </div>

              {/* Right side - Booking Form Container */}
              <div className="flex h-full flex-1 flex-col items-center justify-center">
                <div className="flex w-full flex-col rounded-lg bg-white p-8 shadow-lg md:min-h-[500px] md:min-w-[400px]">
                  <div className="flex h-full w-full flex-col">
                    {/* Top section with fixed spacing */}
                    <div className="flex-1" />

                    {/* Center content that won't shift */}
                    <div className="flex flex-col items-center">
                      <div className="mb-8 flex flex-col items-center justify-start text-neutral-125 md:mb-12">
                        <div className="text-subtitle-small md:text-subtitle">
                          Get started
                        </div>
                        <div className="text-caption">Enter your details</div>
                      </div>
                      <form className="w-full">
                        <InputGroup
                          placeholder="Full name"
                          autoComplete="name"
                          type="text"
                          inputSize="small"
                          useNaturalLettering={true}
                          containerClassName="mb-4"
                          required
                          errorMessage={formErrors.fullName?.message}
                          {...register('fullName', {
                            required: 'Name is required',
                            pattern: {
                              value: NAME_REGEX,
                              message: 'Please enter your first and last name',
                            },
                          })}
                        />
                        <InputGroup
                          placeholder="Email"
                          autoComplete="email"
                          type="email"
                          inputSize="small"
                          useNaturalLettering={true}
                          containerClassName="mb-6 md:mb-2"
                          required
                          errorMessage={formErrors.email?.message}
                          {...register('email', {
                            required: 'Email is required',
                            pattern: {
                              value: EMAIL_REGEX,
                              message: 'Please enter a valid email',
                            },
                          })}
                        />
                        <Button
                          title={DEFAULT_CTA_BUTTON_TEXT}
                          className="mx-auto mb-4 w-full disabled:cursor-default md:mt-8"
                          type="submit"
                          disabled={isFormSubmitting || checkYourEmail}
                          onClick={handleSubmit(onSubmit)}
                          {...(isPublicServiceSignUpLoading && {
                            IconComponent: Spinner,
                            iconClassName: '!h-5 !w-5',
                          })}
                        />
                        {/* Error message in fixed height container */}
                        <div className="h-12">
                          {' '}
                          {/* Fixed height for error message */}
                          <ErrorMessage
                            className="w-full"
                            textClassName={classNames(
                              'text-center w-full text-caption',
                              !formErrors.root?.serverError &&
                                checkYourEmail &&
                                'text-green-125',
                            )}
                          >
                            {formErrors.root?.serverError?.message ||
                              (checkYourEmail &&
                                "We've sent you an email with a link to your client portal.")}
                          </ErrorMessage>
                        </div>
                      </form>
                    </div>

                    {/* Bottom section with fixed spacing */}
                    <div className="flex-1" />
                  </div>
                </div>
              </div>
              <PublicPageFooter className="pb-8 md:hidden" />
            </div>
          </PageContainer>
          <PublicPageFooter className="hidden md:flex" />
        </div>
      ) : (
        <Loading />
      )}
      <ServiceIntakeCompleteModal
        isModalOpen={isServiceIntakeCompleteModalOpen}
        setClosed={() => setIsServiceIntakeCompleteModalOpen(false)}
        avatarImageUrl={publicService?.profileImageUrl}
        avatarName={publicService?.providerName}
      />
    </>
  );
};

export default PublicServicePage;
