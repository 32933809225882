import { useState } from 'react';
import toast from 'react-hot-toast';
import { Transition } from '@headlessui/react';
import { CalendarIcon } from '@heroicons/react/outline';

import { usePatientAuth } from '../../../../contexts/PatientAuthContext';

import {
  useCalendlyEventTypesQuery,
  PatientScheduledEventFragment,
  usePatientScheduledEventsQuery,
} from '../../../../generated/graphql';

import { defaultTransitionProps } from '../../../lib/animation';

import useIncrementNowDate from '../../../hooks/useIncrementNowDate';

import Spinner from '../../../svgs/Spinner';
import IllustrationCalendar from '../../../svgs/IllustrationCalendar';

import Button from '../../../components/Button';
import ToastAlert from '../../../components/ToastAlert';
import PageContainer from '../../../components/Containers/PageContainer';
import PatientScheduledEvent from '../../../components/PatientScheduledEvent';
import ConfirmCancelModal from '../../../components/Modals/ConfirmCancelModal';
import PatientCalendlyBookingModal from '../../../components/Modals/PatientCalendlyBookingModal';

import EmptySection from './EmptySection';
import useIsMobileDevice from '../../../hooks/useIsMobileDevice';
import { shouldUseProviderPrimaryColor } from '../../../lib/colors';

const PatientHome = () => {
  const now = useIncrementNowDate();

  const { authedPatient } = usePatientAuth();

  const [selectedScheduledEvent, setSelectedScheduledEvent] =
    useState<PatientScheduledEventFragment>();
  const [confirmCancelModalOpen, setConfirmCancelModalOpen] = useState(false);

  const hasCalendlyIntegration = authedPatient?.hasCalendlyIntegration;

  const {
    data: scheduledEventsData,
    error: scheduledEventsDataError,
    loading: scheduledEventsDataLoading,
    refetch: refetchPatientScheduledEvents,
  } = usePatientScheduledEventsQuery({
    variables: {
      input: {
        programId: authedPatient.programInstanceId,
        minStartTime: now,
        isCanceled: false,
      },
    },
    skip: !hasCalendlyIntegration,
  });

  const scheduledEvents = scheduledEventsData?.scheduledEvents;

  const { data: calendlyEventTypesData, error: calendlyEventTypesDataError } =
    useCalendlyEventTypesQuery({
      skip: !hasCalendlyIntegration,
      onError: (error) => {
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message="Failed to get Calendly event types."
            level="error"
          />
        ));
      },
    });

  const calendlyEventTypes = calendlyEventTypesData?.calendlyEventTypes ?? [];

  const [patientCalendlyBookingModalOpen, setPatientCalendlyBookingModalOpen] =
    useState(false);

  const isMobileDevice = useIsMobileDevice();

  return (
    <div>
      <PageContainer noPadding containerClassName="md:px-16 px-6 pb-6">
        <div className="flex w-full flex-col">
          {hasCalendlyIntegration && (
            <div>
              <div className="mt-7 mb-6 flex h-[48px] w-full flex-row items-center justify-between md:mb-8">
                <div className="font-serif text-subtitle-small font-light md:text-subtitle">
                  Sessions
                </div>
                <Transition
                  show={Boolean(
                    calendlyEventTypesData && !calendlyEventTypesDataError,
                  )}
                  {...defaultTransitionProps}
                >
                  <Button
                    title={isMobileDevice ? 'Book session' : 'Book a session'}
                    IconComponent={CalendarIcon}
                    theme="secondary-white"
                    iconPosition="left"
                    className="!text-primary-125 shadow-100"
                    onClick={() => setPatientCalendlyBookingModalOpen(true)}
                  />
                </Transition>
              </div>
              <div className="flex min-h-[186px] w-full">
                {scheduledEventsDataLoading ? (
                  <div className="flex h-full w-full flex-row items-center justify-center">
                    <Spinner className="h-6 w-6" />
                  </div>
                ) : (
                  <>
                    <Transition
                      show={Boolean(
                        scheduledEvents?.length === 0 &&
                          !scheduledEventsDataError,
                      )}
                      className="w-full"
                      {...defaultTransitionProps}
                    >
                      <EmptySection
                        IconComponent={IllustrationCalendar}
                        iconClassName="text-secondary-100"
                        titleText="No sessions booked yet"
                        subTitleText={
                          "Check your provider's availability and book a session"
                        }
                      />
                    </Transition>
                    <Transition
                      show={Boolean(
                        scheduledEvents && !scheduledEventsDataError,
                      )}
                      {...defaultTransitionProps}
                    >
                      <div className="flex w-full flex-col items-start">
                        <div className="mb-6 flex w-full flex-wrap gap-7">
                          {Boolean(scheduledEvents) &&
                            scheduledEvents?.map((scheduledEvent, index) => (
                              <PatientScheduledEvent
                                key={index}
                                showRelativeTime={index === 0}
                                isLatestEvent={index === 0}
                                scheduledEvent={scheduledEvent}
                                onClickCancelEvent={() => {
                                  setSelectedScheduledEvent(scheduledEvent);
                                  setConfirmCancelModalOpen(true);
                                }}
                                useProviderPrimaryColor={shouldUseProviderPrimaryColor(
                                  authedPatient?.providerPrimaryColor,
                                )}
                              />
                            ))}
                        </div>
                      </div>
                    </Transition>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </PageContainer>
      <PatientCalendlyBookingModal
        isModalOpen={patientCalendlyBookingModalOpen}
        setClosed={() => setPatientCalendlyBookingModalOpen(false)}
        calendlyEventTypes={calendlyEventTypes}
        authedPatient={authedPatient}
        onCalendlyBooked={async () => {
          await refetchPatientScheduledEvents();
        }}
      />
      <ConfirmCancelModal
        isModalOpen={confirmCancelModalOpen}
        setClosed={() => setConfirmCancelModalOpen(false)}
        selectedScheduledEvent={selectedScheduledEvent}
        setSelectedScheduledEvent={setSelectedScheduledEvent}
        refetchScheduledEventsData={refetchPatientScheduledEvents}
        patientCancelMode
      />
    </div>
  );
};

export default PatientHome;
