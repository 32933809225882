import mixpanel, { Mixpanel, OverridedMixpanel } from 'mixpanel-browser';
import Config from './config';

const isMixpanelEnabled = Config.REACT_APP_MIXPANEL_ENABLED;
const logAnalytics = Config.REACT_APP_MIXPANEL_LOG_ANALYTICS;

// Some custom typing stuff to properly type the custom fields that are created when
// initializing sub-instances for Provider and Client Mixpanel projects
// Documentation here: https://github.com/mixpanel/mixpanel-js/blob/master/doc/readme.io/javascript-full-api-reference.md#mixpanelinit
interface CustomMixpanel extends OverridedMixpanel {
  provider: Mixpanel;
  patient: Mixpanel;
}

const customMixpanel = mixpanel as CustomMixpanel;

if (isMixpanelEnabled) {
  mixpanel.init(
    Config.REACT_APP_MIXPANEL_PROVIDER_PROJECT_TOKEN,
    {
      api_host: `${Config.REACT_APP_HOMECOMING_API_BASE_URI}/mixpanel`,
      debug: logAnalytics,
    },
    'provider',
  );
  mixpanel.init(
    Config.REACT_APP_MIXPANEL_CLIENT_PROJECT_TOKEN,
    {
      api_host: `${Config.REACT_APP_HOMECOMING_API_BASE_URI}/mixpanel`,
      debug: logAnalytics,
    },
    'patient',
  );
}

export const enum CommonAnalyticsEvent {
  PageViewed = 'Page viewed',
  ComponentViewed = 'Component viewed',
  ButtonClicked = 'Button clicked',
  TableRowClicked = 'Table row clicked',
}

// We're starting to track certain Patient events within the Provider Mixpanel project
// in order to link flows from Provider to Patient. These events are prefixed with
// 'Client' and separated here to differentiate them from Provider events.
export const enum ProviderPatientAnalyticsEvent {
  ClientButtonClicked = '[Client] Button clicked',
}

export const enum ProviderAnalyticsEvent {
  DemoClientInvited = 'Demo Client invited',
  IntroStepViewed = 'Intro step viewed',
  MailTemplateApplied = 'Mail template applied',
  NotificationsPanelOpened = 'Notifications menu opened',
  NotificationsAllMarkedAsRead = 'Notifications all marked as read',
  NotificationClicked = 'Notification clicked',
  ProgramTagManagerOpened = 'Program tag manager opened',
  ProgramTagSelected = 'Program tag selected',
  ProviderLoggedIn = 'Provider logged in',
  ProviderUserLoginFailed = 'Provider User login failed',
  ProviderSignedUp = 'Provider signed up',
  ProviderSignUpFailed = 'Provider Sign-up failed',
  ProviderCheckoutFailed = 'Provider Check-out failed',
  ProviderSubscriptionUpdated = 'Provider subscription updated',
  ProviderSubscriptionUpdateFailed = 'Provider subscription update failed',
  ProviderSubscribed = 'Provider subscribed',
  ProviderSubscribeFailed = 'Provider Subscribe failed',
  AddCalendlyIntegrationAttempted = 'Add Calendly integration attempted',
  AddCalendlyIntegrationCancelled = 'Add Calendly integration cancelled',
  AddNylasIntegrationAttempted = 'Add Nylas integration attempted',
  AddNylasIntegrationAttemptErrored = 'Add Nylas integration attempt errored',
  CollectionCopied = 'Collection copied to library',
  AddClientsFromCalendly = 'Add clients from Calendly',
  AddStripeIntegrationAttempted = 'Add Stripe integration attempted',
  AddStripeIntegrationCancelled = 'Add Stripe integration cancelled',
}

export const enum PatientAnalyticsEvent {
  PatientLoggedIn = 'Patient logged in',
}

export const enum AnalyticsPage {
  Login = 'Login',
  ResetPassword = 'Reset Password',
  JoinSignup = 'Join Sign-up',
  SignUp = 'Sign-up',
  PublicStorefront = 'Public Storefront',
  ClientPdfSubmission = 'Client PDF Submission',
  Intro = 'Intro',
  Subscribe = 'Subscribe',
  Home = 'Home',
  FollowUpBuilder = 'Follow-Up Builder',
  AddClientsModal = 'Add Clients Modal',
  ClientsTable = 'Clients Table',
  ClientsTableInvited = 'Clients Table (Invited)',
  ClientsTableActive = 'Clients Table (Active)',
  ClientsTableArchived = 'Clients Table (Archived)',
  ClientProfile = 'Client Profile',
  ClientProfileMessagesTab = 'Client Profile (Messages Tab)',
  ClientProfileFollowUpsTab = 'Client Profile (Follow-ups Tab)',
  ClientProfileNotesTab = 'Client Profile (Notes Tab)',
  ClientProfileActivitiesTab = 'Client Profile (Activities Tab)',
  ClientProfileAssessmentsTab = 'Client Profile (Assessments Tab)',
  ClientProfileDocumentsTab = 'Client Profile (Documents Tab)',
  ClientProfileAccountTab = 'Client Profile (Account Tab)',
  Library = 'Library',
  Calendar = 'Calendar',
  FormBuilder = 'Form Builder',
  SettingsAccount = 'Settings (Account)',
  SettingsNotifications = 'Settings (Notifications)',
  SettingsSubscription = 'Settings (Subscription)',
  SettingsIntegrations = 'Settings (Integrations)',
  SettingsTeam = 'Settings (Team)',
  BillingSuccess = 'Billing Success',
  CalendlyIntegration = 'Calendly Integration',
  StripeIntegration = 'Stripe Integration',
  Payments = 'Payments',
  InvoiceBuilder = 'Invoice Builder',
  Logout = 'Logout',
  Community = 'Community',
  ClientFollowUpView = 'Client Follow-up View',
  MyWebpage = 'My Webpage',
}

export const enum ClientAnalyticsPage {
  ClientLogin = 'Client Login',
  StorefrontIntake = 'StorefrontIntake',
  FollowUpView = 'Follow-up View',
  Messages = 'Messages',
  ClientPdfSubmission = 'Client PDF Submission',
}

export const enum AnalyticsComponent {
  Modal = 'Modal',
  ActivitySlideover = 'Activity Slideover',
  AssessmentSlideover = 'Assessment Slideover',
  ActivityActionsMenu = 'Activity Actions Menu',
  FollowUpDrawer = 'Follow-up Drawer',
  MainHeader = 'Main Header',
  ProgramActivitySlideover = 'Program Activity Slideover',
  UpgradeBanner = 'Upgrade Banner',
}

function runIfEnabled(cb: () => void): void {
  if (!isMixpanelEnabled) {
    return;
  }

  cb();
}

function log(msg: string): void {
  if (logAnalytics) {
    console.log(`[analytics] | ${msg}`);
  }
}

export const trackProviderEvent = (
  eventName:
    | CommonAnalyticsEvent
    | ProviderAnalyticsEvent
    | ProviderPatientAnalyticsEvent,
  properties: Record<string, unknown> = {},
): void => {
  runIfEnabled(() => {
    customMixpanel.provider.track(eventName, properties);
    log(
      `Tracking provider event '${eventName}' with properties ${JSON.stringify(
        properties,
      )}`,
    );
  });
};

export const trackPatientEvent = (
  eventName: CommonAnalyticsEvent | PatientAnalyticsEvent,
  properties: Record<string, unknown> = {},
): void => {
  runIfEnabled(() => {
    customMixpanel.patient.track(eventName, properties);
    log(
      `Tracking patient event '${eventName}' with properties ${JSON.stringify(
        properties,
      )}`,
    );
  });
};

const trackComponentViewedEvent = (
  component: AnalyticsComponent,
  isPatient: boolean,
  originPage?: AnalyticsPage | string,
  properties: Record<string, unknown> = {},
): void => {
  runIfEnabled(() => {
    const eventProperties = {
      component,
      ...(originPage && { originPage }),
      ...properties,
    };
    const mixpanel = isPatient
      ? customMixpanel.patient
      : customMixpanel.provider;

    mixpanel.track(CommonAnalyticsEvent.ComponentViewed, eventProperties);

    log(
      `Tracking ${isPatient ? 'patient' : 'provider'} event '${
        CommonAnalyticsEvent.ComponentViewed
      }' with properties ${JSON.stringify(eventProperties)}`,
    );
  });
};

// Convenience wrappers for trackEvent, but constrained to
// use components defined in AnalyticsComponent enum
// so we can easily see which components are being tracked
export const trackProviderComponentViewedEvent = (
  component: AnalyticsComponent,
  originPage?: AnalyticsPage | string,
  properties: Record<string, unknown> = {},
): void => {
  trackComponentViewedEvent(component, false, originPage, properties);
};

export const trackPatientComponentViewedEvent = (
  component: AnalyticsComponent,
  originPage?: AnalyticsPage | string,
  properties: Record<string, unknown> = {},
): void => {
  trackComponentViewedEvent(component, true, originPage, properties);
};

export const resetProviderMixpanel = (): void => {
  runIfEnabled(() => {
    customMixpanel.provider.reset();
    log(`Reset provider mixpanel`);
  });
};

export const identifyProviderUser = (providerUserId: string): void => {
  runIfEnabled(() => {
    customMixpanel.provider.identify(providerUserId);
    log(`Identified user with Provider User ID: ${providerUserId}`);
  });
};

export const identifyPatient = (patientId: string): void => {
  runIfEnabled(() => {
    customMixpanel.patient.identify(patientId);
    log(`Identified user with Patient ID: ${patientId}`);
  });
};

export const aliasProvider = (
  newDistinctId: string,
  originalDistinctId?: string,
): void => {
  runIfEnabled(() => {
    customMixpanel.provider.alias(newDistinctId);
    log(
      `Aliasing provider Distinct ID ${newDistinctId} to original provider Mixpanel Distinct ID${
        originalDistinctId ? `: ${originalDistinctId}` : ''
      }`,
    );
  });
};

export const getProviderDistinctId = (): string | undefined => {
  let distinctId;
  runIfEnabled(() => {
    distinctId = customMixpanel.provider.get_distinct_id();
    log(`Getting current Mixpanel Distinct ID: ${distinctId}`);
  });
  return distinctId;
};
