import classNames from 'classnames';
import { FC, useState } from 'react';

import { FollowUpTemplateDataFragment } from '../../../../generated/graphql';
import { getTimeStamp } from '../../../lib/time';
import AvatarList from '../AvatarList';
import {
  AnalyticsComponent,
  AnalyticsPage,
  ProviderAnalyticsEvent,
  trackProviderEvent,
} from '../../../../lib/analytics';
import IconButton from '../../../components/IconButton';
import { TrashIcon } from '@heroicons/react/outline';
import Spinner from '../../../svgs/Spinner';
import RecipientTags from './RecipientTags';
import VoiceNoteLabel from '../../../components/VoiceNoteLabel';
import ActivityAttachmentsLabel from '../../../components/ActivityAttachmentsLabel';

const FollowUpDraftCard: FC<{
  followUpDraft: FollowUpTemplateDataFragment;
  onApplyFollowUpTemplate: (
    followUpDraft: FollowUpTemplateDataFragment,
  ) => Promise<void>;
  onDeleteFollowUpDraft: (
    followUpDraft: FollowUpTemplateDataFragment,
  ) => Promise<void>;
  className?: string;
}> = ({
  followUpDraft,
  onApplyFollowUpTemplate,
  onDeleteFollowUpDraft,
  className,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const hasRecipients =
    followUpDraft.patients.length > 0 || followUpDraft.programTags.length > 0;

  const handleCardClick = () => {
    trackProviderEvent(ProviderAnalyticsEvent.MailTemplateApplied, {
      originPage: AnalyticsPage.FollowUpBuilder,
      originComponent: AnalyticsComponent.FollowUpDrawer,
      mailTemplateType: 'draft',
    });
    onApplyFollowUpTemplate(followUpDraft);
  };

  const handleDeleteClick = async () => {
    try {
      setIsDeleting(true);
      await onDeleteFollowUpDraft(followUpDraft);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div
      className={classNames(
        'flex cursor-pointer flex-col justify-start gap-y-3 rounded-lg bg-white p-5 shadow-100 hover:bg-neutral-25 focus:outline-none focus:ring-2 focus:ring-secondary-50',
        className,
      )}
      // Role, tab index, and onKeyDown are necessary to recreate button accessibility in div
      role="button"
      tabIndex={0}
      onClick={handleCardClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleCardClick();
        }
      }}
    >
      <div className="relative flex w-full flex-row items-center justify-center">
        <div className="text-small-caption text-neutral-125">
          {getTimeStamp(followUpDraft.updatedAt, true, false, true)}
        </div>
        <IconButton
          IconComponent={isDeleting ? Spinner : TrashIcon}
          iconClassName={classNames(
            'h-4 w-4 !text-neutral-125',
            !isDeleting && 'hover:text-red-100',
          )}
          aria-label="Delete draft"
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteClick();
          }}
          className="absolute right-0 -top-1"
          disabled={isDeleting}
        />
      </div>
      {hasRecipients && (
        <div className="flex w-full flex-row items-center gap-x-2">
          <div className="text-body font-medium text-neutral-150">To</div>
          <AvatarList
            users={followUpDraft.patients.map((patient) => ({
              id: patient.programInstanceId,
              name: patient.name,
              profileImageMediaUrl: patient.profileImageMedia?.url,
            }))}
            maxDisplayed={5}
          />
          {followUpDraft.programTags.length > 0 && (
            <RecipientTags programTags={followUpDraft.programTags} />
          )}
        </div>
      )}
      {followUpDraft.subject && (
        <div className="mb-2 text-caption font-medium text-neutral-150 line-clamp-1">
          {followUpDraft.subject}
        </div>
      )}
      {followUpDraft.providerNote && (
        <div className="mb-2 text-caption text-neutral-150 line-clamp-3">
          {followUpDraft.providerNote}
        </div>
      )}
      {(followUpDraft.providerVoiceNote ||
        followUpDraft.activityTemplates.length > 0) && (
        <div className="flex flex-row items-center justify-start gap-x-2">
          {followUpDraft.providerVoiceNote && (
            <VoiceNoteLabel
              duration={followUpDraft.providerVoiceNote.duration}
            />
          )}
          {followUpDraft.activityTemplates.length > 0 && (
            <ActivityAttachmentsLabel
              activityCount={followUpDraft.activityTemplates.length}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default FollowUpDraftCard;
