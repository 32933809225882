import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { FC, Fragment, useEffect, useRef } from 'react';
import CloseX from '../svgs/CloseX';
import Spinner from '../svgs/Spinner';
import Button from './Button';
import {
  AnalyticsComponent,
  AnalyticsPage,
  trackProviderComponentViewedEvent,
} from '../../lib/analytics';
import { useRemoveInert } from '../hooks/useRemoveInert';
import { useAuth } from '../../contexts/AuthContext';

const CloseRow: FC<{
  closeButtonRef: React.RefObject<HTMLButtonElement>;
  closeRowText?: string;
  closeRowClassName?: string;
  onClick: () => void;
}> = ({ closeButtonRef, closeRowText, closeRowClassName, onClick }) => (
  <div
    className={classNames(
      'mb-2 flex w-full flex-row justify-between',
      closeRowClassName,
    )}
  >
    <div className="text-body font-medium text-neutral-150">{closeRowText}</div>
    <Button
      ref={closeButtonRef}
      onClick={onClick}
      title="CLOSE"
      iconPosition="left"
      iconClassName="text-neutral-150"
      className="text-neutral-150"
      IconComponent={CloseX}
      theme="secondary"
      size="extra-small"
      noBackground
    />
  </div>
);

export const MODAL_TRANSITION_DURATION = 200;

export type ModalProps = {
  name?: string; // Used for analytics tracking
  originPage?: AnalyticsPage | string; // Used for analytics tracking
  children: React.ReactNode;
  isOpen: boolean;
  setClosed: () => void;
  fetching?: boolean;
  width?: 'small' | 'medium' | 'large';
  overflow?: 'hidden' | 'auto';
  className?: string;
  hasPadding?: boolean;
  hasCloseRow?: boolean;
  closeRowText?: string;
  closeRowClassName?: string;
  aboveEverything?: boolean;
  isBlocking?: boolean;
  shiftDownForNav?: boolean; // Shifts the modal down for the nav to be visible and interactable
};

const Modal: FC<ModalProps> = ({
  name,
  originPage,
  children,
  isOpen,
  setClosed,
  fetching = false,
  width = 'large',
  overflow = 'hidden',
  hasPadding = true,
  hasCloseRow = true,
  closeRowText,
  closeRowClassName,
  className,
  aboveEverything = false,
  isBlocking = false,
  shiftDownForNav = false,
}) => {
  const { showUpgradeBanner } = useAuth();
  useRemoveInert({ isModalOpen: isOpen, enabled: shiftDownForNav });

  // Modal analytics tracking. Needs 'name' prop to be passed in.
  useEffect(() => {
    if (name && isOpen) {
      trackProviderComponentViewedEvent(AnalyticsComponent.Modal, originPage, {
        modalName: name,
      });
    }
  }, [isOpen]);

  /* https://headlessui.dev/react/dialog#managing-focus-within-your-dialog */
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        initialFocus={closeButtonRef}
        className={classNames(
          'fixed overflow-y-auto',
          // z-index +1 above Slideover
          !aboveEverything ? 'z-[51]' : 'z-[100]',
          shiftDownForNav
            ? showUpgradeBanner
              ? 'inset-x-0 top-[calc(var(--top-nav-height)+var(--upgrade-banner-height))] bottom-0'
              : 'inset-x-0 top-[calc(var(--top-nav-height))] bottom-0'
            : 'inset-0',
        )}
        onClose={isBlocking ? () => void 0 : setClosed}
      >
        <div
          className={classNames(
            'flex items-end justify-center pb-20 text-center sm:block sm:p-0',
            shiftDownForNav
              ? showUpgradeBanner
                ? 'min-h-[calc(100vh-var(--top-nav-height)-var(--upgrade-banner-height))]'
                : 'min-h-[calc(100vh-var(--top-nav-height))]'
              : 'min-h-screen',
          )}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={classNames(
                'fixed bg-neutral-125 bg-opacity-50 backdrop-blur-[4px] transition-opacity',
                shiftDownForNav
                  ? showUpgradeBanner
                    ? 'inset-x-0 top-[calc(var(--top-nav-height)+var(--upgrade-banner-height))] bottom-0'
                    : 'inset-x-0 top-[calc(var(--top-nav-height))] bottom-0'
                  : 'inset-0',
              )}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className={classNames(
              'hidden sm:inline-block sm:h-[calc(100vh-var(--top-nav-height))] sm:align-middle',
              shiftDownForNav
                ? showUpgradeBanner
                  ? 'sm:h-[calc(100vh-var(--top-nav-height)-var(--upgrade-banner-height))]'
                  : 'sm:h-[calc(100vh-var(--top-nav-height))]'
                : 'sm:h-[calc(100vh-var(--top-nav-height))]',
            )}
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames(
                hasPadding && 'px-4 pt-5 pb-4 sm:my-8 sm:p-6',
                'relative inline-block transform rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:max-w-[1100px] sm:align-middle',
                {
                  'w-[90%]': width === 'large',
                  'w-[800px]': width === 'medium',
                  'w-[600px]': width === 'small',
                  'overflow-hidden': overflow === 'hidden',
                },
                className,
              )}
            >
              {hasCloseRow && (
                <CloseRow
                  onClick={setClosed}
                  closeRowText={closeRowText}
                  closeButtonRef={closeButtonRef}
                  closeRowClassName={closeRowClassName}
                />
              )}
              {children}
              <Transition
                show={fetching}
                as={Fragment}
                enter="transform ease-out duration-100 transition"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transform ease-in duration-100 transition"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute inset-0 z-50 flex h-full w-full items-center justify-center bg-neutral-25/80">
                  <Spinner className="h-6 w-6 text-green-150" />
                </div>
              </Transition>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
