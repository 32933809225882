import { FC, useState } from 'react';
import { Transition } from '@headlessui/react';

import { getAssessmentsDataExport } from '../../../api/rest/assessments';

import { useAuth } from '../../../contexts/AuthContext';
import { defaultTransitionProps } from '../../lib/animation';

import ProfilePhoto from './ProfilePhoto';
import ChangePasswordModal from './ChangePasswordModal';
import ResetOnboardingModal from './ResetOnboardingModal';
import SettingsItem, { SettingsRow } from './SettingsItem';
import { isProviderUserOwnerOrAdminAuthorized } from '../../../lib/auth';
import RenameProviderModal from './Modals/RenameProviderModal';
import Loading from '../Loading';
import RenameProviderUserModal from './Modals/RenameProviderUserModal';

const AccountSection: FC = () => {
  const {
    authedProviderUser,
    isAuthedProviderUserLoading,
    refreshAuthedProviderUser,
  } = useAuth();

  // Disable in V2 until the export functionality is expanded to make sense for V2 users
  // The existing version was built in the context of a V1 Clinic, specifically 1 clinics
  // particular needs, and as is I don't think it will be useful or make sense for V2 users.
  const canExportAssessmentsData = false;

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isResetOnboardingModalOpen, setIsResetOnboardingModalOpen] =
    useState(false);
  const [isRenameProviderModalOpen, setIsRenameProviderModalOpen] =
    useState(false);
  const [isRenameProviderUserModalOpen, setIsRenameProviderUserModalOpen] =
    useState(false);

  const downloadAssessmentsData = () => {
    getAssessmentsDataExport();
  };

  const settingsItems: SettingsRow[] = [
    {
      label: 'Organization Name',
      data: authedProviderUser?.provider.name ?? '',
      ...(isProviderUserOwnerOrAdminAuthorized(authedProviderUser) && {
        onClickEdit: () => setIsRenameProviderModalOpen(true),
      }),
    },
    {
      label: 'Name',
      data: authedProviderUser?.name ?? '',
      onClickEdit: () => setIsRenameProviderUserModalOpen(true),
    },
    { label: 'Email', data: authedProviderUser?.email ?? '' },
    {
      label: 'Profile photo',
      innerComponent: <ProfilePhoto />,
    },
    ...(canExportAssessmentsData
      ? [
          {
            label: 'Assessments',
            data: 'Export Assessments Data',
            onClick: () => downloadAssessmentsData(),
          },
        ]
      : []),
    ...(authedProviderUser?.provider.isTestProvider
      ? [
          {
            label: 'Testing',
            data: 'Reset Onboarding',
            onClick: () => setIsResetOnboardingModalOpen(true),
          },
        ]
      : []),
  ];

  return (
    <>
      <div className="mt-4 flex w-[750px] flex-col">
        <Transition
          show={Boolean(authedProviderUser)}
          {...defaultTransitionProps}
        >
          {settingsItems.map((settingsItem) => (
            <SettingsItem
              key={`settingsItem_${settingsItem.label}`}
              settingsRow={settingsItem}
            />
          ))}
        </Transition>

        {isAuthedProviderUserLoading && !authedProviderUser && <Loading />}
      </div>

      <ChangePasswordModal
        isModalOpen={isChangePasswordModalOpen}
        setClosed={() => setIsChangePasswordModalOpen(false)}
      />
      <ResetOnboardingModal
        isModalOpen={isResetOnboardingModalOpen}
        setClosed={() => setIsResetOnboardingModalOpen(false)}
        providerUserEmail={authedProviderUser?.email}
      />
      <RenameProviderModal
        isModalOpen={isRenameProviderModalOpen}
        setClosed={() => setIsRenameProviderModalOpen(false)}
        providerName={authedProviderUser?.provider.name}
        onComplete={async () => {
          await refreshAuthedProviderUser();
        }}
      />
      <RenameProviderUserModal
        isModalOpen={isRenameProviderUserModalOpen}
        setClosed={() => setIsRenameProviderUserModalOpen(false)}
        providerUserName={authedProviderUser?.name}
        onComplete={async () => {
          await refreshAuthedProviderUser();
        }}
      />
    </>
  );
};

export default AccountSection;
