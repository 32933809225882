import { DateTime } from 'luxon';
import {
  useCreateNylasCalendarEventMutation,
  useUpdateNylasCalendarEventMutation,
} from '../../../../../generated/graphql';
import { customToast } from '../../../../components/ToastAlert/customToast';
import { CreateEventFormData } from '../components/types';
import { serializeEventTime } from '../utils/eventSerializers';
import { EventDropArg } from '@fullcalendar/core';
import { EventResizeDoneArg } from '@fullcalendar/interaction';

export const useEventMutation = () => {
  const [createEvent] = useCreateNylasCalendarEventMutation();
  const [updateEvent] = useUpdateNylasCalendarEventMutation();

  const handleCreateEvent = async (
    data: CreateEventFormData,
    calendarId?: string,
  ): Promise<boolean> => {
    try {
      const when = serializeEventTime(data);

      const response = await createEvent({
        variables: {
          input: {
            calendarId,
            data: {
              ...when,
              title: data.title,
              description: data.description,
              location: data.location,
              participants: data.participants,
            },
          },
        },
      });

      if (!response.data?.createNylasCalendarEvent) {
        throw new Error('Failed to create event');
      }

      customToast.success('Event created successfully');
      return true;
    } catch (error) {
      console.error('Error creating event:', error);
      customToast.error('Failed to create event');
      return false;
    }
  };

  const handleUpdateEvent = async (
    calendarId: string,
    eventId: string,
    data: CreateEventFormData,
  ) => {
    try {
      const when = serializeEventTime(data);

      const response = await updateEvent({
        variables: {
          input: {
            calendarId,
            eventId,
            data: {
              ...when,
              title: data.title,
              description: data.description,
              location: data.location,
              participants: data.participants,
            },
          },
        },
      });

      if (!response.data?.updateNylasCalendarEvent) {
        throw new Error('Failed to update event');
      }

      customToast.success('Event updated successfully');
      return true;
    } catch (error) {
      console.error('Error updating event:', error);
      customToast.error('Failed to update event');
      return false;
    }
  };

  interface EventDragOrResizeArgs {
    info: EventDropArg | EventResizeDoneArg;
    calendarId?: string;
    onSuccess?: () => void;
    onError?: () => void;
  }

  const handleEventDragOrResize = async ({
    info,
    calendarId,
    onSuccess,
    onError,
  }: EventDragOrResizeArgs): Promise<boolean> => {
    const { event } = info;

    try {
      const eventData = {
        isAllDay: event.allDay,
        when: {
          startDate: event.startStr.split('T')[0],
          endDate: event.endStr.split('T')[0],
          startTime: DateTime.fromISO(event.startStr).toFormat('HH:mm'),
          endTime: DateTime.fromISO(event.endStr).toFormat('HH:mm'),
          startTimezone: event.extendedProps.when.startTimezone,
          endTimezone: event.extendedProps.when.endTimezone,
        },
      };

      const serialized = serializeEventTime(eventData);
      const response = await updateEvent({
        variables: {
          input: {
            calendarId,
            eventId: event.id,
            data: serialized,
          },
        },
      });

      if (!response.data?.updateNylasCalendarEvent) {
        throw new Error('Failed to update event');
      }

      customToast.success('Event updated successfully');
      onSuccess?.();
      return true;
    } catch (error) {
      console.error('Error updating event:', error);
      customToast.error('Failed to update event');
      onError?.();
      return false;
    }
  };

  return { handleCreateEvent, handleUpdateEvent, handleEventDragOrResize };
};
