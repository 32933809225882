import React from 'react';
import {
  SignatureUser,
  ProgramAssessmentSignatureUserDataFragment,
} from '../../../generated/graphql';
import CompletedSignature from '../../components/CompletedSignature';

interface MultiSignatureSectionProps {
  assessmentSignatureOrderIndices: number[];
  programAssessmentSignatureUsers: ProgramAssessmentSignatureUserDataFragment[];
  currentSigner: ProgramAssessmentSignatureUserDataFragment;
  signatureTermsAgreed: boolean;
  signatureRole: string;
  signatureName: string;
  nowFormattedTimestamp: string;
}

const MultiSignatureSection: React.FC<MultiSignatureSectionProps> = ({
  assessmentSignatureOrderIndices,
  programAssessmentSignatureUsers,
  currentSigner,
  signatureTermsAgreed,
  signatureRole,
  signatureName,
  nowFormattedTimestamp,
}) => {
  const signatures = assessmentSignatureOrderIndices.map(
    (orderIndex, index) => {
      const programAssessmentSignatureUser =
        programAssessmentSignatureUsers.find(
          (user) => user.assessmentSignatureUser.orderIndex === orderIndex,
        );

      if (!programAssessmentSignatureUser) return null;

      const isCurrentSigner =
        programAssessmentSignatureUser.assessmentSignatureUser?.orderIndex ===
        currentSigner?.assessmentSignatureUser?.orderIndex;

      const isNotPatientSigner =
        programAssessmentSignatureUser.assessmentSignatureUser
          ?.signatureUserType !== SignatureUser.PatientUser;

      const shouldRenderSignature =
        programAssessmentSignatureUser.signedAt ||
        (isCurrentSigner &&
          signatureTermsAgreed &&
          signatureRole &&
          signatureName);

      if (!shouldRenderSignature) return null;

      return (
        <CompletedSignature
          key={index}
          isCurrentSigner={isCurrentSigner}
          signatureName={signatureName}
          signatureRole={isNotPatientSigner ? signatureRole : 'Client'}
          nowFormattedTimestamp={nowFormattedTimestamp}
          programAssessmentSignatureUser={programAssessmentSignatureUser}
          className="mt-6"
        />
      );
    },
  );

  const hasSignatures = signatures.some(Boolean);

  return (
    <>
      {signatures}
      {!hasSignatures && (
        <p className="mt-4 text-caption font-medium text-neutral-110">
          No signatures yet.
        </p>
      )}
    </>
  );
};

export default MultiSignatureSection;
