import React from 'react';
import classNames from 'classnames';
import { Disclosure } from '@headlessui/react';
import { SvgIconComponent } from '../types/svgs';
import { ChevronDownIcon } from '@heroicons/react/outline';

const CollapseableContainer: React.FC<{
  title: string;
  Icon?: SvgIconComponent;
  children: React.ReactNode;
  className?: string;
  defaultOpen?: boolean;
  ButtonComponent?: React.ReactNode;
}> = ({
  title,
  Icon,
  children,
  className,
  defaultOpen = true,
  ButtonComponent,
}) => {
  return (
    <Disclosure
      as="div"
      className={classNames(
        'relative overflow-hidden rounded-xl border border-neutral-75 bg-white',
        className,
      )}
      defaultOpen={defaultOpen}
    >
      {({ open }) => (
        <>
          <Disclosure.Button
            className={classNames(
              'flex w-full items-center justify-between',
              !ButtonComponent ? 'p-5' : 'px-5 py-3.5',
            )}
          >
            <div className="flex items-center space-x-3">
              {Icon && <Icon className="h-5 w-5 text-neutral-125" />}
              <div className="text-body font-medium text-neutral-150">
                {title}
              </div>
            </div>
            <div className="flex flex-row items-center justify-end">
              {ButtonComponent && ButtonComponent}
              <ChevronDownIcon
                className={classNames(
                  'ui-open:rotate-180 ui-open:transform',
                  'text-purple-500 h-5 w-5',
                  open ? 'rotate-180 transform' : '',
                )}
              />
            </div>
          </Disclosure.Button>
          <Disclosure.Panel>{children}</Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default CollapseableContainer;
