import { FC, useEffect, useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';

import { CommentDataFragment, UserType } from '../../../generated/graphql';

import Comment from './Comment';
import EmptyProfileTab from '../EmptyProfileTab';
import IllustrationChat from '../../svgs/IllustrationChat';

export type CommentPredicate = (
  comment: CommentDataFragment,
  providerUserId?: string,
) => boolean;

const isAuthoredProviderUser: CommentPredicate = (comment, providerUserId) => {
  return (
    comment.authorUserType === UserType.ProviderUser &&
    comment.authorProviderUserId === providerUserId
  );
};

const ConversationContainer: FC<{
  patientFirstName: string;
  comments: CommentDataFragment[];
  onCommentDeleted?: (commentId?: string) => void;
  fullSizeEmptyState?: boolean;
  canPerformActionsOnComment?: CommentPredicate;
  getNextChunkOfComments?: () => Promise<void>;
  areCommentsLoading?: boolean;
  className?: string;
}> = ({
  patientFirstName,
  comments,
  onCommentDeleted,
  fullSizeEmptyState = false,
  canPerformActionsOnComment = isAuthoredProviderUser,
  getNextChunkOfComments,
  areCommentsLoading,
  className,
}) => {
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const hasScrolledToBottomRef = useRef(false);
  const previousScrollHeightRef = useRef<number>(0);

  const handleScroll = async () => {
    if (chatContainerRef.current && getNextChunkOfComments) {
      if (!areCommentsLoading && chatContainerRef.current.scrollTop < 50) {
        await getNextChunkOfComments();
      }
    }
  };

  // Hold the scroll position when new comments are prepended
  // Without this, the scroll position would jump to the top of the container
  useLayoutEffect(() => {
    if (chatContainerRef.current) {
      const newScrollHeight = chatContainerRef.current.scrollHeight;
      const scrollHeightDifference =
        newScrollHeight - previousScrollHeightRef.current;
      chatContainerRef.current.scrollTop += scrollHeightDifference;
      previousScrollHeightRef.current = newScrollHeight;
    }
  }, [comments]);

  useEffect(() => {
    // Scroll to bottom the first time comments are loaded if its a paginated container
    // indicated by the presence of getNextChunkOfComments
    if (
      getNextChunkOfComments &&
      comments.length &&
      chatContainerRef.current &&
      !hasScrolledToBottomRef.current
    ) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
      hasScrolledToBottomRef.current = true;
      previousScrollHeightRef.current = chatContainerRef.current.scrollHeight;

      // Check if the container is scrollable. If it isn't because the first chunk of comments
      // is less than the height of the container, then fetch the next chunk of comments
      if (
        chatContainerRef.current.offsetHeight >=
        chatContainerRef.current.scrollHeight
      ) {
        getNextChunkOfComments();
      }
    }
  }, [comments]);

  return (
    <div
      ref={chatContainerRef}
      onScroll={handleScroll}
      className={classNames(
        'flex h-full w-full flex-col gap-y-4 overflow-y-auto overscroll-y-contain pr-2',
        className,
      )}
    >
      {!comments.length ? (
        fullSizeEmptyState ? (
          <EmptyProfileTab
            IconComponent={IllustrationChat}
            iconClassName={'text-secondary-100'}
            titleText={`${patientFirstName}'s Chat`}
            subTitleText="Write a message to start a conversation"
            buttonTitle={`Follow up with ${patientFirstName}`}
            className="h-[300px]"
          />
        ) : (
          <div className="mb-6 flex w-full items-center justify-center rounded-xl border border-neutral-75 py-8 px-12 text-center text-caption text-neutral-110">
            Write a message to start a conversation
          </div>
        )
      ) : (
        comments.map((comment, index) => {
          const isLastComment = index === comments.length - 1;

          return (
            <Comment
              key={`comment_${index}`}
              comment={comment}
              onCommentDeleted={onCommentDeleted}
              canPerformActionsOnComment={canPerformActionsOnComment}
              className={classNames(isLastComment && 'mb-4')}
            />
          );
        })
      )}
    </div>
  );
};

export default ConversationContainer;
