// index.tsx
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useAuth } from '../../../../contexts/AuthContext';

import { DEFAULT_COLORS } from './constants';
import type { AppearanceForm } from './types';
import { useAppearanceHandlers } from './useAppearanceHandlers';

import ColorInput from './ColorInput';
import { LogoUploadSection } from './LogoUploadSection';

const AppearanceSection: FC = () => {
  const { authedProviderUser, refreshAuthedProviderUser } = useAuth();

  const { watch, register, setValue } = useForm<AppearanceForm>({
    mode: 'onSubmit',
    defaultValues: {
      primaryColor: authedProviderUser?.primaryColor || DEFAULT_COLORS.PRIMARY,
      secondaryColor:
        authedProviderUser?.secondaryColor || DEFAULT_COLORS.SECONDARY,
      squareLogoMediaUrl: authedProviderUser?.squareLogoMedia?.url,
      wordmarkLogoMediaUrl: authedProviderUser?.wordmarkLogoMedia?.url,
      emailImageMediaUrl: authedProviderUser?.emailImageMedia?.url,
    },
  });

  const { updateColor, handleLogoUpload, handleLogoDelete } =
    useAppearanceHandlers(setValue, refreshAuthedProviderUser);

  const watchPrimaryColor = watch('primaryColor');
  const watchSecondaryColor = watch('secondaryColor');
  const watchSquareLogo = watch('squareLogoMediaUrl');
  // const watchWordmarkLogo = watch('wordmarkLogoMediaUrl');
  // const watchEmailImage = watch('emailImageMediaUrl');

  useEffect(() => {
    if (authedProviderUser) {
      const {
        primaryColor,
        secondaryColor,
        squareLogoMedia,
        wordmarkLogoMedia,
        emailImageMedia,
      } = authedProviderUser;

      setValue('primaryColor', primaryColor ?? DEFAULT_COLORS.PRIMARY);
      setValue('secondaryColor', secondaryColor ?? DEFAULT_COLORS.SECONDARY);
      setValue('squareLogoMediaUrl', squareLogoMedia?.url);
      setValue('wordmarkLogoMediaUrl', wordmarkLogoMedia?.url);
      setValue('emailImageMediaUrl', emailImageMedia?.url);
    }
  }, [authedProviderUser, setValue]);

  const createColorRegister = (colorField: 'primaryColor' | 'secondaryColor') =>
    register(colorField, {
      pattern: {
        value: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
        message: 'Please enter a valid hex color code (e.g. #394642)',
      },
      onChange: async (e) => {
        let value = e.target.value;
        value = value.replace('#', '');
        value = '#' + value;
        setValue(colorField, value);

        if (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
          await updateColor(value, colorField);
        }
      },
      onBlur: () => setValue(colorField, watch(colorField).trim()),
    });

  const primaryColorRegister = createColorRegister('primaryColor');
  const secondaryColorRegister = createColorRegister('secondaryColor');

  return (
    <div className="w-[400px] pt-6">
      <div className="mb-4 text-category font-medium text-neutral-125">
        Platform customization
      </div>

      <ColorInput
        colorType="primary"
        description="Choose a darker color that contrasts well with white button text for readability across the platform."
        value={watchPrimaryColor}
        defaultValue={DEFAULT_COLORS.PRIMARY}
        register={primaryColorRegister}
        setValue={async (value) => {
          setValue('primaryColor', value);
          await updateColor(value, 'primaryColor');
        }}
      />

      <ColorInput
        colorType="secondary"
        description="Choose a mild color to subtly highlight selected items on the platform. This color should be soft enough to avoid distraction but still noticeable for emphasis."
        value={watchSecondaryColor}
        defaultValue={DEFAULT_COLORS.SECONDARY}
        register={secondaryColorRegister}
        setValue={async (value) => {
          setValue('secondaryColor', value);
          await updateColor(value, 'secondaryColor');
        }}
      />

      <LogoUploadSection
        title="Upload logo"
        logoType="squareLogoMediaUrl"
        logoPreview={watchSquareLogo}
        onUpload={handleLogoUpload}
        onDelete={handleLogoDelete}
      />

      {/* <div className="mb-4 mt-8 text-category font-medium">
        Email customization
      </div>

      <LogoUploadSection
        title="Upload wordmark"
        logoType="wordmarkLogoMediaUrl"
        logoPreview={watchWordmarkLogo}
        onUpload={handleLogoUpload}
        onDelete={handleLogoDelete}
      />

      <LogoUploadSection
        title="Upload image"
        logoType="emailImageMediaUrl"
        logoPreview={watchEmailImage}
        onUpload={handleLogoUpload}
        onDelete={handleLogoDelete}
      /> */}
    </div>
  );
};

export default AppearanceSection;
