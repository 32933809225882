import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Transition } from '@headlessui/react';
import {
  AnalyticsPage,
  trackProviderEvent,
  CommonAnalyticsEvent,
} from '../../lib/analytics';
import IconButton from '../components/IconButton';
import { ArrowNarrowLeftIcon } from '@heroicons/react/outline';
import Subscription from '../components/Subscription';

const Subscribe: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const stripeCheckoutPortalWebRedirectPath = (
    location.state as { stripeCheckoutPortalWebRedirectPath?: string }
  )?.stripeCheckoutPortalWebRedirectPath;

  const onGoBack = () => {
    trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
      originPage: AnalyticsPage.Subscribe,
      buttonName: 'Go back',
    });
    // Use the stripeCheckoutPortalWebRedirectPath to execute the "back",
    // but default to home. Using navigate(-1) by default risks getting the user stuck on this page if they
    // come from an external link (like the Stripe checkout page).
    if (stripeCheckoutPortalWebRedirectPath) {
      navigate(`/${stripeCheckoutPortalWebRedirectPath}`);
    } else {
      navigate('/');
    }
  };

  return (
    <Transition appear={true} show={true}>
      <div className="flex min-h-[calc(100vh-64px)] flex-col">
        <div className="fixed z-10 flex w-full flex-row items-center justify-between border-b border-neutral-50 bg-white py-2 px-20">
          <IconButton
            IconComponent={ArrowNarrowLeftIcon}
            iconClassName="text-secondary-100 w-6"
            aria-label="Back"
            onClick={onGoBack}
          />
        </div>
        <Subscription />
      </div>
    </Transition>
  );
};

export default Subscribe;
