import _ from 'lodash';

import {
  MultipleChoiceAssessmentQuestion,
  MultipleChoiceScoredAssessmentQuestion,
  MultiSelectAssessmentQuestion,
  FreeTextAssessmentQuestion,
  SignatureAssessmentQuestion,
  MultiSignatureAssessmentQuestion,
  RichTextAssessmentQuestion,
  InitialsAssessmentQuestion,
  CompleteAssessmentAnswerInput,
  MultipleChoiceScoredAssessmentQuestionAnswerOption,
} from '../../generated/graphql';

import {
  isSignatureTypeAssessmentQuestion,
  isMultiSelectAssessmentQuestion,
} from './assessments';

type AssessmentQuestionWithAnswers =
  | MultipleChoiceAssessmentQuestion
  | MultipleChoiceScoredAssessmentQuestion
  | MultiSelectAssessmentQuestion;

export type ProgramAssessmentQuestion =
  | FreeTextAssessmentQuestion
  | InitialsAssessmentQuestion
  | SignatureAssessmentQuestion
  | MultiSignatureAssessmentQuestion
  | RichTextAssessmentQuestion
  | AssessmentQuestionWithAnswers;

export type AssessmentAnswerInput = {
  value: string;
  values: string[];
  signatureName?: string;
  signatureTermsAgreed?: boolean;
};

export function mapAssessmentAnswersToMutationInput(
  questions: ProgramAssessmentQuestion[],
  answers: AssessmentAnswerInput[],
): CompleteAssessmentAnswerInput[] {
  return questions.map((question, idx) => {
    const answerValue = answers?.[idx]?.value ?? '';
    const newAnswer: CompleteAssessmentAnswerInput = {
      question: question.question,
      questionType: question.questionType,
    };

    if (isSignatureTypeAssessmentQuestion(question)) {
      newAnswer.signatureName = answers?.[idx]?.signatureName;
    } else {
      newAnswer.answer = answerValue;
    }

    if (isMultiSelectAssessmentQuestion(question)) {
      const multiSelectAnswers: string[] = answers?.[idx]?.values ?? [];
      newAnswer.answers = multiSelectAnswers;
    }

    const answerOptions =
      (question as MultipleChoiceScoredAssessmentQuestion)?.answerOptions ?? [];

    const answer = answerOptions.find(
      (option) => option.answerOption === answerValue,
    );

    const multipleChoiceSumAnswer =
      answer as MultipleChoiceScoredAssessmentQuestionAnswerOption;
    if (multipleChoiceSumAnswer && !_.isNil(multipleChoiceSumAnswer.score)) {
      newAnswer.score = multipleChoiceSumAnswer.score;
    }

    const multipleChoiceQuestion =
      question as MultipleChoiceScoredAssessmentQuestion;
    if (multipleChoiceQuestion && !_.isNil(multipleChoiceQuestion.subscale)) {
      newAnswer.subscale = multipleChoiceQuestion.subscale;
    }

    return newAnswer;
  });
}
