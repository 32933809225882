export enum CalendarViewType {
  CALENDARS = 'calendars',
  RESOURCES = 'resources',
}

export enum ViewMode {
  TIME_GRID_WEEK = 'timeGridWeek',
  TIME_GRID_DAY = 'timeGridDay',
  DAY_GRID_MONTH = 'dayGridMonth',
  RESOURCE_TIMELINE_WEEK = 'resourceTimelineWeek',
}
