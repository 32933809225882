import classNames from 'classnames';
import { Link } from 'react-router-dom';

type IntegrationCardProps = {
  logo: React.ReactNode;
  name: string;
  description: string | React.ReactNode;
  isConnected: boolean;
  integrationPath: string;
};

const IntegrationCard: React.FC<IntegrationCardProps> = ({
  logo,
  name,
  description,
  isConnected,
  integrationPath,
}) => {
  return (
    <Link
      to={integrationPath}
      className="flex max-w-[360px] flex-col items-start overflow-hidden rounded-lg border border-neutral-75 p-5 text-neutral-125 hover:bg-neutral-25"
      aria-label={`Integration with ${name}`}
    >
      <div>{logo}</div>
      <p className="my-4 flex-grow text-caption">{description}</p>
      <div
        className={classNames(
          'mt-auto rounded-full py-1 px-3 text-small-caption',
          isConnected ? 'bg-green-25 text-green-150' : 'bg-neutral-50',
        )}
      >
        {isConnected ? 'Connected' : 'Not connected'}
      </div>
    </Link>
  );
};

export default IntegrationCard;
