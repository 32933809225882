import { FC, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { BlobProvider } from '@react-pdf/renderer';
import { DownloadIcon } from '@heroicons/react/outline';
import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  LineChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

import {
  AssessmentSchemaType,
  CompletedProgramAssessmentsDataQuery,
  LatestCompletedProgramAssessmentsQuery,
  useCompletedProgramAssessmentsDataLazyQuery,
} from '../../../../../generated/graphql';

import {
  AnalyticsPage,
  AnalyticsComponent,
  trackProviderComponentViewedEvent,
} from '../../../../../lib/analytics';

import { getTimeStamp } from '../../../../lib/time';
import { defaultTransitionProps } from '../../../../lib/animation';

import { colors } from '../../../../lib/colors';
import { downloadAllPdfs, handlePdfDownload } from '../../../../lib/pdf';

import Slideover, {
  SLIDEOVER_TRANSITION_DURATION,
} from '../../../../components/Slideover';
import SlideoverSection from '../../../../components/SlideoverSection';
import Button from '../../../../components/Button';
import { customToast } from '../../../../components/ToastAlert/customToast';

import InterpretationSection from '../../../../components/Assessments/InterpretationSection';
import ScoreSection from '../../../../components/Assessments/ScoreSection';
import ResponsesSection from '../../../../components/Assessments/ResponsesSection';
import HistorySection from '../../../../components/Assessments/HistorySection';
import { getCompletedAssessmentGraphData } from '../../../../components/Assessments/helpers';
import ProgramAssessmentPDF from '../../../../components/ProgramActivitySlideover/ProgramAssessmentPDF';

import { CustomAxisTick, CustomizedDot, CustomTooltip } from './CustomGraph';

const AssessmentsCompletedSlideover: FC<{
  onClose: () => void;
  isOpen: boolean;
  patientFirstName?: string;
  programId: string;
  selectedAssessment:
    | LatestCompletedProgramAssessmentsQuery['latestCompletedProgramAssessments'][number]['assessment']
    | undefined;
}> = ({ onClose, isOpen, patientFirstName, programId, selectedAssessment }) => {
  const [shownAssessmentQandAIndex, setShownAssessmentQandAIndex] = useState<
    number | null
  >(null);

  const assessmentQandAShown = Boolean(shownAssessmentQandAIndex !== null);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setShownAssessmentQandAIndex(null);
      }, SLIDEOVER_TRANSITION_DURATION);
    }
  }, [isOpen]);

  const [
    getCompletedProgramAssessmentsData,
    {
      data: completedProgramAssessmentsData,
      error: completedProgramAssessmentsError,
      loading: isLoadingCompletedProgramAssessments,
    },
  ] = useCompletedProgramAssessmentsDataLazyQuery();

  useEffect(() => {
    if (selectedAssessment) {
      getCompletedProgramAssessmentsData({
        variables: {
          programId,
          assessmentId: selectedAssessment.id,
        },
      });
      trackProviderComponentViewedEvent(
        AnalyticsComponent.AssessmentSlideover,
        AnalyticsPage.ClientProfile,
        {
          assessmentType: selectedAssessment.type,
        },
      );
    }
  }, [selectedAssessment]);

  useEffect(() => {
    if (completedProgramAssessmentsError) {
      customToast.error('Unable to fetch completed assessments.');
    }
  }, [completedProgramAssessmentsError]);

  const completedProgramAssessments =
    completedProgramAssessmentsData?.completedProgramAssessmentsByAssessment as CompletedProgramAssessmentsDataQuery['completedProgramAssessmentsByAssessment'];

  const assessmentTimestampTitle =
    shownAssessmentQandAIndex !== null
      ? getTimeStamp(
          completedProgramAssessments[shownAssessmentQandAIndex].completedAt,
          false,
        )
      : '';

  const { graphData, hasSecondaryScore, graphTitle } =
    getCompletedAssessmentGraphData(completedProgramAssessments);

  const isCustomAssessment =
    selectedAssessment?.schemaType === AssessmentSchemaType.Custom;

  const title = isCustomAssessment
    ? selectedAssessment.name
    : selectedAssessment?.shortName ?? '';

  const subtitle = assessmentQandAShown
    ? assessmentTimestampTitle
    : !isCustomAssessment
    ? `${patientFirstName}'s ${selectedAssessment?.name}`
    : undefined;

  return (
    <Slideover
      isOpen={isOpen}
      fetching={isLoadingCompletedProgramAssessments}
      title={title}
      subtitle={subtitle}
      onClose={onClose}
      backButtonActive={assessmentQandAShown}
      onBackButtonClick={() => setShownAssessmentQandAIndex(null)}
      showContent={
        !isLoadingCompletedProgramAssessments &&
        !completedProgramAssessmentsError &&
        Boolean(completedProgramAssessmentsData)
      }
    >
      <Transition
        show={shownAssessmentQandAIndex === null}
        {...defaultTransitionProps}
      >
        {!isCustomAssessment && (
          <>
            <SlideoverSection
              title={graphTitle}
              className="h-80 pl-0"
              titleClassName="px-6"
            >
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={graphData}
                  margin={{ top: 5, left: -8, right: 15, bottom: 5 }}
                  width={3}
                  height={4}
                >
                  <CartesianGrid stroke={colors.neutral[50]} vertical={false} />
                  <XAxis
                    dataKey="timestamp"
                    tickLine={false}
                    axisLine={false}
                    tick={<CustomAxisTick isXAxis />}
                    dy={10}
                  />
                  <YAxis
                    dx={-20}
                    axisLine={false}
                    tickLine={false}
                    tick={<CustomAxisTick isYAxis />}
                    domain={[
                      completedProgramAssessmentsData?.assessment?.scoreRange
                        ?.min ?? 0,
                      completedProgramAssessmentsData?.assessment?.scoreRange
                        ?.max ?? 100,
                    ]}
                  />
                  <Line
                    type="linear"
                    dataKey="primaryScore"
                    stroke={colors.neutral[100]}
                    dot={<CustomizedDot />}
                    activeDot={
                      <CustomizedDot
                        isActiveDot
                        setShownAssessmentQandAIndex={
                          setShownAssessmentQandAIndex
                        }
                      />
                    }
                    isAnimationActive={false}
                  />
                  {hasSecondaryScore && (
                    <Line
                      type="linear"
                      dataKey="secondaryScore"
                      stroke={colors.neutral[100]}
                      strokeDasharray="4 4"
                      dot={<CustomizedDot isSecondaryScore />}
                      activeDot={
                        <CustomizedDot
                          isActiveDot
                          setShownAssessmentQandAIndex={
                            setShownAssessmentQandAIndex
                          }
                          isSecondaryScore
                        />
                      }
                      isAnimationActive={false}
                    />
                  )}
                  <Tooltip cursor={false} content={<CustomTooltip />} />
                </LineChart>
              </ResponsiveContainer>
            </SlideoverSection>
          </>
        )}
        {!isCustomAssessment && (
          <InterpretationSection
            assessment={completedProgramAssessmentsData?.assessment}
          />
        )}
        <HistorySection
          completedProgramAssessments={completedProgramAssessments}
          assessmentType={selectedAssessment?.type}
          assessmentSchemaType={selectedAssessment?.schemaType}
          setShownAssessmentQandAIndex={setShownAssessmentQandAIndex}
        />
        <SlideoverSection>
          <Button
            title="Download PDFs"
            onClick={() => {
              downloadAllPdfs(completedProgramAssessments, patientFirstName)
                .then((count) => {
                  customToast.success(
                    `Downloaded ${count} PDF${count !== 1 ? 's' : ''}`,
                  );
                })
                .catch((error) => {
                  console.error(`Failed to generate PDFs: ${error.message}`);
                  customToast.error(
                    `Failed to generate PDFs: ${error.message}`,
                  );
                });
            }}
            className="w-full"
            size="small"
            IconComponent={DownloadIcon}
          />
        </SlideoverSection>
        <SlideoverSection
          title="About"
          textContent={completedProgramAssessmentsData?.assessment?.description}
        />
      </Transition>
      <Transition show={assessmentQandAShown} {...defaultTransitionProps}>
        {shownAssessmentQandAIndex !== null && (
          <>
            <SlideoverSection>
              <BlobProvider
                document={
                  <ProgramAssessmentPDF
                    programAssessment={completedProgramAssessments?.[0]}
                    isProviderUser
                  />
                }
              >
                {({ blob, loading, error }) => (
                  <Button
                    title={loading ? 'Generating PDF...' : 'Download PDF'}
                    onClick={() =>
                      handlePdfDownload(
                        blob,
                        completedProgramAssessments?.[0],
                        patientFirstName,
                        () =>
                          customToast.success('PDF downloaded successfully'),
                      )
                    }
                    className="w-full"
                    disabled={loading || Boolean(error)}
                    size="small"
                    theme="primary"
                    IconComponent={DownloadIcon}
                  />
                )}
              </BlobProvider>
            </SlideoverSection>
            {!isCustomAssessment && (
              <ScoreSection
                assessmentType={selectedAssessment?.type}
                title="Score"
                completedAssessment={
                  completedProgramAssessments[shownAssessmentQandAIndex]
                }
              />
            )}
            {Boolean(
              completedProgramAssessments[shownAssessmentQandAIndex]
                .additionalThoughts,
            ) && (
              <SlideoverSection
                title={`Notes from ${patientFirstName}`}
                textContent={
                  `"${completedProgramAssessments[shownAssessmentQandAIndex].additionalThoughts}"` ??
                  undefined
                }
              />
            )}
            <ResponsesSection
              title="Responses"
              questionFrame={
                completedProgramAssessmentsData?.assessment?.questionFrame ??
                undefined
              }
              programAssessment={
                completedProgramAssessments[shownAssessmentQandAIndex]
              }
            />
            <SlideoverSection
              title="About"
              textContent={
                completedProgramAssessmentsData?.assessment?.description
              }
            />
          </>
        )}
      </Transition>
    </Slideover>
  );
};

export default AssessmentsCompletedSlideover;
