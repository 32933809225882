import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Transition } from '@headlessui/react';

import { FeatureFlagProvider } from '../contexts/FeatureFlagContext';

import { defaultTransitionProps } from '../v2/lib/animation';

import PatientHeader from '../v2/components/Headers/PatientHeader';
import { usePatientAuth } from '../contexts/PatientAuthContext';
import { useEffect } from 'react';
import classNames from 'classnames';
import { useHomecomingLoader } from '../v2/hooks/useHomecomingLoader';

const INTERNAL_UNAUTHED_ROUTE = '/login-token';
const HIDE_HEADER_ROUTES = ['/client/intake'];

function AuthedPatientContainer() {
  const navigate = useNavigate();
  const location = useLocation();

  const { patientTokenPayload, authedPatient, refreshAuthedPatient } =
    usePatientAuth();

  const HomecomingLoader = useHomecomingLoader();

  const loadInitialAuthedPatient = async () => {
    try {
      await refreshAuthedPatient();
    } catch (error) {
      // TODO: Test if this is necessary / works
      if (error && error.message !== 'Invalid bearer token') {
        // Don't show an error message for invalid token errors.
        navigate('/login/client');
        // logout();
      }
    }
  };

  useEffect(() => {
    if (patientTokenPayload && !authedPatient) {
      // This will occur upon a page refresh.
      loadInitialAuthedPatient();
    }
  }, [patientTokenPayload, authedPatient]);

  // Special case here for /login-token, which was originally created within the /client sub-route.
  // Because links are live pointing to this route and requiring it to be unauthed, it has
  // to be the exception to the rule here, that all /client routes require auth through the
  // existence of the patient token payload.
  if (location.pathname.includes(INTERNAL_UNAUTHED_ROUTE)) {
    return <Outlet />;
  }

  if (!patientTokenPayload) {
    console.log(
      'No patient token payload, redirecting to login',
      patientTokenPayload,
    );
    return <Navigate to="/login/client" state={{ from: location }} replace />;
  }

  const hideHeader = HIDE_HEADER_ROUTES.some((route) =>
    location.pathname.startsWith(route),
  );

  return (
    <FeatureFlagProvider>
      <Transition
        show={Boolean(authedPatient)}
        {...defaultTransitionProps}
        className="relative"
      >
        <>
          {!hideHeader && <PatientHeader />}
          <main
            className={classNames(!hideHeader && 'pt-[var(--top-nav-height)]')}
          >
            <Outlet />
          </main>
        </>
      </Transition>

      {!authedPatient && HomecomingLoader}
    </FeatureFlagProvider>
  );
}

export default AuthedPatientContainer;
