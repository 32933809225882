import { XIcon } from '@heroicons/react/outline';
import { FC } from 'react';
import {
  ActivityType,
  CuratedProviderCollection,
  ActivityDataFragment,
  ActivityAssessment,
} from '../../../generated/graphql';
import { getProviderActivityTitle } from '../../lib/providerActivity';
import ActivityThumbnail from '../ActivityThumbnail';
import Modal from '../Modal';
import CopyToLibraryButton from '../CopyToLibraryButton';

function getActivityTypeDescription(activityType: ActivityType): string {
  switch (activityType) {
    case ActivityType.Action:
      return 'Action';
    case ActivityType.Audio:
      return 'Audio';
    case ActivityType.Image:
      return 'Image';
    case ActivityType.Assessment:
      return 'Assessment';
    case ActivityType.Link:
      return 'Link';
    case ActivityType.Pdf:
      return 'File';
    case ActivityType.PdfForm:
      return 'File Form';
    case ActivityType.Text:
      return 'Text';
    default:
      return '';
  }
}

interface CuratedCollectionInfoModalProps {
  isOpen: boolean;
  setClosed: () => void;
  collection: CuratedProviderCollection | null;
  onCollectionAdded?: () => void;
}

const CuratedProviderCollectionInfoModal: FC<
  CuratedCollectionInfoModalProps
> = ({ isOpen, setClosed, collection, onCollectionAdded }) => {
  return (
    <Modal
      width="small"
      isOpen={isOpen}
      setClosed={setClosed}
      hasCloseRow={false}
      hasPadding={false}
    >
      <div
        className="relative bg-cover bg-center"
        style={{ backgroundImage: `url(${collection?.headerMedia.url})` }}
      >
        <div className="absolute z-10 h-full w-full bg-black opacity-60" />
        <div className="relative z-20 px-6 pb-5 pt-4">
          <div className="flex justify-end pb-24">
            <div
              onClick={() => setClosed()}
              className="z-20 cursor-pointer rounded-full bg-white p-2 hover:opacity-70"
            >
              <XIcon className="h-4 w-4 text-neutral-150" />
            </div>
          </div>
          <div className="mb-3 font-serif text-subtitle text-neutral-0">
            {collection?.providerCollection.name}
          </div>
          <div className="flex flex-row">
            <div>
              <img
                className="h-[40px] w-[40px] rounded-full"
                src={collection?.curatorAvatarMedia.url}
                alt={collection?.curatorName}
              />
            </div>
            <div className="ml-3 flex flex-col">
              <div className="text-caption font-bold text-neutral-0">
                {collection?.curatorName}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-h-[400px] overflow-auto px-8 pt-5 pb-16">
        <div className="mb-4 text-body font-bold text-green-150">Overview</div>
        <div className="mb-8 text-caption text-neutral-125">
          {collection?.description}
        </div>
        <div className="mb-4 text-body font-bold text-green-150">Resources</div>
        <div>
          {collection?.providerCollection.activities?.map((activity) => {
            return (
              <div key={activity.id} className="mb-5 flex flex-row">
                <div>
                  <ActivityThumbnail
                    activityType={activity.activityType}
                    className="h-[48px] w-[48px] rounded-lg"
                    assessmentSchemaType={
                      (activity as ActivityAssessment)?.assessment?.schemaType
                    }
                  />
                </div>
                <div className="ml-4 flex flex-col">
                  <div className="text-caption font-medium text-green-150">
                    {getProviderActivityTitle(activity as ActivityDataFragment)}
                  </div>
                  <div className="text-small-caption text-neutral-125">
                    {getActivityTypeDescription(activity.activityType)}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="absolute bottom-0 flex w-full flex-row justify-end bg-white py-4 px-6 shadow-400">
        <div>
          <CopyToLibraryButton
            collection={collection!}
            theme="primary"
            onAdded={onCollectionAdded}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CuratedProviderCollectionInfoModal;
