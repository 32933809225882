import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { colors } from '../../../../lib/colors';

interface CompletedSignatureProps {
  name: string;
  role?: string;
  date: string;
  hideNameAndRole?: boolean;
  hideDate?: boolean;
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 4,
  },
  nameRole: {
    fontSize: 10,
    color: colors.neutral[125],
  },
  signature: {
    fontFamily: 'cursive',
    fontSize: 16,
    color: colors.neutral[150],
  },
  date: {
    fontSize: 10,
    color: colors.neutral[125],
  },
});

const CompletedSignature: React.FC<CompletedSignatureProps> = ({
  name,
  role = 'Client',
  date,
  hideNameAndRole = false,
  hideDate = false,
}) => (
  <View style={styles.container}>
    {!hideNameAndRole && (
      <Text style={styles.nameRole}>
        {name}, {role}
      </Text>
    )}
    <Text style={styles.signature}>{name}</Text>
    {!hideDate && <Text style={styles.date}>{date}</Text>}
  </View>
);

export default CompletedSignature;
