import { QueryResult } from '@apollo/client';
import {
  Exact,
  MeProviderQuery,
  useUpdateProviderMutation,
} from '../../../../generated/graphql';
import { customToast } from '../../../components/ToastAlert/customToast';
import { applyColorToRoot } from '../../../lib/colors';
import {
  MAX_UPLOAD_SIZE_BYTES,
  acceptedImageFileExtensions,
} from '../../../lib/media';
import { LOGO_CONFIGS } from './constants';
import { LogoType } from './types';

export const useAppearanceHandlers = (
  setValue: any,
  refreshAuthedProviderUser: () => Promise<
    QueryResult<MeProviderQuery, Exact<{ [key: string]: never }>>
  >,
) => {
  const [updateProvider] = useUpdateProviderMutation();

  const updateColor = async (
    color: string,
    field: 'primaryColor' | 'secondaryColor',
  ) => {
    try {
      await updateProvider({
        variables: { input: { [field]: color } },
      });

      applyColorToRoot(
        color,
        field === 'primaryColor' ? 'primary' : 'secondary',
      );

      customToast.success(
        `${field === 'primaryColor' ? 'Primary' : 'Secondary'} color updated!`,
      );
      await refreshAuthedProviderUser();
    } catch (err) {
      customToast.error('Failed to update color. Please try again later.');
    }
  };

  const validateLogoFile = (file: File): boolean => {
    if (file.size > MAX_UPLOAD_SIZE_BYTES) {
      customToast.error("File is too big. Try one that's smaller than 100 MB");
      return false;
    }
    if (!acceptedImageFileExtensions.includes(file.type)) {
      customToast.error('Please upload a JPG or PNG file');
      return false;
    }
    return true;
  };

  const handleLogoUpload = async (
    files: FileList | null,
    logoType: LogoType,
  ) => {
    if (!files?.[0]) return;
    const file = files[0];

    if (!validateLogoFile(file)) return;

    const reader = new FileReader();
    reader.onload = async (event) => {
      const result = event?.target?.result;
      if (typeof result !== 'string') return;

      setValue(logoType, result);

      try {
        await updateProvider({
          variables: {
            input: { [LOGO_CONFIGS[logoType].graphqlField]: file },
          },
        });
        await refreshAuthedProviderUser();
        customToast.success(LOGO_CONFIGS[logoType].successMessage);
      } catch (err) {
        customToast.error('Failed to upload image');
      }
    };
    reader.readAsDataURL(file);
  };

  const handleLogoDelete = async (logoType: LogoType) => {
    setValue(logoType, '');

    try {
      await updateProvider({
        variables: {
          input: {
            [LOGO_CONFIGS[logoType].deleteField]: true,
          },
        },
      });
      await refreshAuthedProviderUser();
      customToast.success(LOGO_CONFIGS[logoType].deleteMessage);
    } catch (err) {
      customToast.error('Failed to delete image');
    }
  };

  return {
    updateColor,
    handleLogoUpload,
    handleLogoDelete,
  };
};
