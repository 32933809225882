import classNames from 'classnames';
import { FC } from 'react';

import Score, { ScoreProps } from './Score';
import { ASSESSMENTS_COLOR_MAP } from '../../../../../lib/colors';

const LatestAssessmentCard: FC<{
  onClick: () => void;
  shortName: string;
  color?: string;
  increasingScoreTowardsHealth?: boolean;
  completedDate: string;
  primaryScore: ScoreProps;
  secondaryScore?: ScoreProps;
  hasPercentage?: boolean;
}> = ({
  onClick,
  shortName,
  color = 'neutral',
  increasingScoreTowardsHealth = false,
  completedDate,
  primaryScore,
  secondaryScore,
  hasPercentage = false,
}) => {
  const outerColor = ASSESSMENTS_COLOR_MAP?.[color]?.outer;
  const middleColor = ASSESSMENTS_COLOR_MAP?.[color]?.middle;
  const defaultColor = ASSESSMENTS_COLOR_MAP?.[color]?.default;

  return (
    <button
      onClick={onClick}
      className={classNames(
        'relative flex flex-col items-center justify-start',
        'overflow-hidden rounded-lg bg-white p-4 shadow-400',
        'hover:bg-neutral-25 focus:rounded-lg focus:outline-none focus:ring-2 focus:ring-secondary-50',
        'h-[178px] w-[284px]',
      )}
    >
      <div
        className={classNames(
          'absolute bottom-0 right-0 flex h-[100px] w-[100px] flex-col items-end justify-end rounded-tl-full',
          outerColor,
        )}
      >
        <div
          className={classNames(
            'flex h-3/4 w-3/4 flex-col items-end justify-end rounded-tl-full',
            middleColor,
          )}
        >
          <div
            className={classNames(
              'flex h-2/3 w-2/3 flex-col items-end justify-end rounded-tl-full',
              defaultColor,
            )}
          />
        </div>
      </div>
      <div className="mb-4 flex w-full flex-row items-center justify-between">
        <div className="flex items-center rounded-full bg-neutral-50 py-1 px-2">
          <span className="text-small-caption font-bold text-green-125">
            {shortName}
          </span>
        </div>
        <span className="text-small-action font-bold">{completedDate}</span>
      </div>
      <div className="flex w-full flex-row items-center justify-start pl-1">
        <Score
          score={primaryScore.score}
          scoreDifference={primaryScore.scoreDifference}
          categorization={primaryScore.categorization}
          hasPercentage={hasPercentage}
          increasingScoreTowardsHealth={increasingScoreTowardsHealth}
        />
        {secondaryScore && (
          <Score
            score={secondaryScore.score}
            scoreDifference={secondaryScore.scoreDifference}
            categorization={secondaryScore.categorization}
            hasPercentage={hasPercentage}
            increasingScoreTowardsHealth={increasingScoreTowardsHealth}
          />
        )}
      </div>
    </button>
  );
};

export default LatestAssessmentCard;
