import React, { useState } from 'react';
import classNames from 'classnames';
import {
  ClipboardIcon,
  DocumentIcon,
  PencilAltIcon,
  XIcon,
} from '@heroicons/react/outline';
import Button from '../../../components/Button';
import NoteCard from '../../../components/NoteCard';
import {
  FollowUpTemplateDataFragment,
  ProgramNoteDataFragment,
} from '../../../../generated/graphql';
import FollowUpTemplateCard from './FollowUpTemplateCard';
import { SvgIconComponent } from '../../../types/svgs';
import { Transition } from '@headlessui/react';
import Tooltip from '../../../components/Tooltip';
import FollowUpDraftCard from './FollowUpDraftCard';
import {
  AnalyticsComponent,
  AnalyticsPage,
  CommonAnalyticsEvent,
  trackProviderEvent,
} from '../../../../lib/analytics';

enum DrawerMode {
  Notes = 'Notes',
  Templates = 'Templates',
  Drafts = 'Drafts',
}

const DrawerToggleButton: React.FC<{
  title: string;
  IconComponent: SvgIconComponent;
  onClick: () => void;
  disabled?: boolean;
  isSelected?: boolean;
}> = ({ title, IconComponent, onClick, disabled, isSelected }) => {
  return (
    <Button
      size="small"
      type="button"
      theme="alt"
      title={title}
      IconComponent={IconComponent}
      iconPosition="center"
      iconClassName="w-6 h-6"
      className={classNames(
        'h-16 w-16 rounded-sm px-2 text-small-label text-neutral-125 disabled:text-neutral-100',
        isSelected && 'bg-green-25 !text-green-100',
      )}
      onClick={() => {
        trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
          originPage: AnalyticsPage.FollowUpBuilder,
          originComponent: AnalyticsComponent.FollowUpDrawer,
          buttonName: title,
        });
        onClick();
      }}
      disabled={disabled}
    />
  );
};

const FollowUpDrawer: React.FC<{
  programNotes: ProgramNoteDataFragment[];
  onlyPatient: boolean;
  followUpTemplates: FollowUpTemplateDataFragment[];
  followUpDrafts: FollowUpTemplateDataFragment[];
  onApplyFollowUpTemplate: (
    followUpTemplate: FollowUpTemplateDataFragment,
  ) => Promise<void>;
  onRenameFollowUpTemplate: (
    followUpTemplate: FollowUpTemplateDataFragment,
  ) => Promise<void>;
  onDuplicateFollowUpTemplate: (
    followUpTemplate: FollowUpTemplateDataFragment,
  ) => Promise<void>;
  onDeleteFollowUpTemplate: (
    followUpTemplate: FollowUpTemplateDataFragment,
  ) => Promise<void>;
}> = ({
  programNotes,
  onlyPatient,
  followUpTemplates,
  followUpDrafts,
  onApplyFollowUpTemplate,
  onRenameFollowUpTemplate,
  onDuplicateFollowUpTemplate,
  onDeleteFollowUpTemplate,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [drawerMode, setDrawerMode] = useState<DrawerMode>(DrawerMode.Notes);

  const hasNotes = programNotes.length > 0;
  const hasTemplates = followUpTemplates.length > 0;
  const hasDrafts = followUpDrafts.length > 0;

  const handleToggleClick = (mode: DrawerMode) => {
    if (isOpen && drawerMode === mode) {
      setIsOpen(false);
    } else {
      setDrawerMode(mode);
      setIsOpen(true);
    }
  };

  return (
    <div className="relative flex h-full bg-white shadow-400">
      <div className="z-30 flex w-[6rem] flex-col items-center gap-5 px-3 pt-16">
        <Tooltip
          enabled={!hasNotes}
          placement="right-start"
          content={
            onlyPatient
              ? 'This client has no private notes yet. Once you create some, they will appear here for easy reference.'
              : 'Select a single recipient to view their notes.'
          }
        >
          <DrawerToggleButton
            title="Notes"
            IconComponent={PencilAltIcon}
            onClick={() => handleToggleClick(DrawerMode.Notes)}
            disabled={!hasNotes}
            isSelected={isOpen && drawerMode === DrawerMode.Notes}
          />
        </Tooltip>

        <Tooltip
          enabled={!hasTemplates}
          placement="right-start"
          content="You don't have any mail templates yet. Use the 'Save' button to create your first template."
        >
          <DrawerToggleButton
            title="Templates"
            IconComponent={ClipboardIcon}
            onClick={() => handleToggleClick(DrawerMode.Templates)}
            disabled={!hasTemplates}
            isSelected={isOpen && drawerMode === DrawerMode.Templates}
          />
        </Tooltip>

        <Tooltip
          enabled={!hasDrafts}
          placement="right-start"
          content="You don't have any drafts yet. Drafts will automatically be saved as you start creating or editing."
        >
          <DrawerToggleButton
            title="Drafts"
            IconComponent={DocumentIcon}
            onClick={() => handleToggleClick(DrawerMode.Drafts)}
            disabled={!hasDrafts}
            isSelected={isOpen && drawerMode === DrawerMode.Drafts}
          />
        </Tooltip>
      </div>

      <div
        className={classNames(
          'h-full min-w-0 max-w-[480px] transition-all duration-500 ease-in-out',
          isOpen ? 'w-[480px]' : 'w-0',
        )}
      >
        <Transition
          show={isOpen}
          enter="transition-opacity duration-300 ease-in-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300 ease-in-out"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className={classNames(
            'h-full w-full flex-col items-center justify-between overflow-y-auto overflow-x-visible',
          )}
        >
          <div className="flex h-16 w-full items-center justify-end pr-5">
            <Button
              onClick={() => setIsOpen(false)}
              title="CLOSE"
              iconPosition="left"
              IconComponent={XIcon}
              iconClassName="mb-0.5"
              theme="alt"
              size="smaller"
              noBackground
            />
          </div>

          {drawerMode === DrawerMode.Notes && (
            <div className="flex flex-col gap-y-5 pr-5 pl-3 pb-10">
              {programNotes.map((programNote) => (
                <NoteCard
                  key={programNote.id}
                  programNote={programNote}
                  className={classNames(
                    programNote.isPinnedNote && 'border border-secondary-100',
                  )}
                />
              ))}
            </div>
          )}
          {drawerMode === DrawerMode.Templates && (
            <div className="flex flex-col gap-y-5 pr-5 pl-3 pb-10">
              {followUpTemplates.map((followUpTemplate) => (
                <FollowUpTemplateCard
                  key={followUpTemplate.id}
                  followUpTemplate={followUpTemplate}
                  onApplyFollowUpTemplate={onApplyFollowUpTemplate}
                  onRenameFollowUpTemplate={onRenameFollowUpTemplate}
                  onDuplicateFollowUpTemplate={onDuplicateFollowUpTemplate}
                  onDeleteFollowUpTemplate={onDeleteFollowUpTemplate}
                />
              ))}
            </div>
          )}

          {drawerMode === DrawerMode.Drafts && (
            <div className="flex flex-col gap-y-5 pr-5 pl-3 pb-10">
              {followUpDrafts.map((followUpDraft) => (
                <FollowUpDraftCard
                  key={followUpDraft.id}
                  followUpDraft={followUpDraft}
                  onApplyFollowUpTemplate={onApplyFollowUpTemplate}
                  onDeleteFollowUpDraft={onDeleteFollowUpTemplate}
                />
              ))}
            </div>
          )}
        </Transition>
      </div>
    </div>
  );
};

export default FollowUpDrawer;
