import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import {
  PlusSmIcon,
  BriefcaseIcon,
  IdentificationIcon,
} from '@heroicons/react/outline';

import {
  useForm,
  FormProvider,
  SubmitHandler,
  SubmitErrorHandler,
} from 'react-hook-form';

import ToastAlert from '../../components/ToastAlert';
import PageContainer from '../../components/Containers/PageContainer';
import {
  useProviderStorefrontLazyQuery,
  EssentialAssessmentDataFragment,
  useUpdateProviderStorefrontMutation,
} from '../../../generated/graphql';

import { isProviderUserOwnerOrAdminAuthorized } from '../../../lib/auth';

import Button from '../../components/Button';
import ServicesModule from '../../components/ServiceModule';
import CollapseableContainer from '../../components/CollapseableContainer';
import { StagedProgramTagDataFragment } from '../../components/ProgramTag/ProgramTagManager';

import TopBanner from './TopBanner';
import InformationModule from './InformationModule';
import { useAuth } from '../../../contexts/AuthContext';

export interface StorefrontFormData {
  isEnabled: boolean;
  roleTitle: string;
  ctaFormLabel: string;
  ctaButtonText: string;
  tagline: string;
  about: string;
  services: string;
  intakeTags: StagedProgramTagDataFragment[];
  intakeFormAssessment: EssentialAssessmentDataFragment;
  calendlyEventTypeUri: string;
}

export const ROLE_TITLE_MAX_LENGTH = 50;
export const CTA_FORM_LABEL_MAX_LENGTH = 20;
export const CTA_BUTTON_TEXT_MAX_LENGTH = 20;
export const TAGLINE_MAX_LENGTH = 100;
export const ABOUT_MAX_LENGTH = 2000;

export const DEFAULT_CTA_FORM_LABEL = 'Work with me';
export const DEFAULT_CTA_BUTTON_TEXT = 'Continue';

const MyPage = () => {
  const navigate = useNavigate();
  const { authedProviderUser } = useAuth();

  useEffect(() => {
    if (!isProviderUserOwnerOrAdminAuthorized(authedProviderUser)) {
      navigate('/', { replace: true });
    }
  }, [authedProviderUser]);

  const [
    getProviderStorefront,
    {
      data: storefrontData,
      error: storefrontError,
      refetch: refetchStorefront,
    },
  ] = useProviderStorefrontLazyQuery();

  useEffect(() => {
    if (storefrontError) {
      navigate('/home', { replace: true });
    }
  }, [storefrontError]);

  const storefront = storefrontData?.providerStorefront;

  const [updateProviderStorefront] = useUpdateProviderStorefrontMutation();

  const storefrontForm = useForm<StorefrontFormData>({
    mode: 'onSubmit',
    defaultValues: async () => {
      const response = await getProviderStorefront();
      const storefront = response.data?.providerStorefront;
      return {
        isEnabled: storefront?.isEnabled ?? false,
        roleTitle: storefront?.roleTitle ?? '',
        ctaFormLabel: storefront?.ctaFormLabel ?? 'Work with me',
        ctaButtonText: storefront?.ctaButtonText ?? 'Get in touch',
        tagline: storefront?.tagline ?? '',
        about: storefront?.about ?? '',
        services: storefront?.services ?? '',
        intakeTags: storefront?.intakeTags ?? [],
        intakeFormAssessment: storefront?.intakeFormAssessment ?? null,
        calendlyEventTypeUri: storefront?.calendlyEventTypeUri ?? null,
      };
    },
  });

  const onSubmit: SubmitHandler<StorefrontFormData> = async (
    formData: StorefrontFormData,
  ) => {
    try {
      const response = await updateProviderStorefront({
        variables: {
          input: {
            roleTitle: formData.roleTitle.trim(),
            ctaFormLabel:
              formData.ctaFormLabel.trim() || DEFAULT_CTA_FORM_LABEL,
            ctaButtonText:
              formData.ctaButtonText.trim() || DEFAULT_CTA_BUTTON_TEXT,
            tagline: formData.tagline,
            about: formData.about,
            services: formData.services,
          },
        },
      });

      if (response.data?.updateProviderStorefront) {
        const updatedStorefront = response.data.updateProviderStorefront;
        // Resetting vs. setting the form fields to the updated values means that
        // later "resets" when the user discards changes will revert to the updated values,
        // not the originally loaded ones.
        storefrontForm.resetField('roleTitle', {
          defaultValue: updatedStorefront.roleTitle,
        });
        storefrontForm.resetField('tagline', {
          defaultValue: updatedStorefront.tagline,
        });
        storefrontForm.resetField('about', {
          defaultValue: updatedStorefront.about,
        });
        storefrontForm.resetField('services', {
          defaultValue: updatedStorefront.services,
        });
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={'Webpage information updated.'}
          />
        ));
      }
    } catch (error) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Failed to update webpage information."
          level="error"
        />
      ));
    }
  };

  const onErrors: SubmitErrorHandler<StorefrontFormData> = (errors) => {
    console.error(errors);
  };

  const updateStorefrontEnabled = async (isEnabled: boolean) => {
    try {
      const { data } = await updateProviderStorefront({
        variables: {
          input: {
            isEnabled,
          },
        },
      });
      if (data) {
        storefrontForm.setValue(
          'isEnabled',
          data.updateProviderStorefront.isEnabled,
        );
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={
              isEnabled
                ? 'Your webpage is now live.'
                : 'Your webpage is now offline.'
            }
          />
        ));
      }
    } catch (error) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Failed to update webpage."
          level="error"
        />
      ));
    }
  };

  return (
    <PageContainer containerClassName="pt-0 pb-12" noPadding>
      {storefront && (
        <FormProvider {...storefrontForm}>
          <TopBanner
            storefrontSlug={storefront.slug}
            updateStorefrontEnabled={updateStorefrontEnabled}
          />
          <div className="relative -mt-8 space-y-6 px-32">
            <CollapseableContainer
              title="About"
              Icon={IdentificationIcon}
              className="rounded-lg bg-white shadow-sm"
            >
              <InformationModule
                onSubmit={onSubmit}
                onErrors={onErrors}
                profileImageUrl={storefront.profileImageUrl}
                bannerImageUrl={storefront.bannerImageUrl}
                bannerPosition={storefront.bannerPosition}
                onMediaChange={async () => {
                  await refetchStorefront();
                }}
              />
            </CollapseableContainer>
            <CollapseableContainer
              title="Services"
              Icon={BriefcaseIcon}
              className="rounded-lg bg-white shadow-sm"
              ButtonComponent={
                <Button
                  title="Add a service"
                  theme="secondary"
                  size="small"
                  iconPosition="left"
                  className="mr-4"
                  IconComponent={PlusSmIcon}
                  onClick={() => navigate('/services/new')}
                />
              }
            >
              <ServicesModule className="pt-2" />
            </CollapseableContainer>
          </div>
        </FormProvider>
      )}
    </PageContainer>
  );
};

export default MyPage;
