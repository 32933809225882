import classNames from 'classnames';
import { FC } from 'react';

const PractitionerInfoContainer: FC<{
  children?: React.ReactNode;
}> = ({ children }) => (
  <div
    className={classNames(
      'relative flex flex-col items-center',
      'space-y-6 pb-6 pt-[3rem]',
      'md:flex-row md:items-start md:space-y-0 md:pl-6 md:pt-[4.5rem]',
    )}
  >
    {children}
  </div>
);

export default PractitionerInfoContainer;
