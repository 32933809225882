import { isStringTrue } from '../v2/lib/string';

// All custom environment variables must start with 'REACT_APP_'
// https://create-react-app.dev/docs/adding-custom-environment-variables/
interface HomecomingConfig {
  REACT_APP_VERSION: string;
  REACT_APP_ENVIRONMENT: string;
  REACT_APP_LOG_LEVEL: string;
  IS_DEV: boolean;
  REACT_APP_HOMECOMING_API_BASE_URI: string;
  REACT_APP_WEB_APP_URL: string;
  REACT_APP_MIXPANEL_ENABLED: boolean;
  REACT_APP_MIXPANEL_LOG_ANALYTICS: boolean;
  REACT_APP_MIXPANEL_PROVIDER_PROJECT_TOKEN: string;
  REACT_APP_MIXPANEL_CLIENT_PROJECT_TOKEN: string;
  REACT_APP_CALENDLY_CLIENT_ID: string;
  REACT_APP_STRIPE_CLIENT_ID: string;
  USERBACK_TOKEN: string;
  COMMANDBAR_TOKEN: string;
  GTM_ID: string;
  GTM_PREVIEW_ENV: string;
  GTM_PREVIEW_AUTH: string;
  REACT_APP_INTERCOM_APP_ID: string;
  REACT_APP_AUTH0_DOMAIN: string;
  REACT_APP_AUTH0_CLIENT_ID: string;
  REACT_APP_AUTH0_API_AUDIENCE: string;
  REACT_APP_AUTH0_REDIRECT_URI: string;
  REACT_APP_CIRCLE_BASE_URL: string;
  REACT_APP_SENTRY_DSN: string;
  REACT_APP_SENTRY_SAMPLE_RATE?: string;
}

const Config: HomecomingConfig = {
  REACT_APP_VERSION: process.env.REACT_APP_VERSION ?? '',
  REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT ?? '',
  REACT_APP_LOG_LEVEL: process.env.REACT_APP_LOG_LEVEL ?? 'warn',
  IS_DEV: process.env.REACT_APP_ENVIRONMENT !== 'production',
  REACT_APP_HOMECOMING_API_BASE_URI:
    process.env.REACT_APP_HOMECOMING_API_BASE_URI ?? '',
  REACT_APP_WEB_APP_URL: process.env.REACT_APP_WEB_APP_URL ?? '',
  REACT_APP_MIXPANEL_ENABLED: isStringTrue(
    process.env.REACT_APP_MIXPANEL_ENABLED ?? '',
  ),
  REACT_APP_MIXPANEL_LOG_ANALYTICS: isStringTrue(
    process.env.REACT_APP_MIXPANEL_LOG_ANALYTICS ?? '',
  ),
  REACT_APP_MIXPANEL_PROVIDER_PROJECT_TOKEN:
    process.env.REACT_APP_MIXPANEL_PROVIDER_PROJECT_TOKEN ?? '',
  REACT_APP_MIXPANEL_CLIENT_PROJECT_TOKEN:
    process.env.REACT_APP_MIXPANEL_CLIENT_PROJECT_TOKEN ?? '',
  REACT_APP_CALENDLY_CLIENT_ID: process.env.REACT_APP_CALENDLY_CLIENT_ID ?? '',
  REACT_APP_STRIPE_CLIENT_ID: process.env.REACT_APP_STRIPE_CLIENT_ID ?? '',
  USERBACK_TOKEN: '38160|75181|9gvLrudWc65oyLXeCiWraN6fi',
  COMMANDBAR_TOKEN: '651397da',
  GTM_ID: 'GTM-PVT559R',
  GTM_PREVIEW_ENV: 'env-16',
  GTM_PREVIEW_AUTH: 'XrG7bv9U3YmCC49i5EWOHg',
  REACT_APP_INTERCOM_APP_ID: process.env.REACT_APP_INTERCOM_APP_ID ?? '',
  REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
  REACT_APP_AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
  REACT_APP_AUTH0_API_AUDIENCE: process.env.REACT_APP_AUTH0_API_AUDIENCE ?? '',
  REACT_APP_AUTH0_REDIRECT_URI: process.env.REACT_APP_AUTH0_REDIRECT_URI ?? '',
  REACT_APP_CIRCLE_BASE_URL: process.env.REACT_APP_CIRCLE_BASE_URL ?? '',
  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN ?? '',
  REACT_APP_SENTRY_SAMPLE_RATE:
    process.env.REACT_APP_SENTRY_SAMPLE_RATE ?? '0.1',
};

export default Config;
