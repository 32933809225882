// components/LogoUploadField.tsx
import { FC, useState } from 'react';
import classNames from 'classnames';
import { XIcon } from '@heroicons/react/outline';

import IconButton from '../../../../components/IconButton';
import UploadField from '../../../../components/UploadField';
import { acceptedImageFileExtensions } from '../../../../lib/media';

import { LogoUploadProps } from '../types';

export const LogoUploadField: FC<LogoUploadProps> = ({
  logoType,
  logoPreview,
  onUpload,
  onDelete,
  altText,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    if (files?.length) {
      onUpload(files, logoType);
    }
  };

  const getDisplayFilename = (url: string): string => {
    const filename = url.split('/').pop()?.split('?')[0] || '';
    const extension = filename.match(/\.[^.]+$/)?.[0] || '';
    const nameWithoutExtension = filename.replace(extension, '');
    // Split by last underscore and take the first part
    const displayName = nameWithoutExtension.split(/_(?=[^_]+$)/)[0];
    return displayName + extension;
  };

  return (
    <div
      className={classNames(
        'mb-8 flex h-[68px] w-[398px] items-center justify-center rounded-md border-[1.5px] border-dashed bg-neutral-50 text-caption text-neutral-125 transition-colors',
        !isDragging && 'border-secondary-75',
        isDragging && 'border-secondary-125',
      )}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {!logoPreview ? (
        <UploadField
          acceptedFileExtensions={acceptedImageFileExtensions.join(', ')}
          inputId={logoType}
          title="Upload a JPG or PNG file"
          onChange={(e) => onUpload(e.target.files, logoType)}
        />
      ) : (
        <div className="flex w-full flex-row items-start justify-between p-4">
          <div className="flex w-11/12 flex-row items-center justify-start">
            {logoPreview && (
              <img
                src={logoPreview}
                className="mr-4 h-[42px] w-[42px] flex-none rounded-md object-cover"
                alt={altText}
              />
            )}
            <div className="flex flex-col items-start justify-start">
              <div className="w-full text-caption font-medium text-neutral-150 line-clamp-1">
                {logoPreview ? getDisplayFilename(logoPreview) : logoPreview}
              </div>
              <div className="text-small-caption text-neutral-125">Image</div>
            </div>
          </div>
          <IconButton
            IconComponent={XIcon}
            className="h-5 w-5 text-primary-150"
            onClick={() => onDelete(logoType)}
            aria-label={`Delete ${altText}`}
          />
        </div>
      )}
    </div>
  );
};
