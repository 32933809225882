import { useLocation, useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { ArrowNarrowLeftIcon } from '@heroicons/react/outline';
import IconButton from '../IconButton';

const IntegrationsContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const fromPage = (location.state as { fromPage?: string })?.fromPage;
  const fromPageLabel = (location.state as { fromPageLabel?: string })
    ?.fromPageLabel;

  const handleBackNavigation = () => {
    if (fromPage) {
      navigate(fromPage);
    } else {
      navigate('/settings/integrations');
    }
  };

  return (
    <>
      <div className="z-10 flex w-full flex-row items-center justify-between border-b border-neutral-50 bg-white py-2 px-20">
        <div className="w-[350px]">
          <IconButton
            IconComponent={ArrowNarrowLeftIcon}
            iconClassName="text-secondary-100 w-5"
            aria-label="See all integrations"
            className="rounded-xl px-3 py-2 ring-0"
            onClick={handleBackNavigation}
          >
            <div className="small-caption ml-2 font-bold text-secondary-100">
              {fromPageLabel || 'See all integrations'}
            </div>
          </IconButton>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default IntegrationsContainer;
