import { FC } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import classNames from 'classnames';

import {
  TrashIcon,
  PlusSmIcon,
  SparklesIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@heroicons/react/outline';

import {
  SignatureUser,
  AssessmentSignatureUserInput,
} from '../../../../generated/graphql';

import {
  Question,
  QuestionForm,
} from '../../../pages/FormBuilder/QuestionContainer/helpers';
import { SignatureUserTypeLabel } from '../../../lib/multiSignature';

import Button from '../../Button';
import ModalDialog from '../../ModalDialog';
import IconButton from '../../IconButton';
import SignatureUserDisplay from '../../SignatureUserDisplay';
import { customToast } from '../../ToastAlert/customToast';

enum Direction {
  Up = 'up',
  Down = 'down',
}

const SignatureSettingsModal: FC<{
  setClosed: () => void;
  isOpen: boolean;
  watchAssessmentSignatureUsers: AssessmentSignatureUserInput[];
  watchQuestions: Question[];
  setValue: UseFormSetValue<QuestionForm>;
  isTeamAccount: boolean;
}> = ({
  isOpen,
  setClosed,
  watchAssessmentSignatureUsers,
  watchQuestions,
  setValue,
  isTeamAccount,
}) => {
  // Enforce rules: max 3, only 1 client, 1 sending
  const hasSendingPractitioner = watchAssessmentSignatureUsers.some(
    (user) => user.signatureUserType === SignatureUser.SendingProviderUser,
  );

  const addSignatureUser = (signatureUserType: SignatureUser): void => {
    if (
      (signatureUserType === SignatureUser.SendingProviderUser &&
        hasSendingPractitioner) ||
      watchAssessmentSignatureUsers.length >= 3
    ) {
      return;
    }

    const newAssessmentSignatureUser: AssessmentSignatureUserInput = {
      signatureUserType,
      orderIndex: watchAssessmentSignatureUsers.length,
    };

    const updatedAssessmentSignatureUsers = [
      ...watchAssessmentSignatureUsers,
      newAssessmentSignatureUser,
    ];

    const updatedQuestions = watchQuestions.map((question) => {
      const existingPatientUserIndex = watchAssessmentSignatureUsers.find(
        (user) => user.signatureUserType === SignatureUser.PatientUser,
      )?.orderIndex;

      const newOrderIndices = [
        ...question.assessmentUserOrderIndices,
        newAssessmentSignatureUser.orderIndex,
      ];

      if (
        existingPatientUserIndex !== undefined &&
        !question.assessmentUserOrderIndices.includes(existingPatientUserIndex)
      ) {
        newOrderIndices.push(existingPatientUserIndex);
      }

      return {
        ...question,
        assessmentUserOrderIndices: newOrderIndices.sort((a, b) => a - b),
      };
    });

    setValue('assessmentSignatureUsers', updatedAssessmentSignatureUsers);
    setValue('questions', updatedQuestions);
  };

  const removeSignatureUser = (orderIndexToRemove: number) => {
    const userToRemove = watchAssessmentSignatureUsers.find(
      (user) => user.orderIndex === orderIndexToRemove,
    );

    if (userToRemove?.signatureUserType === SignatureUser.PatientUser) {
      customToast.warning('Client is required to sign the form.');
      return;
    }

    const updatedAssessmentSignatureUsers = watchAssessmentSignatureUsers
      .filter((user) => user.orderIndex !== orderIndexToRemove)
      .map((user, index) => ({ ...user, orderIndex: index }));

    // Update questions to remove the index and adjust higher indices
    const updatedQuestions = watchQuestions.map((question) => {
      const updatedSigners = question.assessmentUserOrderIndices
        .filter(
          (assessmentUserOrderIndex) =>
            assessmentUserOrderIndex !== orderIndexToRemove,
        )
        .map((assessmentUserOrderIndex) =>
          assessmentUserOrderIndex > orderIndexToRemove
            ? assessmentUserOrderIndex - 1
            : assessmentUserOrderIndex,
        );
      return { ...question, assessmentUserOrderIndices: updatedSigners };
    });

    setValue('assessmentSignatureUsers', updatedAssessmentSignatureUsers);
    setValue('questions', updatedQuestions);
  };

  const moveSignatureUser = (orderIndex: number, direction: Direction) => {
    const newIndex =
      direction === Direction.Up ? orderIndex - 1 : orderIndex + 1;
    if (newIndex < 0 || newIndex >= watchAssessmentSignatureUsers.length)
      return;

    const updatedUsers = watchAssessmentSignatureUsers.map((user, index) => {
      if (index === orderIndex)
        return { ...watchAssessmentSignatureUsers[newIndex], orderIndex };
      if (index === newIndex)
        return {
          ...watchAssessmentSignatureUsers[orderIndex],
          orderIndex: newIndex,
        };
      return user;
    });

    const updatedQuestions = watchQuestions.map((question) => ({
      ...question,
      assessmentUserOrderIndices: question.assessmentUserOrderIndices.map(
        (index) => {
          if (index === orderIndex) return newIndex;
          if (index === newIndex) return orderIndex;
          return index;
        },
      ),
    }));

    setValue('assessmentSignatureUsers', updatedUsers);
    setValue('questions', updatedQuestions);
  };

  const handleSave = () => {
    setClosed();
  };

  const maxUsersReached = watchAssessmentSignatureUsers.length >= 3;

  return (
    <ModalDialog
      title="Form signers"
      width="small"
      isOpen={isOpen}
      setClosed={handleSave}
      bodyClassName={classNames(
        'flex flex-col justify-between',
        isTeamAccount && 'min-h-[436px]',
      )}
      footerClassName="border-none"
      leftFooter={
        <div className="flex w-full flex-row items-center text-small-caption text-neutral-125">
          <SparklesIcon className="mr-2 h-5 w-5" />
          <div className="w-5/6">
            The signing order is consistent throughout the form. Each signer is
            notified when it's their turn.
            {isTeamAccount &&
              ` Maximum of 3
            signatures per form.`}
          </div>
        </div>
      }
      primaryActionTitle="Save"
      primaryActionOnClick={handleSave}
      primaryActionProps={{
        size: 'small',
      }}
    >
      <div className="flex flex-col">
        <div className="mb-4 text-caption">
          {isTeamAccount
            ? 'Add form signers and change the signing order with the arrows'
            : 'Change the signing order with the arrows'}
        </div>
        <div>
          {watchAssessmentSignatureUsers?.map(
            (assessmentSignatureUser, index) => (
              <div
                key={assessmentSignatureUser.orderIndex}
                className="flex w-full flex-row items-center justify-between"
              >
                <div className="mb-3 flex h-[88px] w-[498px] items-center justify-between rounded-xl border border-neutral-50 border-l-transparent pr-4">
                  <SignatureUserDisplay
                    assessmentSignatureUser={assessmentSignatureUser}
                  />
                  {isTeamAccount &&
                    assessmentSignatureUser.signatureUserType !==
                      SignatureUser.PatientUser && (
                      <IconButton
                        IconComponent={TrashIcon}
                        aria-label="Remove form signer"
                        iconClassName="text-gray-500 h-5 w-5"
                        onClick={() =>
                          removeSignatureUser(
                            assessmentSignatureUser.orderIndex,
                          )
                        }
                      />
                    )}
                </div>
                <div className="flex flex-col pb-2">
                  {index > 0 && (
                    <IconButton
                      IconComponent={ChevronUpIcon}
                      aria-label="Move form signer up"
                      iconClassName="text-gray-500 h-5 w-5"
                      className="mx-2"
                      onClick={() =>
                        moveSignatureUser(
                          assessmentSignatureUser.orderIndex,
                          Direction.Up,
                        )
                      }
                    />
                  )}
                  {index < watchAssessmentSignatureUsers.length - 1 && (
                    <IconButton
                      IconComponent={ChevronDownIcon}
                      aria-label="Move form signer down"
                      iconClassName="text-gray-500 h-5 w-5"
                      className="mx-2"
                      onClick={() =>
                        moveSignatureUser(
                          assessmentSignatureUser.orderIndex,
                          Direction.Down,
                        )
                      }
                    />
                  )}
                </div>
              </div>
            ),
          )}
        </div>
      </div>
      {isTeamAccount && (
        <div className="flex w-full flex-row items-center justify-start">
          {[
            SignatureUser.SendingProviderUser,
            SignatureUser.AssignedProviderUser,
          ].map((signatureUserType) => {
            const isDisabled =
              (signatureUserType === SignatureUser.SendingProviderUser &&
                hasSendingPractitioner) ||
              maxUsersReached;

            return (
              <Button
                key={signatureUserType}
                size="small"
                className="mr-3"
                iconPosition="right"
                theme="secondary"
                IconComponent={PlusSmIcon}
                iconClassName={
                  !isDisabled ? 'text-secondary-100' : 'text-secondary-20'
                }
                title={SignatureUserTypeLabel[signatureUserType]}
                onClick={() =>
                  addSignatureUser(SignatureUser[signatureUserType])
                }
                disabled={isDisabled}
              />
            );
          })}
        </div>
      )}
    </ModalDialog>
  );
};

export default SignatureSettingsModal;
