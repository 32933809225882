import ApiClient from './apiClient';

export const generateFilename = (): string => {
  const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
  return `clients-export_${timestamp}.zip`;
};

export const downloadFile = (blob: Blob, filename: string): void => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const exportClients = async (): Promise<Blob> => {
  return ApiClient.post<Blob>('/patients-data-export', {
    responseType: 'blob',
  });
};
