import { FC } from 'react';
import classNames from 'classnames';
import useIsMobileDevice from '../../hooks/useIsMobileDevice';

const DEFAULT_ROLE_TITLE = 'Your professional designation or area of expertise';

const PractitionerInfoLeftColumn: FC<{
  providerName: string;
  providerStorefrontSlug?: string;
  roleTitle?: string;
  ctaFormLabel?: string;
  ctaButtonText?: string;
  inEditMode?: boolean;
  editModeTitleSpecializationChildren?: React.ReactNode;
  editModeCTAFormLabelChildren?: React.ReactNode;
  editModeCTAButtonTextChildren?: React.ReactNode;
  signUpEnabled?: boolean;
  isPublicPage?: boolean;
}> = ({
  providerName,
  providerStorefrontSlug,
  roleTitle,
  ctaFormLabel,
  ctaButtonText,
  inEditMode,
  editModeTitleSpecializationChildren,
  editModeCTAFormLabelChildren,
  editModeCTAButtonTextChildren,
  signUpEnabled = true,
  isPublicPage = false,
}) => {
  const isMobileDevice = useIsMobileDevice();
  return (
    <div
      className={classNames(
        'flex w-[18rem] flex-col items-center lg:w-[21rem]',
        !inEditMode &&
          'min-w-[90%] transition-all md:min-w-[16rem] lg:min-w-[21rem]',
      )}
    >
      <div className="group relative">
        <div
          className={classNames(
            'text-center font-serif',
            isPublicPage && isMobileDevice
              ? 'text-subtitle-small'
              : 'text-subtitle',
            !inEditMode
              ? 'text-neutral-125'
              : 'cursor-default text-neutral-100',
          )}
        >
          {providerName}
        </div>
        {inEditMode && (
          <div
            className={classNames(
              'absolute inset-0 z-[1001] flex w-[20rem] cursor-default flex-col items-center justify-center',
              'left-1/2 -translate-x-1/2 rounded-lg bg-white px-2 py-1',
              'text-center text-body text-neutral-125',
              'opacity-0 transition-opacity group-hover:opacity-100',
            )}
          >
            You can edit your organization's name in{' '}
            <a
              href="/settings"
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer font-medium text-secondary-100"
            >
              account settings
            </a>
          </div>
        )}
      </div>
      <div
        className={classNames(
          'items-center',
          inEditMode ? 'w-[90%] md:mb-1' : 'md:mb-11',
        )}
      >
        {!inEditMode ? (
          <div className="mt-1 text-center text-caption text-neutral-125 md:mt-2">
            {roleTitle || DEFAULT_ROLE_TITLE}
          </div>
        ) : (
          editModeTitleSpecializationChildren
        )}
      </div>
    </div>
  );
};

export default PractitionerInfoLeftColumn;
