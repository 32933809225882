import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useLogInPatientWithTokenMutation } from '../../../generated/graphql';
import AppStoreLinks from '../../components/AppStoreLinks';
import { usePatientAuth } from '../../../contexts/PatientAuthContext';
import { useHomecomingLoader } from '../../hooks/useHomecomingLoader';

const PatientLoginToken = () => {
  const { updateTokenPayload } = usePatientAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const params = useParams();
  const tokenId = params.tokenId!;
  const HomecomingLoader = useHomecomingLoader();

  const [authPatientWithToken] = useLogInPatientWithTokenMutation();
  const [hasErrors, setHasErrors] = useState(false);

  const redirect = searchParams.get('redirect')!;

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const { data, errors } = await authPatientWithToken({
          variables: { input: { tokenId } },
        });

        const tokenPayload = data?.logInPatientWithToken;
        if (errors) {
          setHasErrors(true);
        } else if (tokenPayload) {
          updateTokenPayload(tokenPayload);
          navigate(redirect);
        }
      } catch (e) {
        setHasErrors(true);
      }
    };

    fetchToken();
  }, []);

  return (
    <div className="flex h-full w-full flex-row items-center justify-center py-20">
      {hasErrors ? (
        <div className="max-w-[500px] rounded-lg border border-neutral-100 p-6">
          <div className="mb-4 border-b border-b-neutral-100 pb-4 text-center font-serif text-subtitle-small text-neutral-150">
            Something Went Wrong
          </div>
          <div className="text-center text-body text-green-150">
            <div>
              There was a problem authorizing you to view this page. Links to
              past follow-ups expire after 28 days, so it's possible that this
              link has expired.
            </div>
            <div className="mt-4">
              You can download the Homecoming client companion app to view all
              of your follow-ups:
            </div>
            <div className="mt-6">
              <AppStoreLinks />
            </div>
          </div>
        </div>
      ) : (
        HomecomingLoader
      )}
    </div>
  );
};

export default PatientLoginToken;
