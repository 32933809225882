import { FC } from 'react';
import { ActivityType } from '../../../generated/graphql';

import Button from '../Button';
import Modal from '../Modal';

type ConfirmArchiveActivityModalProps = {
  isModalOpen: boolean;
  setClosed: () => void;
  onConfirm: () => void;
  isArchivingResource: boolean;
  activityType?: ActivityType;
};

const ConfirmArchiveActivityModal: FC<ConfirmArchiveActivityModalProps> = ({
  isModalOpen,
  setClosed,
  onConfirm,
  isArchivingResource,
  activityType,
}) => {
  let activityTypeText;

  switch (activityType) {
    case ActivityType.Action:
      activityTypeText = 'action';
      break;
    case ActivityType.Assessment:
      activityTypeText = 'form';
      break;
    case ActivityType.Audio:
      activityTypeText = 'audio';
      break;
    case ActivityType.Pdf:
      activityTypeText = 'PDF';
      break;
    case ActivityType.Image:
      activityTypeText = 'image';
      break;
    case ActivityType.Link:
      activityTypeText = 'link';
      break;
    case ActivityType.Text:
      activityTypeText = 'text document';
      break;
    default:
      break;
  }

  return (
    <Modal isOpen={isModalOpen} setClosed={setClosed} width="small">
      <div className="px-10 py-8">
        <div className="flex flex-col items-center">
          <div className="mb-2 flex text-center font-serif text-subtitle-small text-green-150">
            Would you like to delete this {activityTypeText}?
          </div>
          <div className="text-center text-body text-green-125">
            Clients who have already received this {activityTypeText} can still
            access it in the Homecoming app.
          </div>
        </div>
        <div className="mt-11 flex w-full flex-row items-center justify-center">
          <Button
            title="Cancel"
            theme="secondary"
            className="mr-5"
            onClick={setClosed}
          />
          <Button
            title={!isArchivingResource ? 'Delete' : 'Deleting...'}
            theme="primary"
            onClick={onConfirm}
            disabled={isArchivingResource}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmArchiveActivityModal;
