import { ServiceIcon } from '../../../generated/graphql';

import IllustrationCircles from '../../svgs/ServiceIcons/IllustrationCircles';
import IllustrationEnergy from '../../svgs/ServiceIcons/IllustrationEnergy';
import IllustrationFaces from '../../svgs/IllustrationFaces';
import IllustrationLeaf from '../../svgs/ServiceIcons/IllustrationLeaf';
import IllustrationGrapes from '../../svgs/ServiceIcons/IllustrationGrapes';
import IllustrationNotepad from '../../svgs/ServiceIcons/IllustrationNotepad';
import IllustrationPath from '../../svgs/ServiceIcons/IllustrationPath';
import IllustrationPencil from '../../svgs/IllustrationPencil';
import IllustrationStones from '../../svgs/ServiceIcons/IllustrationStones';
import IllustrationSwirls from '../../svgs/ServiceIcons/IllustrationSwirls';
import IllustrationVine from '../../svgs/ServiceIcons/IllustrationVine';

export const CALENDLY_COLOR_MAP = {
  '#ff4f00': 'text-calendly-red',
  '#ff758e': 'text-calendly-redpink',
  '#e55cff': 'text-calendly-pink',
  '#8247f5': 'text-calendly-purple',
  '#0099FF': 'text-calendly-blue',
  '#0ae8f0': 'text-calendly-lightblue',
  '#17e885': 'text-calendly-green',
  '#ccf000': 'text-calendly-lime',
  '#f8e436': 'text-calendly-yellow',
  '#ffa600': 'text-calendly-orange',
};

export const SERVICE_ICONS = [
  ServiceIcon.Circles,
  ServiceIcon.Energy,
  ServiceIcon.Faces,
  ServiceIcon.Grapes,
  ServiceIcon.Leaf,
  ServiceIcon.Notepad,
  ServiceIcon.Path,
  ServiceIcon.Pencil,
  ServiceIcon.Stones,
  ServiceIcon.Swirls,
  ServiceIcon.Vine,
];

export const getIconComponent = (value: ServiceIcon) =>
  ({
    [ServiceIcon.Circles]: IllustrationCircles,
    [ServiceIcon.Energy]: IllustrationEnergy,
    [ServiceIcon.Faces]: IllustrationFaces,
    [ServiceIcon.Grapes]: IllustrationGrapes,
    [ServiceIcon.Leaf]: IllustrationLeaf,
    [ServiceIcon.Notepad]: IllustrationNotepad,
    [ServiceIcon.Path]: IllustrationPath,
    [ServiceIcon.Pencil]: IllustrationPencil,
    [ServiceIcon.Stones]: IllustrationStones,
    [ServiceIcon.Swirls]: IllustrationSwirls,
    [ServiceIcon.Vine]: IllustrationVine,
  }[value]);
