import {
  MePatientDataFragment,
  ProgramActivityDataFragment,
} from '../../../../../generated/graphql';
import { getFirstName, getLastName } from '../../../../lib/copy';
import { AssessmentAnswerInput } from '../../../../lib/program-assessment';

export const localStoreKey = (
  programActivity: ProgramActivityDataFragment,
): string => {
  return ['form', programActivity?.id?.toString()].join(':');
};

export type AssessmentFormValues = {
  answers: AssessmentAnswerInput[];
  singleSignatureTermsAgreed?: boolean;
};

const parsePatientNameAndInitials = (firstName: string, lastName: string) => ({
  fullName: `${firstName} ${lastName}`,
  initials: `${firstName[0]}${lastName
    .split('-')
    .map((part) => part[0])
    .join('')}`,
});

export const getPatientNameAndInitials = (
  authedPatient: MePatientDataFragment | null,
  fullNameFromIntake?: string,
) => {
  const firstName =
    authedPatient?.firstName || getFirstName(fullNameFromIntake);
  const lastName = authedPatient?.lastName || getLastName(fullNameFromIntake);

  return firstName && lastName
    ? parsePatientNameAndInitials(firstName, lastName)
    : { fullName: '', initials: '' };
};
