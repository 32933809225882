import { TrashIcon } from '@heroicons/react/outline';
import { WithOptional } from '../types/utils';
import Button from './Button';
import Modal, { ModalProps } from './Modal';

export type ConfirmDeleteModalProps = {
  title?: string;
  actionButtonTitle?: string;
  performDelete: () => Promise<unknown>;
} & WithOptional<ModalProps, 'children'>;

export default function ConfirmDeleteModal({
  title = 'Are you sure you want to delete this?',
  actionButtonTitle = 'Delete',
  children,
  fetching,
  setClosed,
  performDelete,
  ...rest
}: ConfirmDeleteModalProps) {
  return (
    <Modal
      name="ConfirmDelete"
      width="small"
      fetching={fetching}
      setClosed={setClosed}
      {...rest}
    >
      <div className="mx-auto flex max-w-lg flex-col items-center justify-center px-4 text-center">
        <h1 className="mt-3 font-serif text-subtitle-small text-green-150">
          {title}
        </h1>
        <div className="mt-2 mb-8 w-full text-center font-sans text-body text-green-125">
          {children || 'This cannot be undone.'}
        </div>
        <div className="mb-5 flex w-full flex-row items-center justify-center">
          <Button
            title="Cancel"
            theme="secondary"
            className="mr-5"
            disabled={fetching}
            onClick={setClosed}
          />
          <Button
            theme="destructive"
            IconComponent={TrashIcon}
            iconPosition="left"
            title={actionButtonTitle}
            disabled={fetching}
            onClick={async () => {
              await performDelete();
              setClosed();
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
