import classNames from 'classnames';
import { FC, useState } from 'react';

import { CollectionIcon } from '@heroicons/react/outline';
import { getTimeStamp } from '../../../../lib/time';
import { ProviderCollection } from '../../../../types/providerCollection';
import Button from '../../../Button';
import { ActivityDataFragment } from '../../../../../generated/graphql';

const ProviderCollectionTableRow: FC<{
  providerCollection: ProviderCollection;
  setSelectedProviderCollection: React.Dispatch<
    React.SetStateAction<ProviderCollection | undefined>
  >;
  followUpMode: boolean;
  sendProviderActivitiesInFollowUp?: (
    providerActivities: ActivityDataFragment[],
  ) => void;
  providerActivitiesInCollection: ActivityDataFragment[] | undefined;
}> = ({
  providerCollection,
  setSelectedProviderCollection,
  followUpMode,
  sendProviderActivitiesInFollowUp,
  providerActivitiesInCollection,
}) => {
  const onClick = () => {
    setSelectedProviderCollection(providerCollection);
  };

  const [showActionButton, setShowActionButton] = useState(false);

  return (
    <tr
      className={classNames(
        'max-h-[48px] cursor-pointer text-ellipsis border-b border-neutral-50 text-caption text-green-150 hover:bg-neutral-25',
      )}
      onClick={onClick}
      onMouseEnter={() => setShowActionButton(true)}
      onMouseLeave={() => {
        setShowActionButton(false);
      }}
    >
      <td></td>
      <td className={classNames(!followUpMode ? 'w-2/3' : 'w-1/2')}>
        <div className="flex items-center">
          <div className="mr-4 flex h-[36px] w-[36px] flex-row items-center justify-center rounded-lg">
            <CollectionIcon className="h-[30px] w-[30px] text-secondary-125" />
          </div>

          <div className="flex-col text-green-150">
            <div
              className={classNames(
                'font-medium',
                !followUpMode ? 'line-clamp-2' : 'line-clamp-1',
              )}
            >
              {providerCollection.name}
            </div>
          </div>
        </div>
      </td>

      <td className="w-1/3">
        <div className="text-netural-125 text-caption">
          {getTimeStamp(providerCollection.createdAt, false)}
        </div>
      </td>
      <td>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            sendProviderActivitiesInFollowUp?.(
              providerActivitiesInCollection ?? [],
            );
          }}
          className={classNames(
            !followUpMode || showActionButton ? 'visible' : 'invisible',
          )}
          size="small"
          theme="secondary"
          title={followUpMode ? 'Attach this collection' : 'Send to client'}
        />
      </td>
      <td></td>
    </tr>
  );
};

export default ProviderCollectionTableRow;
