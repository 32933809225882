import React, { FC, Fragment } from 'react';
import classNames from 'classnames';
import { Menu, Transition } from '@headlessui/react';

import {
  ArchiveIcon,
  DotsHorizontalIcon,
  EyeIcon,
  PencilAltIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import {
  BasicProviderNoteTemplateDataFragment,
  ProviderNoteTemplateDataFragment,
} from '../../../../../generated/graphql';
import MenuButton from '../../../../components/MenuButton';
import Avatar from '../../../../components/Avatar';

type ProviderNoteTemplateItemProps = {
  providerNoteTemplate: BasicProviderNoteTemplateDataFragment;
  handleNewNoteFromNoteTemplate: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    providerNoteTemplate: BasicProviderNoteTemplateDataFragment,
  ) => Promise<void>;
  isEditDeleteMenuOpen: boolean;
  setSelectedNoteTemplate: React.Dispatch<
    React.SetStateAction<
      ProviderNoteTemplateDataFragment | BasicProviderNoteTemplateDataFragment
    >
  >;
  handleViewNoteTemplate: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    providerNoteTemplate: BasicProviderNoteTemplateDataFragment,
  ) => Promise<void>;
  handleRemoveNoteTemplate: () => void;
  isLastItem: boolean;
  isFirstOrSecondItem: boolean;
  sharedWithMe: boolean;
  ownedAndSharedFromMe: boolean;
};

const ProviderNoteTemplateItem: FC<ProviderNoteTemplateItemProps> = ({
  providerNoteTemplate,
  handleNewNoteFromNoteTemplate,
  isEditDeleteMenuOpen,
  setSelectedNoteTemplate,
  handleViewNoteTemplate,
  handleRemoveNoteTemplate,
  isLastItem,
  isFirstOrSecondItem,
  sharedWithMe,
  ownedAndSharedFromMe,
}) => {
  return (
    <Menu as={Fragment} key={providerNoteTemplate.id}>
      <Menu.Button as={Fragment}>
        <MenuButton
          titleText={providerNoteTemplate.title}
          className={classNames(
            'pr-[10px] text-secondary-150',
            !isLastItem && 'border-b border-neutral-50',
          )}
          activeText="text-secondary-150"
          HelperAvatarComponent={
            sharedWithMe && (
              <Avatar
                size="small"
                name={providerNoteTemplate?.createdByProviderUser.name}
                imageUrl={
                  providerNoteTemplate?.createdByProviderUser?.profileImageMedia
                    ?.url
                }
              />
            )
          }
          helperText={ownedAndSharedFromMe && 'Shared'}
          onClick={async (e) => {
            e.stopPropagation();
            await handleNewNoteFromNoteTemplate(e, providerNoteTemplate);
          }}
          IconComponent={DotsHorizontalIcon}
          iconButtonOnClick={(e) => {
            setSelectedNoteTemplate(providerNoteTemplate);
            e.stopPropagation();
          }}
          iconClassName="text-secondary-150 !h-5 !w-5"
        />
      </Menu.Button>
      <Transition
        show={isEditDeleteMenuOpen}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            'absolute left-[256px] z-[100] w-48 origin-top-left rounded-md bg-white shadow-lg focus:outline-none',
            isFirstOrSecondItem ? 'top-32' : 'bottom-6',
          )}
        >
          <MenuButton
            titleText={!sharedWithMe ? 'Edit' : 'View'}
            activeText="text-secondary-150"
            textClassName="text-secondary-150"
            iconClassName="text-secondary-150"
            IconComponent={!sharedWithMe ? PencilAltIcon : EyeIcon}
            className="border-b border-neutral-50"
            onClick={async (e) => {
              e.stopPropagation();
              await handleViewNoteTemplate(e, providerNoteTemplate);
            }}
          />
          <MenuButton
            titleText={!sharedWithMe ? 'Delete' : 'Remove'}
            activeText="text-neutral-150"
            textClassName="text-neutral-150"
            IconComponent={!sharedWithMe ? TrashIcon : ArchiveIcon}
            iconClassName={!sharedWithMe && 'text-red-100'}
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveNoteTemplate();
            }}
          />
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ProviderNoteTemplateItem;
