import { FC } from 'react';

import { useVoidProgramActivityMutation } from '../../../generated/graphql';
import ConfirmDeleteModal, {
  ConfirmDeleteModalProps,
} from '../ConfirmDeleteModal';

type ConfirmVoidActivityModalProps = Omit<
  ConfirmDeleteModalProps,
  'performDelete'
> & {
  programActivityId: string;
  onVoided: () => Promise<unknown>;
};

const ConfirmVoidActivityModal: FC<ConfirmVoidActivityModalProps> = ({
  programActivityId,
  onVoided,
  ...otherProps
}) => {
  const [voidProgramActivity, { loading: voidProgramActivityLoading }] =
    useVoidProgramActivityMutation();

  const onConfirm = async () => {
    try {
      await voidProgramActivity({
        variables: {
          programActivityId,
        },
      });
      await onVoided?.();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ConfirmDeleteModal
      fetching={voidProgramActivityLoading}
      title={`Are you sure you want void this form?`}
      actionButtonTitle="Void form"
      performDelete={onConfirm}
      {...otherProps}
    />
  );
};

export default ConfirmVoidActivityModal;
