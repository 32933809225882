import classNames from 'classnames';
import { FC, Fragment, useEffect, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ChevronLeftIcon } from '@heroicons/react/solid';

import CloseX from '../svgs/CloseX';
import Spinner from '../svgs/Spinner';
import { defaultTransitionProps } from '../lib/animation';

import Button from './Button';

export const SLIDEOVER_TRANSITION_DURATION = 500;

const Slideover: FC<{
  isOpen: boolean;
  fetching?: boolean;
  children?: React.ReactNode;
  titleComponent?: React.ReactNode;
  containerClassName?: string;
  showContent?: boolean;
  title?: string;
  subtitle?: string;
  backButtonActive?: boolean;
  onBackButtonClick?: () => void;
  onClose: () => void;
  isAboveModal?: boolean;
}> = ({
  isOpen,
  children = <></>,
  titleComponent = null,
  containerClassName = '',
  fetching = false,
  showContent = true,
  title = '',
  subtitle = '',
  backButtonActive = false,
  onBackButtonClick,
  onClose,
  isAboveModal = false,
}) => {
  const slideoverContainer = useRef() as React.MutableRefObject<HTMLDivElement>;

  const overflowContainer = slideoverContainer?.current;
  const hasScrollbar =
    overflowContainer?.scrollHeight > overflowContainer?.clientHeight;

  useEffect(() => {
    if (backButtonActive && hasScrollbar) {
      slideoverContainer.current.scrollTop = 0;
    }
  }, [backButtonActive]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={classNames('relative', !isAboveModal ? 'z-50' : 'z-[52]')}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-125 bg-opacity-50 backdrop-blur-[4px] transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto flex h-screen w-screen max-w-md flex-col text-green-150">
                  <div className="flex w-full flex-col items-center justify-start border-b border-neutral-50 bg-white px-4 pt-4 pb-6 sm:px-6">
                    <div className="mb-2 flex w-full flex-row items-center justify-between">
                      <Button
                        onClick={onBackButtonClick}
                        title="BACK"
                        theme="secondary"
                        noBackground
                        noOutline
                        iconPosition="left"
                        size="small"
                        IconComponent={ChevronLeftIcon}
                        iconClassName="text-green-125"
                        className={classNames(
                          'text-small-caption font-black text-green-125 transition duration-150',
                          !backButtonActive && 'cursor-default opacity-0',
                          backButtonActive && 'opacity-100',
                        )}
                      />
                      <Button
                        title="CLOSE"
                        iconPosition="right"
                        theme="secondary"
                        size="extra-small"
                        noBackground
                        noOutline
                        IconComponent={CloseX}
                        onClick={onClose}
                        className="text-green-125"
                        iconClassName="text-green-125"
                      />
                    </div>
                    {title && (
                      <div className="flex w-full flex-col items-center justify-center">
                        <span className="mb-1 text-center font-serif text-subtitle-small">
                          {title}
                        </span>
                        {subtitle && (
                          <span className="text-center text-caption text-green-125">
                            {subtitle}
                          </span>
                        )}
                      </div>
                    )}
                    {titleComponent}
                  </div>
                  <div className="flex flex-1 flex-col overflow-hidden bg-white">
                    <div
                      className={classNames(
                        'relative flex-1 overflow-y-auto bg-neutral-50 px-4 pt-6 pb-3 sm:px-6',
                        containerClassName,
                      )}
                      ref={slideoverContainer}
                    >
                      {fetching && <Spinner className="mx-auto mt-4" />}
                      <Transition
                        show={showContent}
                        {...defaultTransitionProps}
                      >
                        {children}
                      </Transition>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Slideover;
