import { forwardRef } from 'react';
import Upload from '../svgs/Upload';

type UploadFieldProps = {
  title: string;
  inputId: string;
  acceptedFileExtensions: string;
} & React.ComponentProps<'input'>;

const UploadField = forwardRef<HTMLInputElement, UploadFieldProps>(
  ({ title, acceptedFileExtensions, inputId, ...rest }, ref) => {
    return (
      <>
        <label
          className="flex h-full w-full cursor-pointer items-center justify-center"
          htmlFor={inputId}
        >
          <Upload className="mr-2 h-5 w-5 text-secondary-100" />
          <span>{title}</span>
        </label>
        <input
          ref={ref}
          id={inputId}
          type="file"
          className="hidden"
          accept={acceptedFileExtensions}
          {...rest}
        />
      </>
    );
  },
);

export default UploadField;
