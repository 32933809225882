import classNames from 'classnames';

interface TooltipIconProps {
  IconComponent: React.ComponentType<{ className?: string }>;
  tooltipText: string;
  iconClassName?: string;
  backgroundColor?: string;
}

const TooltipIcon: React.FC<TooltipIconProps> = ({
  IconComponent,
  tooltipText,
  iconClassName = 'h-4 w-4',
  backgroundColor = 'bg-blue-25',
}) => {
  return (
    <div
      className={classNames(
        'group relative flex items-center justify-center rounded-full px-2 py-1.5',
        backgroundColor,
      )}
    >
      <IconComponent className={classNames('h-4 w-4', iconClassName)} />
      <div className="absolute -bottom-8 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-white px-2 py-1 text-small-caption text-neutral-125 opacity-0 shadow-md transition-opacity group-hover:opacity-100">
        {tooltipText}
      </div>
    </div>
  );
};

export default TooltipIcon;
