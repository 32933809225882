import PageContainer from '../../components/Containers/PageContainer';
import { useAuth } from '../../../contexts/AuthContext';
import useFixPage from '../../hooks/useFixPage';
import CalendarV2 from './CalendarV2';
import EmptyIntegration from '../../components/EmptyIntegration';
import IllustrationCalendar from '../../svgs/IllustrationCalendar';
import usePaintScreen from '../../hooks/usePaintScreen';
import { MODAL_TRANSITION_DURATION } from '../../components/Modal';
import classNames from 'classnames';
import { useFeatureFlags } from '../../../contexts/FeatureFlagContext';
import { Feature } from '../../../generated/graphql';
import { Navigate } from 'react-router-dom';

const Schedule: React.FC = () => {
  useFixPage();

  const { isFeatureEnabled } = useFeatureFlags();
  const isCalendarSyncEnabled = isFeatureEnabled(Feature.CalendarSync);

  const showPage = usePaintScreen(MODAL_TRANSITION_DURATION);
  const { authedProviderUser, showUpgradeBanner } = useAuth();

  const isNylasConnected = authedProviderUser?.hasNylasIntegration;

  if (!isCalendarSyncEnabled) {
    return <Navigate to="/home" replace />;
  }

  return (
    <PageContainer
      show={showPage}
      containerClassName={classNames(
        isNylasConnected && 'pb-1 pt-6',
        showUpgradeBanner
          ? 'h-[calc(100vh-var(--top-nav-height)-var(--upgrade-banner-height))] overflow-y-hidden'
          : 'h-[calc(100vh-var(--top-nav-height))] overflow-y-hidden',
      )}
    >
      {isNylasConnected ? (
        <CalendarV2 />
      ) : (
        <>
          <h1 className="mb-6 font-serif text-subtitle text-green-150">
            Calendar
          </h1>
          <EmptyIntegration
            title="Sync your calendar"
            description="Connect your external calendar to view your schedule in Homecoming."
            ctaPath="/integrations/calendar"
            ctaTitle="Connect my calendar"
            illustration={
              <IllustrationCalendar className="h-[80px] text-blue-100" />
            }
          />
        </>
      )}
    </PageContainer>
  );
};

export default Schedule;
