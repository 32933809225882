import { ProviderActivityType } from '../types/activity';

import { isAssessmentAForm } from '../lib/assessments';

import ThumbnailPDF from '../svgs/ThumbnailPDF';
import ThumbnailPDFESign from '../svgs/ThumbnailPDFESign';
import ThumbnailPdfForm from '../svgs/ThumbnailPdfForm';
import ThumbnailLink from '../svgs/ThumbnailLink';
import ThumbnailForm from '../svgs/ThumbnailForm';
import ThumbnailAudio from '../svgs/ThumbnailAudio';
import ThumbnailImage from '../svgs/ThumbnailImage';
import ThumbnailAssessment from '../svgs/ThumbnailAssessment';

import { getShortLink } from './url';
import { getDurationMSS } from './time';
import ThumbnailText from '../svgs/ThumbnailText';
import {
  ActivityDataFragment,
  ActivityFileCompletionType,
  ActivityType,
  ActivityUseType,
  AssessmentSchemaType,
} from '../../generated/graphql';
import {
  SavedActivityInputPreview,
  activityTypeToContentType,
  defaultActivitySchema,
} from './followUp';
import { FILE_ACTIVITY_TYPES } from './activity';

export const getProviderActivityData = (
  providerActivity: ActivityDataFragment,
) => {
  let activityId = '',
    activityTitle,
    activitySubtitle,
    activityImage,
    activityMediaUrl,
    activityDuration,
    ActivityThumbnail,
    createdAt;

  if (providerActivity.__typename === ProviderActivityType.Assessment) {
    activityId = providerActivity.assessment.id;
    createdAt = providerActivity.assessment.createdAt;
    const isForm = isAssessmentAForm(providerActivity.assessment);

    activityTitle = isForm
      ? providerActivity.assessment.name
      : providerActivity.assessment.shortName;
    activitySubtitle = isForm ? undefined : providerActivity.assessment.name;
    ActivityThumbnail = isForm ? ThumbnailForm : ThumbnailAssessment;
  }

  if (providerActivity.__typename === ProviderActivityType.Image) {
    activityImage = providerActivity.media.url;
    activityMediaUrl = activityImage;
    ActivityThumbnail = ThumbnailImage;
  }

  if (providerActivity.__typename === ProviderActivityType.PDF) {
    ActivityThumbnail =
      providerActivity.completionType === ActivityFileCompletionType.Upload
        ? ThumbnailPdfForm
        : providerActivity.completionType === ActivityFileCompletionType.Esign
        ? ThumbnailPDFESign
        : ThumbnailPDF;
    activityMediaUrl = providerActivity.media.url;
    activitySubtitle = providerActivity.media.isPdf ? 'PDF' : 'File';
  }

  if (providerActivity.__typename === ProviderActivityType.Link) {
    activitySubtitle = getShortLink(providerActivity.url ?? '');
    activityMediaUrl = providerActivity.url;
    activityImage = providerActivity?.thumbnailMedia?.url;
    ActivityThumbnail = ThumbnailLink;
  }

  if (providerActivity.__typename === ProviderActivityType.Audio) {
    activitySubtitle = getDurationMSS(providerActivity.duration);
    activityMediaUrl = providerActivity.media.url;
    activityDuration = providerActivity.duration;
    ActivityThumbnail = ThumbnailAudio;
  }

  if (providerActivity.__typename === ProviderActivityType.Text) {
    ActivityThumbnail = ThumbnailText;
  }

  if (
    providerActivity.__typename === ProviderActivityType.Image ||
    providerActivity.__typename === ProviderActivityType.PDF ||
    providerActivity.__typename === ProviderActivityType.Link ||
    providerActivity.__typename === ProviderActivityType.Audio ||
    providerActivity.__typename === ProviderActivityType.Text
  ) {
    activityId = providerActivity.id;
    createdAt = providerActivity.createdAt;
    activityTitle = providerActivity.title;
  }

  return {
    activityId,
    activityTitle,
    activitySubtitle,
    activityImage,
    activityMediaUrl,
    activityDuration,
    ActivityThumbnail,
    createdAt,
  };
};

export interface ProviderActivityObject {
  [key: string]: ActivityDataFragment;
}

export const getProviderActivitiesObject = (
  providerActivities: ActivityDataFragment[] | undefined,
) => {
  if (providerActivities) {
    const providerActivityObject: ProviderActivityObject = {};
    providerActivities.forEach((providerActivity) => {
      const providerActivityId =
        getProviderActivityData(providerActivity).activityId;
      providerActivityObject[providerActivityId] = providerActivity;
    });
    return providerActivityObject;
  } else {
    return {};
  }
};

export const getProviderActivityTitle = (
  providerActivity: ActivityDataFragment,
): string | undefined => {
  if (providerActivity.__typename === ProviderActivityType.Assessment) {
    return isAssessmentAForm(providerActivity.assessment)
      ? providerActivity.assessment.name
      : providerActivity.assessment.shortName;
  }

  if (
    providerActivity.__typename === ProviderActivityType.Audio ||
    providerActivity.__typename === ProviderActivityType.Image ||
    providerActivity.__typename === ProviderActivityType.Link ||
    providerActivity.__typename === ProviderActivityType.PDF ||
    providerActivity.__typename === ProviderActivityType.Text
  ) {
    return providerActivity.title;
  }
};

export const formatProviderActivityForFollowUp = (
  providerActivity: ActivityDataFragment,
  createDateTimeNudge?: number,
  providerNote?: string,
): SavedActivityInputPreview => {
  const providerActivityData = getProviderActivityData(providerActivity);

  const dateCreated = Date.now() + (createDateTimeNudge || 0);
  let activityType = providerActivity.activityType;
  let activityUseType = ActivityUseType.Reusable;

  if (
    providerActivity.__typename !== ProviderActivityType.Assessment &&
    providerActivity.__typename !== ProviderActivityType.Action
  ) {
    activityUseType = providerActivity.activityUseType;
  }

  if (providerActivity.__typename === ProviderActivityType.PDF) {
    // Use completionType setting on the ActivityPDF to determine activityType
    // for follow-up
    activityType =
      providerActivity.completionType === ActivityFileCompletionType.Upload
        ? ActivityType.PdfForm
        : providerActivity.completionType === ActivityFileCompletionType.Esign
        ? ActivityType.Esign
        : ActivityType.Pdf;
  }

  const { schemaType: assessmentSchemaType, assessmentSignatureUsers } =
    providerActivity.__typename === ProviderActivityType.Assessment
      ? providerActivity.assessment
      : { schemaType: undefined, assessmentSignatureUsers: [] };

  const contentType = activityTypeToContentType(
    activityType,
    assessmentSchemaType,
  );

  const savedActivityInputPreview = {
    ...(assessmentSignatureUsers?.length > 0 && {
      multiSignatureAssessmentConfig: {
        assessmentSignatureUsers,
      },
    }),
    sendFollowUpActivityInputRaw: {
      activityType,
      dateCreated,
      isSubmittable: true,
      isCompletable: true,
      ...(providerNote && {
        providerNote,
      }),
      ...(activityType === ActivityType.Assessment && {
        assessmentId: providerActivityData.activityId,
      }),
      ...(activityType === ActivityType.Link && {
        activityLinkId: providerActivityData.activityId,
      }),
      ...(activityType === ActivityType.Audio && {
        activityAudioId: providerActivityData.activityId,
      }),
      ...(activityType === ActivityType.Image && {
        activityImageId: providerActivityData.activityId,
      }),
      ...(FILE_ACTIVITY_TYPES.includes(activityType) && {
        activityPDFId: providerActivityData.activityId,
      }),
      ...(activityType === ActivityType.Text && {
        activityTextId: providerActivityData.activityId,
      }),
    },
    activityPreviewCardProps: {
      ...defaultActivitySchema.activityPreviewCardProps,
      ...(providerNote && {
        providerNote,
      }),
      contentType,
      ...(activityType !== ActivityType.Assessment && {
        activityUseType,
      }),
      ...(providerActivityData.activityTitle && {
        title: providerActivityData.activityTitle,
      }),
      ...(providerActivityData.activitySubtitle && {
        description: providerActivityData.activitySubtitle,
      }),
      ...(providerActivityData.activityImage && {
        previewThumbnailImageUrl: providerActivityData.activityImage,
      }),
    },
  };

  return savedActivityInputPreview;
};

export function isProviderActivityMultiSignatureAssessment(
  providerActivity: ActivityDataFragment,
) {
  if (providerActivity.__typename === ProviderActivityType.Assessment) {
    return providerActivity?.assessment?.assessmentSignatureUsers?.length > 0;
  }
  return false;
}

export function isProviderActivityANonCustomAssessment(
  providerActivity: ActivityDataFragment,
) {
  if (providerActivity.__typename === ProviderActivityType.Assessment) {
    return (
      providerActivity?.assessment?.schemaType !== AssessmentSchemaType.Custom
    );
  }
  return false;
}
