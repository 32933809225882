import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import IllustrationExplode from '../../svgs/IllustrationExplode';

const FirstServiceCard: FC = () => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate('/services/new')}
      className="h-[312px] w-[382px] cursor-pointer rounded-lg bg-white shadow-lg shadow-neutral-75 transition-shadow hover:shadow-100"
    >
      <div className="flex h-full flex-col px-8 py-6">
        {/* Icon & Price */}
        <div className="mb-4 flex w-full flex-row items-center justify-between">
          <div className="flex flex-row items-end justify-start">
            <IllustrationExplode className="h-16 w-16 text-blue-100" />
          </div>
        </div>

        {/* Title */}
        <div className="mb-2 flex items-start justify-between gap-2">
          <h3 className="font-serif text-subtitle-small font-light text-neutral-200">
            Add your first service
          </h3>
        </div>

        {/* Description */}
        <p className="text-caption text-neutral-125 line-clamp-3">
          Create a new service to start accepting bookings from clients
        </p>
      </div>
    </div>
  );
};

export default FirstServiceCard;
