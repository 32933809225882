import { FC } from 'react';
import ProviderProgramTagManager from '../../components/ProgramTag/ProviderProgramTagManager';

const TagsSection: FC = () => {
  return (
    <ProviderProgramTagManager
      fullPageMode
      className="h-full overflow-y-scroll py-6"
    />
  );
};

export default TagsSection;
