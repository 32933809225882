import { SvgIconComponent } from '../types/svgs';

const RoundedRectangle: SvgIconComponent = ({ className, customColor }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      className={className}
    >
      <path
        d="M26.0001 1.99996C23.5001 -0.499984 4.5002 -0.500154 2.00008 1.99996C-0.500039 4.50008 -0.500085 23.5 2.00008 26C4.50024 28.4999 23.5001 28.4999 26.0001 26C28.5 23.5 28.5001 4.49991 26.0001 1.99996Z"
        fill={customColor}
      />
    </svg>
  );
};

export default RoundedRectangle;
