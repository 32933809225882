import classNames from 'classnames';
import { FC } from 'react';
import { PublicProviderServiceDataFragment } from '../../../generated/graphql';
import ServiceCard from '../ServiceCard';
import { useAuth } from '../../../contexts/AuthContext';

export const DEFAULT_TAGLINE =
  'Tagline that encapsulates the support you offer';
export const DEFAULT_ABOUT =
  "Provide a succinct yet compelling narrative of your professional journey, highlighting your credentials, philosophy, and what sets you apart. Focus on why you're passionate about your niche and how your work transforms lives.";

const PractitionerInfoRightColumn: FC<{
  tagline?: string;
  about?: string;
  inEditMode?: boolean;
  editModeTaglineChildren?: React.ReactNode;
  editModeAboutChildren?: React.ReactNode;
  publicServices?: PublicProviderServiceDataFragment[];
}> = ({
  tagline,
  about,
  inEditMode,
  editModeTaglineChildren,
  editModeAboutChildren,
  publicServices,
}) => {
  const { authedProviderUser } = useAuth();
  return (
    <div
      className={classNames(
        'flex flex-grow flex-col space-y-8 px-6',
        !publicServices ? 'md:pr-12' : 'md:pr-16',
      )}
    >
      {!inEditMode ? (
        <div
          className={classNames(
            'text-left font-serif text-subtitle font-light text-neutral-125 md:mt-2',
            'md:mt-0 md:text-title-small',
          )}
        >
          {tagline || DEFAULT_TAGLINE}
        </div>
      ) : (
        editModeTaglineChildren
      )}
      <div className={classNames(!publicServices ? 'md:pr-8' : 'md:pr-16')}>
        <div
          className={classNames(
            'mb-3 cursor-default text-body font-medium text-neutral-150',
            inEditMode && ' text-neutral-100',
          )}
        >
          About
        </div>
        {!inEditMode ? (
          <div className="whitespace-pre-wrap break-words text-caption text-neutral-125">
            {about || DEFAULT_ABOUT}
          </div>
        ) : (
          editModeAboutChildren
        )}
      </div>
      {publicServices?.length > 0 && (
        <div className="space-y-4">
          <div className="cursor-default text-body font-medium text-neutral-150">
            Services
          </div>
          <div className="flex flex-wrap justify-start gap-6">
            {publicServices?.map((publicService, index) => (
              <ServiceCard
                key={index}
                publicService={publicService}
                providerPrimaryColor={authedProviderUser?.primaryColor}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PractitionerInfoRightColumn;
