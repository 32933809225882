import React from 'react';
import Button from '../../components/Button';
import Modal from '../../components/Modal';

interface ConfirmDismissSessionModalProps {
  sessionId: string;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmDismissSessionModal: React.FC<ConfirmDismissSessionModalProps> = ({
  sessionId,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal
      name="ConfirmDismiss"
      isOpen={true}
      setClosed={onClose}
      width="small"
    >
      <div className="px-10 py-8">
        <div className="flex flex-col items-center">
          <div className="mb-2 flex text-center font-serif text-subtitle-small text-green-150">
            Would you like to dismiss this session?
          </div>
          <div className="text-center text-body text-green-125">
            This session will be marked as dismissed and removed from your
            review queue.
          </div>
        </div>
        <div className="mt-11 flex w-full flex-row items-center justify-center">
          <Button
            title="Cancel"
            theme="secondary"
            className="mr-5"
            onClick={onClose}
          />
          <Button title="Dismiss" theme="primary" onClick={onConfirm} />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDismissSessionModal;
