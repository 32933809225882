import React, { useState, useEffect } from 'react';

import {
  useGetClientSessionsLazyQuery,
  useGetClientSessionsCountQuery,
  ClientSessionReviewStatus,
  ClientSession,
} from '../../../generated/graphql';

import Avatar from '../../components/Avatar';
import Button from '../../components/Button';
import ClientSessionsPagination from './ClientSessionsPagination';
import ConfirmDismissSessionModal from './ConfirmDismissSessionModal';
import ViewClientSessionModal from './ViewClientSessionModal';
import { DateTime } from 'luxon';
const PAGE_SIZE = 10;

const ClientSessionsView: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sessionToView, setSessionToView] = useState<ClientSession | null>(
    null,
  );
  const [sessionToDismiss, setSessionToDismiss] = useState<string | null>(null);

  const [
    getClientSessions,
    { data: sessionsData, loading: isLoadingSessions },
  ] = useGetClientSessionsLazyQuery();

  const { data: countData, loading: isLoadingCount } =
    useGetClientSessionsCountQuery({
      variables: {
        input: { reviewStatus: ClientSessionReviewStatus.PendingReview },
      },
    });

  useEffect(() => {
    getClientSessions({
      variables: {
        input: {
          pageSize: PAGE_SIZE,
          page: currentPage,
          filter: { reviewStatus: ClientSessionReviewStatus.PendingReview },
        },
      },
    });
  }, [currentPage]); // Re-fetch when page changes

  const clientSessions = sessionsData?.clientSessions;
  const clientSessionsCount = countData?.clientSessionsCount || 0;
  const totalPages = Math.ceil(clientSessionsCount / PAGE_SIZE);

  if (isLoadingSessions || isLoadingCount) {
    return <div>Loading...</div>;
  }

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(1, prev - 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(totalPages, prev + 1));
  };

  const handleViewClick = (clientSession: ClientSession) => {
    setSessionToView(clientSession);
  };

  const handleDismissClick = (clientSession: ClientSession) => {
    setSessionToDismiss(clientSession.id);
  };

  const handleConfirmDismiss = () => {
    setSessionToDismiss(null);
  };

  return (
    <div>
      {clientSessions?.map((session: ClientSession) => (
        <div
          key={`session-${session.id}`}
          className="flex w-full cursor-pointer flex-row items-center rounded-sm py-3 px-5 hover:bg-neutral-25 focus:bg-neutral-50 focus:outline-none"
          onClick={() => handleViewClick(session)}
        >
          <Avatar
            size="medium-plus"
            name={session.patient.name}
            imageUrl={session.patient.profileImageMedia?.url}
            className="mr-3"
          />
          <div className="mr-10 flex-grow">
            <div className="text-large-caption text-green-150">
              <span className="font-medium">Review session</span> with{' '}
              <span className="font-medium">{session.patient.name}</span>
            </div>
            <div className="mb-1 text-small-caption text-green-150 line-clamp-2">
              {DateTime.fromISO(session.createdAt).toRelative()} ·{' '}
              {session.eventTitle}
            </div>
          </div>
          <div className="flex flex-row">
            <Button
              size="small"
              title="Review"
              theme="secondary"
              onClick={(e) => {
                e.stopPropagation();
                handleViewClick(session);
              }}
            />
            <Button
              size="small"
              title="Dismiss"
              theme="destructive"
              className="ml-2"
              onClick={(e) => {
                e.stopPropagation();
                handleDismissClick(session);
              }}
            />
          </div>
        </div>
      ))}

      {totalPages > 1 && (
        <ClientSessionsPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPreviousPage={handlePreviousPage}
          onNextPage={handleNextPage}
        />
      )}

      {sessionToView && (
        <ViewClientSessionModal
          clientSession={sessionToView}
          onClose={() => setSessionToView(null)}
        />
      )}

      {sessionToDismiss && (
        <ConfirmDismissSessionModal
          sessionId={sessionToDismiss}
          onClose={() => setSessionToDismiss(null)}
          onConfirm={handleConfirmDismiss}
        />
      )}
    </div>
  );
};

export default ClientSessionsView;
