import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { UnsignedFormDataFragment } from '../../../generated/graphql';
import Avatar from '../../components/Avatar';
import Button from '../../components/Button';
import { FormSignatureContent } from '../../lib/notifications';
import classNames from 'classnames';

const UnsignedFormItem: FC<{
  unsignedForm: UnsignedFormDataFragment;
  unsignedForms: UnsignedFormDataFragment[];
}> = ({ unsignedForm, unsignedForms }) => {
  const navigate = useNavigate();

  const remainingProgramAssessmentIdsNeedingSignature = unsignedForms
    .filter((form) => form.id !== unsignedForm.id)
    .map((form) => form.id);

  const onRowClick = () =>
    navigate(`/provider-form/${unsignedForm?.id}`, {
      state: {
        remainingProgramAssessmentIdsNeedingSignature,
      },
    });

  const onButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onRowClick();
  };

  return (
    <div
      onClick={onRowClick}
      className={classNames(
        'flex w-full cursor-pointer flex-row items-center rounded-sm py-3 px-5 hover:bg-neutral-25 focus:bg-neutral-50 focus:outline-none',
      )}
    >
      <Avatar
        size="medium-plus"
        name={unsignedForm.patientNameData?.name}
        imageUrl={unsignedForm.patientNameData?.profileImageMedia?.url}
        className="mr-3"
      />
      <div className="mr-10 flex-grow">
        <div className="text-caption text-green-150 line-clamp-2">
          <FormSignatureContent
            formName={unsignedForm.assessment.name}
            patientName={unsignedForm.patientNameData?.name}
          />
        </div>
      </div>
      <div className="flex flex-row">
        <Button
          size="small"
          title="Complete form"
          theme="secondary"
          onClick={onButtonClick}
        />
      </div>
    </div>
  );
};

export default UnsignedFormItem;
