import { FC, useEffect } from 'react';
import classNames from 'classnames';
import toast from 'react-hot-toast';
import { ChevronRightIcon } from '@heroicons/react/outline';
import useFormPersist from 'react-hook-form-persist';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';

import {
  SignatureUser,
  AssessmentQuestionType,
  useProgramAssessmentQuery,
  ProgramActivityDataFragment,
  CompleteAssessmentAnswerInput,
  useCompleteProgramActivityMutation,
  MePatientDataFragment,
  AssessmentAnswer,
} from '../../../../../generated/graphql';
import Button from '../../../../components/Button';
import Spinner from '../../../../svgs/Spinner';

import {
  isAssessmentAForm,
  isStatementAssessmentQuestion,
} from '../../../../lib/assessments';
import {
  mapAssessmentAnswersToMutationInput,
  ProgramAssessmentQuestion,
} from '../../../../lib/program-assessment';

import Checkbox from '../../../../components/Checkbox';
import ToastAlert from '../../../../components/ToastAlert';

import {
  localStoreKey,
  AssessmentFormValues,
  getPatientNameAndInitials,
} from './helpers';
import Question from './Question';
import useIsMobileDevice from '../../../../hooks/useIsMobileDevice';

const ProgramAssessmentForm: FC<{
  authedPatient: MePatientDataFragment;
  fullNameFromIntake?: string;
  programActivity: ProgramActivityDataFragment;
  breadcrumbLabel?: string;
  onComplete?: () => void;
  onClickBreadcrumb?: () => void;
}> = ({
  authedPatient,
  fullNameFromIntake,
  programActivity,
  onComplete,
  onClickBreadcrumb,
  breadcrumbLabel,
}) => {
  const { watch, setValue, control, register, handleSubmit, formState } =
    useForm<AssessmentFormValues>();

  const isMobileDevice = useIsMobileDevice();

  const patientName = getPatientNameAndInitials(
    authedPatient,
    fullNameFromIntake,
  );

  useFieldArray({
    control,
    name: 'answers',
    rules: { required: true },
  });

  const { clear: clearForm } = useFormPersist(localStoreKey(programActivity), {
    watch,
    setValue,
  });

  const { data, loading } = useProgramAssessmentQuery({
    variables: { programAssessmentId: programActivity?.programAssessment?.id },
    skip: !programActivity?.programAssessment?.id,
  });

  const programAssessment = data?.programAssessment;
  const programAssessmentSignatureUsers =
    data?.programAssessment?.programAssessmentSignatureUsers;
  const currentSigner = data?.programAssessment?.currentSigner;

  const formIsReadOnly =
    currentSigner &&
    currentSigner?.assessmentSignatureUser.signatureUserType &&
    currentSigner?.assessmentSignatureUser.signatureUserType !==
      SignatureUser.PatientUser;

  useEffect(() => {
    if (formIsReadOnly) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          level={'warning'}
          message="It's not your turn to complete this form"
        />
      ));
    }
  }, [formIsReadOnly]);

  const questions = programAssessment?.assessment
    ?.questions as ProgramAssessmentQuestion[];
  const answers = programAssessment?.answers as AssessmentAnswer[];

  const [completeProgramActivity] = useCompleteProgramActivityMutation();

  const onSubmit: SubmitHandler<AssessmentFormValues> = async (data) => {
    const assessmentAnswers: CompleteAssessmentAnswerInput[] =
      mapAssessmentAnswersToMutationInput(questions, data.answers);

    await completeProgramActivity({
      variables: {
        input: {
          programActivityId: programActivity.id,
          assessmentAnswers,
        },
      },
    });

    clearForm(); // Clear persisted form values
    onComplete?.();
  };

  if (loading) {
    return (
      <div className="mt-20 flex h-full w-full flex-row items-center justify-center">
        <Spinner />
      </div>
    );
  }

  // Identify signature and multi-signature questions
  const signatureQuestionIndexes = questions
    ?.map((question, idx) => {
      if (
        question.questionType === AssessmentQuestionType.Signature ||
        question.questionType === AssessmentQuestionType.MultiSignature
      ) {
        return idx;
      }
      return null;
    })
    .filter((idx) => idx !== null) as number[];

  const requiresTermsAndConditions = signatureQuestionIndexes?.length > 0;

  const handleSingleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = e.target.checked;
    signatureQuestionIndexes.forEach((idx) => {
      setValue(`answers.${idx}.signatureTermsAgreed`, isChecked);
    });
  };

  return (
    <div className="mt-12 flex flex-row justify-center px-6">
      <div className="mt-6 flex w-full flex-col lg:w-[800px]">
        {onClickBreadcrumb && breadcrumbLabel && (
          <div className="mb-2 flex flex-row">
            <button
              onClick={onClickBreadcrumb}
              className="mr-1 text-caption font-medium text-secondary-100 hover:underline"
            >
              {breadcrumbLabel}
            </button>
            <ChevronRightIcon className="relative top-[1px] mx-1 h-4 w-4 text-neutral-150" />
            <span className="text-caption text-green-150">
              {programAssessment?.assessment.name}
            </span>
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className="mb-4 font-serif text-subtitle"
            id="program-assessment-title"
          >
            {programAssessment?.assessment.name}
          </div>
          <div className="mb-6 whitespace-pre-wrap break-words text-body">
            {programAssessment?.assessment.instructions ??
              (!isAssessmentAForm(programAssessment?.assessment) &&
                programAssessment?.assessment.description)}
          </div>
          <div className="grid gap-y-6">
            {questions?.map((question, idx) => {
              const hasError = Boolean(formState.errors?.answers?.[idx]);
              const isStatementQuestion =
                isStatementAssessmentQuestion(question);

              return (
                <div key={`${idx}_${question.question}`}>
                  <div
                    className={classNames(
                      !isStatementQuestion &&
                        'rounded-lg border border-neutral-75 p-4',
                      {
                        'border-red-100': !isStatementQuestion && hasError,
                      },
                    )}
                  >
                    {programAssessment?.assessment.questionFrame && (
                      <div className="mb-2 text-caption text-neutral-125">
                        {programAssessment?.assessment.questionFrame}
                      </div>
                    )}
                    <Question
                      key={`${idx}_${question.question}`}
                      question={question as ProgramAssessmentQuestion}
                      answer={answers?.[idx]}
                      idx={idx}
                      register={register}
                      control={control}
                      watch={watch}
                      patientAssessmentSignatureUserId={
                        currentSigner?.assessmentSignatureUser.id
                      }
                      programAssessmentSignatureUsers={
                        programAssessmentSignatureUsers
                      }
                      currentSigner={currentSigner}
                      isMobileDevice={isMobileDevice}
                      patientName={patientName}
                      formIsReadOnly={formIsReadOnly}
                    />
                  </div>
                  {hasError && (
                    <div className="mt-2 text-body text-red-100">
                      {formState.errors.answers?.[idx]?.signatureName
                        ?.message ??
                        formState.errors.answers?.[idx]?.message ??
                        'Please answer above'}
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {!formIsReadOnly && (
            <div
              className={classNames(
                'mt-6 mb-12 flex',
                requiresTermsAndConditions
                  ? 'flex-col items-start justify-start md:flex-row md:items-center md:justify-between'
                  : 'justify-end',
              )}
            >
              {requiresTermsAndConditions && (
                <div className="flex w-full flex-col justify-start">
                  <Checkbox
                    id="single-signature-terms-agreed"
                    readOnly={formIsReadOnly}
                    labelContent={
                      <div className="xs:w-1/2 ml-2 md:w-full">
                        I agree to the electronic signature{' '}
                        <a
                          href="https://www.homecoming.health/terms-of-service"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-100"
                        >
                          terms of service
                        </a>
                        .
                      </div>
                    }
                    onChange={handleSingleCheckboxChange}
                    {...register('singleSignatureTermsAgreed', {
                      required: true,
                    })}
                  />
                  {formState.errors.singleSignatureTermsAgreed && (
                    <div className="xs:w-1/2 mt-3 text-caption text-red-100 md:w-full">
                      You must agree to the electronic signature terms of
                      service
                    </div>
                  )}
                </div>
              )}
              <Button
                className="mt-6 justify-end md:mt-0"
                type="submit"
                title="Submit"
                disabled={formIsReadOnly}
              />
            </div>
          )}
          <div className="h-16" />
        </form>
      </div>
    </div>
  );
};

export default ProgramAssessmentForm;
