import { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import {
  AnalyticsPage,
  trackProviderEvent,
  CommonAnalyticsEvent,
  ClientAnalyticsPage,
  trackPatientEvent,
} from '../../lib/analytics';

// This hook abstracts away page tracking, but it does require the developer to keep the
// routeToPageNameMap up to date. This is the least amount of developer overhead I could
// manage, and it perhaps has some benefits, like giving fine-grained control of which
// routes are tracked and what the human-readable names are (eg. we can just not track V1
// or other certain routes we might not be interested in).
export default function usePageViewTracking() {
  const [prevLocationPathname, setPrevLocationPathname] = useState<string>();
  const location = useLocation();

  useEffect(() => {
    // Only track page views if the pathname has changed
    if (prevLocationPathname !== location.pathname) {
      trackPageView(
        routeToPageNameMap,
        trackProviderEvent,
        location.pathname,
        prevLocationPathname,
      );
      trackPageView(
        clientRouteToPageNameMap,
        trackPatientEvent,
        location.pathname,
        prevLocationPathname,
      );
    }
    setPrevLocationPathname(location.pathname);
  }, [location]);
}

const trackPageView = (
  routeMap: Record<string, AnalyticsPage | ClientAnalyticsPage>,
  trackEventFunction: (
    eventName: CommonAnalyticsEvent,
    properties?: Record<string, unknown>,
  ) => void,
  pathname: string,
  previousPathname?: string,
) => {
  let currentPage;
  let previousPage;

  for (const [routePath, pageName] of Object.entries(routeMap)) {
    if (!currentPage) {
      const match = matchPath(routePath, pathname);
      if (match) {
        currentPage = { pageName, params: match.params };
      }
    }
    if (previousPathname && !previousPage) {
      const previousMatch = matchPath(routePath, previousPathname);
      if (previousMatch) {
        previousPage = { pageName };
      }
    }
  }

  if (currentPage) {
    const eventProperties = {
      page: currentPage.pageName,
      ...(previousPage && { previousPage: previousPage.pageName }),
      ...currentPage.params,
    };
    trackEventFunction(CommonAnalyticsEvent.PageViewed, eventProperties);
  }
};

const routeToPageNameMap: Record<string, AnalyticsPage> = {
  // Unauthed
  '/login': AnalyticsPage.Login,
  '/login/client': AnalyticsPage.Login,
  '/reset': AnalyticsPage.ResetPassword,
  '/join': AnalyticsPage.JoinSignup,
  '/sign-up': AnalyticsPage.SignUp,
  '/sign-up/:slug': AnalyticsPage.SignUp,
  '/p/:slug': AnalyticsPage.PublicStorefront,
  // V1 (Don't track)
  // V2
  '/intro': AnalyticsPage.Intro,
  '/subscribe': AnalyticsPage.Subscribe,
  '/': AnalyticsPage.Home,
  '/home': AnalyticsPage.Home,
  '/follow-ups/new': AnalyticsPage.FollowUpBuilder,
  '/:programId/follow-ups/new/': AnalyticsPage.FollowUpBuilder,
  '/clients': AnalyticsPage.ClientsTable,
  '/clients/invited': AnalyticsPage.ClientsTableInvited,
  '/clients/active': AnalyticsPage.ClientsTableActive,
  '/clients/archived': AnalyticsPage.ClientsTableArchived,
  '/clients/:programId': AnalyticsPage.ClientProfile,
  '/clients/:programId/messages': AnalyticsPage.ClientProfileMessagesTab,
  '/clients/:programId/follow-ups': AnalyticsPage.ClientProfileFollowUpsTab,
  '/clients/:programId/follow-ups/:programFollowUpId':
    AnalyticsPage.ClientProfileFollowUpsTab,
  '/clients/:programId/notes': AnalyticsPage.ClientProfileNotesTab,
  '/clients/:programId/notes/:programNoteId':
    AnalyticsPage.ClientProfileNotesTab,
  '/clients/:programId/activities': AnalyticsPage.ClientProfileActivitiesTab,
  '/clients/:programId/forms-and-assessments':
    AnalyticsPage.ClientProfileAssessmentsTab,
  '/clients/:programId/documents': AnalyticsPage.ClientProfileDocumentsTab,
  '/clients/:programId/account': AnalyticsPage.ClientProfileAccountTab,
  '/library': AnalyticsPage.Library,
  '/library/forms/new': AnalyticsPage.FormBuilder,
  '/library/forms/:assessmentId': AnalyticsPage.FormBuilder,
  '/calendar': AnalyticsPage.Calendar,
  '/settings': AnalyticsPage.SettingsAccount,
  '/settings/notifications': AnalyticsPage.SettingsNotifications,
  '/settings/billing': AnalyticsPage.SettingsSubscription,
  '/settings/integrations': AnalyticsPage.SettingsIntegrations,
  '/billing/success': AnalyticsPage.BillingSuccess,
  '/integrations/calendly': AnalyticsPage.CalendlyIntegration,
  '/integrations/stripe': AnalyticsPage.StripeIntegration,
  '/payments': AnalyticsPage.Payments,
  '/payments/new': AnalyticsPage.InvoiceBuilder,
  '/logout': AnalyticsPage.Logout,
  '/patient/follow-ups/:programFollowUpId': AnalyticsPage.ClientFollowUpView,
  '/your-page': AnalyticsPage.MyWebpage,
};

const clientRouteToPageNameMap: Record<string, ClientAnalyticsPage> = {
  '/login/client': ClientAnalyticsPage.ClientLogin,
  // File submission token auth
  '/file-submission': ClientAnalyticsPage.ClientPdfSubmission,
  // Login token auth
  '/client/intake': ClientAnalyticsPage.StorefrontIntake,
  '/client/messages': ClientAnalyticsPage.Messages,
  '/client/follow-ups/:programFollowUpId': ClientAnalyticsPage.FollowUpView,
};
