import { SvgIconComponent } from '../types/svgs';

const BellBadgeForm: SvgIconComponent = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <path
      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
      stroke="#394642"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="18.75"
      cy="4.25"
      r="3.5"
      fill="#00B20D"
      stroke="white"
      strokeWidth="1.5"
    />
  </svg>
);

export default BellBadgeForm;
