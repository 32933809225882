import { useState, useCallback, RefObject } from 'react';
import { CalendarViewType, ViewMode } from '../utils/enums';
import { logger } from '../../../../../lib/logger';
import FullCalendar from '@fullcalendar/react';

export const useCalendarViewState = (calendarRef: RefObject<FullCalendar>) => {
  const [viewType, setViewType] = useState<CalendarViewType>(
    CalendarViewType.CALENDARS,
  );
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.TIME_GRID_WEEK);
  const [selectedCalendarIds, setSelectedCalendarIds] = useState<string[]>([]);
  const [selectedResourceIds, setSelectedResourceIds] = useState<string[]>([]);

  const handleViewTypeChange = useCallback(
    (newViewType: CalendarViewType) => {
      logger.debug('📅 Calendar View Type Changed:', {
        from: viewType,
        to: newViewType,
      });

      setViewType(newViewType);

      // Update view mode based on view type
      if (newViewType === CalendarViewType.RESOURCES) {
        // Force FullCalendar to update by using the API
        calendarRef.current
          ?.getApi()
          .changeView(ViewMode.RESOURCE_TIMELINE_WEEK);
        setViewMode(ViewMode.RESOURCE_TIMELINE_WEEK);
      } else {
        calendarRef.current?.getApi().changeView(ViewMode.TIME_GRID_WEEK);
        setViewMode(ViewMode.TIME_GRID_WEEK);
      }
    },
    [viewType, calendarRef],
  );

  const handleCalendarSelect = useCallback((ids: string[]) => {
    logger.debug('📅 Selected Calendars Changed:', { ids });
    setSelectedCalendarIds(ids);
  }, []);

  const handleResourceSelect = useCallback((ids: string[]) => {
    logger.debug('📅 Selected Resources Changed:', { ids });
    setSelectedResourceIds(ids);
  }, []);

  return {
    viewType,
    viewMode,
    selectedCalendarIds,
    selectedResourceIds,
    handleViewTypeChange,
    setViewMode,
    handleCalendarSelect,
    handleResourceSelect,
  };
};
