import {
  AssessmentType,
  CompletedProgramAssessmentsDataQuery,
} from '../../../generated/graphql';
import { AssessmentCompletionDataTypename } from '../../lib/assessments';
import { getTimeStamp } from '../../lib/time';

export type CompletedProgramAssessment =
  CompletedProgramAssessmentsDataQuery['completedProgramAssessmentsByAssessment'][number];

export const getScoreProps = (
  completedAssessment: CompletedProgramAssessment | undefined,
  assessmentType: AssessmentType | undefined,
) => {
  type ScoreProps = {
    score?: number;
    categorization?: string;
    color?: string;
    hasPercentage?: boolean;
  };

  let primary: ScoreProps = {};
  let secondary: ScoreProps = {};

  if (
    completedAssessment?.completionData?.__typename ===
    AssessmentCompletionDataTypename.MultipleChoiceSumScoredAssessmentCompletionData
  ) {
    primary = {
      ...completedAssessment?.completionData,
      hasPercentage: assessmentType === AssessmentType.Meq30,
    };
  }

  if (
    completedAssessment?.completionData?.__typename ===
    AssessmentCompletionDataTypename.HADSAssessmentCompletionData
  ) {
    const { subscores } = completedAssessment?.completionData;
    primary = { ...subscores[0] };
    secondary = { ...subscores[1] };
  }

  return {
    primary,
    secondary,
  };
};

export const getCompletedAssessmentGraphData = (
  completedProgramAssessments: CompletedProgramAssessment[] | undefined,
) => {
  let hasSecondaryScore = false;

  const graphData = completedProgramAssessments
    ?.map((completedProgramAssessment, completedProgramAssessmentIndex) => {
      if (
        completedProgramAssessment?.completionData?.__typename ===
        AssessmentCompletionDataTypename.MultipleChoiceSumScoredAssessmentCompletionData
      ) {
        return {
          completedProgramAssessmentIndex,
          primaryScore: completedProgramAssessment?.completionData.score,
          primaryColor: completedProgramAssessment?.completionData.color,
          timestamp: getTimeStamp(
            completedProgramAssessment.completedAt,
            false,
            false,
            false,
          ),
        };
      }
      if (
        completedProgramAssessment?.completionData?.__typename ===
        AssessmentCompletionDataTypename.HADSAssessmentCompletionData
      ) {
        if (!hasSecondaryScore) hasSecondaryScore = true;
        const { subscores } = completedProgramAssessment?.completionData;
        const primary = { ...subscores[0] };
        const secondary = { ...subscores[1] };
        return {
          completedProgramAssessmentIndex,
          primaryScore: primary.score,
          primaryColor: primary.color,
          secondaryScore: secondary.score,
          secondaryColor: secondary.color,
          timestamp: getTimeStamp(
            completedProgramAssessment.completedAt,
            false,
            false,
            false,
          ),
        };
      }
      return {};
    })
    .slice(0, 7) // display the first 8 assessments for the 1st graphs iteration
    .reverse(); // display in chronological order

  let graphTitle = '';
  if (graphData?.length) graphTitle += `${graphData[0].timestamp}`;
  if (graphData?.length && graphData.length > 1)
    graphTitle += ` - ${graphData[graphData.length - 1].timestamp}`;

  return {
    graphData,
    hasSecondaryScore,
    graphTitle,
  };
};
