import { useMemo } from 'react';
import { useNylasCalendarsQuery } from '../../../../../generated/graphql';
import { logger } from '../../../../../lib/logger';

export const useCalendars = () => {
  const { data, loading, error, refetch } = useNylasCalendarsQuery();

  const calendars = useMemo(() => {
    logger.debug('📅 useCalendars recomputing memoized calendars:', {
      count: data?.nylasCalendars?.length,
    });
    return data?.nylasCalendars || [];
  }, [data?.nylasCalendars]);

  return {
    calendars,
    loading,
    error,
    refetch,
  };
};
