import { useState, useCallback } from 'react';
import { customToast } from '../ToastAlert/customToast';

const MIN_POSITION = 0;
const MAX_POSITION = 100;

interface UseBannerDragProps {
  initialPosition: number;
  onPositionChange?: (position: number) => Promise<void>;
  isEditable: boolean;
}

export const useBannerDrag = ({
  initialPosition,
  onPositionChange,
  isEditable,
}: UseBannerDragProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({
    y: 0,
    position: initialPosition,
  });
  const [bannerPosition, setBannerPosition] = useState(initialPosition);

  const handleBannerMouseDown = useCallback(
    (e: React.MouseEvent) => {
      if (!isEditable) return;
      setIsDragging(true);
      setDragStart({ y: e.clientY, position: bannerPosition });
    },
    [isEditable, bannerPosition],
  );

  const handleBannerMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (!isDragging || !e.currentTarget) return;
      const { height } = (
        e.currentTarget as HTMLElement
      ).getBoundingClientRect();
      const deltaY = dragStart.y - e.clientY;
      const deltaPercent = (deltaY / height) * MAX_POSITION;
      const newPosition = Math.max(
        MIN_POSITION,
        Math.min(MAX_POSITION, dragStart.position + deltaPercent),
      );
      setBannerPosition(newPosition);
    },
    [isDragging, dragStart],
  );

  const handleBannerMouseUp = useCallback(async () => {
    if (!isDragging) return;
    setIsDragging(false);
    try {
      await onPositionChange?.(bannerPosition);
      customToast.success('Banner photo position successfully moved.');
    } catch (err) {
      customToast.warning('Failed to move banner position. Try again later.');
    }
  }, [isDragging, bannerPosition, onPositionChange]);

  return {
    bannerPosition,
    handleBannerMouseDown,
    handleBannerMouseMove,
    handleBannerMouseUp,
  };
};
