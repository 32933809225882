import classNames from 'classnames';
import { FC } from 'react';

import {
  AssessmentType,
  CompletedProgramAssessmentsDataQuery,
} from '../../../../generated/graphql';
import ArrowRightLong from '../../../svgs/ArrowRightLong';
import Button from '../../Button';
import SlideoverSection from '../../SlideoverSection';
import InterpretationPill from './InterpretationPill';

const InterpretationSection: FC<{
  assessment: CompletedProgramAssessmentsDataQuery['assessment'] | undefined;
}> = ({ assessment }) => {
  const hasProviderInterpretation = Boolean(assessment?.providerInterpretation);
  return (
    <SlideoverSection title="Interpretation">
      {hasProviderInterpretation && (
        <span className="text-small-caption">
          {assessment?.providerInterpretation}
        </span>
      )}
      {assessment?.scoreRanges && (
        <div
          className={classNames(
            'grid grid-cols-2 gap-3',
            hasProviderInterpretation && 'mt-4',
          )}
        >
          {assessment?.scoreRanges?.map((scoreRange, scoreRangeIndex) => {
            return (
              <InterpretationPill
                key={scoreRangeIndex}
                color={scoreRange.color}
                min={scoreRange.min}
                max={scoreRange.max}
                categorization={scoreRange.categorization}
                hasPercentage={assessment?.type === AssessmentType.Meq30}
              />
            );
          })}
        </div>
      )}
      {assessment?.shortName && assessment?.moreInfoLink && (
        <Button
          onClick={() =>
            // `window.open` takes a special URL type - string | URL | undefined
            typeof assessment.moreInfoLink === 'string' &&
            window.open(assessment.moreInfoLink, '_blank')
          }
          theme="secondary"
          size="small"
          className="mt-4 text-caption text-secondary-150"
          title={`Learn more about the ${assessment?.shortName}`}
          iconPosition="right"
          IconComponent={ArrowRightLong}
          iconClassName="text-secondary-100"
          noBackground
          noOutline
        />
      )}
    </SlideoverSection>
  );
};

export default InterpretationSection;
