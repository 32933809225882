import React from 'react';
import Linkifier from '../Linkifier';

interface ProviderNoteProps {
  isSmallStyle: boolean;
  providerNote: string;
}

export const ProviderNote: React.FC<ProviderNoteProps> = ({
  isSmallStyle,
  providerNote,
}) => {
  if (isSmallStyle || !providerNote) return null;

  return (
    <div className="mt-4 whitespace-pre-wrap break-words text-caption text-neutral-125">
      <Linkifier text={providerNote} />
    </div>
  );
};
