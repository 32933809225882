import * as Sentry from '@sentry/react';
import SentryHelpers from '../lib/sentry';

type LogLevel = 'debug' | 'info' | 'warn' | 'error';
type LogData = Record<string, any>;

const LOG_LEVELS: LogLevel[] = ['debug', 'info', 'warn', 'error'];
const CURRENT_LOG_LEVEL =
  (process.env.REACT_APP_LOG_LEVEL as LogLevel) || 'warn';

const shouldLog = (level: LogLevel): boolean => {
  return LOG_LEVELS.indexOf(level) >= LOG_LEVELS.indexOf(CURRENT_LOG_LEVEL);
};

const debug = (...args: any[]) => {
  if (shouldLog('debug')) {
    console.debug(...args);
  }
};

const info = (...args: any[]) => {
  if (shouldLog('info')) {
    console.info(...args);
  }
};

const warn = (...args: any[]) => {
  if (shouldLog('warn')) {
    console.warn(...args);
    SentryHelpers.safeSentryCall(() => {
      Sentry.addBreadcrumb({
        category: 'warning',
        message: args.join(' '),
        level: 'warning',
      });
    });
  }
};

const error = (error: Error | string, context?: LogData) => {
  if (shouldLog('error')) {
    console.error(error, context);
    SentryHelpers.safeSentryCall(() => {
      if (error instanceof Error) {
        Sentry.captureException(error, { extra: context });
      } else {
        Sentry.captureMessage(error, {
          level: 'error',
          extra: context,
        });
      }
    });
  }
};

const trackEvent = (eventName: string, data?: LogData) => {
  if (shouldLog('info')) {
    console.log(`📊 [Event] ${eventName}:`, data);
  }
  SentryHelpers.safeSentryCall(() => {
    Sentry.addBreadcrumb({
      category: 'event',
      message: eventName,
      data,
      level: 'info',
    });
  });
};

export const logger = {
  debug,
  info,
  warn,
  error,
  trackEvent,
};
