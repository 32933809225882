import { FC } from 'react';
import { LOGO_CONFIGS } from '../constants';
import { LogoType } from '../types';
import { LogoUploadField } from './LogoUploadField';

interface LogoUploadSectionProps {
  title: string;
  logoType: LogoType;
  logoPreview: string | undefined;
  onUpload: (files: FileList | null, logoType: LogoType) => Promise<void>;
  onDelete: (logoType: LogoType) => Promise<void>;
}

export const LogoUploadSection: FC<LogoUploadSectionProps> = ({
  title,
  logoType,
  logoPreview,
  onUpload,
  onDelete,
}) => {
  return (
    <>
      <div className="mb-1 text-caption font-medium text-green-150">
        {title}
      </div>
      <div className="mb-3 text-small-caption text-neutral-125">
        {LOGO_CONFIGS[logoType].description}
      </div>
      <LogoUploadField
        logoType={logoType}
        logoPreview={logoPreview}
        onUpload={onUpload}
        onDelete={onDelete}
        altText={LOGO_CONFIGS[logoType].altText}
      />
    </>
  );
};
