import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CompleteProgramAssessment from '../CompleteProgramAssessment';
import { usePatientAuth } from '../../../../contexts/PatientAuthContext';
import { useProgramActivityQuery } from '../../../../generated/graphql';
import Spinner from '../../../svgs/Spinner';

const CompletePatientForm: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { authedPatient } = usePatientAuth();

  const programActivityId = params.programActivityId;

  const { data: programActivityData, loading: programActivityLoading } =
    useProgramActivityQuery({
      variables: {
        programActivityId,
      },
      skip: Boolean(!authedPatient?.programInstanceId || !programActivityId),
    });

  const programActivity = programActivityData?.programActivity;

  return (
    <>
      {programActivityLoading ? (
        <Spinner className="mt-16 h-10 w-10" />
      ) : (
        <CompleteProgramAssessment
          authedPatient={authedPatient}
          assessmentProgramActivity={programActivity}
          onBack={() => navigate(-1)}
          onComplete={async () => {
            navigate(-1);
          }}
        />
      )}
    </>
  );
};

export default CompletePatientForm;
