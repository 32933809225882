import { useEffect } from 'react';
import { logger } from '../../../../../lib/logger';

interface UseAutoRefreshOptions {
  seconds?: number;
  enabled?: boolean;
}

const setupAutoRefresh = (
  callback: () => void | Promise<void>,
  interval: number,
  enabled: boolean,
) => {
  if (!enabled) return;

  let intervalId: ReturnType<typeof setInterval>;
  let lastUpdateTime = Date.now();

  const wrappedCallback = async () => {
    logger.debug('🔄 Auto Refresh Executing:', {
      interval,
      enabled,
      status: 'executing',
    });
    await callback();
    lastUpdateTime = Date.now();
  };

  const handleVisibilityChange = () => {
    if (document.hidden) {
      logger.debug('🔄 Auto Refresh Paused:', {
        interval,
        enabled,
        status: 'hidden',
      });
      // Clear interval when page is hidden
      if (intervalId) clearInterval(intervalId);
    } else {
      // Check if enough time has passed since last update
      const timeSinceLastUpdate = Date.now() - lastUpdateTime;
      if (timeSinceLastUpdate >= interval) {
        logger.debug('🔄 Auto Refresh Immediate Update:', {
          timeSinceLastUpdate,
          interval,
          status: 'stale',
        });
        wrappedCallback();
      }

      logger.debug('🔄 Auto Refresh Started:', {
        interval,
        enabled,
        status: 'visible',
      });
      // Start interval when page becomes visible
      intervalId = setInterval(wrappedCallback, interval);
    }
  };

  // Initial setup
  if (!document.hidden) {
    logger.debug('🔄 Auto Refresh Initialized:', {
      interval,
      enabled,
      status: 'visible',
    });
    intervalId = setInterval(wrappedCallback, interval);
  }

  // Add visibility change listener
  document.addEventListener('visibilitychange', handleVisibilityChange);

  // Cleanup function
  return () => {
    if (intervalId) clearInterval(intervalId);
    document.removeEventListener('visibilitychange', handleVisibilityChange);
    logger.debug('🔄 Auto Refresh Cleanup:', {
      interval,
      enabled,
      status: 'destroyed',
    });
  };
};

export const useAutoRefresh = (
  callback: () => void | Promise<void>,
  options: UseAutoRefreshOptions = {},
) => {
  const { seconds = 30, enabled = true } = options;
  const interval = seconds * 1000;

  useEffect(
    () => setupAutoRefresh(callback, interval, enabled),
    [callback, interval, enabled],
  );
};
