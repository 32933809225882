import classNames from 'classnames';

interface BodySectionLabelProps {
  text: string;
  className?: string;
}

const BodySectionLabel: React.FC<BodySectionLabelProps> = ({
  text,
  className,
}) => {
  return (
    <div
      className={classNames('mb-4 text-caption text-neutral-125', className)}
    >
      {text}
    </div>
  );
};

export default BodySectionLabel;
