import { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import {
  useNylasCalendarEventsQuery,
  useNylasPrimaryCalendarQuery,
  useDeleteNylasCalendarEventMutation,
  useUpdateNylasCalendarMutation,
  NylasCalendarEventDataFragment,
} from '../../../../../generated/graphql';
import { customToast } from '../../../../components/ToastAlert/customToast';
import { DEFAULT_CALENDAR_HEX_COLOR } from '../../../../lib/colors';
import { whenToCalendarEvent } from '../utils/whenHelpers';

export const useCalendarEvents = (isNylasConnected: boolean) => {
  const [currentDate, setCurrentDate] = useState(DateTime.now());

  const {
    data: nylasPrimaryCalendarData,
    loading: isLoadingNylasPrimaryCalendar,
    refetch: refetchNylasPrimaryCalendar,
  } = useNylasPrimaryCalendarQuery({
    skip: !isNylasConnected,
  });

  const {
    data: nylasCalendarEventsData,
    loading: isLoadingNylasCalendarEvents,
    refetch: refetchNylasCalendarEvents,
    networkStatus: calendarEventsNetworkStatus,
  } = useNylasCalendarEventsQuery({
    variables: {
      input: {
        startTime: currentDate.startOf('week'),
        endTime: currentDate.endOf('week'),
      },
    },
    skip: !isNylasConnected,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const [updateNylasCalendar] = useUpdateNylasCalendarMutation();
  const [deleteNylasCalendarEvent, { loading: isDeletingEvent }] =
    useDeleteNylasCalendarEventMutation();

  const handleCalendarColorChange = async (
    calendarId: string,
    hexColor: string,
  ) => {
    try {
      const response = await updateNylasCalendar({
        variables: {
          input: { calendarId, hexColor },
        },
      });

      if (response.data?.updateNylasCalendar) {
        await refetchNylasPrimaryCalendar();
        customToast.success('Calendar color updated');
      }
    } catch (error) {
      customToast.error('Error updating calendar color');
    }
  };

  const handleDeleteEvent = async (event: NylasCalendarEventDataFragment) => {
    try {
      const response = await deleteNylasCalendarEvent({
        variables: {
          input: {
            eventId: event.id,
            calendarId: event.calendarId,
          },
        },
      });

      if (response.data?.deleteNylasCalendarEvent) {
        await refetchNylasCalendarEvents();
        customToast.success('Event deleted');
        return true;
      }
    } catch (error) {
      customToast.error('Error deleting event');
    }
    return false;
  };

  const transformEventsForCalendar = () => {
    return (
      nylasCalendarEventsData?.nylasCalendarEvents
        ?.map((event) => {
          const baseEventProps = {
            id: event.id,
            calendarId: nylasPrimaryCalendarData?.nylasPrimaryCalendar?.id, // TODO: Expose this with the event response
            title: event.title,
            backgroundColor: getBackgroundColor(event),
            extendedProps: event,
            // Enable FullCalendar editing
            editable: !event.readOnly,
            startEditable: !event.readOnly,
            durationEditable: !event.readOnly,
          };

          return whenToCalendarEvent(event.when, baseEventProps);
        })
        .filter(Boolean) || []
    );
  };

  const getBackgroundColor = (event) => {
    const defaultColor =
      nylasPrimaryCalendarData?.nylasPrimaryCalendar?.hexColor ||
      DEFAULT_CALENDAR_HEX_COLOR;
    return defaultColor;
  };

  const events = useMemo(
    () => transformEventsForCalendar(),
    [
      nylasCalendarEventsData?.nylasCalendarEvents,
      nylasPrimaryCalendarData?.nylasPrimaryCalendar?.id,
      nylasPrimaryCalendarData?.nylasPrimaryCalendar?.hexColor,
    ],
  );

  return {
    currentDate,
    setCurrentDate,
    events,
    isLoading:
      isLoadingNylasPrimaryCalendar ||
      isLoadingNylasCalendarEvents ||
      calendarEventsNetworkStatus === 4, // Refetching
    isDeletingEvent,
    primaryCalendar: nylasPrimaryCalendarData?.nylasPrimaryCalendar,
    handleCalendarColorChange,
    handleDeleteEvent,
    refetchEvents: refetchNylasCalendarEvents,
  };
};
