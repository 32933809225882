import { Navigate, Outlet, useLocation } from 'react-router-dom';

import useFixPage from '../../hooks/useFixPage';

import PageContainer from '../../components/Containers/PageContainer';
import Tabs from '../../components/Tabs';
import PageTitle from '../../components/PageTitle';
import { useAuth } from '../../../contexts/AuthContext';
import { isProviderUserOwnerOrAdminAuthorized } from '../../../lib/auth';
import classNames from 'classnames';

const Settings = () => {
  useFixPage();

  const { authedProviderUser, showUpgradeBanner } = useAuth();
  const location = useLocation();

  const ownerAdminRoutes = [
    'team',
    'billing',
    'integrations',
    'tags',
    'appearance',
  ];

  if (
    !isProviderUserOwnerOrAdminAuthorized(authedProviderUser) &&
    ownerAdminRoutes.some((route) => location.pathname.includes(route))
  ) {
    return <Navigate to="/settings" state={{ from: location }} replace />;
  }

  const tabList = [
    {
      route: '.',
      name: 'Account',
    },
    {
      route: 'notifications',
      name: 'Notifications',
    },
    ...(isProviderUserOwnerOrAdminAuthorized(authedProviderUser)
      ? [
          {
            route: 'team',
            name: 'Team',
          },
          {
            route: 'billing',
            name: 'Subscription',
          },
          {
            route: 'integrations',
            name: 'Integrations',
          },
          {
            route: 'tags',
            name: 'Tags',
          },
          {
            route: 'appearance',
            name: 'Appearance',
          },
        ]
      : []),
  ];

  return (
    <PageContainer
      containerClassName={classNames(
        'pb-0',
        showUpgradeBanner
          ? 'h-[calc(100vh-var(--top-nav-height)-var(--upgrade-banner-height))] overflow-y-hidden'
          : 'h-[calc(100vh-var(--top-nav-height))] overflow-y-hidden',
      )}
    >
      <PageTitle title="Settings" />
      <Tabs tabList={tabList} fullWidth />
      <div className="h-full w-full overflow-y-auto">
        <Outlet />
      </div>
    </PageContainer>
  );
};

export default Settings;
