import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { LightningBoltIcon, PhoneIcon } from '@heroicons/react/outline';

import { useGlobalModalsContext } from '../../../contexts/GlobalModalsContext';

import TopNav from '../TopNav';
import UpdateButton from '../UpdateButton';
import SettingsMenu from '../SettingsMenu';
import NotificationsPanel from '../NotificationsPanel';
import {
  AnalyticsComponent,
  CommonAnalyticsEvent,
  trackProviderEvent,
} from '../../../lib/analytics';
import ClockTrial from '../../svgs/ClockTrial';
import { daysUntil } from '../../lib/time';
import { stripLeadingSlash } from '../../lib/string';
import { pluralize } from '../../lib/copy';
import { openBookACallLink } from '../../lib/url';
import { useAuth } from '../../../contexts/AuthContext';
import UpgradeBanner from './UpgradeBanner';
import MyPageButton from './MyPageButton';
import { isProviderUserOwnerOrAdminAuthorized } from '../../../lib/auth';
import HeaderLogo from '../HeaderLogo';

const TRIAL_ENDING_PERIOD_DAYS = 7;

const MainHeader: React.FC = () => {
  const { authedProviderUser, showUpgradeBanner } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const providerName = authedProviderUser?.name;
  const providerEmail = authedProviderUser?.email;
  const providerUserLogo = authedProviderUser?.squareLogoMedia?.url;

  const hasBeenSubscribed = authedProviderUser?.hasBeenSubscribed ?? false;
  const isTrialing = authedProviderUser?.isTrialing ?? false;
  const trialEndsAt =
    isTrialing && authedProviderUser ? authedProviderUser.trialEndsAt : null;
  const trialDaysLeft = trialEndsAt ? daysUntil(trialEndsAt) : null;
  const isTrialEnding = trialDaysLeft
    ? trialDaysLeft <= TRIAL_ENDING_PERIOD_DAYS
    : null;

  const isPartner = authedProviderUser?.signUpConfig?.slug != null;

  const updateButtonThemeColor =
    hasBeenSubscribed || !isTrialing
      ? 'orange'
      : isTrialEnding
      ? 'red'
      : 'blue';
  const updateButtonText = hasBeenSubscribed
    ? 'Reactivate'
    : isTrialing && trialDaysLeft
    ? `${pluralize(trialDaysLeft, 'day', 'days')} left in trial`
    : 'Subscribe';
  const updateButtonIconComponent = isTrialing ? ClockTrial : LightningBoltIcon;

  const { showUpgradeModal } = useGlobalModalsContext();

  const onUpgradeClick = () => {
    if (isPartner) {
      showUpgradeModal(AnalyticsComponent.MainHeader);
    } else {
      navigate('/subscribe', {
        state: {
          stripeCheckoutPortalWebRedirectPath: stripLeadingSlash(
            location.pathname,
          ),
        },
      });
    }
    trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
      buttonName: updateButtonText,
      buttonType: 'Subscribe',
      originComponent: AnalyticsComponent.MainHeader,
    });
  };

  const isCommunityActive = location.pathname.startsWith('/community');

  return (
    <header className="fixed top-0 z-[41] flex w-screen flex-col">
      <div
        className={classNames(
          'flex h-[var(--top-nav-height)] w-full flex-row items-center justify-between px-20',
          'border-b border-neutral-50 bg-neutral-25 text-caption font-medium text-green-150',
        )}
      >
        {/* Left-hand nav */}
        <div className="flex flex-row items-center justify-start">
          <HeaderLogo
            hasLoadedUser={Boolean(authedProviderUser?.createdAt)}
            customLogoUrl={providerUserLogo}
            showDefaultLogo={!!authedProviderUser.createdAt}
          />
          <TopNav />
        </div>

        {/* Right-hand nav */}
        <div className="flex flex-row items-center justify-end">
          <div className="flex items-center">
            {isTrialing && (
              <UpdateButton
                onClick={onUpgradeClick}
                IconComponent={updateButtonIconComponent}
                theme={updateButtonThemeColor}
                text={updateButtonText}
              />
            )}
            {!isCommunityActive && (
              <UpdateButton
                onClick={openBookACallLink}
                IconComponent={PhoneIcon}
                theme="neutral"
                text="Book a call with us"
              />
            )}
            {isProviderUserOwnerOrAdminAuthorized(authedProviderUser) && (
              <MyPageButton />
            )}
            {!isCommunityActive && <NotificationsPanel />}
            <SettingsMenu
              name={providerName}
              email={providerEmail}
              imageUrl={authedProviderUser?.profileImageMedia?.url}
              externalMode={isCommunityActive}
            />
          </div>
        </div>
      </div>
      {showUpgradeBanner && <UpgradeBanner />}
    </header>
  );
};

export default MainHeader;
