import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  MailIcon,
  ChatIcon,
  UserIcon,
  InboxIcon,
  PlusSmIcon,
} from '@heroicons/react/outline';

import { Transition } from '@headlessui/react';

import {
  Feature,
  PatientDataPermission,
  ClientSessionReviewStatus,
  useGetClientSessionsCountQuery,
  useUninvitedEventInviteesQuery,
  useUpcomingScheduledEventsQuery,
  useMeProviderProgramTagsWithCountQuery,
  useProviderUserSignableFormsCountQuery,
} from '../../../generated/graphql';

import { useAuth } from '../../../contexts/AuthContext';
import { useFeatureFlags } from '../../../contexts/FeatureFlagContext';
import { useGlobalModalsContext } from '../../../contexts/GlobalModalsContext';

import {
  HAS_DISMISSED_BOOK_A_CALL,
  HAS_DISMISSED_PAYMENTS_SETUP,
  HAS_DISMISSED_SCHEDULE_SETUP,
  HAS_DISMISSED_CALENDAR_SETUP,
  HAS_DISMISSED_STOREFRONT_SETUP,
  HAS_DISMISSED_ADD_CALENDLY_CLIENTS_SETUP,
  HAS_DISMISSED_APPEARANCE_SETUP,
} from '../../lib/storage';

import { openBookACallLink } from '../../lib/url';
import { AnalyticsPage } from '../../../lib/analytics';
import { defaultTransitionProps } from '../../lib/animation';
import { isProviderUserOwnerOrAdminAuthorized } from '../../../lib/auth';

import BanknotesIcon from '../../svgs/BanknotesIcon';
import BellBadgeForm from '../../svgs/BellBadgeForm';
import HomeIllustration from '../../svgs/HomeIllustration';
import BellBadgeCalendar from '../../svgs/BellBadgeCalendar';
import IllustrationFaces from '../../svgs/IllustrationFaces';
import IllustrationCalendar from '../../svgs/IllustrationCalendar';

import { PatientRow } from '../../types/PatientManagement';

import useIncrementNowDate from '../../hooks/useIncrementNowDate';
import useLocalStorageValue from '../../hooks/useLocalStorageValue';

import Button from '../../components/Button';
import UpcomingEvent from '../../components/UpcomingEvent';
import AddPDFModal from '../../components/Modals/AddPDFModal';
import AddLinkModal from '../../components/Modals/AddLinkModal';
import AddToLibraryMenu from '../../components/AddToLibraryMenu';
import AddAudioModal from '../../components/Modals/AddAudioModal';
import AddImageModal from '../../components/Modals/AddImageModal';
import PageContainer from '../../components/Containers/PageContainer';
import AddPatientsModal from '../../components/Modals/AddPatientsModal';
import AddOrEditTextModal from '../../components/Modals/AddOrEditTextModal';

import InboxView from './InboxView';
import { QuickActionProps } from './QuickAction';
import SelectPatientModal from './SelectPatientModal';
import UnsignedFormsView from './UnsignedFormsView';
import ClientSessionsView from './ClientSessionsView';
import { DEFAULT_COLORS } from '../Settings/AppearanceSection/constants';
import classNames from 'classnames';
import IllustrationBanknotes from '../../svgs/IllustrationBanknotes';
import IllustrationPencil from '../../svgs/IllustrationPencil';
import IllustrationBook from '../../svgs/IllustrationBook';
import IllustrationPhone from '../../svgs/IllustrationPhone';
import IllustrationSwirls from '../../svgs/ServiceIcons/IllustrationSwirls';

enum ViewMode {
  Inbox = 'Inbox',
  UnsignedForms = 'UnsignedForms',
  ClientSessions = 'ClientSessions',
}

const Home = () => {
  const navigate = useNavigate();

  const {
    authedProviderUser,
    willHitClientLimit,
    refreshAuthedProviderUserNotifications,
  } = useAuth();

  const now = useIncrementNowDate();
  const { isFeatureEnabled } = useFeatureFlags();
  const { showUpgradeModal } = useGlobalModalsContext();
  const isCalendarSyncEnabled = isFeatureEnabled(Feature.CalendarSync);
  const isClientSessionsEnabled = isFeatureEnabled(Feature.ClientSessions);

  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Inbox);

  const isOwnerOrAdmin =
    isProviderUserOwnerOrAdminAuthorized(authedProviderUser);

  const hasNylasIntegration = Boolean(authedProviderUser?.hasNylasIntegration);

  const hasCalendlyIntegration = Boolean(
    authedProviderUser?.provider.calendlyIntegration,
  );

  const hasStripeIntegration = Boolean(
    authedProviderUser?.provider.stripeAccountId,
  );

  const { data: upcomingEventsData, loading: upcomingEventsLoading } =
    useUpcomingScheduledEventsQuery({
      variables: {
        input: {
          count: 3,
          minStartTime: now,
          isCanceled: false,
        },
      },
      skip: !hasCalendlyIntegration,
    });

  const upcomingEvents = hasCalendlyIntegration
    ? upcomingEventsData?.scheduledEvents
    : [];

  const hasUpcomingEvents = Boolean(upcomingEvents?.length);

  const { data: uninvitedEventInviteesData } = useUninvitedEventInviteesQuery();

  const { data: signableFormsCountData, loading: isLoadingSignableFormsCount } =
    useProviderUserSignableFormsCountQuery();
  const unsignedFormsCount = isLoadingSignableFormsCount
    ? 0
    : signableFormsCountData?.providerUserSignableFormsCount ?? 0;

  const { data: pendingClientSessionsCountData } =
    useGetClientSessionsCountQuery({
      variables: {
        input: { reviewStatus: ClientSessionReviewStatus.PendingReview },
      },
    });

  const pendingClientSessionsCount =
    pendingClientSessionsCountData?.clientSessionsCount ?? 0;

  const uniqueInvitees: PatientRow[] | undefined =
    uninvitedEventInviteesData?.uninvitedEventInvitees.map((invitee) => ({
      name: `${invitee.firstName} ${invitee.lastName}`,
      email: invitee.email,
    }));

  const [showPrefilledPatientsList, setShowPrefilledPatientsList] =
    useState(false);

  const [hasDismissedBookACallStep, setHasDismissedBookACallStep] =
    useLocalStorageValue<boolean>(
      HAS_DISMISSED_BOOK_A_CALL,
      authedProviderUser.email,
    );

  const [hasDismissedCalendarStep, setHasDismissedCalendarStep] =
    useLocalStorageValue<boolean>(
      HAS_DISMISSED_CALENDAR_SETUP,
      authedProviderUser.email,
    );

  const [hasDismissedScheduleStep, setHasDismissedScheduleStep] =
    useLocalStorageValue<boolean>(
      HAS_DISMISSED_SCHEDULE_SETUP,
      authedProviderUser.email,
    );

  const [hasDismissedPaymentsStep, setHasDismissedPaymentsStep] =
    useLocalStorageValue<boolean>(
      HAS_DISMISSED_PAYMENTS_SETUP,
      authedProviderUser.email,
    );

  const [hasDismissedCalendlyClientsStep, setHasDismissedCalendlyClientsStep] =
    useLocalStorageValue<boolean>(
      HAS_DISMISSED_ADD_CALENDLY_CLIENTS_SETUP,
      authedProviderUser.email,
    );

  const [hasDismissedStorefrontStep, setHasDismissedStorefrontStep] =
    useLocalStorageValue<boolean>(
      HAS_DISMISSED_STOREFRONT_SETUP,
      authedProviderUser.email,
    );

  const [hasDismissedServiceStep, setHasDismissedServiceStep] =
    useLocalStorageValue<boolean>(
      HAS_DISMISSED_STOREFRONT_SETUP,
      authedProviderUser.email,
    );

  const [hasDismissedAppearanceStep, setHasDismissedAppearanceStep] =
    useLocalStorageValue<boolean>(
      HAS_DISMISSED_APPEARANCE_SETUP,
      authedProviderUser.email,
    );

  const showBookACall = Boolean(
    authedProviderUser && !hasDismissedBookACallStep,
  );

  const showCalendarSetup =
    authedProviderUser &&
    isOwnerOrAdmin &&
    !hasNylasIntegration &&
    isCalendarSyncEnabled &&
    !hasDismissedCalendarStep;

  const showSchedulingSetup =
    authedProviderUser &&
    isOwnerOrAdmin &&
    !hasCalendlyIntegration &&
    !hasDismissedScheduleStep;

  const showPaymentsSetup =
    authedProviderUser &&
    isOwnerOrAdmin &&
    !hasStripeIntegration &&
    !hasDismissedPaymentsStep;

  const showCalendlyClientsSetup = Boolean(
    authedProviderUser &&
      !hasCalendlyIntegration &&
      !hasDismissedCalendlyClientsStep,
  );

  const showSetupMyPage = Boolean(
    authedProviderUser &&
      isOwnerOrAdmin &&
      !authedProviderUser.storefrontSlug &&
      !hasDismissedStorefrontStep,
  );

  const showSetupAService = Boolean(
    authedProviderUser &&
      isOwnerOrAdmin &&
      !authedProviderUser.hasCreatedAProviderService &&
      !hasDismissedServiceStep,
  );

  const hasDefaultAppearance =
    (authedProviderUser?.primaryColor === DEFAULT_COLORS.PRIMARY ||
      !authedProviderUser?.primaryColor) &&
    (authedProviderUser?.secondaryColor === DEFAULT_COLORS.SECONDARY ||
      !authedProviderUser?.secondaryColor) &&
    authedProviderUser?.secondaryColor === DEFAULT_COLORS.SECONDARY &&
    !authedProviderUser?.squareLogoMedia?.url;

  const showSetupMyAppearance = Boolean(
    authedProviderUser &&
      isOwnerOrAdmin &&
      hasDefaultAppearance &&
      !hasDismissedAppearanceStep,
  );

  const onboardingSteps: QuickActionProps[] = useMemo(
    () => [
      ...(showBookACall
        ? [
            {
              title: 'Book a call with us',
              description: 'Get personal guidance from the Homecoming team',
              IconComponent: IllustrationPhone,
              iconClassName: 'text-green-125',
              buttonTitle: 'Book a Call',
              onClick: openBookACallLink,
              onDismiss: () => setHasDismissedBookACallStep(true),
            },
          ]
        : []),
      ...(showCalendarSetup
        ? [
            {
              title: 'Sync your calendar',
              description: 'Connect your Google calendar with Homecoming',
              IconComponent: IllustrationCalendar,
              iconClassName: 'text-blue-125',
              buttonTitle: 'Connect calendar',
              onClick: () => {
                navigate('/integrations/calendar');
              },
              onDismiss: () => setHasDismissedCalendarStep(true),
            },
          ]
        : []),
      ...(showSchedulingSetup
        ? [
            {
              title: 'Set up client bookings',
              description:
                'Connect your Calendly account to allow clients to book appointments',
              IconComponent: IllustrationCalendar,
              iconClassName: 'text-blue-100',
              buttonTitle: 'Connect Calendly',
              onClick: () => {
                navigate('/integrations/calendly');
              },
              onDismiss: () => setHasDismissedScheduleStep(true),
            },
          ]
        : []),
      ...(showPaymentsSetup
        ? [
            {
              title: 'Set up payments',
              description: 'Connect your Stripe account with Homecoming',
              IconComponent: IllustrationBanknotes,
              iconClassName: 'text-green-100',
              buttonTitle: 'Connect Stripe',
              onClick: () => {
                navigate('/integrations/stripe');
              },
              onDismiss: () => setHasDismissedPaymentsStep(true),
            },
          ]
        : []),
      ...(showSetupMyAppearance
        ? [
            {
              title: 'Change the appearance of Homecoming',
              description:
                'Customize the Homecoming experience for you and your clients',
              IconComponent: IllustrationPencil,
              iconClassName: 'text-purple-100',
              buttonTitle: 'Change appearance',
              onClick: () => {
                navigate('/settings/appearance');
              },
              onDismiss: () => setHasDismissedAppearanceStep(true),
            },
          ]
        : []),
      ...(showCalendlyClientsSetup
        ? [
            {
              title: 'Import clients',
              description:
                'Some clients from Calendly are not on Homecoming, would you like to add them?',
              IconComponent: IllustrationFaces,
              iconClassName: 'text-green-125',
              onClick: () => {
                setShowPrefilledPatientsList(true);
                addPatients();
              },
              onDismiss: () => setHasDismissedCalendlyClientsStep(true),
            },
          ]
        : []),
      ...(showSetupMyPage
        ? [
            {
              title: 'Create your Homecoming page',
              description:
                'Customize your webpage and start accepting new clients',
              IconComponent: IllustrationSwirls,
              iconClassName: 'text-primary-150',
              buttonTitle: 'Set up webpage',
              onClick: () => {
                navigate('/your-page');
              },
              onDismiss: () => setHasDismissedStorefrontStep(true),
            },
          ]
        : []),
      ...(showSetupAService
        ? [
            {
              title: 'Create a service page',
              description:
                'Intake clients with forms, session bookings, payments, and more.',
              IconComponent: IllustrationBook,
              iconClassName: 'text-secondary-125',
              buttonTitle: 'Create your first service',
              onClick: () => {
                navigate('/services/new');
              },
              onDismiss: () => setHasDismissedServiceStep(true),
            },
          ]
        : []),
    ],
    [
      showBookACall,
      uniqueInvitees,
      showPaymentsSetup,
      showCalendarSetup,
      authedProviderUser,
      showSetupMyPage,
      showSetupAService,
      showSetupMyAppearance,
      showSchedulingSetup,
      showCalendlyClientsSetup,
    ],
  );

  const addPatients = () => {
    if (willHitClientLimit()) {
      showUpgradeModal(AnalyticsPage.Home);
      return;
    }
    setIsAddPatientsModalOpen(true);
  };

  const notifications =
    authedProviderUser?.notifications?.filter(
      (notification) => !Boolean(notification.readAt),
    ) ?? [];

  // Refresh notifications when the page is loaded
  useEffect(() => {
    refreshAuthedProviderUserNotifications();
  }, []);

  const [isSelectClientModalOpen, setSelectClientModalOpen] = useState(false);
  const [isAddPatientsModalOpen, setIsAddPatientsModalOpen] = useState(false);

  const [isAddPDFModalOpen, setIsAddPDFModalOpen] = useState(false);
  const [isAddLinkModalOpen, setIsAddLinkModalOpen] = useState(false);
  const [isAddAudioModalOpen, setIsAddAudioModalOpen] = useState(false);
  const [isAddImageModalOpen, setIsAddImageModalOpen] = useState(false);
  const [isAddOrEditTextModalOpen, setIsAddOrEditTextModalOpen] =
    useState(false);

  const openAddOrEditTextModal = () => setIsAddOrEditTextModalOpen(true);

  const navigateToLibrary = () => {
    return async () => {
      navigate('/library');
    };
  };

  const {
    data: meProviderProgramTagsData,
    refetch: refetchMeProviderProgramTags,
  } = useMeProviderProgramTagsWithCountQuery();

  const providerProgramTags =
    meProviderProgramTagsData?.meProvider?.provider?.programTags ?? [];

  const allNotifications = [...onboardingSteps, ...notifications];

  const isInboxView = viewMode === ViewMode.Inbox;
  const isUnsignedFormsView = viewMode === ViewMode.UnsignedForms;
  const isClientSessionsView = viewMode === ViewMode.ClientSessions;

  return (
    <div className="md:h-[calc(100vh-var(--top-nav-height))] md:overflow-y-scroll">
      <HomeIllustration className="l-0 z-1 fixed h-screen text-neutral-30" />
      <PageContainer
        noPadding
        containerClassName="md:px-20 px-6 py-8 relative z-2"
      >
        <>
          <div className="mb-10 flex flex-row gap-x-3">
            <Button
              title="Add clients"
              size="small"
              IconComponent={UserIcon}
              theme="secondary-white"
              iconPosition="left"
              className="shadow-lg shadow-neutral-50"
              onClick={addPatients}
            />
            {authedProviderUser?.patientDataPermission !==
              PatientDataPermission.None && (
              <>
                <Button
                  title="Send mail"
                  size="small"
                  IconComponent={MailIcon}
                  theme="secondary-white"
                  iconPosition="left"
                  className="!text-purple-100 shadow-lg shadow-neutral-50"
                  onClick={() => navigate('/follow-ups/new')}
                />
                <Button
                  title="Start chat"
                  size="small"
                  IconComponent={ChatIcon}
                  theme="secondary-white"
                  iconPosition="left"
                  className="!text-blue-100 shadow-lg shadow-neutral-50"
                  onClick={() => setSelectClientModalOpen(true)}
                />
              </>
            )}
            <AddToLibraryMenu
              buttonClassName="!text-orange-125/80 shadow-lg shadow-neutral-50"
              buttonTheme="secondary-white"
              buttonSize="small"
              onClickAddLink={() => setIsAddLinkModalOpen(true)}
              onClickAddImage={() => setIsAddImageModalOpen(true)}
              onClickAddPDF={() => setIsAddPDFModalOpen(true)}
              onClickAddAudio={() => setIsAddAudioModalOpen(true)}
              onClickAddForm={() =>
                navigate('/library/forms/new', {
                  state: {
                    fromPage: 'home',
                  },
                })
              }
              onClickAddText={openAddOrEditTextModal}
              IconComponent={PlusSmIcon}
            />
            <Button
              title="Invoice"
              size="small"
              IconComponent={BanknotesIcon}
              theme="secondary-white"
              iconPosition="left"
              className="!text-green-100 shadow-lg shadow-neutral-50"
              onClick={() => {
                if (hasStripeIntegration) {
                  navigate('/payments/new');
                } else if (isOwnerOrAdmin) {
                  navigate('/integrations/stripe');
                } else {
                  navigate('/payments');
                }
              }}
            />
          </div>
          <div className="flex flex-col gap-x-24 pl-2 lg:flex-row">
            {hasCalendlyIntegration && (
              <div className="mb-6 flex w-full flex-col items-start justify-start lg:w-[300px]">
                <Transition
                  show={true}
                  appear={true}
                  {...defaultTransitionProps}
                  style={{ transitionDelay: '0ms' }}
                >
                  <div
                    className={classNames(
                      'text-subtitle-small',
                      upcomingEventsLoading && 'animate-pulse',
                    )}
                  >
                    Up next
                  </div>
                </Transition>
                <Transition
                  as="div"
                  show={Boolean(upcomingEventsData?.scheduledEvents)}
                  {...defaultTransitionProps}
                  className="mt-6 flex flex-wrap items-start gap-6 sm:flex-row lg:flex-col lg:flex-nowrap lg:gap-y-5"
                  style={{ transitionDelay: '100ms' }}
                >
                  <>
                    {hasUpcomingEvents ? (
                      <>
                        {upcomingEvents?.map((upcomingEvent, index) => (
                          <Transition
                            key={index}
                            show={true}
                            {...defaultTransitionProps}
                            appear={true}
                            style={{ transitionDelay: `${100 + index * 50}ms` }}
                          >
                            <UpcomingEvent
                              upcomingEvent={upcomingEvent}
                              isDemo={!hasCalendlyIntegration}
                            />
                          </Transition>
                        ))}
                      </>
                    ) : (
                      <div className="text-neutral-125">
                        No upcoming sessions
                      </div>
                    )}
                  </>
                </Transition>
              </div>
            )}
            <div className="flex-1">
              <Transition
                show={true}
                appear={true}
                {...defaultTransitionProps}
                style={{
                  transitionDelay: hasCalendlyIntegration ? '50ms' : '0ms',
                }}
              >
                <div className="mb-6 flex h-[30px] w-full flex-row items-center justify-between">
                  <div className="font-sans text-subtitle-small">
                    {isInboxView && 'Unreads'}
                    {isUnsignedFormsView && 'Outstanding forms'}
                    {isClientSessionsView && 'Client sessions'}
                  </div>
                  <div className="flex space-x-2">
                    {!isInboxView && (
                      <Button
                        title="Return to unreads"
                        IconComponent={InboxIcon}
                        theme="secondary-white"
                        iconPosition="left"
                        size="small"
                        className="!text-neutral-125 shadow-lg shadow-neutral-50"
                        onClick={() => setViewMode(ViewMode.Inbox)}
                      />
                    )}
                    {!isUnsignedFormsView && unsignedFormsCount > 0 && (
                      <Button
                        title={`Complete ${unsignedFormsCount} outstanding ${
                          unsignedFormsCount === 1 ? 'form' : 'forms'
                        }`}
                        IconComponent={BellBadgeForm}
                        theme="secondary-white"
                        iconPosition="left"
                        size="small"
                        className="!text-neutral-125 shadow-lg shadow-neutral-50"
                        onClick={() => setViewMode(ViewMode.UnsignedForms)}
                      />
                    )}
                    {!isClientSessionsView && isClientSessionsEnabled && (
                      <Button
                        title={`Review ${pendingClientSessionsCount} client ${
                          pendingClientSessionsCount === 1
                            ? 'session'
                            : 'sessions'
                        }`}
                        IconComponent={BellBadgeCalendar}
                        theme="secondary-white"
                        iconPosition="left"
                        size="small"
                        className="!text-neutral-125 shadow-lg shadow-neutral-50"
                        onClick={() => setViewMode(ViewMode.ClientSessions)}
                      />
                    )}
                  </div>
                </div>
              </Transition>

              <Transition
                show={true}
                appear={true}
                {...defaultTransitionProps}
                style={{
                  transitionDelay: hasCalendlyIntegration ? '150ms' : '100ms',
                }}
              >
                <div className="rounded-lg bg-white p-7 shadow-lg shadow-neutral-30">
                  {isInboxView && (
                    <InboxView
                      allNotifications={allNotifications}
                      initialDelay={hasCalendlyIntegration ? 200 : 150}
                      providerPrimaryColor={authedProviderUser?.primaryColor}
                    />
                  )}
                  {isUnsignedFormsView && <UnsignedFormsView />}
                  {isClientSessionsView && <ClientSessionsView />}
                </div>
              </Transition>
            </div>
          </div>
        </>

        <SelectPatientModal
          isOpen={isSelectClientModalOpen}
          setClosed={() => setSelectClientModalOpen(false)}
        />
        <AddLinkModal
          isModalOpen={isAddLinkModalOpen}
          setClosed={() => setIsAddLinkModalOpen(false)}
          onAdded={navigateToLibrary}
        />
        <AddPDFModal
          isModalOpen={isAddPDFModalOpen}
          setClosed={() => setIsAddPDFModalOpen(false)}
          onAdded={navigateToLibrary}
        />
        <AddImageModal
          isModalOpen={isAddImageModalOpen}
          setClosed={() => setIsAddImageModalOpen(false)}
          onAdded={navigateToLibrary}
        />
        <AddAudioModal
          isModalOpen={isAddAudioModalOpen}
          setClosed={() => setIsAddAudioModalOpen(false)}
          onAdded={navigateToLibrary}
        />
        <AddOrEditTextModal
          isModalOpen={isAddOrEditTextModalOpen}
          setClosed={() => setIsAddOrEditTextModalOpen(false)}
          onAdded={navigateToLibrary}
        />
        <AddPatientsModal
          isClientsPage={false}
          isModalOpen={isAddPatientsModalOpen}
          setClosed={() => setIsAddPatientsModalOpen(false)}
          providerProgramTags={providerProgramTags}
          refetchMeProviderProgramTags={refetchMeProviderProgramTags}
          {...(showPrefilledPatientsList && {
            prefilledPatientsList: uniqueInvitees,
            isFromCalendly: true,
          })}
        />
      </PageContainer>
    </div>
  );
};

export default Home;
