import { AssessmentAnswer } from '../../../generated/graphql';
import {
  isClientSignatureTypeAssessmentQuestion,
  isInitialsAssessmentAnswer,
  isSignatureAssessmentAnswer,
} from '../../lib/assessments';
import { ProgramAssessmentQuestion } from '../../lib/program-assessment';

export const isIncompleteClientSignatureQuestion = (
  question: ProgramAssessmentQuestion,
  answer?: AssessmentAnswer,
): boolean => {
  const isNonCollaborativeSignatureQuestion =
    isClientSignatureTypeAssessmentQuestion(question);

  const isCompletedClientInitialsAnswer =
    answer && isInitialsAssessmentAnswer(answer) && answer.signatureName;

  const isCompletedClientSignatureAnswer =
    answer && isSignatureAssessmentAnswer(answer) && answer.signatureName;

  return (
    isNonCollaborativeSignatureQuestion &&
    !isCompletedClientInitialsAnswer &&
    !isCompletedClientSignatureAnswer
  );
};

export type CompleteProviderFormData = {
  providerSignatureName: string;
  providerSignatureRole: string;
  signatureTermsAgreed: boolean;
  answers: {
    value: string;
    values: string[];
  }[];
};
