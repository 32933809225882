import React from 'react';
import isEmpty from 'lodash.isempty';

import {
  Page,
  Text,
  View,
  Font,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';

import {
  AssessmentAnswer,
  ProgramAssessmentDataFragment,
} from '../../../../generated/graphql';

import { isAssessmentAForm } from '../../../lib/assessments';

import { colors } from '../../../lib/colors';
import { getFullDateTimeStamp } from '../../../lib/time';
import { ProgramAssessmentQuestion } from '../../../lib/program-assessment';

import { getScoreProps } from '../../Assessments/helpers';

import Score from './components/Score';
import Header from './components/Header';
import ResponsesSection from './components/ResponsesSection';

Font.register({
  family: 'sans',
  fonts: [
    {
      src: require('./fonts/FKGroteskNeue-Light.ttf'),
      fontWeight: 300,
    },
    {
      src: require('./fonts/FKGroteskNeue-Regular.ttf'),
      fontWeight: 400,
    },
    {
      src: require('./fonts/FKGroteskNeue-Medium.ttf'),
      fontWeight: 500,
    },
    {
      src: require('./fonts/FKGroteskNeue-Bold.ttf'),
      fontWeight: 700,
    },
  ],
});

Font.register({
  family: 'serif',
  fonts: [
    {
      src: require('./fonts/FKRomanStandard-Light.ttf'),
      fontWeight: 300,
    },
    {
      src: require('./fonts/FKRomanStandard-Regular.ttf'),
      fontWeight: 400,
    },
    {
      src: require('./fonts/FKRomanStandard-Medium.ttf'),
      fontWeight: 500,
    },
    {
      src: require('./fonts/FKRomanStandard-Bold.ttf'),
      fontWeight: 700,
    },
  ],
});

Font.register({
  family: 'cursive',
  fonts: [
    {
      src: require('./fonts/Cursive.ttf'),
      fontWeight: 400,
    },
  ],
});

const ProgramAssessmentPDF: React.FC<{
  programAssessment: ProgramAssessmentDataFragment;
  isProviderUser: boolean;
}> = ({ programAssessment, isProviderUser }) => {
  const title = programAssessment?.assessment?.name;

  const isForm = isAssessmentAForm(programAssessment?.assessment);

  const { primary, secondary } = getScoreProps(
    programAssessment,
    programAssessment.assessment.type,
  );

  const questions = programAssessment?.assessment?.questions as
    | ProgramAssessmentQuestion[]
    | undefined;

  const answers = programAssessment?.answers as AssessmentAnswer[] | undefined;

  const completedAtDateFormatted = getFullDateTimeStamp(
    programAssessment?.completedAt,
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Header
            patientName={programAssessment?.patientNameData?.name}
            completedAtDateFormatted={completedAtDateFormatted}
          />

          <Text
            style={{
              fontSize: 16,
              color: colors.neutral[150],
              fontFamily: 'serif',
              fontWeight: 400,
              marginBottom: 8,
            }}
          >
            {title}
          </Text>

          {!isForm && (
            <View>
              <Text style={styles.sectionTitle}>Score</Text>
              {!isEmpty(primary) && (
                <Score
                  score={primary.score}
                  categorization={primary.categorization}
                  color={primary.color}
                  hasPercentage={primary.hasPercentage}
                />
              )}
              {!isEmpty(secondary) && (
                <Score
                  score={secondary.score}
                  categorization={secondary.categorization}
                  color={secondary.color}
                  hasPercentage={secondary.hasPercentage}
                />
              )}
            </View>
          )}

          {programAssessment?.assessment.instructions && (
            <View>
              <Text style={styles.sectionTitle}>Instructions</Text>
              <Text
                style={{
                  fontSize: 10,
                  marginBottom: 4,
                  color: colors.neutral[125],
                  fontFamily: 'sans',
                  fontWeight: 400,
                }}
              >
                {programAssessment?.assessment.instructions}
              </Text>
            </View>
          )}

          <ResponsesSection
            questions={questions}
            answers={answers}
            isForm={isForm}
          />

          {programAssessment.assessment.description && isProviderUser && (
            <View>
              <Text style={styles.sectionTitle}>About</Text>
              <Text
                style={{
                  fontSize: 10,
                  lineHeight: 1,
                  color: colors.neutral[125],
                  fontFamily: 'sans',
                  fontWeight: 400,
                }}
              >
                {programAssessment.assessment.description}
              </Text>
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default ProgramAssessmentPDF;

export const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontFamily: 'sans',
    backgroundColor: 'white',
  },
  sectionTitle: {
    fontSize: 13,
    fontWeight: 500,
    color: colors.neutral[150],
    marginVertical: 12,
  },
  formQuestion: {
    marginBottom: 12,
    borderColor: colors.neutral[75],
    borderWidth: 1,
    borderRadius: 8,
    padding: 12,
  },
  assessmentQuestion: {
    flexDirection: 'row',
    marginBottom: 12,
  },
  questionText: {
    fontSize: 12,
    color: colors.neutral[150],
    fontWeight: 400,
  },
  answerText: {
    fontSize: 12,
    color: colors.neutral[150],
    fontWeight: 500,
  },
  questionNumberContainer: {
    width: 20,
    marginRight: 12,
  },
  questionNumber: {
    fontSize: 12,
    color: colors.neutral[200],
    textAlign: 'right',
    fontWeight: 400,
  },
});
