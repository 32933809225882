import { useState, useRef, useCallback } from 'react';
import { DateTime } from 'luxon';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin, {
  EventResizeDoneArg,
} from '@fullcalendar/interaction';
import { useAuth } from '../../../../contexts/AuthContext';
import { NylasCalendarEventDataFragment } from '../../../../generated/graphql';
import { useCalendarEvents } from './hooks/useCalendarEvents';
import { CalendarHeader } from './components/CalendarHeader';
import EventDetailsPopover from './components/EventDetailsPopover';
import CreateEventModal from './components/CreateEventModal';
import EditEventModal from './components/EditEventModal';
import ConfirmDeleteModal from '../../../components/ConfirmDeleteModal';
import './CalendarV2.styles.css';
import { EventDropArg } from '@fullcalendar/core';
import { useEventMutation } from './hooks/useEventMutations';
import { useCalendars } from './hooks/useCalendars';
// import { useResources } from './hooks/useResources';

// TODO: Remove these once we have real data
import { useDemoResources } from './hooks/useDemoResources';
import { useDemoEvents } from './hooks/useDemoEvents';

import { logger } from '../../../../lib/logger';
import { useAutoRefresh } from './hooks/useAutoRefresh';
import { useCalendarViewState } from './hooks/useCalendarViewState';
import { CalendarViewType } from './utils/enums';
// import { useMultiCalendarEvents } from './hooks/useMultiCalendarEvents';

const Calendar = () => {
  const { authedProviderUser } = useAuth();
  const calendarRef = useRef(null);
  const isNylasConnected = authedProviderUser?.hasNylasIntegration;

  const {
    currentDate,
    setCurrentDate,
    events,
    isLoading: isLoadingEvents,
    isDeletingEvent,
    primaryCalendar,
    handleDeleteEvent,
    refetchEvents,
  } = useCalendarEvents(isNylasConnected);

  // View state management
  const {
    viewType,
    viewMode,
    selectedCalendarIds,
    selectedResourceIds,
    handleViewTypeChange,
    handleCalendarSelect,
    handleResourceSelect,
  } = useCalendarViewState(calendarRef);

  // Fetch available calendars and resources
  const { calendars, loading: isLoadingCalendars } = useCalendars();
  // const { resources, loading: isLoadingResources } = useResources();

  const { events: demoEvents } = useDemoEvents(viewType, events);
  const { resources, loading: isLoadingResources } = useDemoResources();

  // Fetch events based on view type
  // const { events, isLoading: isLoadingEvents } = useMultiCalendarEvents(
  //   viewType,
  //   viewType === CalendarViewType.CALENDARS
  //     ? selectedCalendarIds
  //     : selectedResourceIds,
  //   currentDate,
  // );

  // Event mutation handlers
  const { handleEventDragOrResize } = useEventMutation();

  // Auto refresh
  const refreshCallback = useCallback(() => {
    void refetchEvents();
  }, [refetchEvents]);

  useAutoRefresh(refreshCallback, {
    seconds: 30,
    enabled: isNylasConnected,
  });

  // State for modals and popovers
  const [selectedEvent, setSelectedEvent] =
    useState<NylasCalendarEventDataFragment | null>(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(
    null,
  );
  const [isEventDetailsPopoverOpen, setIsEventDetailsPopoverOpen] =
    useState(false);
  const [isNewEventModalOpen, setIsNewEventModalOpen] = useState(false);
  const [newEventSelectInfo, setNewEventSelectInfo] = useState(null);
  const [isEditEventModalOpen, setIsEditEventModalOpen] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);

  const handleEventClick = ({ event, el }) => {
    setSelectedEvent(event.extendedProps as NylasCalendarEventDataFragment);
    setPopoverAnchorEl(el);
    setIsEventDetailsPopoverOpen(true);
  };

  const handleDeleteClick = (event: NylasCalendarEventDataFragment) => {
    setSelectedEvent(event);
    setIsConfirmDeleteModalOpen(true);
  };

  const handleModifyClick = (event: NylasCalendarEventDataFragment) => {
    setSelectedEvent(event);
    setIsEditEventModalOpen(true);
    setIsEventDetailsPopoverOpen(false);
    setPopoverAnchorEl(null);
  };

  const handleEventDrop = (info: EventDropArg) => {
    logger.debug('🎯 Event Drop Started:', {
      eventId: info.event.id,
      start: info.event.start,
      end: info.event.end,
    });

    handleEventDragOrResize({
      info,
      calendarId: primaryCalendar?.id,
      onSuccess: refetchEvents,
      onError: info.revert,
    });
    return true;
  };

  const handleEventResize = (info: EventResizeDoneArg) => {
    logger.debug('📏 Event Resize Started:', {
      eventId: info.event.id,
      start: info.event.start,
      end: info.event.end,
    });

    handleEventDragOrResize({
      info,
      calendarId: primaryCalendar?.id,
      onSuccess: refetchEvents,
      onError: info.revert,
    });
    return true;
  };

  return (
    <div
      className="flex h-full flex-col"
      onMouseDown={() => {
        if (isEventDetailsPopoverOpen) {
          setIsEventDetailsPopoverOpen(false);
          setPopoverAnchorEl(null);
        }
      }}
      onTouchStart={() => {
        if (isEventDetailsPopoverOpen) {
          setIsEventDetailsPopoverOpen(false);
          setPopoverAnchorEl(null);
        }
      }}
    >
      <CalendarHeader
        viewType={viewType}
        onViewTypeChange={handleViewTypeChange}
        calendars={calendars}
        resources={resources}
        selectedCalendarIds={selectedCalendarIds}
        selectedResourceIds={selectedResourceIds}
        isLoading={isLoadingEvents}
        isLoadingCalendars={isLoadingCalendars}
        isLoadingResources={isLoadingResources}
        onTodayClick={() => {
          calendarRef.current?.getApi().today();
          setCurrentDate(DateTime.now());
        }}
        onPrevClick={() => {
          calendarRef.current?.getApi().prev();
          setCurrentDate(currentDate.minus({ weeks: 1 }));
        }}
        onNextClick={() => {
          calendarRef.current?.getApi().next();
          setCurrentDate(currentDate.plus({ weeks: 1 }));
        }}
        onCalendarSelect={handleCalendarSelect}
        onResourceSelect={handleResourceSelect}
      />

      <div className="flex-1 overflow-auto">
        <FullCalendar
          ref={calendarRef}
          initialView={viewMode}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            resourceTimelinePlugin,
            interactionPlugin,
          ]}
          events={viewType === CalendarViewType.RESOURCES ? demoEvents : events}
          resources={resources}
          schedulerLicenseKey="0642643305-fcs-1732645980"
          headerToolbar={false}
          nowIndicator={true}
          allDaySlot={true}
          handleWindowResize={true}
          slotMinTime="00:00:00"
          slotMaxTime="24:00:00"
          slotDuration="00:30:00"
          snapDuration="00:15:00"
          selectable={true}
          select={(selectInfo) => {
            setNewEventSelectInfo(selectInfo);
            setIsNewEventModalOpen(true);
          }}
          selectMirror={true}
          unselectAuto={true}
          eventClick={handleEventClick}
          height="100%"
          editable={true}
          eventResizableFromStart={true}
          eventDurationEditable={true}
          eventDrop={handleEventDrop}
          eventResize={handleEventResize}
          dayHeaderContent={(args) => (
            <span className="text-small-caption font-medium text-neutral-125">
              {args.text}
            </span>
          )}
          eventContent={(eventInfo) => (
            <div className="cursor-pointer p-0.5 transition-all hover:bg-opacity-90">
              <div className="truncate text-small-caption font-medium">
                {eventInfo.event.title}
              </div>
              <div className="text-small-caption opacity-75">
                {eventInfo.timeText}
              </div>
            </div>
          )}
          slotLabelContent={(args) => (
            <span className="text-small-caption font-medium text-neutral-125">
              {args.text}
            </span>
          )}
          allDayContent={() => <></>}
        />
      </div>

      {selectedEvent && (
        <EventDetailsPopover
          event={selectedEvent}
          referenceElement={popoverAnchorEl}
          isOpen={isEventDetailsPopoverOpen}
          onClose={() => {
            setIsEventDetailsPopoverOpen(false);
            setSelectedEvent(null);
            setPopoverAnchorEl(null);
          }}
          onDeleteEventClick={handleDeleteClick}
          onModifyEventClick={handleModifyClick}
        />
      )}

      {isNewEventModalOpen && (
        <CreateEventModal
          key={newEventSelectInfo?.start?.getTime()}
          isOpen={true}
          onClose={() => {
            setIsNewEventModalOpen(false);
            setNewEventSelectInfo(null);
          }}
          selectInfo={newEventSelectInfo}
          calendarId={primaryCalendar?.id}
          onEventCreated={() => {
            refetchEvents();
          }}
        />
      )}

      {isEditEventModalOpen && (
        <EditEventModal
          isOpen={true}
          calendarId={primaryCalendar?.id}
          onClose={() => {
            setIsEditEventModalOpen(false);
            setSelectedEvent(null);
          }}
          event={selectedEvent}
          onEventUpdated={() => {
            refetchEvents();
          }}
        />
      )}

      {isConfirmDeleteModalOpen && (
        <ConfirmDeleteModal
          isOpen={isConfirmDeleteModalOpen}
          setClosed={() => setIsConfirmDeleteModalOpen(false)}
          performDelete={async () => {
            if (selectedEvent) {
              const success = await handleDeleteEvent(selectedEvent);
              if (success) {
                setIsConfirmDeleteModalOpen(false);
                setIsEventDetailsPopoverOpen(false);
                setSelectedEvent(null);
                setPopoverAnchorEl(null);
              }
            }
          }}
          title="Delete event?"
          fetching={isDeletingEvent}
        >
          Changes will be reflected in your external calendar and any
          participants will be notified.
        </ConfirmDeleteModal>
      )}
    </div>
  );
};

export default Calendar;
