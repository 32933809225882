import React from 'react';
import { getTimeStamp } from '../lib/time';
import { ProgramAssessmentSignatureUserDataFragment } from '../../generated/graphql';
import classNames from 'classnames';

const CompletedSignature: React.FC<{
  programAssessmentSignatureUser?: ProgramAssessmentSignatureUserDataFragment;
  isCurrentSigner?: boolean;
  signatureName?: string;
  nowFormattedTimestamp?: string;
  signatureRole?: string;
  signedAt?: string;
  hideNameAndRole?: boolean;
  className?: string;
  size?: 'regular' | 'small';
  hideDate?: boolean;
}> = ({
  programAssessmentSignatureUser,
  isCurrentSigner = false,
  signatureName,
  signatureRole = '',
  nowFormattedTimestamp,
  signedAt,
  hideNameAndRole = false,
  className,
  size = 'regular',
  hideDate = false,
}) => {
  const displayName = isCurrentSigner
    ? signatureName
    : programAssessmentSignatureUser?.signatureName ?? signatureName;

  const displayRole = isCurrentSigner
    ? signatureRole
    : programAssessmentSignatureUser?.signatureRole ?? signatureRole;

  const displayTimestamp = isCurrentSigner
    ? nowFormattedTimestamp
    : getTimeStamp(
        programAssessmentSignatureUser?.signedAt ??
          signedAt ??
          new Date().toISOString(),
        false,
        false,
        false,
        true,
      );

  return (
    <div
      className={classNames(
        'flex flex-col items-start justify-start',
        className,
      )}
    >
      {!hideNameAndRole && (
        <div
          className={classNames(
            'mb-1 text-neutral-125',
            size === 'regular' && 'text-caption',
            size === 'small' && 'text-small-caption',
          )}
        >
          {displayName}, {displayRole || 'Client'}
        </div>
      )}
      <div
        className={classNames(
          'font-cursive',
          size === 'regular' && 'text-subtitle',
          size === 'small' && 'text-small',
        )}
      >
        {displayName}
      </div>
      {!hideDate && (
        <div
          className={classNames(
            'mt-2 text-neutral-125',
            size === 'regular' && 'text-small-caption',
            size === 'small' && 'text-small-label',
          )}
        >
          {displayTimestamp}
        </div>
      )}
    </div>
  );
};

export default CompletedSignature;
