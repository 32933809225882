import { ArrowNarrowLeftIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import {
  MePatientDataFragment,
  ProgramActivityDataFragment,
} from '../../../../generated/graphql';
import IconButton from '../../../components/IconButton';
import ProgramAssessmentForm from './ProgramAssessmentForm';
import useScrollTitle from './useScrollTitle';
import { customToast } from '../../../components/ToastAlert/customToast';

interface CompleteProgramAssessmentProps {
  authedPatient: MePatientDataFragment;
  assessmentProgramActivity: ProgramActivityDataFragment;
  onBack: () => void;
  onComplete: () => Promise<void>;
}

const CompleteProgramAssessment: React.FC<CompleteProgramAssessmentProps> = ({
  authedPatient,
  assessmentProgramActivity,
  onBack,
  onComplete,
}) => {
  const title = useScrollTitle('program-assessment-title');

  return (
    <>
      <div className="md:px-22 fixed top-16 z-10 flex w-full flex-row items-center border-b border-neutral-50 bg-white py-2 pl-2 transition-all duration-300 ease-in-out md:pl-20">
        <IconButton
          IconComponent={ArrowNarrowLeftIcon}
          iconClassName="text-secondary-100 w-5"
          aria-label="Back"
          className="rounded-xl px-3 py-2"
          onClick={onBack}
        >
          <div className="small-caption ml-2 font-bold text-secondary-100">
            Back
          </div>
        </IconButton>
        <div className="flex flex-grow justify-center">
          <div
            className={classNames(
              'transition-opacity duration-300 ease-in-out',
              !title ? 'opacity-0' : 'opacity-100',
            )}
          >
            <div className="text-center font-serif text-paragraph text-neutral-125 line-clamp-1 md:text-small">
              {title}
            </div>
          </div>
        </div>
        <div className="w-20" /> {/* This div balances the layout */}
      </div>
      <ProgramAssessmentForm
        authedPatient={authedPatient}
        programActivity={assessmentProgramActivity}
        onComplete={async () => {
          await onComplete();
          customToast.success('Response successfully submitted');
        }}
      />
    </>
  );
};

export default CompleteProgramAssessment;
