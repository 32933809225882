import React from 'react';
import { View, Text } from '@react-pdf/renderer';

import { AssessmentAnswer } from '../../../../../generated/graphql';

import { isStatementAssessmentQuestion } from '../../../../lib/assessments';
import { ProgramAssessmentQuestion } from '../../../../lib/program-assessment';

import { QuestionSubscale } from '../../../Assessments/QuestionsSection';
import { findAnswerForQuestion } from '../../../Assessments/ResponsesSection/helpers';

import { getAnswerContent } from '../helpers';
import { styles } from '../index';
import { colors } from '../../../../lib/colors';

interface ResponsesSectionProps {
  questions: ProgramAssessmentQuestion[] | undefined;
  answers: AssessmentAnswer[] | undefined;
  isForm: boolean;
}

const ResponsesSection: React.FC<ResponsesSectionProps> = ({
  questions,
  answers,
  isForm,
}) => {
  return (
    <View>
      <Text style={styles.sectionTitle}>Responses</Text>
      {questions?.map((question, questionIndex) => {
        const answer = findAnswerForQuestion(answers ?? [], question);
        const answerContent = getAnswerContent(answer);
        const isStatementQuestion = isStatementAssessmentQuestion(question);

        if (isForm) {
          return (
            <React.Fragment key={questionIndex}>
              {!isStatementQuestion ? (
                <View
                  wrap={false}
                  style={{
                    marginBottom: 12,
                    borderColor: colors.neutral[75],
                    borderWidth: 1,
                    borderRadius: 8,
                    padding: 12,
                  }}
                >
                  <View
                    style={{
                      borderBottomWidth: 1,
                      borderBottomColor: colors.neutral[50],
                      borderBottomStyle: 'solid',
                      width: '100%',
                      paddingBottom: 4,
                      marginBottom: 8,
                    }}
                  >
                    <Text style={[styles.questionText, { marginBottom: 4 }]}>
                      {question.question}
                    </Text>
                  </View>
                  {answerContent}
                </View>
              ) : (
                <Text
                  wrap={false}
                  style={[styles.questionText, { marginBottom: 4 }]}
                >
                  {question.question}
                </Text>
              )}
            </React.Fragment>
          );
        } else {
          return (
            <View
              key={questionIndex}
              wrap={false}
              style={styles.assessmentQuestion}
            >
              <View style={styles.questionNumberContainer}>
                <Text style={styles.questionNumber}>{`${
                  questionIndex + 1
                }.`}</Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.questionText}>{question.question}</Text>
                {answerContent}
                {!answer && !isStatementAssessmentQuestion(question) && (
                  <Text style={styles.answerText}>
                    {QuestionSubscale[question.questionType]}
                  </Text>
                )}
              </View>
            </View>
          );
        }
      })}
    </View>
  );
};

export default ResponsesSection;
