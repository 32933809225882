import { useEffect } from 'react';

const useDisableOverscroll = () => {
  useEffect(() => {
    // Apply the style when the component mounts
    document.body.style.overscrollBehavior = 'none';

    // Remove the style when the component unmounts
    return () => {
      document.body.style.overscrollBehavior = '';
    };
  }, []);
};

export default useDisableOverscroll;
