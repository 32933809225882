import classNames from 'classnames';
import { forwardRef } from 'react';

import { SvgIconComponent } from '../types/svgs';

export type ButtonTheme =
  | 'primary'
  | 'secondary'
  | 'secondary-white'
  | 'alt'
  | 'destructive'
  | 'none';

export type ButtonSize = 'medium' | 'small' | 'smaller' | 'extra-small';

export type ButtonProps = {
  title?: string;
  subtitle?: string;
  subLabel?: string;
  theme?: ButtonTheme;
  IconComponent?: SvgIconComponent;
  iconPosition?: 'right' | 'left' | 'center';
  iconClassName?: string;
  size?: ButtonSize;
  noBackground?: boolean;
  noOutline?: boolean;
  noFocus?: boolean;
  circular?: boolean;
  contentAlign?: 'center' | 'left' | 'right';
  // Using the ComponentPropsWithoutRef type allows other components to be composed of a Button component
  // with the ability to pass Button props through without TypeScript complaining about a missing ref.
  // e.g. check out DashedBoxButton.tsx
} & React.ComponentPropsWithoutRef<'button'>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      title,
      subtitle,
      subLabel,
      theme = 'primary',
      IconComponent,
      iconPosition = 'right',
      iconClassName,
      size = 'medium',
      className,
      disabled,
      noBackground = false,
      noOutline = false,
      noFocus = false,
      circular = false,
      contentAlign = 'center',
      ...rest
    },
    ref,
  ) => {
    const icon = IconComponent && (
      <IconComponent
        className={classNames(
          size === 'medium' && 'h-6 w-6',
          size === 'small' && 'h-4 w-4',
          size === 'smaller' && 'h-4 w-4',
          size === 'extra-small' && 'h-2.5 w-2.5',
          iconPosition === 'left' && 'mr-2',
          iconPosition === 'right' && 'ml-2',
          iconPosition === 'center' && 'mb-1',
          'flex-shrink-0',
          iconClassName,
        )}
      />
    );

    return (
      <button
        ref={ref}
        type="button"
        className={classNames(
          'group transition-colors focus:outline-none',
          circular ? 'rounded-full' : 'rounded-3xl',
          !noFocus && 'focus:ring-2 focus:ring-secondary-50',
          size === 'medium' && 'text-body',
          size === 'small' && 'text-caption',
          size === 'smaller' && 'text-small-caption',
          size === 'extra-small' && 'text-small-caption font-bold',
          !noOutline && size === 'medium' && 'py-3 px-6',
          !noOutline && size === 'small' && 'py-2 px-4',
          !noOutline && size === 'smaller' && 'py-1.5 px-3',
          !noOutline && size === 'extra-small' && 'py-1 px-2',
          theme === 'primary' &&
            'text-white disabled:cursor-not-allowed disabled:text-white',
          !noBackground &&
            theme === 'primary' &&
            'bg-primary-125 hover:bg-primary-150 disabled:bg-primary-100',
          theme === 'secondary' &&
            'text-neutral-125 disabled:cursor-not-allowed disabled:text-neutral-100',
          !noBackground &&
            theme === 'secondary' &&
            'bg-neutral-50 hover:bg-neutral-75 disabled:bg-neutral-50',
          theme === 'secondary-white' &&
            'text-neutral-125 hover:text-neutral-150 disabled:cursor-not-allowed disabled:text-neutral-125/50',
          !noBackground &&
            theme === 'secondary-white' &&
            'bg-white hover:bg-neutral-50 disabled:bg-white',
          theme === 'destructive' &&
            'text-red-100 disabled:cursor-not-allowed disabled:text-red-100/50',
          !noBackground &&
            theme === 'destructive' &&
            'bg-neutral-50 hover:bg-neutral-75 disabled:bg-neutral-50',
          className,
        )}
        disabled={disabled}
        {...rest}
      >
        <div className={classNames(subLabel && 'flex flex-col items-start')}>
          <div
            className={classNames(
              'flex items-center font-sans font-medium',
              iconPosition === 'center' ? 'flex-col-reverse' : 'flex-row',
              contentAlign === 'left' && 'justify-start',
              contentAlign === 'right' && 'justify-end',
              contentAlign === 'center' && 'justify-center',
            )}
          >
            {iconPosition === 'left' && icon}
            <div
              className={classNames(
                subtitle && 'flex flex-col items-start justify-center',
                'whitespace-nowrap',
              )}
            >
              {title}
              <span
                className={classNames(
                  size === 'medium' && 'text-caption',
                  size === 'small' && 'text-small-caption',
                  size === 'extra-small' && 'text-small-action',
                )}
              >
                {subtitle}
              </span>
            </div>
            {(iconPosition === 'right' || iconPosition === 'center') && icon}
          </div>
          {subLabel && (
            <span className="mt-1 text-small-label text-neutral-110">
              {subLabel}
            </span>
          )}
        </div>
      </button>
    );
  },
);

export default Button;
