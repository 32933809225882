import React from 'react';
import classNames from 'classnames';
import { Switch } from '@headlessui/react';
import { useFormContext } from 'react-hook-form';
import { ExternalLinkIcon } from '@heroicons/react/outline';

import { handleSeeMyPage, urlFromSlug } from '../../../lib/storefront';

import Button from '../../components/Button';

import { StorefrontFormData } from '.';

const TopBanner: React.FC<{
  storefrontSlug: string;
  updateStorefrontEnabled: (isEnabled: boolean) => Promise<void>;
}> = ({ storefrontSlug, updateStorefrontEnabled }) => {
  const storefrontUrl = urlFromSlug(storefrontSlug);
  const { watch } = useFormContext<StorefrontFormData>();
  const isStorefrontEnabled = watch('isEnabled');
  const isEnabledTextColor = isStorefrontEnabled
    ? '!text-neutral-150'
    : '!text-neutral-110';

  return (
    <div className="flex w-full flex-row items-center justify-between gap-x-10 px-[152px] pt-[40px] pb-[72px] text-neutral-150">
      <div className="mr-4 flex flex-col gap-y-3">
        <div className="w-full text-subtitle-small">
          Create and publish your page
        </div>
        <div className="w-full text-caption">
          Create your page with personal and practice details, then add your
          services for seemless scheduling, intake, and more.
        </div>
      </div>
      <div className="flex w-1/2 flex-row items-center justify-center gap-x-3">
        <Button
          title={storefrontUrl}
          IconComponent={ExternalLinkIcon}
          iconPosition="left"
          size="small"
          noFocus
          className={isEnabledTextColor}
          noBackground
          disabled={!isStorefrontEnabled}
          onClick={() => handleSeeMyPage(storefrontUrl)}
          circular
        />
      </div>
      {isStorefrontEnabled != null && (
        <div className="flex min-w-[141px] flex-row items-center justify-end">
          <div
            className={classNames(
              'mr-4 text-caption font-medium',
              isEnabledTextColor,
            )}
          >
            {isStorefrontEnabled ? 'Published' : 'Draft'}
          </div>
          <Switch
            checked={isStorefrontEnabled}
            onChange={async () => {
              await updateStorefrontEnabled(!isStorefrontEnabled);
            }}
            className={classNames(
              !isStorefrontEnabled ? 'bg-neutral-100' : 'bg-secondary-100',
              'relative inline-flex h-[31px] w-[58px] flex-shrink-0 cursor-pointer rounded-full',
              'transition duration-200 ease-in-out focus:outline-none focus:ring-0',
            )}
          >
            <div
              className={classNames(
                !isStorefrontEnabled ? 'translate-x-0' : 'translate-x-full',
                'pointer-events-none absolute top-[2px] left-0.5 inline-block h-[27px] w-[27px]',
                'transform rounded-full shadow ring-0 transition duration-200 ease-in-out',
                'bg-white',
              )}
            />
          </Switch>
        </div>
      )}
    </div>
  );
};

export default TopBanner;
