import React, { useState } from 'react';
import { usePopper } from 'react-popper';

import { ProgramTagColor } from '../../../generated/graphql';
import classNames from 'classnames';
import { Listbox, Portal } from '@headlessui/react';
import { PROGRAM_TAG_COLOR_TO_TAILWIND_BG_COLOR_MAP } from '../../lib/colors';

type ProgramTagColorSelectorProps = {
  selectedColor: ProgramTagColor;
  onColorChange?: (color: ProgramTagColor) => void;
  className?: string;
};

const ProgramTagColorSelector: React.FC<ProgramTagColorSelectorProps> = ({
  selectedColor,
  onColorChange,
  className,
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    strategy: 'fixed',
    modifiers: [{ name: 'offset', options: { offset: [85, 5] } }],
  });

  return (
    <Listbox
      as="div"
      value={selectedColor}
      className="relative"
      onChange={onColorChange}
    >
      <Listbox.Button
        className={classNames(
          'h-9 w-9 rounded-full border-2 border-neutral-110 focus:outline-secondary-100',
          PROGRAM_TAG_COLOR_TO_TAILWIND_BG_COLOR_MAP[selectedColor],
          className,
        )}
        ref={setReferenceElement}
      />
      <Portal>
        <Listbox.Options
          className="absolute z-50 divide-y divide-neutral-75 rounded-md border border-neutral-75 bg-white shadow-lg focus:outline-none"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {Object.entries(PROGRAM_TAG_COLOR_TO_TAILWIND_BG_COLOR_MAP).map(
            ([programTagColor, tailwindColor]) => (
              <Listbox.Option
                key={programTagColor}
                value={programTagColor}
                className={({ active }) =>
                  classNames(
                    'flex cursor-pointer flex-row items-center px-5 py-3 text-neutral-150',
                    active && 'bg-neutral-25',
                  )
                }
              >
                <div
                  className={classNames(
                    'mr-3 h-5 w-5 min-w-[1.25rem] rounded-full',
                    PROGRAM_TAG_COLOR_TO_TAILWIND_BG_COLOR_MAP[programTagColor],
                  )}
                />
                <div className="truncate text-caption">{programTagColor}</div>
              </Listbox.Option>
            ),
          )}
        </Listbox.Options>
      </Portal>
    </Listbox>
  );
};

export default ProgramTagColorSelector;
