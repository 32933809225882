import { View, Text } from '@react-pdf/renderer';
import {
  AssessmentAnswer,
  AssessmentQuestionType,
  MultipleChoiceAssessmentAnswer,
  MultiSelectAssessmentAnswer,
  FreeTextAssessmentAnswer,
  InitialsAssessmentAnswer,
  SignatureAssessmentAnswer,
  MultiSignatureAssessmentAnswer,
} from '../../../../generated/graphql';
import { getFullDateTimeStamp } from '../../../lib/time';
import CompletedSignature from './components/CompletedSignature';
import { styles } from '.';

export const getAnswerContent = (
  answer: AssessmentAnswer | undefined,
): React.ReactNode => {
  if (!answer) return <></>;

  switch (answer.questionType) {
    case AssessmentQuestionType.MultipleChoiceScored:
    case AssessmentQuestionType.MultipleChoice: {
      const multipleChoiceAnswer = answer as MultipleChoiceAssessmentAnswer;
      return (
        <Text style={styles.answerText}>
          {multipleChoiceAnswer.answer ?? 'No answer provided'}
        </Text>
      );
    }

    case AssessmentQuestionType.MultiSelect: {
      const multiSelectAnswer = answer as MultiSelectAssessmentAnswer;
      return (
        <Text style={styles.answerText}>
          {multiSelectAnswer.answers?.length
            ? multiSelectAnswer.answers.join(', ')
            : 'Nothing selected'}
        </Text>
      );
    }

    case AssessmentQuestionType.FreeText:
    case AssessmentQuestionType.RichText: {
      const textAnswer = answer as FreeTextAssessmentAnswer;
      return (
        <Text style={styles.answerText}>
          {textAnswer.answer ?? 'No answer provided'}
        </Text>
      );
    }

    case AssessmentQuestionType.Initials: {
      const initialsAnswer = answer as InitialsAssessmentAnswer;
      return initialsAnswer.signatureName ? (
        <View style={{ marginTop: 2 }}>
          <CompletedSignature
            name={initialsAnswer.signatureName}
            role="Client"
            date={getFullDateTimeStamp(initialsAnswer?.signedAt)}
            hideNameAndRole={true}
            hideDate={false}
          />
        </View>
      ) : (
        <Text style={styles.answerText}>Not initialed</Text>
      );
    }

    case AssessmentQuestionType.Signature: {
      const signatureAnswer = answer as SignatureAssessmentAnswer;
      return signatureAnswer.signatureName ? (
        <View style={{ marginTop: 4 }}>
          <CompletedSignature
            name={signatureAnswer.signatureName}
            role="Client"
            date={getFullDateTimeStamp(signatureAnswer.signedAt)}
            hideNameAndRole={false}
            hideDate={false}
          />
        </View>
      ) : (
        <Text style={styles.answerText}>Not signed</Text>
      );
    }

    case AssessmentQuestionType.MultiSignature: {
      const multiSignatureAnswer = answer as MultiSignatureAssessmentAnswer;
      return multiSignatureAnswer.signatures?.length ? (
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 8,
            marginTop: 16,
          }}
        >
          {multiSignatureAnswer.signatures.map((signature, index) => (
            <View
              key={index}
              style={{
                flexBasis: '25%',
                marginBottom: 8,
              }}
            >
              <CompletedSignature
                name={signature.signatureName}
                role={signature.signatureRole || 'Client'}
                date={getFullDateTimeStamp(signature.signedAt)}
                hideNameAndRole={false}
                hideDate={false}
              />
            </View>
          ))}
        </View>
      ) : (
        <Text style={styles.answerText}>Not signed</Text>
      );
    }
    default:
      return <Text style={styles.answerText}>{getAnswerContent(answer)}</Text>;
  }
};
