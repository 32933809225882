import React from 'react';
import ModalDialog from '../../../../components/ModalDialog';
import EventForm from './EventForm';
import { NylasCalendarEvent } from '../../../../../generated/graphql';
import { CreateEventFormData } from './types';
import { useEventMutation } from '../hooks/useEventMutations';
import { getEditEventFormValues } from '../utils/formUtils';

interface EditEventModalProps {
  isOpen: boolean;
  onClose: () => void;
  event: NylasCalendarEvent | null;
  calendarId: string;
  onEventUpdated?: () => void;
}

const EditEventModal: React.FC<EditEventModalProps> = ({
  isOpen,
  onClose,
  event,
  calendarId,
  onEventUpdated,
}) => {
  const defaultValues = getEditEventFormValues(event);
  const { handleUpdateEvent } = useEventMutation();

  const handleSubmit = async (data: CreateEventFormData) => {
    if (!event) return;

    const success = await handleUpdateEvent(calendarId, event.id, data);
    if (success) {
      onEventUpdated?.();
      onClose();
    }
  };

  return (
    <ModalDialog
      isOpen={isOpen}
      setClosed={onClose}
      title="Edit Event"
      width="small"
    >
      <EventForm
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        isAllDay={defaultValues.isAllDay}
      />
    </ModalDialog>
  );
};

export default EditEventModal;
