import React from 'react';
import hcLogo from '../../assets/images/logo/logo-green-xl.png';
import classNames from 'classnames';

const PublicPageFooter: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <footer
      className={classNames(
        'mt-auto mb-2 flex items-center justify-center py-2 px-3',
        className,
      )}
    >
      <span className="mr-2 text-caption text-neutral-110">Powered by</span>
      <a
        href="https://homecoming.health"
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center"
      >
        <img src={hcLogo} alt="Homecoming Logo" className="mr-1 h-4 w-4" />
        <span className="font-serif text-body text-green-125">Homecoming</span>
      </a>
    </footer>
  );
};

export default PublicPageFooter;
