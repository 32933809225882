import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { Transition } from '@headlessui/react';

import {
  useAddCalendlyIntegrationMutation,
  useCalendlyEventTypesLazyQuery,
  useChangeCalendlyEventTypeVisibilityMutation,
  useRemoveCalendlyIntegrationMutation,
  CalendlyEventTypesDocument,
  CalendlyImportStatusDocument,
} from '../../../../generated/graphql';

import {
  CALENDLY_AUTHORIZATION_PAGE_URL,
  CALENDLY_LANDING_PAGE_URL,
  CALENDLY_SCHEDULED_EVENTS_URL,
  getCalendlyRedirectUri,
} from '../../../lib/scheduled-events';
import Config from '../../../../lib/config';
import { defaultTransitionProps } from '../../../lib/animation';

import { useAuth } from '../../../../contexts/AuthContext';
import Alert from '../../../components/Alert';
import CalendlyLogo from '../../../svgs/CalendlyLogo';
import Spinner from '../../../svgs/Spinner';
import {
  ProviderAnalyticsEvent,
  trackProviderEvent,
} from '../../../../lib/analytics';
import Integration from '../../../components/Integration';
import PageContainer from '../../../components/Containers/PageContainer';
import { isProviderUserOwnerOrAdminAuthorized } from '../../../../lib/auth';

const CalendlyIntegration: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [isIntegrationLoading, setIsIntegrationLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [calendlyAuthorizationCode, setCalendlyAuthorizationCode] = useState<
    string | null
  >(searchParams.get('code'));

  const { authedProviderUser, refreshAuthedProviderUser } = useAuth();

  useEffect(() => {
    // TODO: Replace with some global permission route handling
    if (
      authedProviderUser &&
      !isProviderUserOwnerOrAdminAuthorized(authedProviderUser)
    ) {
      navigate('/');
    }
  }, [authedProviderUser]);

  const [
    calendlyEventTypes,
    { data: calendlyEventTypeData, loading: calendlyEventTypesLoading },
  ] = useCalendlyEventTypesLazyQuery({
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      setErrorMessage(error.message);
    },
  });
  const [
    changeCalendlyEventTypeVisibilityMutation,
    { loading: isChangingCalendlyEventTypeVisibility },
  ] = useChangeCalendlyEventTypeVisibilityMutation({
    onError: (error) => {
      setErrorMessage(error.message);
    },
    refetchQueries: [CalendlyEventTypesDocument],
  });
  const [addCalendlyIntegrationMutation] = useAddCalendlyIntegrationMutation({
    refetchQueries: [CalendlyImportStatusDocument],
  });
  const [removeCalendlyIntegrationMutation] =
    useRemoveCalendlyIntegrationMutation({
      refetchQueries: [CalendlyImportStatusDocument],
    });

  useEffect(() => {
    // Note that this effect will run twice in development. Disregard any error messages resulting from the second run.
    if (!isIntegrationLoading && calendlyAuthorizationCode) {
      if (!authedProviderUser?.provider.calendlyIntegration) {
        handleAuthorizationCode();
      } else {
        clearSearchParams();
      }
    }
  }, [
    authedProviderUser?.provider.calendlyIntegration,
    calendlyAuthorizationCode,
  ]);

  useEffect(() => {
    if (authedProviderUser?.provider.calendlyIntegration) {
      fetchCalendlyEventTypes();
    }
  }, [authedProviderUser?.provider.calendlyIntegration]);

  const clearSearchParams = () => {
    window.history.replaceState({}, document.title, window.location.pathname);
    setCalendlyAuthorizationCode(null);
  };

  const handleAuthorizationCode = async () => {
    if (!calendlyAuthorizationCode) {
      return;
    }

    setIsIntegrationLoading(true);
    setSuccessMessage(null);
    setErrorMessage(null);

    try {
      await addCalendlyIntegrationMutation({
        variables: {
          authorizationCode: calendlyAuthorizationCode,
        },
      });

      // Refetch authedProviderUser so it has the new integration data
      await refreshAuthedProviderUser();
      setSuccessMessage(`Your Calendly account is connected to Homecoming. Your clients
      will now be able to access your Calendly schedule and book
      sessions through their Homecoming app. They’ll also be alerted
      when a session is coming up.`);
    } catch (error) {
      setErrorMessage((error as Error).message);
    } finally {
      clearSearchParams();
      setIsIntegrationLoading(false);
    }
  };

  const fetchCalendlyEventTypes = async () => {
    setErrorMessage(null);

    try {
      await calendlyEventTypes();
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  };

  const onPressConnectCalendly = async () => {
    trackProviderEvent(ProviderAnalyticsEvent.AddCalendlyIntegrationAttempted);

    // Redirect to Calendly authorization page
    window.location.href = `${CALENDLY_AUTHORIZATION_PAGE_URL}?client_id=${
      Config.REACT_APP_CALENDLY_CLIENT_ID
    }&redirect_uri=${getCalendlyRedirectUri()}&response_type=code`;
  };

  const onPressDisconnectCalendly = async () => {
    setIsIntegrationLoading(true);
    setSuccessMessage(null);
    setErrorMessage(null);

    try {
      await removeCalendlyIntegrationMutation();
      // Refetch authedProviderUser so it has the new integration data
      await refreshAuthedProviderUser();
      setSuccessMessage(
        `Your Calendly account is now disconnected from Homecoming. If you’d like to reconnect Calendly, you can do so at any time.`,
      );
    } catch (error) {
      setErrorMessage((error as Error).message);
    } finally {
      setIsIntegrationLoading(false);
    }
  };

  return (
    <PageContainer containerClassName="min-h-[calc(100vh-200px)] flex text-neutral-125 items-center">
      <Integration
        name="Calendly"
        logo={<CalendlyLogo className="h-24 w-24" />}
        isConnected={!!authedProviderUser?.provider.calendlyIntegration}
        isLoading={isIntegrationLoading}
        onPressConnect={onPressConnectCalendly}
        onPressDisconnect={onPressDisconnectCalendly}
        successMessage={successMessage}
        dismissSuccessMessage={() => setSuccessMessage(null)}
        errorMessage={errorMessage}
        dismissErrorMessage={() => setErrorMessage(null)}
        accountUrl={CALENDLY_SCHEDULED_EVENTS_URL}
        supportUrl="https://homecoming-health.notion.site/Calendly-FAQ-42b534145bb241f7863c7c091e1a6820"
        pricing="Free and paid plans available"
        description={
          <>
            <a
              className="text-blue-100 hover:text-blue-125"
              href={CALENDLY_LANDING_PAGE_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Calendly
            </a>{' '}
            is your scheduling automation platform for eliminating the
            back-and-forth emails for finding the perfect time — and so much
            more.
          </>
        }
        features={[
          {
            title: 'Allow clients to see your availability and book sessions.',
            description:
              'Your clients can see your availability and book sessions through their mobile app. Simply set your availability preferences in Calendly, and let your clients take care of the rest.',
          },
          {
            title: 'Keep clients informed about upcoming sessions.',
            description:
              'No more missed appointments or confusion about scheduling. Keep your clients informed about upcoming sessions with automatic notifications.',
          },
          {
            title: 'Your schedule and your clients, all in one place.',
            description:
              'Keep track of all your client meetings and events on Homecoming.',
          },
        ]}
        disconnectConfirmText="Your clients will be unable to book with you in the mobile app, or be
      notified of upcoming sessions via push notifications. Any stored data
      from Calendly will be deleted."
      >
        <h6 className="mt-5 mb-1 font-sans text-caption font-medium text-green-150">
          Event types
        </h6>
        <p className="m-0 text-caption">
          Choose all the event types that you’d like to be visible in Homecoming
          and bookable by your clients.
        </p>

        {calendlyEventTypesLoading &&
          !calendlyEventTypeData?.calendlyEventTypes?.length && (
            <Spinner className="mt-5" />
          )}

        <Transition
          show={
            !calendlyEventTypesLoading ||
            Boolean(calendlyEventTypeData?.calendlyEventTypes?.length)
          }
          {...defaultTransitionProps}
        >
          <form className="mt-5">
            {calendlyEventTypeData?.calendlyEventTypes.length === 0 && (
              <Alert level="info" message="No event types found" />
            )}
            {calendlyEventTypeData?.calendlyEventTypes.map(
              (eventType, index) => (
                <div
                  className={classNames(
                    'flex items-center',
                    index !== 0 && 'mt-6',
                    (calendlyEventTypesLoading ||
                      isChangingCalendlyEventTypeVisibility) &&
                      'animate-pulse',
                  )}
                  key={eventType.calendlyEventTypeUri}
                >
                  <input
                    type="checkbox"
                    id={`eventType-${eventType.calendlyEventTypeUri}`}
                    name={eventType.name}
                    value={eventType.name}
                    checked={eventType.isVisible}
                    disabled={isChangingCalendlyEventTypeVisibility}
                    onChange={(e) => {
                      setErrorMessage(null);
                      changeCalendlyEventTypeVisibilityMutation({
                        variables: {
                          calendlyEventTypeUri: eventType.calendlyEventTypeUri,
                          shouldBeVisible: e.target.checked,
                        },
                      });
                    }}
                    className="h-4 w-4 cursor-pointer rounded border-neutral-100 text-green-125 focus:ring-green-125"
                  />
                  <label
                    htmlFor={`eventType-${eventType.calendlyEventTypeUri}`}
                    className="flex items-center text-caption text-green-150 hover:cursor-pointer"
                  >
                    <span
                      className="mr-3 ml-5 inline-block h-4 w-4 rounded-full"
                      style={{ backgroundColor: eventType.color }}
                    />
                    {eventType.name}
                  </label>
                </div>
              ),
            )}
          </form>
        </Transition>
      </Integration>
    </PageContainer>
  );
};

export default CalendlyIntegration;
