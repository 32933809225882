import { FC, useEffect, useRef, useState } from 'react';

import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ActivityType,
  ProgramActivityDataFragment,
  UserType,
  useCompleteProgramActivityMutation,
  useDismissProgramActivityMutation,
  useMarkCommentsReadMutation,
  useMarkProgramActivitiesSeenMutation,
  useProgramFollowUpQuery,
  useProgramFollowUpsQuery,
} from '../../../../generated/graphql';
import AudioPlayer from '../../../components/AudioPlayer';
import Avatar from '../../../components/Avatar';
import PatientChat from '../../../components/Comments/PatientChat';
import ProgramActivityCard from '../../../components/ProgramActivityCard';
import ProgramActivitySlideover from '../../../components/ProgramActivitySlideover';
import { defaultTransitionProps } from '../../../lib/animation';
import { getWeekdayDate } from '../../../lib/time';
import Spinner from '../../../svgs/Spinner';
import UnsupportedModal from './UnsupportedModal';
import IconButton from '../../../components/IconButton';
import {
  ArrowNarrowLeftIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/outline';
import Button from '../../../components/Button';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { usePatientAuth } from '../../../../contexts/PatientAuthContext';
import Esign from '../../../components/Esign';
import Linkifier from '../../../components/Linkifier';
import FollowUpSelector from '../../../components/FollowUpSelector';
import CompleteProgramAssessment from '../CompleteProgramAssessment';
import { getPatientNameAndInitials } from '../CompleteProgramAssessment/ProgramAssessmentForm/helpers';
import IllustrationMail from '../../../svgs/IllustrationMail';
import EmptySection from '../PatientHome/EmptySection';
import { getFirstName } from '../../../lib/copy';

const PatientFollowUpDetails: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const programFollowUpId = params.programFollowUpId;

  const { authedPatient } = usePatientAuth();
  const providerFirstName = getFirstName(authedPatient?.providerName);
  const { fullName: patientName } = getPatientNameAndInitials(authedPatient);

  const { data: programFollowUpsData } = useProgramFollowUpsQuery({
    variables: {
      programId: authedPatient?.programInstanceId,
    },
    skip: !authedPatient?.programInstanceId,
    fetchPolicy: 'network-only', // Enable cache updates
  });

  const {
    data: programFollowUpData,
    error: programFollowUpError,
    loading: programFollowUpLoading,
    refetch: refetchProgramFollowUp,
  } = useProgramFollowUpQuery({
    variables: {
      programFollowUpId,
    },
    skip: !programFollowUpId,
  });

  const programFollowUps = programFollowUpsData?.programFollowUps;
  const selectedProgramFollowUp = programFollowUpData?.programFollowUp;

  const noteText = selectedProgramFollowUp?.providerNote;
  const voiceNote = selectedProgramFollowUp?.providerVoiceNote;

  const [selectedProgramActivity, setSelectedProgramActivity] =
    useState<ProgramActivityDataFragment>();
  const [isProgramActivitySlideoverOpen, setIsProgramActivitySlideoverOpen] =
    useState<boolean>(false);

  const [isUnsupportedModalOpen, setUnsupportedModalOpen] = useState(false);
  const [assessmentProgramActivity, setAssessmentProgramActivity] =
    useState<ProgramActivityDataFragment | null>(null);
  const [esignProgramActivity, setEsignProgramActivity] =
    useState<ProgramActivityDataFragment | null>(null);

  const [markCommentsReadMutation] = useMarkCommentsReadMutation();
  const [markProgramActivitiesSeenMutation] =
    useMarkProgramActivitiesSeenMutation();

  const [completeProgramActivity] = useCompleteProgramActivityMutation();
  const [dismissProgramActivity] = useDismissProgramActivityMutation();

  const formattedTimestamp = getWeekdayDate(selectedProgramFollowUp?.createdAt);
  const formattedTimestampWithYear = getWeekdayDate(
    selectedProgramFollowUp?.createdAt,
    true,
  );

  const [isFollowUpsPanelOpen, setIsFollowUpsPanelOpen] = useState(false);
  const followUpsPanelRef = useRef();

  useOnClickOutside(followUpsPanelRef, () => setIsFollowUpsPanelOpen(false));

  // Direct to latest follow up if none is selected
  useEffect(() => {
    if (!programFollowUpId && programFollowUps?.length) {
      const latestProgramFollowUpId = programFollowUps[0].id;
      navigate(latestProgramFollowUpId);
    }
  }, [programFollowUps, programFollowUpId]);

  useEffect(() => {
    const markFollowUpCommentsRead = async () => {
      if (selectedProgramFollowUp) {
        await markCommentsReadMutation({
          variables: {
            input: {
              programFollowUpId: selectedProgramFollowUp.id,
            },
          },
          update: (cache, { data }) => {
            cache.modify({
              id: cache.identify(selectedProgramFollowUp),
              fields: {
                unreadCommentCount: () => 0,
              },
            });
          },
        });
      }
    };

    markFollowUpCommentsRead();
  }, [selectedProgramFollowUp]);

  useEffect(() => {
    const markFollowUpActivitiesSeen = async () => {
      if (selectedProgramFollowUp) {
        await markProgramActivitiesSeenMutation({
          variables: {
            programActivityIds: selectedProgramFollowUp.programActivities.map(
              (programActivity) => programActivity.id,
            ),
          },
        });
      }
    };

    markFollowUpActivitiesSeen();
  }, [selectedProgramFollowUp]);

  if (assessmentProgramActivity) {
    return (
      <CompleteProgramAssessment
        authedPatient={authedPatient}
        assessmentProgramActivity={assessmentProgramActivity}
        onBack={() => setAssessmentProgramActivity(null)}
        onComplete={async () => {
          setAssessmentProgramActivity(null);
          await refetchProgramFollowUp();
        }}
      />
    );
  }

  if (selectedProgramFollowUp && esignProgramActivity && authedPatient) {
    return (
      <>
        <div className="flex w-full flex-row items-center justify-between border-b border-neutral-50 bg-white py-2 px-20">
          <IconButton
            IconComponent={ArrowNarrowLeftIcon}
            iconClassName="text-secondary-100 w-6"
            aria-label="Back"
            onClick={() => {
              setEsignProgramActivity(null);
            }}
          >
            <div className="small-caption ml-3 font-bold text-secondary-100">
              Back
            </div>
          </IconButton>
        </div>
        <Esign
          programActivity={esignProgramActivity}
          providerName={selectedProgramFollowUp.sentByName}
          providerEmail={selectedProgramFollowUp.sentByEmail}
          patient={authedPatient}
          onComplete={async () => {
            await refetchProgramFollowUp();
          }}
        />
      </>
    );
  }

  const handleOpenProgramActivitySlideover = (
    programActivity: ProgramActivityDataFragment,
  ) => {
    if (
      programActivity.activityType === ActivityType.Assessment &&
      !programActivity.completedAt &&
      !programActivity.programAssessment?.programAssessmentSignatureUsers &&
      !programActivity.voidedAt
    ) {
      setAssessmentProgramActivity(programActivity);
    } else if (
      programActivity.activityType === ActivityType.Esign &&
      !programActivity.completedAt
    ) {
      setEsignProgramActivity(programActivity);
    } else {
      setSelectedProgramActivity(programActivity);
      setIsProgramActivitySlideoverOpen(true);
    }
  };

  const hasProgramFollowUps = programFollowUps?.length > 0;

  return (
    <>
      <Transition
        show={Boolean(programFollowUps && !programFollowUpError)}
        {...defaultTransitionProps}
        className="sm:px-16 sm:pt-8 md:h-[calc(100vh-var(--top-nav-height))] md:overflow-y-auto"
      >
        {hasProgramFollowUps ? (
          <div className="flex h-full flex-row">
            {/* Panel mode for small screens */}
            <div
              className={classNames(
                'absolute z-40 flex min-w-[35px] max-w-[200px] flex-col border-r border-neutral-75 bg-white pt-4 transition-all duration-500 ease-in-out sm:hidden',
                isFollowUpsPanelOpen ? 'w-[200px]' : 'w-0',
              )}
              ref={followUpsPanelRef}
            >
              <div className="absolute -right-14 z-50">
                <Button
                  size="small"
                  type="button"
                  theme="alt"
                  title="All mail"
                  IconComponent={
                    isFollowUpsPanelOpen
                      ? ChevronDoubleLeftIcon
                      : ChevronDoubleRightIcon
                  }
                  iconPosition="left"
                  iconClassName="text-neutral-125"
                  className="rounded-full bg-white px-2 py-1 text-small-caption shadow-100"
                  circular
                  onClick={() => setIsFollowUpsPanelOpen(!isFollowUpsPanelOpen)}
                />
              </div>

              <div
                className={classNames(
                  'h-screen w-full flex-col items-center justify-between overflow-y-scroll p-4 transition-all duration-1000 ease-in-out',
                  isFollowUpsPanelOpen ? 'opacity-100' : 'opacity-0',
                )}
              >
                {isFollowUpsPanelOpen && (
                  <>
                    <div className="mb-3 font-serif text-subtitle-small font-light md:text-subtitle">
                      Mail
                    </div>
                    {programFollowUps?.map((programFollowUp) => (
                      <FollowUpSelector
                        key={`followUpSelector-${programFollowUp.id}`}
                        id={programFollowUp.id}
                        isIntake={programFollowUp.isIntake}
                        subject={programFollowUp.subject}
                        providerNote={programFollowUp.providerNote}
                        createdAt={programFollowUp.createdAt}
                        unreadCommentCount={programFollowUp.unreadCommentCount}
                        isSelected={programFollowUpId === programFollowUp.id}
                        onClick={() => {
                          navigate(`/client/follow-ups/${programFollowUp.id}`);
                          setIsFollowUpsPanelOpen(false);
                        }}
                      />
                    ))}
                  </>
                )}
              </div>
            </div>

            {/* Desktop mode */}
            <div
              className={classNames(
                'hidden h-full w-[200px] shrink-0 grow-0 flex-col border-r border-neutral-75 pr-4 sm:flex',
              )}
            >
              <div className="mb-4 font-serif text-subtitle-small font-light md:text-subtitle">
                Mail
              </div>
              <div className="h-full overflow-hidden overflow-y-auto">
                {programFollowUps?.map((programFollowUp) => (
                  <FollowUpSelector
                    key={`followUpSelector-${programFollowUp.id}`}
                    id={programFollowUp.id}
                    isIntake={programFollowUp.isIntake}
                    subject={programFollowUp.subject}
                    providerNote={programFollowUp.providerNote}
                    createdAt={programFollowUp.createdAt}
                    unreadCommentCount={programFollowUp.unreadCommentCount}
                    isSelected={programFollowUpId === programFollowUp.id}
                    onClick={() =>
                      navigate(`/client/follow-ups/${programFollowUp.id}`)
                    }
                  />
                ))}
              </div>
            </div>
            {selectedProgramFollowUp ? (
              <div className="flex h-full w-full flex-col pl-8 sm:pl-0 lg:flex-row">
                <div className="flex h-full flex-auto flex-col shadow-none">
                  <div className="px-6 pt-16 sm:pt-0">
                    <div className="mb-4 flex flex-row items-center justify-between gap-x-6 sm:mb-6 sm:mt-2">
                      <div className="break-inside-avoid font-serif text-subtitle-small font-light">
                        {selectedProgramFollowUp.subject ||
                          formattedTimestampWithYear}
                      </div>
                      {selectedProgramFollowUp.subject && (
                        <div className="whitespace-nowrap text-small-caption font-light text-neutral-110">
                          {formattedTimestamp}
                        </div>
                      )}
                    </div>
                    <div className="mb-4 flex flex-row">
                      <Avatar
                        size="medium"
                        imageUrl={selectedProgramFollowUp.sentByProfileImageUrl}
                        name={selectedProgramFollowUp.sentByName ?? ''}
                      />
                      <div className="ml-2 flex flex-col justify-center text-caption font-bold text-neutral-125">
                        {selectedProgramFollowUp.sentByName}
                      </div>
                    </div>
                  </div>
                  <div className="flex h-full w-full flex-col px-6 md:overflow-y-auto md:overscroll-y-contain">
                    {voiceNote && (
                      <AudioPlayer
                        playerId={voiceNote.id}
                        duration={voiceNote.duration}
                        mediaUrl={voiceNote.media.url}
                        className="mb-4"
                        triggerStop
                      />
                    )}
                    {noteText && (
                      <div className="mb-6 whitespace-pre-wrap break-words text-caption">
                        <Linkifier text={noteText} />
                      </div>
                    )}
                    {!noteText && !voiceNote && (
                      <div className="mb-6 flex w-full items-center justify-center whitespace-pre-wrap rounded-lg border border-neutral-75 p-4 text-caption font-medium text-neutral-100">
                        No note provided
                      </div>
                    )}
                    <div className="flex flex-col gap-y-4">
                      {selectedProgramFollowUp.programActivities.map(
                        (programActivity, index) => (
                          <ProgramActivityCard
                            key={`programActivityCard_${index}`}
                            userType={UserType.Patient}
                            programActivity={programActivity}
                            onClickProgramActivity={
                              handleOpenProgramActivitySlideover
                            }
                            className={classNames(
                              index ===
                                selectedProgramFollowUp.programActivities
                                  .length -
                                  1 && 'mb-6',
                            )}
                          />
                        ),
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full border-neutral-75 lg:w-[400px] lg:shrink-0 lg:grow-0 lg:border-l">
                  <div className="relative">
                    <div className="mb-4 mt-[13px] pl-6 text-body font-medium text-neutral-150 lg:mb-0">
                      Comments
                    </div>
                    <div className="mx-6 h-full rounded-lg border border-neutral-75 lg:m-0 lg:border-0">
                      <PatientChat
                        comments={selectedProgramFollowUp.comments}
                        chatHeader={null}
                        programFollowUpId={selectedProgramFollowUp.id}
                        onCommentAdded={() => refetchProgramFollowUp()}
                        onCommentDeleted={() => refetchProgramFollowUp()}
                        conversationContainerClassName="max-h-[300px] md:max-h-[calc(100vh-14rem)]"
                        autoFocusChat={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="px-6 text-neutral-125">No mail selected</div>
            )}
          </div>
        ) : (
          <Transition show className="mt-10 w-full" {...defaultTransitionProps}>
            <EmptySection
              IconComponent={IllustrationMail}
              iconClassName="text-blue-100"
              titleText="No mail yet"
              subTitleText={`Keep an eye out for mail from ${providerFirstName}`}
            />
          </Transition>
        )}
      </Transition>
      <ProgramActivitySlideover
        isOpen={isProgramActivitySlideoverOpen}
        onClose={() => setIsProgramActivitySlideoverOpen(false)}
        programActivity={selectedProgramActivity}
        userType={UserType.Patient}
        onMarkComplete={async () => {
          if (selectedProgramActivity) {
            await completeProgramActivity({
              variables: {
                input: {
                  programActivityId: selectedProgramActivity.id,
                },
              },
            });

            refetchProgramFollowUp();
            setIsProgramActivitySlideoverOpen(false);
          }
        }}
        onDismiss={async () => {
          if (selectedProgramActivity) {
            await dismissProgramActivity({
              variables: {
                input: {
                  programActivityId: selectedProgramActivity.id,
                },
              },
            });

            refetchProgramFollowUp();
            setIsProgramActivitySlideoverOpen(false);
          }
        }}
        onSubmitPdf={() => {
          refetchProgramFollowUp();
          setIsProgramActivitySlideoverOpen(false);
        }}
        patientName={patientName}
      />
      <UnsupportedModal
        isOpen={isUnsupportedModalOpen}
        setClosed={() => setUnsupportedModalOpen(false)}
      />
      {programFollowUpLoading && <Spinner className="mx-auto mt-8" />}
    </>
  );
};

export default PatientFollowUpDetails;
