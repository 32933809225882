import { OnboardingStepName } from '../types/onboarding';

export const storeItem = <T>(key: string, value: T): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const retrieveItem = <T>(key: string): T | null => {
  const item = localStorage.getItem(key);
  if (!item) {
    return null;
  }
  return JSON.parse(item);
};

export const clearItem = (key: string): void => {
  localStorage.removeItem(key);
};

const userIdKey = (userId: string, key: string) => `${userId}:${key}`;

export const storeUserItem = <T>(
  userId: string,
  key: string,
  value: T,
): void => {
  storeItem(userIdKey(userId, key), value);
};

export const retrieveUserItem = <T>(userId: string, key: string): T | null => {
  return retrieveItem(userIdKey(userId, key));
};

const clearUserItem = (userId: string, key: string): void => {
  clearItem(userIdKey(userId, key));
};

export const markOnboardingStepCompleted = (
  userEmail: string,
  onboardingStep: OnboardingStepName,
): void => {
  storeUserItem(userEmail, onboardingStep, true);
};

export const getOnboardingStepCompleted = (
  userEmail: string,
  onboardingStep: OnboardingStepName,
): boolean => {
  return retrieveUserItem<boolean>(userEmail, onboardingStep) ?? false;
};

export const clearOnboardingStepCompleted = (
  userId: string,
  onboardingStep: OnboardingStepName,
): void => {
  clearUserItem(userId, onboardingStep);
};

export const HAS_DISMISSED_BOOK_A_CALL = 'hasDismissedBookACall';
export const HAS_DISMISSED_CALENDAR_SETUP = 'hasDismissedCalendarSetup';
export const HAS_DISMISSED_SCHEDULE_SETUP = 'hasDismissedScheduleSetup';
export const HAS_DISMISSED_PAYMENTS_SETUP = 'hasDismissedPaymentsSetup';
export const HAS_DISMISSED_ADD_CALENDLY_CLIENTS_SETUP =
  'hasDismissedCalendlyClientsSetup';
export const HAS_DISMISSED_STOREFRONT_SETUP = 'hasDismissedStorefrontSetup';
export const HAS_DISMISSED_SERVICE_SETUP = 'hasDismissedServiceSetup';
export const HAS_DISMISSED_APPEARANCE_SETUP = 'hasDismissedAppearanceSetup';

export const HAS_DISMISSED_CHAT_NUDGE = 'hasDismissedChatNudge';

export const PATIENT_MANAGEMENT_COLUMN_ORDER = 'patientManagementColumnOrder';
