import { FC, useState } from 'react';

import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import { UploadIcon } from '@heroicons/react/outline';

import {
  MePatientDataFragment,
  ProgramActivityDataFragment,
  useCompleteProgramActivityMutation,
} from '../../../generated/graphql';

import Spinner from '../../svgs/Spinner';
import { NAME_REGEX } from '../../lib/regex';
import { getFirstName } from '../../lib/copy';
import { USER_LOCAL_TIMEZONE } from '../../lib/time';

import Button from '../Button';
import InputGroup from '../InputGroup';
import SubmissionSuccessful from '../SubmissionSuccessful';

interface EsignFormData {
  signatureName: string;
  signatureTosAgreement: boolean;
}

const Esign: FC<{
  programActivity: ProgramActivityDataFragment;
  providerName: string;
  providerEmail: string;
  patient: MePatientDataFragment;
  onComplete?: () => Promise<void>;
  patientHomeMode?: boolean;
}> = ({
  programActivity,
  providerName,
  providerEmail,
  patient,
  onComplete,
  patientHomeMode = false,
}) => {
  const [completeProgramActivity] = useCompleteProgramActivityMutation();

  const [hasBeenSigned, setHasBeenSigned] = useState<boolean>(false);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors: validationErrors, isSubmitting },
    clearErrors,
  } = useForm<EsignFormData>({
    mode: 'onSubmit',
    defaultValues: {
      signatureName: '',
      signatureTosAgreement: false,
    },
  });

  const signatureName = watch('signatureName');
  const signatureTosAgreement = watch('signatureTosAgreement');

  const isSignButtonEnabled = Boolean(signatureName) && signatureTosAgreement;

  const signAndSubmit = async (formData: EsignFormData) => {
    clearErrors();
    try {
      await completeProgramActivity({
        variables: {
          input: {
            programActivityId: programActivity.id,
            signatureData: {
              signature: formData.signatureName,
              signatureTosAgreement: formData.signatureTosAgreement,
              signatureDate: new Date(),
            },
          },
        },
      });

      setHasBeenSigned(true);

      onComplete?.();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="h-screen p-16">
      {programActivity.completedAt || hasBeenSigned ? (
        <div className="flex justify-center">
          <SubmissionSuccessful
            messageOverride={`${getFirstName(
              providerName,
            )} has received the file you signed. ${
              !patientHomeMode
                ? 'You can go back to your follow-up now.'
                : 'You will be redirected back to your home page now.'
            } `}
          />
        </div>
      ) : (
        <div className="flex flex-col lg:h-full lg:flex-row-reverse">
          <div className="lg:h-full lg:flex-grow">
            <div className="mb-6 pb-5 lg:hidden">
              <div className="font-serif text-subtitle-small text-secondary-150">
                {patient.firstName}, please read and sign “
                {programActivity.name}”
              </div>
            </div>
            <div className="mb-4">
              <div className="font-caption mb-1 flex flex-row text-secondary-150">
                <div className="min-w-[64px] font-medium">From:</div>
                <div>
                  {providerName} ({providerEmail})
                </div>
              </div>
              <div className="font-caption flex flex-row text-secondary-150">
                <div className="min-w-[64px] font-medium">To:</div>
                <div>
                  {patient.name} ({patient.email})
                </div>
              </div>
            </div>
            <object
              data={programActivity.activityPDF?.media?.url}
              aria-label="Document to sign"
              className="h-[600px] w-full"
            />
          </div>

          <div className="flex flex-col lg:w-2/5 lg:pr-16">
            <form>
              <div className="divide-y divide-neutral-75">
                <div className="mb-6 hidden pb-5 lg:block">
                  <div className="font-serif text-subtitle-small text-secondary-150">
                    {patient.firstName}, please read and sign “
                    {programActivity.name}”
                  </div>
                </div>

                <div className="py-6">
                  <div className="mb-2 text-caption font-bold uppercase text-secondary-100">
                    Step 1
                  </div>
                  <div className="mb-2 text-body font-medium text-secondary-150">
                    Read {programActivity.name}
                  </div>
                  <div className="text-caption text-neutral-125">
                    Read the document displayed, in full.
                  </div>
                </div>

                <div className="py-6">
                  <div className="mb-2 text-caption font-bold uppercase text-secondary-100">
                    Step 2
                  </div>
                  <div className="mb-5 text-body font-medium text-secondary-150">
                    Type your name here to sign electronically.
                  </div>

                  <InputGroup
                    type="text"
                    placeholder="First Last"
                    required
                    maxLength={100}
                    errorMessage={validationErrors.signatureName?.message}
                    {...register('signatureName', {
                      required: 'Signature is required',
                      pattern: {
                        value: NAME_REGEX,
                        message: 'Please enter your first and last name',
                      },
                    })}
                  />
                  {/* TODO: Render error message... for what though? */}

                  {Boolean(signatureName) && (
                    <div className="pt-6">
                      <div className="mb-6 whitespace-normal font-cursive text-title font-normal text-secondary-150">
                        {signatureName}
                      </div>
                      <div className="mb-6 text-caption text-neutral-125">
                        {DateTime.now()
                          .setZone(USER_LOCAL_TIMEZONE)
                          .toLocaleString({
                            weekday: 'long',
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                          })}
                      </div>
                      <div className="flex">
                        <input
                          id="signature-tos-agreement-input"
                          type="checkbox"
                          className="mr-2 h-5 w-5 cursor-pointer rounded border-neutral-100 text-secondary-125 focus:ring-secondary-125"
                          {...register('signatureTosAgreement')}
                        />
                        <label
                          className="cursor-pointer text-caption text-neutral-125"
                          htmlFor="signature-tos-agreement-input"
                        >
                          I agree to the electronic signature{' '}
                          <a
                            href="https://www.homecoming.health/terms-of-service"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-100"
                          >
                            terms of service
                          </a>
                          .
                        </label>
                      </div>
                    </div>
                  )}
                </div>
                <div className="py-6">
                  <Button
                    title="Sign and Submit"
                    theme="primary"
                    IconComponent={isSubmitting ? Spinner : UploadIcon}
                    onClick={handleSubmit(signAndSubmit)}
                    disabled={!isSignButtonEnabled || isSubmitting}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Esign;
