import {
  When,
  TimespanWhen,
  DateWhen,
  DatespanWhen,
} from '../../../../../generated/graphql';
import { DateTime } from 'luxon';

// Type guards
export function isTimespanWhen(when: When): when is TimespanWhen {
  return when.__typename === 'TimespanWhen';
}

export function isDateWhen(when: When): when is DateWhen {
  return when.__typename === 'DateWhen';
}

export function isDatespanWhen(when: When): when is DatespanWhen {
  return when.__typename === 'DatespanWhen';
}

export const whenToCalendarEvent = (when: When, baseEventProps: any) => {
  if (isTimespanWhen(when)) {
    return {
      ...baseEventProps,
      start: when.startTime,
      end: when.endTime,
      allDay: false,
    };
  }

  if (isDateWhen(when)) {
    return {
      ...baseEventProps,
      start: when.date,
      end: when.date,
      allDay: true,
    };
  }

  if (isDatespanWhen(when)) {
    return {
      ...baseEventProps,
      start: when.startDate,
      end: when.endDate,
      allDay: true,
    };
  }

  return null;
};

export const calendarEventToWhen = (event: any, timezone: string): When => {
  const isAllDay = event.allDay;

  if (isAllDay) {
    const startDate = event.start.toISOString().split('T')[0];
    const endDate = event.end?.toISOString().split('T')[0];

    if (endDate && startDate !== endDate) {
      const dateSpanWhen: DatespanWhen = {
        __typename: 'DatespanWhen',
        startDate,
        endDate,
      };
      return dateSpanWhen;
    } else {
      const dateWhen: DateWhen = {
        __typename: 'DateWhen',
        date: startDate,
      };
      return dateWhen;
    }
  }

  const timeSpanWhen: TimespanWhen = {
    __typename: 'TimespanWhen',
    startTime: event.start.toISOString(),
    endTime: event.end.toISOString(),
    startTimezone: timezone,
    endTimezone: timezone,
  };
  return timeSpanWhen;
};

export const formatWhen = (when: When): string => {
  if (isDateWhen(when)) {
    return DateTime.fromISO(when.date).toLocaleString(DateTime.DATE_FULL);
  }

  if (isDatespanWhen(when)) {
    const start = DateTime.fromISO(when.startDate);
    const end = DateTime.fromISO(when.endDate);
    return `${start.toLocaleString(DateTime.DATE_FULL)} - ${end.toLocaleString(
      DateTime.DATE_FULL,
    )}`;
  }

  if (isTimespanWhen(when)) {
    const startTime = DateTime.fromISO(when.startTime);
    const endTime = DateTime.fromISO(when.endTime);
    return `${startTime.toLocaleString(
      DateTime.DATETIME_FULL,
    )} - ${endTime.toLocaleString(DateTime.TIME_SIMPLE)}`;
  }

  return '';
};
