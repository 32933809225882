import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BlobProvider } from '@react-pdf/renderer';
import toast from 'react-hot-toast';

import {
  CheckCircleIcon,
  DownloadIcon,
  XCircleIcon,
} from '@heroicons/react/outline';

import {
  UserType,
  ActivityType,
  AssessmentSchemaType,
  ProgramActivityDataFragment,
  useProgramAssessmentLazyQuery,
} from '../../../generated/graphql';

import {
  AnalyticsComponent,
  trackProviderComponentViewedEvent,
} from '../../../lib/analytics';
import { handlePdfDownload } from '../../lib/pdf';
import { programActivityOpen } from '../../lib/programActivity';

import {
  ProgramActivityStatus,
  getProgramActivityStatus,
  ProgramActivityStatusOption,
} from '../ProgramActivityStatus';

import Button from '../Button';
import ToastAlert from '../ToastAlert';
import AudioPlayer from '../AudioPlayer';
import SlideoverSection from '../SlideoverSection';
import PdfSubmissionForm from '../PdfSubmissionForm';
import ScoreSection from '../Assessments/ScoreSection';
import { customToast } from '../ToastAlert/customToast';
import AddOrEditTextModal from '../Modals/AddOrEditTextModal';
import ResponsesSection from '../Assessments/ResponsesSection';
import QuestionsSection from '../Assessments/QuestionsSection';
import Slideover, { SLIDEOVER_TRANSITION_DURATION } from '../Slideover';
import ConfirmVoidActivityModal from '../Modals/ConfirmVoidActivityModal';
import InterpretationSection from '../Assessments/InterpretationSection';
import ResponseOptionsSection from '../Assessments/ResponseOptionsSection';

import ProgramActivityTitle from './ProgramActivityTitle';
import ProgramAssessmentPDF from './ProgramAssessmentPDF';
import { isProviderUserCurrentSigner } from '../ProgramActivityCard/helpers';

const ProgramActivitySlideover: FC<{
  onClose: () => void;
  isOpen: boolean;
  programActivity?: ProgramActivityDataFragment;
  onAssessmentOpen?: () => void;
  onMarkComplete?: () => void;
  onDismiss?: () => void;
  onSubmitPdf?: () => void;
  userType?: UserType;
  onVoided?: () => Promise<unknown>;
  authedProviderUserId?: string;
  patientName?: string;
}> = ({
  onClose,
  isOpen,
  programActivity,
  onAssessmentOpen,
  onMarkComplete,
  onDismiss,
  onSubmitPdf,
  userType = UserType.ProviderUser,
  onVoided,
  authedProviderUserId,
  patientName,
}) => {
  const navigate = useNavigate();

  const isProviderUser = userType === UserType.ProviderUser;

  const [shouldShowAssessmentDetails, setShouldShowAssessmentDetails] =
    useState<boolean>(false);
  const defaultOnAssessmentOpen = () => {
    setShouldShowAssessmentDetails(true);
  };
  const [isAddOrEditTextModalOpen, setIsAddOrEditTextModalOpen] =
    useState<boolean>(false);
  const [isConfirmVoidModalOpen, setIsConfirmVoidModalOpen] = useState(false);

  const [
    getProgramAssessment,
    {
      data: programAssessmentData,
      error: programAssessmentError,
      loading: isLoadingProgramAssessment,
    },
  ] = useProgramAssessmentLazyQuery();

  const programAssessment =
    programActivity?.programAssessment?.id ===
    programAssessmentData?.programAssessment?.id
      ? programAssessmentData?.programAssessment
      : undefined;

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setShouldShowAssessmentDetails(false);
      }, SLIDEOVER_TRANSITION_DURATION);
    }
  }, [isOpen]);

  useEffect(() => {
    if (programActivity) {
      if (programActivity.programAssessment?.id) {
        getProgramAssessment({
          variables: {
            programAssessmentId: programActivity.programAssessment.id,
          },
        });
      }
      trackProviderComponentViewedEvent(
        AnalyticsComponent.ProgramActivitySlideover,
        undefined,
        {
          activityType: programActivity.activityType,
        },
      );
    }
  }, [programActivity]);

  useEffect(() => {
    if (programAssessmentError) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to fetch assessment details."
          level="error"
        />
      ));
    }
  }, [programAssessmentError]);

  const onTextOpen = () => {
    setIsAddOrEditTextModalOpen(true);
  };

  const {
    onOpen = undefined,
    openText = undefined,
    openIcon = undefined,
    secondaryOnOpen = undefined,
    secondaryOpenText = undefined,
    secondaryOpenIcon = undefined,
  } = programActivity
    ? programActivityOpen(
        programActivity,
        onAssessmentOpen ?? defaultOnAssessmentOpen,
        onTextOpen,
      )
    : {};

  const allowCompletion = (programActivity: ProgramActivityDataFragment) =>
    onMarkComplete &&
    programActivity?.activityType !== ActivityType.Assessment &&
    programActivity?.activityType !== ActivityType.PdfForm &&
    programActivity?.activityType !== ActivityType.Esign &&
    programActivity?.isCompletable &&
    !programActivity?.completedAt;

  const allowDismissal = (programActivity: ProgramActivityDataFragment) =>
    onDismiss &&
    programActivity?.isCompletable &&
    !programActivity?.completedAt &&
    !programActivity?.dismissedAt &&
    !programActivity?.voidedAt;

  const programActivityStatus = getProgramActivityStatus(
    programActivity,
    userType,
  );

  const allowPdfDownload = (programActivity: ProgramActivityDataFragment) =>
    Boolean(programActivity?.programAssessment?.completedAt) &&
    programActivityStatus === ProgramActivityStatusOption.Completed &&
    Boolean(programAssessment);

  const isPdfFormWithSubmission =
    programActivity?.activityType === ActivityType.PdfForm &&
    programActivity?.pdfSubmission;

  const allowVoidForm =
    isProviderUser && programActivity?.isVoidable && !programActivity?.voidedAt;

  const isProviderCurrentSigner = isProviderUserCurrentSigner(
    programActivity,
    authedProviderUserId,
  );

  const isPatientCurrentSigner =
    !programActivity?.programAssessment?.currentSigner?.providerUser?.id &&
    !programActivity?.completedAt;

  const navigateToCompleteProviderForm = () => {
    navigate(`/provider-form/${programActivity?.programAssessment?.id}`, {
      state: {
        shouldNavigateBack: true,
      },
    });
  };

  const navigateToCompletePatientForm = () => {
    navigate(`/client/client-form/${programActivity?.id}`);
  };

  const isMultipleSig =
    programActivity?.programAssessment?.programAssessmentSignatureUsers
      ?.length > 1;

  const hideMultiUseButtons =
    (programActivity?.activityAudio && isProviderUser) ||
    programActivity?.voidedAt;

  return (
    <Slideover
      isOpen={isOpen}
      onClose={onClose}
      showContent={Boolean(programActivity) && !isLoadingProgramAssessment}
      titleComponent={
        programActivity && (
          <ProgramActivityTitle
            programActivity={programActivity}
            subtitle={
              shouldShowAssessmentDetails && programAssessment
                ? programAssessment.assessment.name
                : undefined
            }
          />
        )
      }
      backButtonActive={shouldShowAssessmentDetails}
      onBackButtonClick={() => setShouldShowAssessmentDetails(false)}
    >
      {/* Main slideover */}
      {programActivity && !shouldShowAssessmentDetails && (
        <div className="pb-6">
          {/* Buttons section */}
          {!hideMultiUseButtons && (
            <SlideoverSection className="gap-y-3">
              {isPdfFormWithSubmission ? (
                <PdfSubmissionForm
                  linkToken={programActivity?.pdfSubmission?.linkToken}
                  onSuccess={onSubmitPdf}
                />
              ) : (
                <>
                  {programActivity?.activityType ===
                    ActivityType.Assessment && (
                    <>
                      {isMultipleSig &&
                        isProviderCurrentSigner &&
                        isProviderUser && (
                          <Button
                            title="Complete & Sign"
                            onClick={navigateToCompleteProviderForm}
                            className="w-full"
                            theme="primary"
                            size="small"
                          />
                        )}
                      {isPatientCurrentSigner && !isProviderUser && (
                        <Button
                          title={`Complete${isMultipleSig ? ' & Sign' : ''}`}
                          onClick={navigateToCompletePatientForm}
                          className="w-full"
                          theme="primary"
                          size="small"
                        />
                      )}
                      {allowPdfDownload(programActivity) && (
                        <BlobProvider
                          document={
                            <ProgramAssessmentPDF
                              programAssessment={programAssessment}
                              isProviderUser={isProviderUser}
                            />
                          }
                        >
                          {({ blob, loading, error }) => {
                            return (
                              <Button
                                title={
                                  loading ? 'Generating PDF...' : 'Download PDF'
                                }
                                onClick={() =>
                                  handlePdfDownload(
                                    blob,
                                    programAssessment,
                                    patientName,
                                    () =>
                                      customToast.success(
                                        'PDF downloaded successfully',
                                      ),
                                  )
                                }
                                className="w-full"
                                disabled={loading || Boolean(error)}
                                size="small"
                                theme={onOpen ? 'secondary' : 'primary'}
                                IconComponent={DownloadIcon}
                              />
                            );
                          }}
                        </BlobProvider>
                      )}
                    </>
                  )}
                  {onOpen && (
                    <Button
                      title={openText}
                      onClick={onOpen}
                      className="w-full"
                      theme="primary"
                      size="small"
                      {...(openIcon && { IconComponent: openIcon })}
                    />
                  )}
                  {secondaryOnOpen && (
                    <Button
                      theme="secondary"
                      title={secondaryOpenText}
                      onClick={secondaryOnOpen}
                      className="w-full"
                      size="small"
                      {...(secondaryOpenIcon && {
                        IconComponent: secondaryOpenIcon,
                      })}
                    />
                  )}

                  {allowCompletion(programActivity) && (
                    <Button
                      theme={onOpen ? 'secondary' : 'primary'}
                      title="Mark complete"
                      size="small"
                      onClick={onMarkComplete}
                      className="w-full"
                      IconComponent={CheckCircleIcon}
                    />
                  )}
                  {allowDismissal(programActivity) && (
                    <Button
                      theme="secondary"
                      title="Revisit later"
                      size="small"
                      onClick={onDismiss}
                      className="w-full"
                    />
                  )}

                  {allowVoidForm && (
                    <Button
                      title="Void form"
                      theme="destructive"
                      size="small"
                      iconClassName="text-red-100"
                      IconComponent={XCircleIcon}
                      className="w-full"
                      onClick={() => setIsConfirmVoidModalOpen(true)}
                    />
                  )}
                </>
              )}
            </SlideoverSection>
          )}

          {programActivityStatus && (
            <SlideoverSection
              title="Status"
              titleClassName="mb-0"
              TitleRightComponent={
                <ProgramActivityStatus
                  programActivity={programActivity}
                  className="!flex text-small-caption"
                  userType={userType}
                />
              }
            />
          )}

          {/* Completed Assessment/Form specific sections */}
          {programAssessment && (
            <>
              {programAssessment.completedAt &&
                programAssessment.assessment.schemaType !==
                  AssessmentSchemaType.Custom && (
                  <ScoreSection
                    assessmentType={programAssessment.assessment.type}
                    title="Score"
                    completedAssessment={programAssessment}
                  />
                )}
              {Boolean(programAssessment.additionalThoughts) && (
                <SlideoverSection
                  title={
                    patientName ? `Note from ${patientName}` : 'Client note'
                  }
                  textContent={`"${programAssessment.additionalThoughts}"`}
                />
              )}
              <ResponsesSection
                title="Responses"
                programAssessment={programAssessment}
                questionFrame={
                  programAssessment.assessment.questionFrame ?? undefined
                }
              />
            </>
          )}

          {programActivity.providerNote && (
            <SlideoverSection
              title="Guidance"
              textContent={programActivity.providerNote}
            />
          )}

          {/* Assessment/Form specific description. In spec, goes after Guidance */}
          {programAssessment && isProviderUser && (
            <SlideoverSection
              title="About"
              textContent={programAssessment.assessment.description}
            />
          )}

          {/* Audio specific sections */}
          {programActivity.activityAudio && (
            <SlideoverSection title="Audio">
              <AudioPlayer
                playerId={
                  programActivity.activityAudio.id ?? 'provider-activity-player'
                }
                mediaUrl={programActivity.activityAudio.media.url}
                duration={programActivity.activityAudio.duration}
                triggerStop={!isOpen}
              />
            </SlideoverSection>
          )}
        </div>
      )}

      {/* Assessment details sub-slideover */}
      {programActivity && programAssessment && shouldShowAssessmentDetails && (
        <div>
          {programAssessment.assessment.instructions && (
            <SlideoverSection
              title="Instructions"
              textContent={programAssessment.assessment.instructions}
            />
          )}
          <InterpretationSection assessment={programAssessment.assessment} />
          <ResponseOptionsSection
            questions={programAssessment.assessment.questions}
          />
          <QuestionsSection
            questionFrame={programAssessment.assessment.questionFrame}
            questions={programAssessment.assessment.questions}
          />
        </div>
      )}
      <AddOrEditTextModal
        isModalOpen={isAddOrEditTextModalOpen}
        setClosed={() => setIsAddOrEditTextModalOpen(false)}
        selectedActivityText={programActivity?.activityText}
        viewOnly
      />

      <ConfirmVoidActivityModal
        programActivityId={programActivity?.id}
        isOpen={isConfirmVoidModalOpen}
        setClosed={() => {
          setIsConfirmVoidModalOpen(false);
        }}
        onVoided={onVoided}
      />
    </Slideover>
  );
};

export default ProgramActivitySlideover;
