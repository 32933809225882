import { FC } from 'react';
import { CheckIcon, PencilIcon, XIcon } from '@heroicons/react/outline';
import IconButton from './IconButton';
import classNames from 'classnames';
import Button from './Button';

const EditControls: FC<{
  isEditing: boolean;
  onEditClick: () => void;
  handleSaveChanges: () => void;
  handleDiscardChanges: () => void;
  useAbsolutePosition?: boolean;
}> = ({
  isEditing,
  onEditClick,
  handleSaveChanges,
  handleDiscardChanges,
  useAbsolutePosition = false,
}) => {
  return (
    <div
      className={classNames(useAbsolutePosition && 'absolute top-4 right-4')}
    >
      {!isEditing ? (
        <Button
          title="Edit your page"
          IconComponent={PencilIcon}
          theme="secondary"
          iconPosition="left"
          size="small"
          onClick={onEditClick}
        />
      ) : (
        <div className="flex flex-row items-center space-x-3">
          <div className="flex items-center justify-center rounded-full bg-neutral-25 px-3 py-1">
            <span className="text-small-caption text-neutral-125">
              Editing mode
            </span>
          </div>
          <IconButton
            IconComponent={CheckIcon}
            className="h-8 w-8 justify-center !rounded-full bg-secondary-75 drop-shadow-lg hover:bg-secondary-100"
            iconClassName="text-white h-6 w-6"
            aria-label="Save changes"
            onClick={handleSaveChanges}
          />
          <IconButton
            IconComponent={XIcon}
            className="h-8 w-8 justify-center !rounded-full bg-red-100 drop-shadow-lg hover:bg-red-125"
            iconClassName="text-white h-6 w-6"
            aria-label="Discard changes"
            onClick={handleDiscardChanges}
          />
        </div>
      )}
    </div>
  );
};

export default EditControls;
