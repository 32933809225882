import {
  ServiceIcon,
  PatientAccessLevel,
  ServiceTagsDataFragment,
} from '../../../generated/graphql';

export interface ServiceBuilderForm {
  isEnabled: boolean;
  isShownOnStorefront: boolean;
  name: string;
  serviceIcon: ServiceIcon;
  serviceIconColorUseCalendlyDefault: boolean;
  calendlyEventColor: string | null;
  slug: string;
  description: string;
  isPaid: boolean;
  price: number;
  duration: number;
  calendlyEventTypeUri: string | null;
  serviceTags: ServiceTagsDataFragment[];
  intakeFormAssessmentId: string;
  patientAccessLevel: PatientAccessLevel;
}

export const MAX_NAME_LENGTH = 50;
export const MAX_DESCRIPTION_LENGTH = 600;
