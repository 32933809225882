import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { colors } from '../../../../lib/colors';
import HomecomingLogo from './HomecomingLogo';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 12,
  },
  patientName: {
    fontSize: 12,
    color: colors.neutral[150],
    fontWeight: 500,
  },
  completedDate: {
    fontSize: 10,
    color: colors.neutral[125],
  },
});

interface HeaderProps {
  patientName: string | undefined;
  completedAtDateFormatted: string;
}

const Header: React.FC<HeaderProps> = ({
  patientName,
  completedAtDateFormatted,
}) => (
  <View style={styles.container}>
    <View>
      <Text style={styles.patientName}>{patientName}</Text>
      <Text style={styles.completedDate}>{completedAtDateFormatted}</Text>
    </View>
    <HomecomingLogo />
  </View>
);

export default Header;
