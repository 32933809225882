import { View, Text } from '@react-pdf/renderer';

import { colors } from '../../../../lib/colors';

const Score = ({
  score,
  categorization,
  color = 'neutral',
  hasPercentage = false,
}) => {
  const getColor = (colorName, type) => {
    const colorMap = {
      neutral: { outer: '#F5F5F5', middle: '#EAEAEA', default: '#DBD8D1' },
      'green-50': { outer: '#E6F4E6', middle: '#C2E5C4', default: '#8ADD8E' },
      'red-50': { outer: '#FDEAE6', middle: '#F9C3B6', default: '#F1785F' },
      'orange-50': { outer: '#FFF3E0', middle: '#FFE0B2', default: '#FCCC80' },
      'blue-50': { outer: '#E8EAFF', middle: '#C7CEFF', default: '#9EADFF' },
      'purple-50': { outer: '#F9E6FF', middle: '#F1BFFF', default: '#E98FFF' },
    };
    return colorMap[colorName]?.[type] || colorMap.neutral[type];
  };

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <View
        style={{
          width: 60,
          height: 60,
          borderRadius: 30,
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: 8,
          backgroundColor: getColor(color, 'outer'),
        }}
      >
        <View
          style={{
            width: 52,
            height: 52,
            borderRadius: 26,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: getColor(color, 'middle'),
          }}
        >
          <View
            style={{
              width: 44,
              height: 44,
              borderRadius: 22,
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: getColor(color, 'default'),
            }}
          >
            <Text
              style={{
                fontSize: hasPercentage ? 14 : 16,
                color: colors.neutral[150],
                fontFamily: 'sans',
                fontWeight: 400,
              }}
            >
              {score}
              {hasPercentage && '%'}
            </Text>
          </View>
        </View>
      </View>
      <Text
        style={{
          fontSize: 12,
          color: colors.neutral[150],
          fontFamily: 'sans',
          fontWeight: 400,
        }}
      >
        {categorization}
      </Text>
    </View>
  );
};

export default Score;
