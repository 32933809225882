import { FC } from 'react';

import { ProgramActivityDataFragment } from '../../../generated/graphql';

import { getTimeStamp } from '../../lib/time';
import { programActivityTitle } from '../../lib/programActivity';

import { ProgramActivityThumbnail } from '../ProgramActivityThumbnail';

const ProgramActivityTitle: FC<{
  programActivity: ProgramActivityDataFragment;
  subtitle?: string;
}> = ({ programActivity, subtitle }) => {
  const title = programActivityTitle(programActivity);

  return (
    <div className="flex items-center self-start pt-2">
      <ProgramActivityThumbnail
        programActivity={programActivity}
        className="mr-4 h-[60px] w-[60px]"
      />
      <div className="flex-col text-green-150">
        <div className="pr-4 text-big-category font-medium line-clamp-2">
          {title}
        </div>
        <div className="truncate text-caption text-neutral-125">
          {subtitle ? subtitle : getTimeStamp(programActivity.sentAt, false)}
        </div>
      </div>
    </div>
  );
};

export default ProgramActivityTitle;
