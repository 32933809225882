import { useMemo } from 'react';
import { logger } from '../../../../../lib/logger';
import { Resource } from '../components/types';

export const useDemoResources = () => {
  const resources = useMemo(() => {
    logger.debug('📅 useDemoResources recomputing memoized resources');

    return [
      {
        id: 'room1@demo.com',
        name: 'Conference Room A',
        email: 'room1@demo.com',
        building: 'Main Building',
        capacity: 12,
        floorName: 'First Floor',
        floorNumber: 1,
        floorSection: 'East Wing',
      },
      {
        id: 'room2@demo.com',
        name: 'Conference Room B',
        email: 'room2@demo.com',
        building: 'Main Building',
        capacity: 8,
        floorName: 'First Floor',
        floorNumber: 1,
        floorSection: 'West Wing',
      },
      {
        id: 'room3@demo.com',
        name: 'Meeting Room 1',
        email: 'room3@demo.com',
        building: 'Main Building',
        capacity: 4,
        floorName: 'Second Floor',
        floorNumber: 2,
        floorSection: 'North Wing',
      },
      {
        id: 'room4@demo.com',
        name: 'Meeting Room 2',
        email: 'room4@demo.com',
        building: 'Main Building',
        capacity: 6,
        floorName: 'Second Floor',
        floorNumber: 2,
        floorSection: 'South Wing',
      },
    ] as Resource[];
  }, []);

  return {
    resources,
    loading: false,
    error: null,
    refetch: () => Promise.resolve(),
  };
};
