/* eslint-disable @typescript-eslint/no-unused-vars */
/* TODO: Remove the above later... */
import { FC } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import {
  ClientSession,
  useSubmitClientSessionFormMutation,
} from '../../../generated/graphql';
import Button from '../../components/Button';
import InputGroup from '../../components/InputGroup';
import TextAreaGroup from '../../components/TextAreaGroup';
import Modal from '../../components/Modal';
import { customToast } from '../../components/ToastAlert/customToast';
import { PlusSmIcon, TrashIcon } from '@heroicons/react/outline';
import RadioOption from '../../components/RadioOption';
import { logger } from '../../../lib/logger';
import SelectMenu from '../../components/SelectMenu';

interface ViewClientSessionModalProps {
  clientSession: ClientSession;
  onClose: () => void;
}

interface ClientSessionFormData {
  date: string;
  startTime: string;
  endTime: string;
  status: string;
  isDosing: boolean;
  wasDenied: boolean;
  denyReason?: string;
  dosing: {
    administeredAt: string;
    substances: {
      name: string;
      amount: string;
      unit: string;
    }[];
    hasAdverseEvents: boolean;
    adverseEventDetails?: string;
  }[];
  notes?: string;
}

const UNIT_OPTIONS = ['mg', 'g', 'ml', 'µg'];

const ViewClientSessionModal: FC<ViewClientSessionModalProps> = ({
  clientSession,
  onClose,
}) => {
  const [submitForm, { loading: isSubmitting }] =
    useSubmitClientSessionFormMutation();

  const {
    control,
    register,
    watch,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm<ClientSessionFormData>({
    defaultValues: {
      date: new Date(clientSession.startTime).toISOString().split('T')[0],
      startTime: new Date(clientSession.startTime).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }),
      endTime: new Date(clientSession.endTime).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }),
      status: 'Completed',
      isDosing: false,
      wasDenied: false,
      notes: '',
      dosing: [
        {
          administeredAt: '',
          substances: [
            {
              name: '',
              amount: '',
              unit: 'mg',
            },
          ],
          hasAdverseEvents: false,
          adverseEventDetails: '',
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dosing',
  });

  const isDosing = watch('isDosing');
  const wasDenied = watch('wasDenied');

  const onSubmit = async (formData: ClientSessionFormData) => {
    try {
      // Format the data to match the expected JSON structure
      const formattedData = {
        date: formData.date,
        time: {
          start: formData.startTime,
          end: formData.endTime,
        },
        status: formData.status,
        isDosing: formData.isDosing,
        wasDenied: formData.wasDenied,
        ...(formData.wasDenied && { denyReason: formData.denyReason }),
        ...(formData.isDosing && {
          dosing: formData.dosing.map((dose) => ({
            administeredAt: dose.administeredAt,
            substances: dose.substances.map((substance) => ({
              name: substance.name,
              amount: parseFloat(substance.amount),
              unit: substance.unit,
            })),
            hasAdverseEvents: dose.hasAdverseEvents,
            ...(dose.hasAdverseEvents && {
              adverseEventDetails: dose.adverseEventDetails,
            }),
          })),
        }),
        notes: formData.notes || '',
      };

      logger.debug('🎯 formattedData', formattedData);

      // TODO: Remove this once we have the backend logic in place
      onClose();
      customToast.success('Session form submitted successfully');

      /*
      const response = await submitForm({
        variables: {
          input: {
            clientSessionId: clientSession.id,
            formType: ClientSessionFormType.Sb303V1,
            data: formattedData,
          },
        },
      });

      if (response.data?.submitClientSessionForm.success) {
        customToast.success('Session form submitted successfully');
        onClose();
      } else {
        // Handle validation errors from the backend
        const errors = response.data?.submitClientSessionForm.errors;
        if (errors?.length) {
          // Map backend errors to form fields if needed
          errors.forEach((error) => {
            if (error.field && error.message) {
              setError(error.field as keyof ClientSessionFormData, {
                type: 'server',
                message: error.message,
              });
            }
          });
          customToast.error('Please correct the errors in the form');
        }
      }*/
    } catch (err) {
      console.error('Error submitting form:', err);
      customToast.error('Failed to submit session form');
    }
  };

  return (
    <Modal name="ViewSession" isOpen={true} setClosed={onClose} width="medium">
      <form onSubmit={handleSubmit(onSubmit)} className="p-6">
        <div className="space-y-8">
          <div className="mb-6 text-center">
            <h2 className="font-serif text-subtitle text-green-150">
              Validate your session with {clientSession.patient.name}?
            </h2>
            <p className="text-caption text-neutral-125">
              Provide details for dosing or regular sessions to ensure
              regulatory compliance.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
            <InputGroup
              label="Date"
              useNaturalLettering
              type="date"
              {...register('date', { required: 'Date is required' })}
              errorMessage={errors.date?.message}
            />
            <InputGroup
              label="Start Time"
              useNaturalLettering
              type="time"
              {...register('startTime', { required: 'Start time is required' })}
              errorMessage={errors.startTime?.message}
            />
            <InputGroup
              label="End Time"
              useNaturalLettering
              type="time"
              {...register('endTime', { required: 'End time is required' })}
              errorMessage={errors.endTime?.message}
            />
          </div>

          <hr className="border-t border-neutral-100" />
          <Controller
            name="isDosing"
            control={control}
            render={({ field }) => (
              <div className="flex items-center gap-6">
                <span className="mt-2 min-w-[220px] font-medium">
                  Is this a dosing event?
                </span>
                <div className="flex items-center gap-4">
                  <RadioOption
                    id={`isDosing-yes`}
                    value="true"
                    checked={isDosing === true}
                    onChange={() => {
                      field.onChange(true);
                      if (fields.length === 0) {
                        append({
                          administeredAt: '',
                          substances: [
                            {
                              name: '',
                              amount: '',
                              unit: 'mg',
                            },
                          ],
                          hasAdverseEvents: false,
                          adverseEventDetails: '',
                        });
                      }
                    }}
                    labelContent="Yes"
                  />
                  <RadioOption
                    id={`isDosing-no`}
                    value="false"
                    checked={isDosing === false}
                    onChange={() => {
                      field.onChange(false);
                      remove();
                    }}
                    labelContent="No"
                  />
                </div>
              </div>
            )}
          />

          {isDosing && (
            <div className="space-y-6">
              {fields.map((field, index) => (
                <div key={field.id}>
                  <div className="mb-2 flex items-center justify-between">
                    <div className="small-caption text-xs font-semibold text-neutral-150">
                      Dose {index + 1}
                    </div>
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => remove(index)}
                        className="text-red-125 hover:text-red-150"
                      >
                        <TrashIcon
                          className={
                            'h-7 w-7 cursor-pointer rounded-full p-1 text-neutral-125 hover:bg-neutral-125 hover:text-white'
                          }
                        />
                      </button>
                    )}
                  </div>
                  <div className="space-y-4">
                    <div className="w-1/2">
                      <InputGroup
                        label="Administered at"
                        useNaturalLettering
                        type="time"
                        {...register(`dosing.${index}.administeredAt`, {
                          required: 'Administration time is required',
                        })}
                        errorMessage={
                          errors.dosing?.[index]?.administeredAt?.message
                        }
                      />
                    </div>
                    <Controller
                      name={`dosing.${index}.substances`}
                      control={control}
                      render={({ field: substancesField }) => (
                        <div className="space-y-4">
                          {substancesField.value.map((_, substanceIndex) => (
                            <div
                              key={substanceIndex}
                              className="grid grid-cols-1 gap-4 md:grid-cols-4"
                            >
                              <div className="space-y-2">
                                <InputGroup
                                  label="Substance"
                                  useNaturalLettering
                                  placeholder="Psilocybin"
                                  {...register(
                                    `dosing.${index}.substances.${substanceIndex}.name`,
                                  )}
                                />
                              </div>
                              <div className="space-y-2">
                                <InputGroup
                                  label="Amount"
                                  useNaturalLettering
                                  type="number"
                                  placeholder="0.0"
                                  {...register(
                                    `dosing.${index}.substances.${substanceIndex}.amount`,
                                  )}
                                />
                              </div>
                              <div className="space-y-2">
                                <SelectMenu
                                  label="Unit"
                                  fieldValue={watch(
                                    `dosing.${index}.substances.${substanceIndex}.unit`,
                                  )}
                                  onChange={(value) =>
                                    setValue(
                                      `dosing.${index}.substances.${substanceIndex}.unit`,
                                      value as string,
                                    )
                                  }
                                  fieldOptions={UNIT_OPTIONS}
                                  placeholder="Select unit"
                                />
                              </div>
                              <div className="flex items-center pt-6">
                                <button
                                  type="button"
                                  onClick={() => {
                                    const newSubstances = [
                                      ...substancesField.value,
                                    ];
                                    newSubstances.splice(substanceIndex, 1);
                                    substancesField.onChange(newSubstances);
                                  }}
                                  className={`text-red-125 hover:text-red-150 ${
                                    substanceIndex === 0 ? 'hidden' : ''
                                  }`}
                                >
                                  <TrashIcon
                                    className={
                                      'h-7 w-7 cursor-pointer rounded-full p-1 text-neutral-125 hover:bg-neutral-125 hover:text-white'
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                          ))}

                          <button
                            type="button"
                            onClick={() => {
                              substancesField.onChange([
                                ...substancesField.value,
                                { name: '', amount: '', unit: 'mg' },
                              ]);
                            }}
                            className="hover:text-green-175 flex items-center gap-1 text-caption text-green-150"
                          >
                            <span>Add substance</span>
                            <PlusSmIcon className="h-4 w-4" />
                          </button>
                        </div>
                      )}
                    />

                    <div className="mt-4">
                      <Controller
                        name={`dosing.${index}.hasAdverseEvents`}
                        control={control}
                        render={({ field }) => (
                          <div className="flex items-center gap-6">
                            <span className="mt-2 min-w-[220px] font-medium">
                              Adverse behavioral events?
                            </span>
                            <div className="flex items-center gap-4">
                              <RadioOption
                                id={`adverse-events-yes-${index}`}
                                value="true"
                                checked={field.value === true}
                                onChange={() => field.onChange(true)}
                                labelContent="Yes"
                              />
                              <RadioOption
                                id={`adverse-events-no-${index}`}
                                value="false"
                                checked={field.value === false}
                                onChange={() => field.onChange(false)}
                                labelContent="No"
                              />
                            </div>
                          </div>
                        )}
                      />

                      {watch(`dosing.${index}.hasAdverseEvents`) && (
                        <div className="mt-4">
                          <TextAreaGroup
                            label="Please provide details"
                            {...register(`dosing.${index}.adverseEventDetails`)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              <Button
                title="Add dosing event"
                size="small"
                onClick={() =>
                  append({
                    administeredAt: '',
                    substances: [
                      {
                        name: '',
                        amount: '',
                        unit: 'mg',
                      },
                    ],
                    hasAdverseEvents: false,
                    adverseEventDetails: '',
                  })
                }
                theme="secondary"
                IconComponent={PlusSmIcon}
              />
            </div>
          )}

          <hr className="border-t border-neutral-100" />

          <Controller
            name="wasDenied"
            control={control}
            render={({ field }) => (
              <div className="flex items-center gap-6">
                <span className="mt-2 min-w-[220px] font-medium">
                  Was the session denied?
                </span>
                <div className="flex items-center gap-4">
                  <RadioOption
                    id="session-denied-yes"
                    value="true"
                    checked={field.value === true}
                    onChange={() => field.onChange(true)}
                    labelContent="Yes"
                  />
                  <RadioOption
                    id="session-denied-no"
                    value="false"
                    checked={field.value === false}
                    onChange={() => field.onChange(false)}
                    labelContent="No"
                  />
                </div>
              </div>
            )}
          />

          {wasDenied && (
            <TextAreaGroup
              label="Please provide details"
              {...register('denyReason', {
                required: wasDenied
                  ? 'Reason is required when session is denied'
                  : false,
              })}
              errorMessage={errors.denyReason?.message}
            />
          )}

          <hr className="border-t border-neutral-100" />

          <TextAreaGroup label="Other notes" {...register('notes')} />

          <div className="flex justify-center">
            <Button
              title="Confirm session"
              disabled={isSubmitting}
              type="submit"
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ViewClientSessionModal;
