import { FC } from 'react';
import { marked } from 'marked';
import classNames from 'classnames';
import { $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown';
import { Control, Controller, UseFormRegister } from 'react-hook-form';

import {
  AssessmentAnswer,
  AssessmentQuestionType,
  FreeTextAssessmentQuestion,
  RichTextAssessmentQuestion,
  SignatureAssessmentQuestion,
  MultiSelectAssessmentQuestion,
  MultiSignatureAssessmentQuestion,
  MultipleChoiceAssessmentQuestion,
  ProgramAssessmentSignatureUserDataFragment,
  InitialsAssessmentQuestion,
} from '../../../generated/graphql';

import {
  isFreeTextAssessmentAnswer,
  isRichTextAssessmentAnswer,
  isSignatureAssessmentAnswer,
  isMultiSelectAssessmentAnswer,
  isMultipleChoiceAssessmentAnswer,
  isInitialsAssessmentAnswer,
} from '../../lib/assessments';

import markdownToTxt from '../../lib/markdown-to-txt';
import { ProgramAssessmentQuestion } from '../../lib/program-assessment';

import SignatureFlagName from '../../svgs/SignatureFlagName';

import Editor from '../../components/Editor';
import Checkbox from '../../components/Checkbox';
import InputGroup from '../../components/InputGroup';
import CompletedSignature from '../../components/CompletedSignature';

import { CompleteProviderFormData } from './helpers';
import MultiSignatureSection from './MultiSignatureSection';

const QuestionField: FC<{
  question: string;
  isStatementQuestion?: boolean;
}> = ({ question, isStatementQuestion = false }) => {
  return (
    <div
      className={classNames(
        'text-body',
        !isStatementQuestion && 'mb-4 border-b border-neutral-75 pb-1',
        isStatementQuestion && 'mt-4',
      )}
    >
      <div
        className="editor text-green-150"
        dangerouslySetInnerHTML={{ __html: marked(question) }}
      />
    </div>
  );
};

const Question: FC<{
  question: ProgramAssessmentQuestion;
  answer?: AssessmentAnswer;
  idx: number;
  register: UseFormRegister<CompleteProviderFormData>;
  control: Control<CompleteProviderFormData, unknown>;
  programAssessmentSignatureUsers: ProgramAssessmentSignatureUserDataFragment[];
  currentSigner: ProgramAssessmentSignatureUserDataFragment;
  signatureName: string;
  signatureRole: string;
  signatureTermsAgreed: boolean;
  nowFormattedTimestamp: string;
  formIsReadOnly: boolean;
}> = ({
  question,
  answer,
  idx,
  register,
  control,
  programAssessmentSignatureUsers,
  currentSigner,
  signatureName,
  signatureRole,
  signatureTermsAgreed,
  nowFormattedTimestamp,
  formIsReadOnly,
}) => {
  switch (question.questionType) {
    case AssessmentQuestionType.Statement:
      question = question as FreeTextAssessmentQuestion;
      return <QuestionField question={question.question} isStatementQuestion />;

    case AssessmentQuestionType.FreeText:
      question = question as FreeTextAssessmentQuestion;
      return (
        <div>
          <QuestionField question={question.question} />
          <InputGroup
            key={markdownToTxt(question.question)}
            label={question.question}
            defaultValue={
              answer && isFreeTextAssessmentAnswer(answer) ? answer.answer : ''
            }
            {...register(`answers.${idx}.value`, {
              required: question.isRequired,
            })}
            inputSize="extra-small"
            className="max-w-[398px]"
            labelHidden
          />
        </div>
      );

    case AssessmentQuestionType.RichText:
      question = question as RichTextAssessmentQuestion;
      return (
        <div>
          <QuestionField question={question.question} />
          <Controller
            control={control}
            name={`answers.${idx}.value`}
            defaultValue={
              answer && isRichTextAssessmentAnswer(answer) ? answer.answer : ''
            }
            rules={{
              required: {
                value: question.isRequired,
                message: 'Please provide a response',
              },
            }}
            render={({ field }) => {
              const { onChange, value } = field;
              return (
                <Editor
                  placeholder="Type your response..."
                  className="text-neutral-150"
                  initialContentMarkdown={value}
                  allowLinks={false}
                  onChange={(editorState) => {
                    editorState.read(() => {
                      onChange($convertToMarkdownString(TRANSFORMERS));
                    });
                  }}
                  onError={(error: Error) => {
                    console.error(error);
                  }}
                  readOnly={formIsReadOnly}
                />
              );
            }}
          />
        </div>
      );

    case AssessmentQuestionType.MultipleChoice:
      const multipleChoiceQuestion =
        question as MultipleChoiceAssessmentQuestion;
      const answerOptions = multipleChoiceQuestion.answerOptions;

      return (
        <div>
          <QuestionField question={multipleChoiceQuestion.question} />
          <Controller
            name={`answers.${idx}.value`}
            control={control}
            rules={{
              required: {
                value: multipleChoiceQuestion.isRequired,
                message: 'Please choose one of the options',
              },
            }}
            defaultValue={
              answer && isMultipleChoiceAssessmentAnswer(answer)
                ? answer.answer
                : undefined
            }
            render={({ field: { onChange, value } }) => (
              <>
                {answerOptions.map(({ answerOption }, index) => (
                  <Checkbox
                    key={index}
                    id={`answer-${idx}-${index}`}
                    type="radio"
                    className="mb-2"
                    value={answerOption}
                    checked={value === answerOption}
                    readOnly={formIsReadOnly}
                    onChange={() => onChange(answerOption)}
                    labelContent={
                      <div className="ml-2 text-caption">{answerOption}</div>
                    }
                  />
                ))}
              </>
            )}
          />
        </div>
      );

    case AssessmentQuestionType.MultiSelect:
      question = question as MultiSelectAssessmentQuestion;

      return (
        <div>
          <QuestionField question={question.question} />
          {question.answerOptions.map(({ answerOption }, index) => {
            return (
              <div key={index} className="mb-2">
                <Controller
                  name={`answers.${idx}.values`}
                  control={control}
                  rules={{
                    required: {
                      value: question.isRequired,
                      message: 'Please choose one at least one option',
                    },
                  }}
                  defaultValue={
                    answer && isMultiSelectAssessmentAnswer(answer)
                      ? answer.answers
                      : []
                  }
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      id={`answer-${idx}-${index}`}
                      value={answerOption}
                      checked={
                        Array.isArray(value) && value.includes(answerOption)
                      }
                      disabled={formIsReadOnly}
                      onChange={(e) => {
                        const newValue = e.target.checked
                          ? [...(value || []), answerOption]
                          : (value || []).filter((v) => v !== answerOption);
                        onChange(newValue);
                      }}
                      labelContent={
                        <div className="ml-2 text-caption">{answerOption}</div>
                      }
                    />
                  )}
                />
              </div>
            );
          })}
        </div>
      );

    case AssessmentQuestionType.Initials:
      question = question as InitialsAssessmentQuestion;

      const displayCompletedSignature =
        answer && isInitialsAssessmentAnswer(answer) && answer.signatureName;

      return (
        <div>
          <QuestionField question={question.question} />
          {displayCompletedSignature ? (
            <CompletedSignature
              signatureName={answer.signatureName}
              signatureRole={'Client'}
              signedAt={answer.signedAt}
              className="my-2"
            />
          ) : (
            <>
              <div className="flex flex-col items-start justify-start">
                <div className="mb-2 text-caption font-medium">
                  Click the box to add your initials
                </div>
                <InputGroup
                  IconLeft={SignatureFlagName}
                  iconLeftClassName="h-5 w-5 -ml-1"
                  containerClassName="mt-2 mb-1 w-[120px]"
                  className="cursor-default rounded-lg"
                  inputSize="extra-small"
                  disabled
                />
              </div>
            </>
          )}
        </div>
      );

    case AssessmentQuestionType.Signature:
      question = question as SignatureAssessmentQuestion;
      return (
        <div>
          <QuestionField question={question.question} />

          {answer && isSignatureAssessmentAnswer(answer) && (
            <CompletedSignature
              signatureName={answer.signatureName}
              signatureRole={'Client'}
              signedAt={answer.signedAt}
              className="my-2"
            />
          )}
          {!answer && (
            <>
              <div className="mb-3 text-caption font-medium">
                Type your name to sign electronically
              </div>
              <InputGroup
                placeholder="Client name"
                IconLeft={SignatureFlagName}
                containerClassName="mb-2 w-[398px]"
                className="rounded-lg pl-12"
                disabled
              />
            </>
          )}
        </div>
      );

    case AssessmentQuestionType.MultiSignature:
      question = question as MultiSignatureAssessmentQuestion;

      return (
        <div>
          <QuestionField question={question.question} />
          <MultiSignatureSection
            assessmentSignatureOrderIndices={
              question.assessmentSignatureOrderIndices
            }
            programAssessmentSignatureUsers={programAssessmentSignatureUsers}
            currentSigner={currentSigner}
            signatureTermsAgreed={signatureTermsAgreed}
            signatureRole={signatureRole}
            signatureName={signatureName}
            nowFormattedTimestamp={nowFormattedTimestamp}
          />
        </div>
      );
  }

  return <></>;
};

export default Question;
